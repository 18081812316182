import FolderCopy from '@mui/icons-material/FolderCopy';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function UserGrantItem({
    userGrant,
    producer,

    isSelected,
    onClick
}) {
    const navigate = useNavigate();

    return (<>
        <ListItem>
            <ListItemButton onClick={() => onClick && onClick(userGrant || producer)}
                sx={{
                    p: 2,
                    ...{ backgroundImage: (isSelected) && 'linear-gradient(90deg, #8FB954 0%, #D2D550 100%)' }
                }}
            >
                <ListItemIcon>
                    <FolderCopy />
                </ListItemIcon>
                <ListItemText
                    primary={(producer?.first_name && (producer?.first_name + ' ' + producer?.last_name))
                        || (producer?.business_name)
                        || (userGrant?.producer_description)
                        || (userGrant?.producer_email)}
                />
            </ListItemButton>
        </ListItem>
    </>)
}
