import { DOCUMENT_FORM_NAME_BALANCE_SHEET, DOCUMENT_FORM_NAME_DEBT_SCHEDULE, DOCUMENT_FORM_NAME_EQUIPMENT_INVENTORY, DOCUMENT_FORM_NAME_INCOME_STATEMENT, DOCUMENT_FORM_NAME_SCHEDULE_F } from "@datanac/datanac-api-toolkit";
import { UsersApiHelper } from "api/ApiHelper";
import { MODULE_FINANCIAL } from "components/Menu/NavigationMenu";
import { v4 as uuidv4 } from 'uuid';
import { DOCUMENT_FORM_NAME_LOAN_ORIGINATION_MEMO, OBJECT_NAME_LOAN_ORIGINATION_MEMO } from "./FinancialHelpers";
import { CONTAINER_NAME_BALANCE_SHEET_ASSETS_CURRENT, CONTAINER_NAME_BALANCE_SHEET_ASSETS_NONCURRENT, CONTAINER_NAME_BALANCE_SHEET_ASSETS_PERSONAL, CONTAINER_NAME_BALANCE_SHEET_EQUITY, CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_CURRENT, CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_NONCURRENT, CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_PERSONAL, CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_TAX, DOCUMENT_DICTIONARY_DEFAULT_VALUES_BALANCE_SHEET, ITEM_NAME_BALANCE_SHEET_ASSETS_BREEDING_LIVESTOCK, ITEM_NAME_BALANCE_SHEET_ASSETS_CASH, ITEM_NAME_BALANCE_SHEET_ASSETS_INVENTORIES_CROPS, ITEM_NAME_BALANCE_SHEET_ASSETS_MACHINERY_EQUIPMENT, ITEM_NAME_BALANCE_SHEET_ASSETS_REAL_ESTATE, OBJECT_NAME_BALANCE_SHEET } from "./forms/BalanceSheetHelper";
import { COLLATERAL_MARGIN_RATE_ARC_PLC, COLLATERAL_MARGIN_RATE_CROPS, COLLATERAL_MARGIN_RATE_EQUIPMENT, COLLATERAL_MARGIN_RATE_LIVESTOCK, COLLATERAL_MARGIN_RATE_REAL_ESTATE, CONTAINER_NAME_DEBT_SCHEDULE_CROPS, CONTAINER_NAME_DEBT_SCHEDULE_EQUIPMENT, CONTAINER_NAME_DEBT_SCHEDULE_LIVESTOCK, CONTAINER_NAME_DEBT_SCHEDULE_REAL_ESTATE, ITEM_NAME_DEBT_SCHEDULE_LIABILITY_CURRENT, ITEM_NAME_DEBT_SCHEDULE_LIABILITY_NONCURRENT, OBJECT_NAME_DEBT_SCHEDULE } from "./forms/DebtScheduleHelper";
import { ITEM_NAME_EQUIPMENT_INVENTORY_CROP, ITEM_NAME_EQUIPMENT_INVENTORY_GENERAL, ITEM_NAME_EQUIPMENT_INVENTORY_LIVESTOCK, ITEM_NAME_EQUIPMENT_INVENTORY_VEHICLES, OBJECT_NAME_EQUIPMENT_INVENTORY } from "./forms/EquipmentInventoryHelper";
import { CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_DEBT_SERVICE, CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_DEPRECIATION, CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_INTEREST, CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_LIVESTOCK, CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_LIVING_EXPENSES, CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_OTHER, CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_OTHER_OPERATIONS, CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_TAXES, CONTAINER_NAME_INCOME_STATEMENT_REVENUE_LIVESTOCK, CONTAINER_NAME_INCOME_STATEMENT_REVENUE_OTHER, CONTAINER_NAME_INCOME_STATEMENT_REVENUE_OTHER_OPERATIONS, DOCUMENT_DICTIONARY_DEFAULT_VALUES_INCOME_STATEMENT, OBJECT_NAME_INCOME_STATEMENT } from "./forms/IncomeStatementHelper";
import { KPI_DEBT_TO_EQUITY_RATIO, KPI_EQUITY_TO_ASSETS_RATIO, KPI_EXCESS_CASH_FLOW_RATIO, KPI_EXPECTED_LOSS, KPI_FICO, KPI_LOAN_TO_VALUE, KPI_PRIOR_YEAR_CARRY_OVER_RATIO, KPI_REVENUE_TO_PAYMENTS_RATIO, KPI_WORKING_CAPITAL_TO_GROSS_REVENUE_RATIO, calculateKpiGrade } from "./forms/KPIGrades";
import { CONTAINER_NAME_SCHEDULE_F_EXPENSES, CONTAINER_NAME_SCHEDULE_F_EXPENSES_NAMES, CONTAINER_NAME_SCHEDULE_F_HEADER, CONTAINER_NAME_SCHEDULE_F_INCOME, OBJECT_NAME_SCHEDULE_F } from "./forms/ScheduleFHelper";

export function FinancialService({
    apiConfig,

    year
}) {


    // --- --- ---

    const getBalanceSheet = async () => {
        return getBalanceSheet_Intern({ year });
    }

    const getBalanceSheetPriorYear = async () => {
        return getBalanceSheet_Intern({ year: year - 1 });
    }

    const getBalanceSheet_Intern = async ({ year }) => {
        const _documentsFilter = {
            year: year,
            document_type_name: DOCUMENT_FORM_NAME_BALANCE_SHEET,
            is_active: true,
        }
        const _documents = await UsersApiHelper.users_selectObject("FinancialDocument", _documentsFilter, apiConfig);
        const _documentDictionaryFilter = {
            year: year,
            module: MODULE_FINANCIAL,
            object: OBJECT_NAME_BALANCE_SHEET,
            is_active: true,
        }
        const _documentDictionary = await UsersApiHelper.selectDocumentDictionary(_documentDictionaryFilter, apiConfig);

        const _documentID = (_documents?.length) ? _documents[0].id : uuidv4();
        if (!_documents?.length) {
            const _financialDocument = {
                id: _documentID,
                year: year,
                document_type_name: DOCUMENT_FORM_NAME_BALANCE_SHEET,
                is_active: true,
            }
            // await UsersApiHelper.updateFinancialDocument(_financialDocument, apiConfig);
        }

        DOCUMENT_DICTIONARY_DEFAULT_VALUES_BALANCE_SHEET.forEach(v => {
            ensureDefaultValue(_documentDictionary, v, _documentID)
        });

        const _balanceSheet = hydrateBalanceSheetDocumentDictionaries(_documentDictionary);

        return _balanceSheet;
    }

    // --- --- ---

    const getIncomeStatement = async () => {
        return getIncomeStatement_Intern({ year });
    }

    const getIncomeStatement_Intern = async ({ year }) => {
        const _documentsFilter = {
            year: year,
            document_type_name: DOCUMENT_FORM_NAME_INCOME_STATEMENT,
            is_active: true,
        }
        const _documents = await UsersApiHelper.users_selectObject("FinancialDocument", _documentsFilter, apiConfig);
        const _documentDictionaryFilter = {
            year: year,
            module: MODULE_FINANCIAL,
            object: OBJECT_NAME_INCOME_STATEMENT,
            is_active: true,
        }
        const _documentDictionary = await UsersApiHelper.selectDocumentDictionary(_documentDictionaryFilter, apiConfig);

        const _documentID = (_documents?.length) ? _documents[0].id : uuidv4();
        if (!_documents?.length) {
            const _financialDocument = {
                id: _documentID,
                year: year,
                document_type_name: DOCUMENT_FORM_NAME_INCOME_STATEMENT,
                is_active: true,
            }
            // await UsersApiHelper.updateFinancialDocument(_financialDocument, apiConfig);
        }

        DOCUMENT_DICTIONARY_DEFAULT_VALUES_INCOME_STATEMENT.forEach(v => {
            ensureDefaultValue(_documentDictionary, v, _documentID)
        });

        _documentDictionary.forEach(current => {
            current._GROUP_KEY = JSON.stringify({
                year: current.year,
                section: current.section,
                module: current.module,
                object: current.object,
                container: current.container,
                item: current.item,
            });
        });
        const _documentDictionaryGroup = _(_documentDictionary)
            .groupBy("_GROUP_KEY")
            .map((currentGroup, id) => ({
                // Deserialize GROUP_KEY id object props:
                ...JSON.parse(id),
                // Individual values:
                document_id: currentGroup[0]?.document_id,
                id: currentGroup[0]?.id,
                // Aggregates:
                value:
                    _.toNumber(_.toNumber(
                        _.sumBy(currentGroup, item => Number(item.value) || 0)
                    )?.toFixed(2)),
            }))
            .value();

        const _incomeStatement = {
            id: _documentID,
            year: year,
            document_type_name: DOCUMENT_FORM_NAME_INCOME_STATEMENT,
            is_active: true,

            document_id: _documentID,

            revenue_livestock: {
                values: _documentDictionaryGroup.filter(dd =>
                    dd.object == OBJECT_NAME_INCOME_STATEMENT
                    && dd.container == CONTAINER_NAME_INCOME_STATEMENT_REVENUE_LIVESTOCK
                )
            },
            revenue_other_operations: {
                values: _documentDictionaryGroup.filter(dd =>
                    dd.object == OBJECT_NAME_INCOME_STATEMENT
                    && dd.container == CONTAINER_NAME_INCOME_STATEMENT_REVENUE_OTHER_OPERATIONS
                )
            },
            revenue_other: {
                values: _documentDictionaryGroup.filter(dd =>
                    dd.object == OBJECT_NAME_INCOME_STATEMENT
                    && dd.container == CONTAINER_NAME_INCOME_STATEMENT_REVENUE_OTHER
                )
            },

            //

            expenses_livestock: {
                values: _documentDictionaryGroup.filter(dd =>
                    dd.object == OBJECT_NAME_INCOME_STATEMENT
                    && dd.container == CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_LIVESTOCK
                )
            },
            expenses_other_operations: {
                values: _documentDictionaryGroup.filter(dd =>
                    dd.object == OBJECT_NAME_INCOME_STATEMENT
                    && dd.container == CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_OTHER_OPERATIONS
                )
            },
            expenses_other: {
                values: _documentDictionaryGroup.filter(dd =>
                    dd.object == OBJECT_NAME_INCOME_STATEMENT
                    && dd.container == CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_OTHER
                )
            },
            expenses_debt_service: {
                values: _documentDictionaryGroup.filter(dd =>
                    dd.object == OBJECT_NAME_INCOME_STATEMENT
                    && dd.container == CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_DEBT_SERVICE
                )
            },
            expenses_living_expenses: {
                values: _documentDictionaryGroup.filter(dd =>
                    dd.object == OBJECT_NAME_INCOME_STATEMENT
                    && dd.container == CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_LIVING_EXPENSES
                )
            },
            expenses_interest: {
                values: _documentDictionaryGroup.filter(dd =>
                    dd.object == OBJECT_NAME_INCOME_STATEMENT
                    && dd.container == CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_INTEREST
                )
            },
            expenses_depreciation: {
                values: _documentDictionaryGroup.filter(dd =>
                    dd.object == OBJECT_NAME_INCOME_STATEMENT
                    && dd.container == CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_DEPRECIATION
                )
            },
            expenses_taxes: {
                values: _documentDictionaryGroup.filter(dd =>
                    dd.object == OBJECT_NAME_INCOME_STATEMENT
                    && dd.container == CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_TAXES
                )
            },

            values: _documentDictionaryGroup
        };

        _incomeStatement.revenue = {
            values: [
                ..._incomeStatement.revenue_livestock.values,
                ..._incomeStatement.revenue_other_operations.values,
                ..._incomeStatement.revenue_other.values
            ]
        };
        _incomeStatement.expenses = {
            values: [
                ..._incomeStatement.expenses_livestock.values,
                ..._incomeStatement.expenses_other_operations.values,
                ..._incomeStatement.expenses_other.values,
                ..._incomeStatement.expenses_living_expenses.values,
                ..._incomeStatement.expenses_interest.values,
                ..._incomeStatement.expenses_debt_service.values,
                ..._incomeStatement.expenses_depreciation.values,
                ..._incomeStatement.expenses_taxes.values
            ]
        };

        _incomeStatement.revenue_livestock.total = _.sum(_incomeStatement.revenue_livestock?.values?.map(v => v.value));
        _incomeStatement.revenue_other_operations.total = _.sum(_incomeStatement.revenue_other_operations?.values?.map(v => v.value));
        _incomeStatement.revenue_other.total = _.sum(_incomeStatement.revenue_other?.values?.map(v => v.value));
        _incomeStatement.revenue.total = _.sum(_incomeStatement.revenue?.values?.map(v => v.value));

        _incomeStatement.expenses_livestock.total = _.sum(_incomeStatement.expenses_livestock?.values?.map(v => v.value));
        _incomeStatement.expenses_other_operations.total = _.sum(_incomeStatement.expenses_other_operations?.values?.map(v => v.value));
        _incomeStatement.expenses_other.total = _.sum(_incomeStatement.expenses_other?.values?.map(v => v.value));
        _incomeStatement.expenses_living_expenses.total = _.sum(_incomeStatement.expenses_living_expenses?.values?.map(v => v.value));
        _incomeStatement.expenses_interest.total = _.sum(_incomeStatement.expenses_interest?.values?.map(v => v.value));
        _incomeStatement.expenses_debt_service.total = _.sum(_incomeStatement.expenses_debt_service?.values?.map(v => v.value));
        _incomeStatement.expenses_depreciation.total = _.sum(_incomeStatement.expenses_depreciation?.values?.map(v => v.value));
        _incomeStatement.expenses_taxes.total = _.sum(_incomeStatement.expenses_taxes?.values?.map(v => v.value));
        _incomeStatement.expenses.total = _.sum(_incomeStatement.expenses?.values?.map(v => v.value));

        _incomeStatement?.revenue?.values?.forEach(v => {
            _incomeStatement[v?.item?.toLowerCase() + "_revenue"] = v.value;
            _incomeStatement.revenue[v?.item?.toLowerCase()] = v.value;
        });
        _incomeStatement?.expenses?.values?.forEach(v => {
            _incomeStatement[v?.item?.toLowerCase() + "_expense"] = v.value;
            _incomeStatement.expenses[v?.item?.toLowerCase()] = v.value;
        });

        return _incomeStatement;
    }

    // --- --- ---

    const getScheduleF = async () => {
        const _documentsFilter = {
            year: year,
            document_type_name: DOCUMENT_FORM_NAME_SCHEDULE_F,
            is_active: true,
        }
        const _documents = await UsersApiHelper.users_selectObject("FinancialDocument", _documentsFilter, apiConfig);
        const _documentDictionaryFilter = {
            year: year,
            module: MODULE_FINANCIAL,
            object: OBJECT_NAME_SCHEDULE_F,
            is_active: true,
        }
        const _documentDictionary = await UsersApiHelper.selectDocumentDictionary(_documentDictionaryFilter, apiConfig);

        const _documentID = (_documents?.length) ? _documents[0].id : uuidv4();
        if (!_documents?.length) {
            const _financialDocument = {
                id: _documentID,
                year: year,
                document_type_name: DOCUMENT_FORM_NAME_SCHEDULE_F,
                is_active: true,
            }
            // await UsersApiHelper.updateFinancialDocument(_financialDocument, apiConfig);
        }

        // Schedule F does not have default values.
        // DOCUMENT_DICTIONARY_DEFAULT_VALUES_SCHEDULE_F.forEach(v => {
        //     ensureDefaultValue(_documentDictionary, v, _documentID)
        // });

        _documentDictionary.forEach(current => {
            current._GROUP_KEY = JSON.stringify({
                year: current.year,
                section: current.section,
                module: current.module,
                object: current.object,
                container: current.container,
                item: current.item,
            });
        });
        const _documentDictionaryGroup = _(_documentDictionary)
            .groupBy("_GROUP_KEY")
            .map((currentGroup, id) => ({
                // Deserialize GROUP_KEY id object props:
                ...JSON.parse(id),
                // Individual values:
                document_id: currentGroup[0]?.document_id,
                id: currentGroup[0]?.id,
                // Aggregates:
                value:
                    _.toNumber(_.toNumber(
                        _.sumBy(currentGroup, item => Number(item.value) || 0)
                    )?.toFixed(2)) || currentGroup[0]?.value,
            }))
            .value();

        const _scheduleF = {
            id: _documentID,
            year: year,
            document_type_name: DOCUMENT_FORM_NAME_SCHEDULE_F,
            is_active: true,

            document_id: _documentID,

            header: {
                values: _documentDictionaryGroup.filter(dd =>
                    dd.object == OBJECT_NAME_SCHEDULE_F
                    && dd.container == CONTAINER_NAME_SCHEDULE_F_HEADER
                )
            },
            income: {
                values: _documentDictionaryGroup.filter(dd =>
                    dd.object == OBJECT_NAME_SCHEDULE_F
                    && dd.container == CONTAINER_NAME_SCHEDULE_F_INCOME
                )
            },
            expenses: {
                values: _documentDictionaryGroup.filter(dd =>
                    dd.object == OBJECT_NAME_SCHEDULE_F
                    && dd.container == CONTAINER_NAME_SCHEDULE_F_EXPENSES
                )
            },
            expenses_names: {
                values: _documentDictionaryGroup.filter(dd =>
                    dd.object == OBJECT_NAME_SCHEDULE_F
                    && dd.container == CONTAINER_NAME_SCHEDULE_F_EXPENSES_NAMES
                )
            },

            values: _documentDictionaryGroup
        };

        _scheduleF?.header?.values?.forEach(v => {
            _scheduleF.header[v?.item?.toLowerCase()] = v.value;
        });

        _scheduleF?.income?.values?.forEach(v => {
            _scheduleF.income[v?.item?.toLowerCase()] = v.value;
        });
        _scheduleF?.expenses?.values?.forEach(v => {
            _scheduleF.expenses[v?.item?.toLowerCase()] = v.value;
        });
        _scheduleF?.expenses_names?.values?.forEach(v => {
            _scheduleF.expenses_names[v?.item?.toLowerCase()] = v.value;
        });

        _scheduleF.income.total =
            (_scheduleF.income.income_resale || 0)
            + (_scheduleF.income.revenue_farm || 0)
            + (_scheduleF.income.patronage_dividends_taxable || 0)
            + (_scheduleF.income.program_payments_taxable || 0)
            + (_scheduleF.income.ccc_loans || 0)
            + (_scheduleF.income.ccc_loans_forfeited_taxable || 0)
            + (_scheduleF.income.insurance_disaster_proceeds_taxable || 0)
            + (_scheduleF.income.insurance_disaster_proceeds_deferred || 0)
            + (_scheduleF.income.custom_hire || 0)
            + (_scheduleF.income.other || 0)
            // Deprecated:
            + ((_scheduleF.income.livestock_resale_sales || 0) - (_scheduleF.income.livestock_resale_cost || 0))
            + (_scheduleF.income.livestock_sales || 0)
        _scheduleF.expenses.total = _.sum(_scheduleF.expenses?.values?.map(v => v.value));

        return _scheduleF;
    }

    // --- --- ---

    const getEquipmentInventory = async () => {
        const _documentsFilter = {
            year: year,
            document_type_name: DOCUMENT_FORM_NAME_EQUIPMENT_INVENTORY,
            is_active: true,
        }
        const _documents = await UsersApiHelper.users_selectObject("FinancialDocument", _documentsFilter, apiConfig);
        const _documentDictionaryFilter = {
            year: year,
            module: MODULE_FINANCIAL,
            object: OBJECT_NAME_EQUIPMENT_INVENTORY,
            is_active: true,
        }
        const _documentDictionary = await UsersApiHelper.selectDocumentDictionary(_documentDictionaryFilter, apiConfig);

        const _documentID = (_documents?.length) ? _documents[0].id : uuidv4();
        if (!_documents?.length) {
            const _financialDocument = {
                id: _documentID,
                year: year,
                document_type_name: DOCUMENT_FORM_NAME_EQUIPMENT_INVENTORY,
                is_active: true,
            }
            // await UsersApiHelper.updateFinancialDocument(_financialDocument, apiConfig);
        }

        // Equipment Inventory does not have default values.
        // DOCUMENT_DICTIONARY_DEFAULT_VALUES_SCHEDULE_F.forEach(v => {
        //     ensureDefaultValue(_documentDictionary, v, _documentID)
        // });

        _documentDictionary.forEach(current => {
            current._GROUP_KEY = JSON.stringify({
                year: current.year,
                section: current.section,
                module: current.module,
                object: current.object,
                container: current.container,
                item: current.item,
            });
        });
        const _documentDictionaryGroup = _(_documentDictionary)
            .groupBy("_GROUP_KEY")
            .map((currentGroup, id) => ({
                // Deserialize GROUP_KEY id object props:
                ...JSON.parse(id),
                // Individual values:
                document_id: currentGroup[0]?.document_id,
                id: currentGroup[0]?.id,
                // Aggregates:
                value:
                    _.toNumber(_.toNumber(
                        _.sumBy(currentGroup, item => Number(item.value) || 0)
                    )?.toFixed(2)) || currentGroup[0]?.value,
            }))
            .value();

        const _equipmentInventory = {
            id: _documentID,
            year: year,
            document_type_name: DOCUMENT_FORM_NAME_EQUIPMENT_INVENTORY,
            is_active: true,

            document_id: _documentID,

            vehicles: {
                values: _documentDictionaryGroup.filter(dd =>
                    dd.object == OBJECT_NAME_EQUIPMENT_INVENTORY
                    && dd.item == ITEM_NAME_EQUIPMENT_INVENTORY_VEHICLES
                )
            },
            crop: {
                values: _documentDictionaryGroup.filter(dd =>
                    dd.object == OBJECT_NAME_EQUIPMENT_INVENTORY
                    && dd.item == ITEM_NAME_EQUIPMENT_INVENTORY_CROP
                )
            },
            livestock: {
                values: _documentDictionaryGroup.filter(dd =>
                    dd.object == OBJECT_NAME_EQUIPMENT_INVENTORY
                    && dd.item == ITEM_NAME_EQUIPMENT_INVENTORY_LIVESTOCK
                )
            },
            general: {
                values: _documentDictionaryGroup.filter(dd =>
                    dd.object == OBJECT_NAME_EQUIPMENT_INVENTORY
                    && dd.item == ITEM_NAME_EQUIPMENT_INVENTORY_GENERAL
                )
            },

            values: _documentDictionaryGroup
        };

        _equipmentInventory.vehicles.total = _.sum(_equipmentInventory.vehicles?.values?.map(v => v.value));
        _equipmentInventory.crop.total = _.sum(_equipmentInventory.crop?.values?.map(v => v.value));
        _equipmentInventory.livestock.total = _.sum(_equipmentInventory.livestock?.values?.map(v => v.value));
        _equipmentInventory.general.total = _.sum(_equipmentInventory.general?.values?.map(v => v.value));

        _equipmentInventory.total = _equipmentInventory.vehicles.total
            + _equipmentInventory.crop.total
            + _equipmentInventory.livestock.total
            + _equipmentInventory.general.total;

        return _equipmentInventory;
    }

    // --- --- ---

    const getDebtSchedule = async () => {
        const _documentsFilter = {
            year: year,
            document_type_name: DOCUMENT_FORM_NAME_DEBT_SCHEDULE,
            is_active: true,
        }
        const _documents = await UsersApiHelper.users_selectObject("FinancialDocument", _documentsFilter, apiConfig);
        const _documentDictionaryFilter = {
            year: year,
            module: MODULE_FINANCIAL,
            object: OBJECT_NAME_DEBT_SCHEDULE,
            is_active: true,
        }
        const _documentDictionary = await UsersApiHelper.selectDocumentDictionary(_documentDictionaryFilter, apiConfig);

        const _documentID = (_documents?.length) ? _documents[0].id : uuidv4();
        if (!_documents?.length) {
            const _financialDocument = {
                id: _documentID,
                year: year,
                document_type_name: DOCUMENT_FORM_NAME_DEBT_SCHEDULE,
                is_active: true,
            }
            // await UsersApiHelper.updateFinancialDocument(_financialDocument, apiConfig);
        }

        // Debt Schedule does not have default values.
        // DOCUMENT_DICTIONARY_DEFAULT_VALUES_DEBT_SCHEDULE.forEach(v => {
        //     ensureDefaultValue(_documentDictionary, v, _documentID)
        // });

        _documentDictionary.forEach(current => {
            current._GROUP_KEY = JSON.stringify({
                year: current.year,
                section: current.section,
                module: current.module,
                object: current.object,
                container: current.container,
                item: current.item,
            });
        });
        const _documentDictionaryGroup = _(_documentDictionary)
            .groupBy("_GROUP_KEY")
            .map((currentGroup, id) => ({
                // Deserialize GROUP_KEY id object props:
                ...JSON.parse(id),
                // Individual values:
                document_id: currentGroup[0]?.document_id,
                id: currentGroup[0]?.id,
                // Aggregates:
                value:
                    _.toNumber(_.toNumber(
                        _.sumBy(currentGroup, item => Number(item.value) || 0)
                    )?.toFixed(2)) || currentGroup[0]?.value,
            }))
            .value();

        const _debtSchedule = {
            id: _documentID,
            year: year,
            document_type_name: DOCUMENT_FORM_NAME_DEBT_SCHEDULE,
            is_active: true,

            document_id: _documentID,

            crops: {
                values: _documentDictionaryGroup.filter(dd =>
                    dd.object == OBJECT_NAME_DEBT_SCHEDULE
                    && dd.container == CONTAINER_NAME_DEBT_SCHEDULE_CROPS
                )
            },
            equipment: {
                values: _documentDictionaryGroup.filter(dd =>
                    dd.object == OBJECT_NAME_DEBT_SCHEDULE
                    && dd.container == CONTAINER_NAME_DEBT_SCHEDULE_EQUIPMENT
                )
            },
            livestock: {
                values: _documentDictionaryGroup.filter(dd =>
                    dd.object == OBJECT_NAME_DEBT_SCHEDULE
                    && dd.container == CONTAINER_NAME_DEBT_SCHEDULE_LIVESTOCK
                )
            },
            real_estate: {
                values: _documentDictionaryGroup.filter(dd =>
                    dd.object == OBJECT_NAME_DEBT_SCHEDULE
                    && dd.container == CONTAINER_NAME_DEBT_SCHEDULE_REAL_ESTATE
                )
            },

            values: _documentDictionaryGroup
        };

        _debtSchedule?.crops?.values?.forEach(v => {
            _debtSchedule.crops[v?.item?.toLowerCase()] = v.value;
        });
        _debtSchedule?.equipment?.values?.forEach(v => {
            _debtSchedule.equipment[v?.item?.toLowerCase()] = v.value;
        });
        _debtSchedule?.livestock?.values?.forEach(v => {
            _debtSchedule.livestock[v?.item?.toLowerCase()] = v.value;
        });
        _debtSchedule?.real_estate?.values?.forEach(v => {
            _debtSchedule.real_estate[v?.item?.toLowerCase()] = v.value;
        });

        // Totals:
        _debtSchedule.total = {};
        _debtSchedule?.crops?.values?.forEach(v => {
            _debtSchedule.total[v?.item?.toLowerCase()] =
                (_debtSchedule.crops[v?.item?.toLowerCase()] || 0)
                + (_debtSchedule.equipment[v?.item?.toLowerCase()] || 0)
                + (_debtSchedule.livestock[v?.item?.toLowerCase()] || 0)
                + (_debtSchedule.real_estate[v?.item?.toLowerCase()] || 0);
        });

        return _debtSchedule;
    }

    // --- --- ---

    const ensureDefaultValue = (_documentDictionaries, _defaultDocumentDictionary, _documentID) => {
        if (_documentDictionaries?.length) {
            const _foundElement = _documentDictionaries?.find(v =>
                v.section == _defaultDocumentDictionary?.section
                && v.module == _defaultDocumentDictionary?.module
                && v.object == _defaultDocumentDictionary?.object
                && v.container == _defaultDocumentDictionary?.container
                && v.item == _defaultDocumentDictionary?.item
            );
            if (!_foundElement) {
                createDefaultValue(_documentDictionaries, _defaultDocumentDictionary, _documentID);
                return true;
            } else {
                return false;
            }
        } else {
            createDefaultValue(_documentDictionaries, _defaultDocumentDictionary, _documentID);
            return true;
        }
    }

    const createDefaultValue = (_documentDictionaries, _defaultDocumentDictionary, _documentID) => {
        _defaultDocumentDictionary.document_id = _documentID;
        _defaultDocumentDictionary.id = _defaultDocumentDictionary.id || uuidv4();
        _documentDictionaries.push(_defaultDocumentDictionary);
    }

    // --- --- ---

    const getLoanOriginationMemo = async () => {
        const _documentsFilter = {
            year: year,
            document_type_name: DOCUMENT_FORM_NAME_LOAN_ORIGINATION_MEMO,
            is_active: true,
        }
        const _documents = await UsersApiHelper.users_selectObject("FinancialDocument", _documentsFilter, apiConfig);
        const _documentDictionaryFilter = {
            year: year,
            module: MODULE_FINANCIAL,
            object: OBJECT_NAME_LOAN_ORIGINATION_MEMO,
            is_active: true,
        }
        const _documentDictionary = await UsersApiHelper.selectDocumentDictionary(_documentDictionaryFilter, apiConfig);

        const _documentID = (_documents?.length) ? _documents[0].id : uuidv4();
        if (!_documents?.length) {
            const _financialDocument = {
                id: _documentID,
                year: year,
                document_type_name: DOCUMENT_FORM_NAME_LOAN_ORIGINATION_MEMO,
                is_active: true,
            }
            // await UsersApiHelper.updateFinancialDocument(_financialDocument, apiConfig);
        }

        const _loanOriginationMemo = {
            id: _documentID,
            year: year,
            document_type_name: DOCUMENT_FORM_NAME_LOAN_ORIGINATION_MEMO,
            is_active: true,

            document_id: _documentID,

            values: _documentDictionary.filter(dd =>
                dd.object == OBJECT_NAME_LOAN_ORIGINATION_MEMO
            )
        };

        _loanOriginationMemo.values?.forEach(v => {
            _loanOriginationMemo[v.item?.toLowerCase()] = v.value;
        });

        return _loanOriginationMemo;
    }

    // --- --- ---

    const getDebtRatios = ({
        balanceSheet,
        incomeStatement,
        arcPlc,
        insuranceScenario,
        debtSchedule,
        loanOriginationMemo,
        financeRatios,
    }) => {
        const _ratios = {
            equipment: {},
            real_estate: {},
            livestock: {},
            crops: {},
            arc_plc: {},
        };

        // ---
        // "Collateral" section:

        _ratios.equipment.assets = balanceSheet?.assets[ITEM_NAME_BALANCE_SHEET_ASSETS_MACHINERY_EQUIPMENT];
        _ratios.equipment.liabilities = (debtSchedule?.equipment[ITEM_NAME_DEBT_SCHEDULE_LIABILITY_CURRENT]
            + debtSchedule?.equipment[ITEM_NAME_DEBT_SCHEDULE_LIABILITY_NONCURRENT]) || 0.00;
        if (_ratios.equipment.assets != 0) {
            _ratios.equipment.loan_to_value = _ratios.equipment.liabilities / _ratios.equipment.assets;
        }
        _ratios.equipment.equity = _ratios.equipment.assets - _ratios.equipment.liabilities;
        _ratios.equipment.margin_rate = COLLATERAL_MARGIN_RATE_EQUIPMENT;
        _ratios.equipment.margin_value = _ratios.equipment.assets * _ratios.equipment.margin_rate;
        if (_ratios.equipment.margin_value != 0) {
            _ratios.equipment.loan_to_margin_value = _ratios.equipment.liabilities / _ratios.equipment.margin_value;
        }


        _ratios.real_estate.assets = balanceSheet?.assets[ITEM_NAME_BALANCE_SHEET_ASSETS_REAL_ESTATE];
        _ratios.real_estate.liabilities = (debtSchedule?.real_estate[ITEM_NAME_DEBT_SCHEDULE_LIABILITY_CURRENT]
            + debtSchedule?.real_estate[ITEM_NAME_DEBT_SCHEDULE_LIABILITY_NONCURRENT]) || 0.00;
        if (_ratios.real_estate.assets != 0) {
            _ratios.real_estate.loan_to_value = _ratios.real_estate.liabilities / _ratios.real_estate.assets;
        }
        _ratios.real_estate.equity = _ratios.real_estate.assets - _ratios.real_estate.liabilities;
        _ratios.real_estate.margin_rate = COLLATERAL_MARGIN_RATE_REAL_ESTATE;
        _ratios.real_estate.margin_value = _ratios.real_estate.assets * _ratios.real_estate.margin_rate;
        if (_ratios.real_estate.margin_value != 0) {
            _ratios.real_estate.loan_to_margin_value = _ratios.real_estate.liabilities / _ratios.real_estate.margin_value;
        }


        _ratios.livestock.assets = balanceSheet?.assets[ITEM_NAME_BALANCE_SHEET_ASSETS_BREEDING_LIVESTOCK];
        _ratios.livestock.liabilities = (debtSchedule?.livestock[ITEM_NAME_DEBT_SCHEDULE_LIABILITY_CURRENT]
            + debtSchedule?.livestock[ITEM_NAME_DEBT_SCHEDULE_LIABILITY_NONCURRENT]) || 0.00;
        if (_ratios.livestock.assets != 0) {
            _ratios.livestock.loan_to_value = _ratios.livestock.liabilities / _ratios.livestock.assets;
        }
        _ratios.livestock.equity = _ratios.livestock.assets - _ratios.livestock.liabilities;
        _ratios.livestock.margin_rate = COLLATERAL_MARGIN_RATE_LIVESTOCK;
        _ratios.livestock.margin_value = _ratios.livestock.assets * _ratios.livestock.margin_rate;
        if (_ratios.livestock.margin_value != 0) {
            _ratios.livestock.loan_to_margin_value = _ratios.livestock.liabilities / _ratios.livestock.margin_value;
        }


        _ratios.crops.assets = balanceSheet?.assets[ITEM_NAME_BALANCE_SHEET_ASSETS_INVENTORIES_CROPS];
        _ratios.crops.liabilities = (debtSchedule?.crops[ITEM_NAME_DEBT_SCHEDULE_LIABILITY_CURRENT]
            + debtSchedule?.crops[ITEM_NAME_DEBT_SCHEDULE_LIABILITY_NONCURRENT]) || 0.00;
        if (_ratios.crops.assets != 0) {
            _ratios.crops.loan_to_value = _ratios.crops.liabilities / _ratios.crops.assets;
        }
        _ratios.crops.equity = _ratios.crops.assets - _ratios.crops.liabilities;
        _ratios.crops.margin_rate = COLLATERAL_MARGIN_RATE_CROPS;
        _ratios.crops.margin_value = _ratios.crops.assets * _ratios.crops.margin_rate;
        if (_ratios.crops.margin_value != 0) {
            _ratios.crops.loan_to_margin_value = _ratios.crops.liabilities / _ratios.crops.margin_value;
        }

        _ratios.arc_plc.assets = arcPlc?.totals?.programElections?.totalPayment;
        _ratios.arc_plc.liabilities = 0.00//N/A;
        _ratios.arc_plc.loan_to_value = 0.00//N/A;
        _ratios.arc_plc.equity = _ratios.arc_plc.assets - _ratios.arc_plc.liabilities;
        _ratios.arc_plc.margin_rate = COLLATERAL_MARGIN_RATE_ARC_PLC;
        _ratios.arc_plc.margin_value = _ratios.arc_plc.assets * _ratios.arc_plc.margin_rate;
        _ratios.arc_plc.loan_to_margin_value = 0.00//N/A;

        _ratios.total = {};
        _ratios.total.assets =
            _ratios.equipment.assets
            + _ratios.real_estate.assets
            + _ratios.livestock.assets
            + _ratios.crops.assets
            + _ratios.arc_plc.assets;
        _ratios.total.liabilities =
            _ratios.equipment.liabilities
            + _ratios.real_estate.liabilities
            + _ratios.livestock.liabilities
            + _ratios.crops.liabilities
            + _ratios.arc_plc.liabilities;
        if (_ratios.total.assets != 0) {
            _ratios.total.loan_to_value = _ratios.total.liabilities / _ratios.total.assets;
        }
        _ratios.total.equity = _ratios.total.assets - _ratios.total.liabilities;
        _ratios.total.margin_value =
            _ratios.equipment.margin_value
            + _ratios.real_estate.margin_value
            + _ratios.livestock.margin_value
            + _ratios.crops.margin_value
            + _ratios.arc_plc.margin_value;
        if (_ratios.total.assets != 0) {
            _ratios.total.margin_rate = _ratios.total.margin_value / _ratios.total.assets;
        }
        if (_ratios.total.margin_value != 0) {
            _ratios.total.loan_to_margin_value = _ratios.total.liabilities / _ratios.total.margin_value;
        }

        // ---
        // Ratios and derived calculations:

        //Prior Year Carryover / Total Assets:
        if (balanceSheet.assets.total != 0) {
            _ratios.prior_year_carry_over_ratio = loanOriginationMemo?.prior_year_carry_over / balanceSheet.assets.total;
        } else {
            _ratios.prior_year_carry_over_ratio = null;
        }

        if (balanceSheet?.equity.total != 0) {
            // _ratios.debt_to_equity_ratio = debtSchedule?.total?.amount / balanceSheet?.equity?.total;
            _ratios.debt_to_equity_ratio = balanceSheet?.liabilities?.total / balanceSheet?.equity?.total;
        } else {
            _ratios.debt_to_equity_ratio = 0;
        }

        if (incomeStatement?.revenue?.total != 0) {
            _ratios.debt_to_income_ratio = debtSchedule?.total?.amount / incomeStatement?.revenue?.total;
        } else {
            _ratios.debt_to_income_ratio = 0;
        }

        // ---
        // CECL grades:

        _ratios.kpiGrades = {
            fico: calculateKpiGrade(KPI_FICO, loanOriginationMemo?.fico),
            revenue_to_payments_ratio: calculateKpiGrade(KPI_REVENUE_TO_PAYMENTS_RATIO, financeRatios?.revenue_to_payments_ratio),
            excess_cash_flow_ratio: calculateKpiGrade(KPI_EXCESS_CASH_FLOW_RATIO, financeRatios?.excess_cash_flow_ratio),
            prior_year_carry_over_ratio: calculateKpiGrade(KPI_PRIOR_YEAR_CARRY_OVER_RATIO, _ratios?.prior_year_carry_over_ratio),
            equity_to_assets_ratio: calculateKpiGrade(KPI_EQUITY_TO_ASSETS_RATIO, balanceSheet?.equity_to_assets_ratio),

            loan_to_value: calculateKpiGrade(KPI_LOAN_TO_VALUE, _ratios?.total?.loan_to_value),
            debt_to_equity_ratio: calculateKpiGrade(KPI_DEBT_TO_EQUITY_RATIO, _ratios?.debt_to_equity_ratio),
            working_capital_to_gross_revenue_ratio: calculateKpiGrade(KPI_WORKING_CAPITAL_TO_GROSS_REVENUE_RATIO, financeRatios?.working_capital_to_gross_revenue_ratio),
        }
        _ratios.kpiGrades.probability_of_default =
            _ratios.kpiGrades?.fico?.probability_of_default_weighted
            + _ratios.kpiGrades?.revenue_to_payments_ratio?.probability_of_default_weighted
            + _ratios.kpiGrades?.excess_cash_flow_ratio?.probability_of_default_weighted
            + _ratios.kpiGrades?.prior_year_carry_over_ratio?.probability_of_default_weighted
            + _ratios.kpiGrades?.equity_to_assets_ratio?.probability_of_default_weighted

        _ratios.kpiGrades.loss_given_default =
            _ratios.kpiGrades?.loan_to_value?.loss_given_default_weighted
            + _ratios.kpiGrades?.debt_to_equity_ratio?.loss_given_default_weighted
            + _ratios.kpiGrades?.working_capital_to_gross_revenue_ratio?.loss_given_default_weighted

        _ratios.kpiGrades.expected_loss = calculateKpiGrade(KPI_EXPECTED_LOSS, _ratios.kpiGrades.probability_of_default / 100 * _ratios.kpiGrades.loss_given_default / 100);

        return _ratios;
    }

    // --- --- ---

    const getRatios = ({
        budget,
        insuranceScenario,
        arcPlc,
        incomeStatement,
        balanceSheet, balanceSheetPriorYear
    }) => {
        // Also called (FarmDoc) VFP (Value of Farm Production)
        // (NOTE: FarmDoc formula differs slightly: VFP = Gross Revenue - Purchased Feed - Purchased Market Livestock)
        const gross_revenue = budget?.revenue
            + arcPlc?.totals?.programElections?.totalPayment;

        const revenue_operations = budget?.revenue
            + arcPlc?.totals?.programElections?.totalPayment
            + incomeStatement?.revenue?.total;

        // Excludes interest, debt service, living expenses, taxes(?)
        const expenses_operations = budget?.production_cost
            + incomeStatement?.expenses_livestock?.total
            + incomeStatement?.expenses_other_operations?.total
            // + incomeStatement?.expenses_taxes?.total
            + incomeStatement?.expenses_depreciation?.total;

        // const income_operations =
        //     // Income from operations:
        //     budget?.revenue
        //     - budget?.production_cost
        //     + arcPlc?.totals?.programElections?.totalPayment
        //     // +/- Revenues/Expenses
        //     + incomeStatement?.revenue?.total
        //     - incomeStatement?.expenses?.total;

        // cf., FarmDoc NFIFO = Net Farm Income from Operations excluding gains or losses from the disposal of farm capital assets.
        const net_income =
            // Income from operations:
            budget?.revenue
            - budget?.production_cost
            + arcPlc?.totals?.programElections?.totalPayment
            // +/- Revenues/Expenses
            + incomeStatement?.revenue?.total
            - incomeStatement?.expenses?.total
            // + Depreciation/amortization expense (add-back)
            + incomeStatement?.expenses_depreciation?.total;

        const revenue_guaranteed =
            insuranceScenario?.totals?.liability_amount
            + arcPlc?.totals?.programElections?.totalPayment
            + incomeStatement?.revenue_livestock?.total
            + incomeStatement?.revenue_other_operations?.total
            + incomeStatement?.revenue_other?.total;

        const net_income_guaranteed =
            revenue_guaranteed
            - budget?.expenses?.total
            - incomeStatement?.expenses?.total;

        // const administrative_expenses = budget?.production_cost
        //     + incomeStatement?.expenses?.total;

        const owner_withdrawal_expense = incomeStatement?.owner_withdrawal_expense || 0;

        const debt_repayment_capacity =
            // Income from operations:
            budget?.revenue
            - budget?.production_cost
            + arcPlc?.totals?.programElections?.totalPayment
            // +/- Revenues/Expenses
            + incomeStatement?.revenue?.total
            - incomeStatement?.expenses?.total
            // + Depreciation/amortization expense
            + incomeStatement?.expenses_depreciation?.total
            // - Owner withdrawals:
            - owner_withdrawal_expense;

        const principal_on_debt_leases =
            // Prior year current portion of long-term debt
            balanceSheet?.current_portion_of_term_debt_liability
            // + Prior year current portion of finance lease
            + balanceSheet?.current_portion_of_finance_leases_liability
            // = Total principal on term debt
            ;

        // https://farmdocdaily.illinois.edu/2018/09/measuring-repayment-capacity-and-farm-growth-potential.html#:~:text=The%20capital%20debt%20repayment%20capacity%20margin%20is%20computed%20by%20subtracting,from%20capital%20debt%20repayment%20capacity.
        const repayment_margin =
            debt_repayment_capacity
            - principal_on_debt_leases;

        const total_principal_interest_on_term_debt =
            // Prior year current portion of long-term debt
            balanceSheet?.current_portion_of_term_debt_liability
            // + Prior year current portion of finance lease
            + balanceSheet?.current_portion_of_finance_leases_liability
            // + Interest expense on term debt
            + incomeStatement?.interest_on_debt_expense
            // + Interest expense on finance leases
            + incomeStatement?.interest_on_leases_expense
            // = Total principal and interest on term debt
            ;

        const uses_of_repayment_replacement_capacity =
            // Prior year current portion of long-term debt
            balanceSheet?.current_portion_of_term_debt_liability
            // + Prior year current portion of finance lease
            + balanceSheet?.current_portion_of_finance_leases_liability
            // + Interest expense on term debt
            + incomeStatement?.interest_on_debt_expense
            // + Interest expense on finance leases
            + incomeStatement?.interest_on_leases_expense
            // = Total principal and interest on term debt
            // + Interest expense on current debt
            + incomeStatement?.expenses_interest?.total
            // Payment on unpaid operating debt from a prior period (loss carryover)
            // Total annual payments on personal liabilities (if not in withdrawals)
            // = Uses of repayment and replacement capacity
            ;

        const replacement_margin =
            // Prior year current portion of long-term debt
            balanceSheet?.current_portion_of_term_debt_liability
            // + Prior year current portion of finance lease
            + balanceSheet?.current_portion_of_finance_leases_liability
            // + Interest expense on term debt
            + incomeStatement?.interest_on_debt_expense
            // + Interest expense on finance leases
            + incomeStatement?.interest_on_leases_expense
            // = Total principal and interest on term debt
            // + Interest expense on current debt
            + incomeStatement?.expenses_interest?.total
            // Payment on unpaid operating debt from a prior period (loss carryover)
            // Total annual payments on personal liabilities (if not in withdrawals)
            // = Uses of repayment and replacement capacity
            // - Replacement allowance/Unfunded capital expenditures
            // = Replacement margin
            ;

        // const net_sales = budget?.revenue
        //     + arcPlc?.totals?.programElections?.totalPayment
        //     + incomeStatement?.revenue_livestock?.total
        //     + incomeStatement?.revenue_other_operations?.total;

        const taxes = incomeStatement?.expenses_taxes?.total;
        const interest_expense = budget?.interest_cost;
        const depreciation_expense = incomeStatement?.expenses_depreciation?.total;
        const debt_service_expense = incomeStatement?.expenses_debt_service?.total;

        const _ebitda = net_income
            + taxes
            + interest_expense
            + depreciation_expense;

        const livestock_income = incomeStatement?.revenue_livestock?.total - incomeStatement?.expenses_livestock?.total;

        const _ratios = {
            revenue_operations,
            expenses_operations,

            gross_revenue,
            livestock_income,
            net_income,

            revenue_guaranteed,
            net_income_guaranteed,

            // net_sales,
            // administrative_expenses,

            ebitda: _ebitda,

            debt_repayment_capacity,
            repayment_margin,
            replacement_margin
        };

        // Average assets or Average equity:
        if (balanceSheetPriorYear) {
            _ratios.average_total_assets = (balanceSheet?.assets?.total + balanceSheetPriorYear?.assets?.total) / 2;
            _ratios.average_total_equity = (balanceSheet?.equity?.total + balanceSheetPriorYear?.equity?.total) / 2;
        } else {
            // FFSC p. 159, #3. "If data are not available to calculate average balances, the calculations use only period-ending balances"
            _ratios.average_total_assets = balanceSheet?.assets?.total;
            _ratios.average_total_equity = balanceSheet?.equity?.total;
        }

        // ROA:
        if (_ratios.average_total_assets != 0) {
            // FarmDoc: (NFIFO + Farm Interest Expense - Operator Management Fee) / Average Total Farm Assets
            _ratios.return_on_assets_ratio = (net_income + incomeStatement?.expenses_interest?.total)
                / _ratios.average_total_assets;
        } else {
            _ratios.return_on_assets_ratio = 0;
        }

        // ROE:
        if (_ratios.average_total_equity != 0) {
            // FarmDoc: (NFIFO + Farm Interest Expense - Operator Management Fee) / Average Total Farm Equity
            _ratios.return_on_equity_ratio = (net_income + incomeStatement?.expenses_interest?.total)
                / _ratios.average_total_equity;
        } else {
            _ratios.return_on_equity_ratio = 0;
        }

        if (gross_revenue != 0) {
            _ratios.working_capital_to_gross_revenue_ratio = balanceSheet?.working_capital / gross_revenue;
        } else {
            _ratios.working_capital_to_gross_revenue_ratio = 0;
        }

        if (budget?.expenses?.total != 0) {
            _ratios.working_capital_to_loan_value_ratio = balanceSheet?.working_capital / budget?.expenses?.total;
        } else {
            _ratios.working_capital_to_loan_value_ratio = 0;
        }

        if (gross_revenue != 0) {
            _ratios.operating_profit_margin_ratio = (net_income + incomeStatement?.expenses_interest?.total) / gross_revenue;
        } else {
            _ratios.operating_profit_margin_ratio = 0;
        }

        if (total_principal_interest_on_term_debt != 0) {
            _ratios.debt_coverage_ratio = debt_repayment_capacity / total_principal_interest_on_term_debt;
        } else {
            _ratios.debt_coverage_ratio = 0;
        }

        const replacement_allowance = 0;
        if ((uses_of_repayment_replacement_capacity + replacement_allowance) != 0) {
            _ratios.replacement_coverage_ratio = (debt_repayment_capacity)
                / (uses_of_repayment_replacement_capacity + replacement_allowance);
        } else {
            _ratios.replacement_coverage_ratio = 0;
        }

        if (_ratios.average_total_equity != 0) {
            _ratios.asset_turnover_ratio = gross_revenue / _ratios.average_total_assets;
        } else {
            _ratios.asset_turnover_ratio = 0;
        }

        if (gross_revenue != 0) {
            // Add-back depreciation:
            _ratios.operating_expense_ratio = (expenses_operations - incomeStatement?.expenses_depreciation?.total)
                / gross_revenue;

            _ratios.depreciation_expense_ratio = (incomeStatement?.expenses_depreciation?.total)
                / gross_revenue;
            _ratios.interest_expense_ratio = (incomeStatement?.expenses_interest?.total)
                / gross_revenue;
            _ratios.income_ratio = net_income
                / gross_revenue;
        } else {
            _ratios.operating_expense_ratio = 0;
            _ratios.depreciation_expense_ratio = 0;
            _ratios.interest_expense_ratio = 0;
            _ratios.income_ratio = 0;
        }

        if (incomeStatement?.expenses?.total != 0) {
            _ratios.revenue_to_payments_ratio = incomeStatement?.revenue?.total / incomeStatement?.expenses?.total;
        } else {
            _ratios.revenue_to_payments_ratio = 0.00;
        }

        // Cash Flow: indirect method:
        if (balanceSheetPriorYear) {
            const _cash_increase = balanceSheet?.assets[ITEM_NAME_BALANCE_SHEET_ASSETS_CASH] - balanceSheetPriorYear?.assets[ITEM_NAME_BALANCE_SHEET_ASSETS_CASH];
            _ratios.cash_flow = net_income + _cash_increase;

            _ratios.net_cash_flow = net_income;

            if (_ratios.cash_flow > _ratios?.net_cash_flow) {
                _ratios.excess_cash_flow = _ratios.cash_flow - _ratios.net_cash_flow;
            } else {
                _ratios.excess_cash_flow = 0;
            }

            if (_ratios.excess_cash_flow != 0) {
                _ratios.excess_cash_flow_ratio = _ratios.cash_flow / _ratios?.excess_cash_flow;
            } else {
                _ratios.excess_cash_flow_ratio = 0.00;
            }
        }

        return _ratios;
    }

    // --- --- ---

    const actions = {
        getBalanceSheet,
        getBalanceSheetPriorYear,
        getIncomeStatement,

        getScheduleF,
        getEquipmentInventory,
        getDebtSchedule,

        getLoanOriginationMemo,

        getRatios,
        getDebtRatios,
    };

    return {
        actions
    }
}

export const hydrateBalanceSheetDocumentDictionaries = (_documentDictionary) => {
    let _documentID = null;
    let _year = null;
    if (_documentDictionary?.length) {
        _documentID = _documentDictionary[0]?.document_id
        _year = _documentDictionary[0]?.year
    }

    _documentDictionary.forEach(current => {
        current._GROUP_KEY = JSON.stringify({
            year: current.year,
            section: current.section,
            module: current.module,
            object: current.object,
            container: current.container,
            item: current.item,
        });
    });
    const _documentDictionaryGroup = _(_documentDictionary)
        .groupBy("_GROUP_KEY")
        .map((currentGroup, id) => ({
            // Deserialize GROUP_KEY id object props:
            ...JSON.parse(id),
            // Individual values:
            document_id: currentGroup[0]?.document_id,
            id: currentGroup[0]?.id,
            // Aggregates:
            value:
                _.toNumber(_.toNumber(
                    _.sumBy(currentGroup, item => Number(item.value) || 0)
                )?.toFixed(2)),
        }))
        .value();

    const _balanceSheet = {
        id: _documentID,
        year: _year,
        document_type_name: DOCUMENT_FORM_NAME_BALANCE_SHEET,
        is_active: true,

        document_id: _documentID,

        assets_current: {
            values: _documentDictionaryGroup.filter(dd =>
                dd.object == OBJECT_NAME_BALANCE_SHEET
                && dd.container == CONTAINER_NAME_BALANCE_SHEET_ASSETS_CURRENT
            )
        },
        assets_noncurrent: {
            values: _documentDictionaryGroup.filter(dd =>
                dd.object == OBJECT_NAME_BALANCE_SHEET
                && dd.container == CONTAINER_NAME_BALANCE_SHEET_ASSETS_NONCURRENT
            )
        },
        assets_personal: {
            values: _documentDictionaryGroup.filter(dd =>
                dd.object == OBJECT_NAME_BALANCE_SHEET
                && dd.container == CONTAINER_NAME_BALANCE_SHEET_ASSETS_PERSONAL
            )
        },

        liabilities_current: {
            values: _documentDictionaryGroup.filter(dd =>
                dd.object == OBJECT_NAME_BALANCE_SHEET
                && dd.container == CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_CURRENT
            )
        },
        liabilities_noncurrent: {
            values: _documentDictionaryGroup.filter(dd =>
                dd.object == OBJECT_NAME_BALANCE_SHEET
                && dd.container == CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_NONCURRENT
            )
        },
        liabilities_personal: {
            values: _documentDictionaryGroup.filter(dd =>
                dd.object == OBJECT_NAME_BALANCE_SHEET
                && dd.container == CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_PERSONAL
            )
        },
        liabilities_tax: {
            values: _documentDictionaryGroup.filter(dd =>
                dd.object == OBJECT_NAME_BALANCE_SHEET
                && dd.container == CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_TAX
            )
        },

        equity: {
            values: _documentDictionaryGroup.filter(dd =>
                dd.object == OBJECT_NAME_BALANCE_SHEET
                && dd.container == CONTAINER_NAME_BALANCE_SHEET_EQUITY
            )
        },

        values: _documentDictionaryGroup
    };

    _balanceSheet.assets = {
        values: [
            ..._balanceSheet.assets_current.values,
            ..._balanceSheet.assets_noncurrent.values,
            ..._balanceSheet.assets_personal.values
        ],
    }
    _balanceSheet.liabilities = {
        values: [
            ..._balanceSheet.liabilities_current.values,
            ..._balanceSheet.liabilities_noncurrent.values,
            ..._balanceSheet.liabilities_personal.values,
            ..._balanceSheet.liabilities_tax.values
        ],
    }

    _balanceSheet.assets_current.total = _.sum(_balanceSheet.assets_current.values.map(v => v.value));
    _balanceSheet.assets_noncurrent.total = _.sum(_balanceSheet.assets_noncurrent.values.map(v => v.value));
    _balanceSheet.assets_personal.total = _.sum(_balanceSheet.assets_personal.values.map(v => v.value));
    _balanceSheet.assets.total = _.sum(_balanceSheet.assets.values.map(v => v.value));

    _balanceSheet.liabilities_current.total = _.sum(_balanceSheet.liabilities_current.values.map(v => v.value));
    _balanceSheet.liabilities_noncurrent.total = _.sum(_balanceSheet.liabilities_noncurrent.values.map(v => v.value));
    _balanceSheet.liabilities_personal.total = _.sum(_balanceSheet.liabilities_personal.values.map(v => v.value));
    _balanceSheet.liabilities_tax.total = _.sum(_balanceSheet.liabilities_tax.values.map(v => v.value));
    _balanceSheet.liabilities.total = _.sum(_balanceSheet.liabilities.values.map(v => v.value));

    _balanceSheet.equity.total = _.sum(_balanceSheet.equity.values.map(v => v.value));

    if (_balanceSheet.assets.total != 0) {
        _balanceSheet.equity_to_assets_ratio = _balanceSheet.equity.total / _balanceSheet.assets.total;
        _balanceSheet.debt_to_assets_ratio = _balanceSheet.liabilities.total / _balanceSheet.assets.total;
    } else {
        _balanceSheet.equity_to_assets_ratio = 0;
        _balanceSheet.debt_to_assets_ratio = 0;
    }

    if (_balanceSheet.liabilities_current.total != 0) {
        _balanceSheet.current_ratio = _balanceSheet.assets_current.total / _balanceSheet.liabilities_current.total;
    } else {
        _balanceSheet.current_ratio = 0;
    }

    _balanceSheet.working_capital = _balanceSheet.assets_current.total - _balanceSheet.liabilities_current.total;

    _balanceSheet.assets.values?.forEach(v => {
        _balanceSheet[v.item?.toLowerCase() + "_asset"] = v.value;
        _balanceSheet.assets[v.item?.toLowerCase()] = v.value;
    });
    _balanceSheet.liabilities.values?.forEach(v => {
        _balanceSheet[v.item?.toLowerCase() + "_liability"] = v.value;
        _balanceSheet.liabilities[v.item?.toLowerCase()] = v.value;
    });
    _balanceSheet.equity.values?.forEach(v => {
        _balanceSheet.equity[v.item?.toLowerCase()] = v.value;
    });

    return _balanceSheet;
}