import { ProducerFarmHelper } from '@datanac/datanac-api-toolkit'
import { getCurrentReinsuranceYear } from '@datanac/datanac-api-toolkit/dist/utility/InsurancePlanHelper'
import { useCardController, useDialog } from '@datanac/datanac-mui-toolkit'
import AddCircleOutline from '@mui/icons-material/AddCircleOutline'
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import { Box, Divider, Menu, MenuItem, Stack, Typography, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import { AppContext } from 'App'
import { UsersApiHelper } from 'api/ApiHelper'
import CountyDataView from 'components/County/CountyDataView'
import { FarmVaultIcon } from 'components/Icons/Icons'
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import WorkspaceViewSelector from 'components/Workspaces/WorkspaceModeSelector'
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from 'components/Workspaces/helpers'
import _ from 'lodash'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { formatPracticeName } from 'utility/formatters'
import ProducerFarmCropCard from './components/ProducerFarmCropCard'
import ProducerFarmCropDataTable from './components/ProducerFarmCropDataTable'
import { CROP_YEAR_POTENTIAL } from './helpers'

export default function FarmListWorkspace() {
    const producerFarmHelper = new ProducerFarmHelper();

    const { globalState, globalDispatch } = useContext(AppContext);
    const {
        crop_year,
        location_state_name, location_county_name
    } = useParams();
    const navigate = useNavigate();

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const [isLoading, setIsLoading] = useState(true);

    const [workspaceMode, setWorkspaceMode] = useState(isSmall ? WORKSPACE_VIEW_CARD : WORKSPACE_VIEW_TABLE);

    const [countyPractices, setCountyPractices] = useState([]);
    const [selectedCountyPractice, setSelectedCountyPractice] = useState({});

    // ---

    const { state: producerFarms, actions: producerFarmActions } = useCardController();

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    const loadProducerFarms = async () => {
        try {
            const producerFilter = {
                producer_token: globalState.producer_token,
                location_state_name,
                location_county_name,
                ...(crop_year != CROP_YEAR_POTENTIAL && { crop_year }),
                is_active: true
            };
            const farms = await UsersApiHelper.selectProducerFarms(producerFilter, globalState);
            if (farms?.length) {
                producerFarmActions.replace(farms);
            } else {
                producerFarmActions.replace([]);
            }

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            producerFarmActions.replace([]);
            handleError(err);
        }
    }

    useEffect(() => {
        if (globalState?.producer_token) {
            loadProducerFarms();
        } else {
            setIsLoading(false);
        }
    }, [globalState?.producer_token]);

    // ---

    const { state: producerFarmFieldCrops, actions: producerFarmFieldCropActions } = useCardController();
    const loadProducerFarmFieldCrop = async () => {
        try {
            const producerFilter = {
                producer_token: globalState.producer_token,
                location_state_name,
                location_county_name,
                ...(crop_year != CROP_YEAR_POTENTIAL && { crop_year }),
                is_active: true
            };
            const farms = await UsersApiHelper.selectProducerFarmFieldCrops(producerFilter, globalState);
            producerFarmFieldCropActions.replace(farms);

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            producerFarmFieldCropActions.replace([]);
            handleError(err);
        }
    }

    useEffect(() => {
        if (globalState?.producer_token) {
            loadProducerFarmFieldCrop();
        } else {
            setIsLoading(false);
        }
    }, [globalState?.producer_token]);

    // ---

    const { state: producerFarm_union_Crops, actions: producerFarm_union_CropActions } = useCardController();

    useEffect(() => {
        if (producerFarms?.length && !producerFarmFieldCrops?.length) {
            producerFarm_union_CropActions.replace(producerFarms);
        } else if (producerFarms?.length
            && (producerFarmFieldCrops?.length || producerFarmFieldCrops?.length)) {
            const loadUnionProducerFarmCrops = async () => {
                // Union:
                const _producerFarmsUnion = [...producerFarms];
                _producerFarmsUnion.forEach(currentProducerFarm => {
                    currentProducerFarm.ProducerFarmCrops = producerFarmFieldCrops.filter(crop =>
                        crop.producer_farm_id == currentProducerFarm.id
                    );

                    currentProducerFarm.CropProfiles = _.uniqBy(currentProducerFarm?.ProducerFarmCrops, pfc =>
                        JSON.stringify({
                            commodity_name: pfc.commodity_name,
                            type_name: pfc.type_name,
                            practice_name: pfc.practice_name,
                        }))
                        ?.sort((a, b) =>
                            a.commodity_name?.localeCompare(b.commodity_name)
                            || a.type_name?.localeCompare(b.type_name)
                            || a.practice_name?.localeCompare(b.typpractice_namee_name)
                        );
                    currentProducerFarm.CropProfiles_Sort = currentProducerFarm.CropProfiles?.reduce((accumulator, currentValue) =>
                        (accumulator?.length ? ", " : "")
                        + currentValue?.commodity_name
                        + "-" + formatPracticeName(currentValue?.practice_name)
                        , "");
                });

                producerFarm_union_CropActions.replace(_producerFarmsUnion);
            }
            loadUnionProducerFarmCrops();
        } else {
            producerFarm_union_CropActions.replace([]);
            setIsLoading(false);
        }
    }, [globalState?.producer_token, producerFarms, producerFarmFieldCrops]);

    // ---

    const handleProducerFarmCropCardOnChange = async (_producerFarmCrop) => {
        UsersApiHelper.updateProducerFarm(_producerFarmCrop, globalState).then(() => {
            loadProducerFarms();
        });
    }

    const handleAddNewClick = () => {
        navigate(`/vault/${crop_year}/${location_state_name}/${location_county_name}/0`);
    }

    return (
        <>
            <WorkspaceHeader
                title={Boolean(location_state_name && location_county_name) ?
                    (location_county_name + ", " + location_state_name)
                    : "Farm Vault"}
                breadCrumbs={[
                    {
                        title: 'Farm Vault '
                            + ((crop_year != CROP_YEAR_POTENTIAL && " - " + crop_year) || ''),
                        path: '/vault' + (crop_year == CROP_YEAR_POTENTIAL ? "/potential" : ""),
                        icon: <FarmVaultIcon fontSize='3pt' />
                    },
                ]}
            />

            {workspaceMode == WORKSPACE_VIEW_CARD && <>
                <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton'>
                    <Typography variant='h1'>
                        Farms
                    </Typography>
                    <WorkspaceViewSelector workspaceMode={workspaceMode} setWorkspaceMode={setWorkspaceMode} />
                </Stack>

                <Box className='cardContainer'>
                    {producerFarm_union_Crops.map((currentProducerFarmCrop, index) =>
                        <ProducerFarmCropCard key={index} currentProducerFarmCrop={currentProducerFarmCrop} onChange={handleProducerFarmCropCardOnChange} />
                    )}
                </Box>
            </>}

            {workspaceMode == WORKSPACE_VIEW_TABLE && Boolean(producerFarm_union_Crops?.length) &&
                <ProducerFarmCropDataTable producerFarmCrops={producerFarm_union_Crops}
                    title={<Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton'>
                        <Typography variant='h1'>
                            Farms
                        </Typography>
                        <WorkspaceViewSelector workspaceMode={workspaceMode} setWorkspaceMode={setWorkspaceMode} />
                    </Stack>}
                />}

            <WorkspaceActionBar content={<>
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={handleAddNewClick}>
                        <AddCircleOutline sx={{ mr: 1 }} />
                        New Farm
                    </Button>
                </Stack>
            </>} />

            {Boolean(countyPractices?.length)
                && location_state_name
                && location_county_name
                && selectedCountyPractice?.commodity_name
                && selectedCountyPractice?.type_name
                && selectedCountyPractice?.practice_name && <>
                    <Divider />
                    <Typography variant='h1' display="inline">
                        County History
                    </Typography>
                    <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                            <>
                                <Button variant="text" {...bindTrigger(popupState)}>
                                    <ArrowDropDownOutlined />
                                </Button>
                                <Menu {...bindMenu(popupState)}>
                                    {countyPractices.map((cp, index) =>
                                        <MenuItem key={index} onClick={() => {
                                            setSelectedCountyPractice(cp);
                                            popupState.close();
                                        }}>
                                            {cp.commodity_name}
                                            / {cp.type_name == "No Type Specified" ? "" : cp.type_name + " /"}
                                            {cp.practice_name}
                                        </MenuItem>)}
                                </Menu>
                            </>
                        )}
                    </PopupState>

                    {selectedCountyPractice
                        && location_state_name
                        && location_county_name
                        && selectedCountyPractice?.commodity_name
                        && selectedCountyPractice?.type_name
                        && selectedCountyPractice?.practice_name && <>
                            <CountyDataView countyPractice={{
                                ...selectedCountyPractice,
                                reinsurance_year: getCurrentReinsuranceYear(),
                                location_state_name,
                                location_county_name,
                            }} />
                        </>}
                </>}

            {confirmDialogComponent}
        </>
    )
}
