import { Stack, Typography, useMediaQuery } from "@mui/material"
import { SkeletonWrapper } from "./SkeletonWrapper";

export const ValueLabel = ({
    label,
    value,

    isLoading,

    sx,
}) => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });

    return (
        <Stack className="valueLabel" direction={'column'} justifyContent='space-between'>
            <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>
                {label}
            </Typography>
            <SkeletonWrapper isLoading={isLoading} sx={{minWidth: "100%"}}>
                <Typography variant='body2' sx={sx}>
                    {value}
                </Typography>
            </SkeletonWrapper>
        </Stack>
    )
}