import { MODULE_FINANCIAL } from "components/Menu/NavigationMenu";

export const OBJECT_NAME_INCOME_STATEMENT = "income_statement";

export const CONTAINER_NAME_INCOME_STATEMENT_REVENUE_FARM = "revenue_farm";

export const CONTAINER_NAME_INCOME_STATEMENT_REVENUE_LIVESTOCK = "revenue_livestock";
export const ITEM_NAME_INCOME_STATEMENT_LIVESTOCK_CATTLE = "cattle";
export const ITEM_NAME_INCOME_STATEMENT_LIVESTOCK_HOGS = "hogs";
export const ITEM_NAME_INCOME_STATEMENT_LIVESTOCK_MILK = "milk";
export const ITEM_NAME_INCOME_STATEMENT_LIVESTOCK_DAIRY_LIVESTOCK = "dairy_livestock";
export const ITEM_NAME_INCOME_STATEMENT_LIVESTOCK_BREEDING_LIVESTOCK = "breeding_livestock";

export const CONTAINER_NAME_INCOME_STATEMENT_REVENUE_OTHER_OPERATIONS = "revenue_other_operations";
export const ITEM_NAME_INCOME_STATEMENT_OPERATIONS_MACHINE_WORK = "machine_work";

export const CONTAINER_NAME_INCOME_STATEMENT_REVENUE_OTHER = "revenue_other";
export const ITEM_NAME_INCOME_STATEMENT_OTHER_PATRONAGE_DIVIDENDS = "patronage_dividends";
export const ITEM_NAME_INCOME_STATEMENT_OTHER_MISC = "miscellaneous";

//

export const CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_LIVESTOCK = "expenses_livestock";
export const ITEM_NAME_INCOME_STATEMENT_EXPENSES_LIVESTOCK_CATTLE = "cost_of_cattle_sold";
export const ITEM_NAME_INCOME_STATEMENT_EXPENSES_LIVESTOCK_HOGS = "cost_of_hogs_sold";
export const ITEM_NAME_INCOME_STATEMENT_EXPENSES_LIVESTOCK_BREEDING = "breeding_fees";
export const ITEM_NAME_INCOME_STATEMENT_EXPENSES_LIVESTOCK_FEED = "feed";
export const ITEM_NAME_INCOME_STATEMENT_EXPENSES_LIVESTOCK_VETERINARY = "veterinary";

export const CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_OTHER_OPERATIONS = "expenses_other_operations";
export const ITEM_NAME_INCOME_STATEMENT_EXPENSES_OTHER_SUBSCRIPTION_DUES = "subscription_dues";
export const ITEM_NAME_INCOME_STATEMENT_EXPENSES_OTHER_EMPLOYEE_BENEFITS = "employee_benefits";
export const ITEM_NAME_INCOME_STATEMENT_EXPENSES_OTHER_ACCOUNTING = "accounting";
export const ITEM_NAME_INCOME_STATEMENT_EXPENSES_OTHER_LEGAL = "legal_fees";
export const ITEM_NAME_INCOME_STATEMENT_EXPENSES_OTHER_OFFICE_SUPPLIES = "office_supplies";
export const ITEM_NAME_INCOME_STATEMENT_EXPENSES_OTHER_PENSION = "pension";

export const CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_OTHER = "expenses_other";

export const CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_DEBT_SERVICE = "expenses_debt_service";
export const ITEM_NAME_INCOME_STATEMENT_EXPENSES_DEBT_SERVICE_DEBT_SERVICE = "debt_service";

export const CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_LIVING_EXPENSES = "expenses_living_expenses";
export const ITEM_NAME_INCOME_STATEMENT_EXPENSES_LIVING_EXPENSES_LIVING_EXPENSES = "living_expenses";
export const ITEM_NAME_INCOME_STATEMENT_EXPENSES_LIVING_EXPENSES_OWNER_WITHDRAWAL = "owner_withdrawal";

export const CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_INTEREST = "expenses_interest";
export const ITEM_NAME_INCOME_STATEMENT_EXPENSES_INTEREST_DEBT = "interest_on_debt";
export const ITEM_NAME_INCOME_STATEMENT_EXPENSES_INTEREST_LEASE = "interest_on_leases";

export const CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_DEPRECIATION = "expenses_depreciation";
export const ITEM_NAME_INCOME_STATEMENT_EXPENSES_DEPRECIATION = "depreciation_amortization";

export const CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_TAXES = "expenses_taxes";
export const ITEM_NAME_INCOME_STATEMENT_EXPENSES_TAXES = "taxes";

// --- --- ---

export const DOCUMENT_DICTIONARY_DEFAULT_VALUES_INCOME_STATEMENT = [
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_INCOME_STATEMENT,
        container: CONTAINER_NAME_INCOME_STATEMENT_REVENUE_LIVESTOCK,
        item: ITEM_NAME_INCOME_STATEMENT_LIVESTOCK_CATTLE
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_INCOME_STATEMENT,
        container: CONTAINER_NAME_INCOME_STATEMENT_REVENUE_LIVESTOCK,
        item: ITEM_NAME_INCOME_STATEMENT_LIVESTOCK_HOGS
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_INCOME_STATEMENT,
        container: CONTAINER_NAME_INCOME_STATEMENT_REVENUE_LIVESTOCK,
        item: ITEM_NAME_INCOME_STATEMENT_LIVESTOCK_MILK
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_INCOME_STATEMENT,
        container: CONTAINER_NAME_INCOME_STATEMENT_REVENUE_LIVESTOCK,
        item: ITEM_NAME_INCOME_STATEMENT_LIVESTOCK_DAIRY_LIVESTOCK
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_INCOME_STATEMENT,
        container: CONTAINER_NAME_INCOME_STATEMENT_REVENUE_LIVESTOCK,
        item: ITEM_NAME_INCOME_STATEMENT_LIVESTOCK_BREEDING_LIVESTOCK
    },

    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_INCOME_STATEMENT,
        container: CONTAINER_NAME_INCOME_STATEMENT_REVENUE_OTHER,
        item: ITEM_NAME_INCOME_STATEMENT_OTHER_PATRONAGE_DIVIDENDS
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_INCOME_STATEMENT,
        container: CONTAINER_NAME_INCOME_STATEMENT_REVENUE_OTHER,
        item: ITEM_NAME_INCOME_STATEMENT_OTHER_MISC
    },

    //

    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_INCOME_STATEMENT,
        container: CONTAINER_NAME_INCOME_STATEMENT_REVENUE_OTHER_OPERATIONS,
        item: ITEM_NAME_INCOME_STATEMENT_OPERATIONS_MACHINE_WORK
    },

    //

    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_INCOME_STATEMENT,
        container: CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_LIVESTOCK,
        item: ITEM_NAME_INCOME_STATEMENT_EXPENSES_LIVESTOCK_CATTLE
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_INCOME_STATEMENT,
        container: CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_LIVESTOCK,
        item: ITEM_NAME_INCOME_STATEMENT_EXPENSES_LIVESTOCK_HOGS
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_INCOME_STATEMENT,
        container: CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_LIVESTOCK,
        item: ITEM_NAME_INCOME_STATEMENT_EXPENSES_LIVESTOCK_BREEDING
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_INCOME_STATEMENT,
        container: CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_LIVESTOCK,
        item: ITEM_NAME_INCOME_STATEMENT_EXPENSES_LIVESTOCK_FEED
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_INCOME_STATEMENT,
        container: CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_LIVESTOCK,
        item: ITEM_NAME_INCOME_STATEMENT_EXPENSES_LIVESTOCK_VETERINARY
    },

    // ---

    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_INCOME_STATEMENT,
        container: CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_DEBT_SERVICE,
        item: ITEM_NAME_INCOME_STATEMENT_EXPENSES_DEBT_SERVICE_DEBT_SERVICE
    },

    // ---

    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_INCOME_STATEMENT,
        container: CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_OTHER_OPERATIONS,
        item: ITEM_NAME_INCOME_STATEMENT_EXPENSES_OTHER_SUBSCRIPTION_DUES
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_INCOME_STATEMENT,
        container: CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_OTHER_OPERATIONS,
        item: ITEM_NAME_INCOME_STATEMENT_EXPENSES_OTHER_EMPLOYEE_BENEFITS
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_INCOME_STATEMENT,
        container: CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_OTHER_OPERATIONS,
        item: ITEM_NAME_INCOME_STATEMENT_EXPENSES_OTHER_ACCOUNTING
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_INCOME_STATEMENT,
        container: CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_OTHER_OPERATIONS,
        item: ITEM_NAME_INCOME_STATEMENT_EXPENSES_OTHER_LEGAL
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_INCOME_STATEMENT,
        container: CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_OTHER_OPERATIONS,
        item: ITEM_NAME_INCOME_STATEMENT_EXPENSES_OTHER_OFFICE_SUPPLIES
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_INCOME_STATEMENT,
        container: CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_OTHER_OPERATIONS,
        item: ITEM_NAME_INCOME_STATEMENT_EXPENSES_OTHER_PENSION
    },

    //

    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_INCOME_STATEMENT,
        container: CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_LIVING_EXPENSES,
        item: ITEM_NAME_INCOME_STATEMENT_EXPENSES_LIVING_EXPENSES_LIVING_EXPENSES
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_INCOME_STATEMENT,
        container: CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_LIVING_EXPENSES,
        item: ITEM_NAME_INCOME_STATEMENT_EXPENSES_LIVING_EXPENSES_OWNER_WITHDRAWAL
    },

    //

    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_INCOME_STATEMENT,
        container: CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_INTEREST,
        item: ITEM_NAME_INCOME_STATEMENT_EXPENSES_INTEREST_DEBT
    },
    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_INCOME_STATEMENT,
        container: CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_INTEREST,
        item: ITEM_NAME_INCOME_STATEMENT_EXPENSES_INTEREST_LEASE
    },


    //

    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_INCOME_STATEMENT,
        container: CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_DEPRECIATION,
        item: ITEM_NAME_INCOME_STATEMENT_EXPENSES_DEPRECIATION
    },

    //

    {
        section: null,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_INCOME_STATEMENT,
        container: CONTAINER_NAME_INCOME_STATEMENT_EXPENSES_TAXES,
        item: ITEM_NAME_INCOME_STATEMENT_EXPENSES_TAXES
    },
];
