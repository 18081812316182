import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Autocomplete from '@mui/material/Autocomplete'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import React, { useEffect, useState, useReducer, useContext } from 'react'

import Popper from '@mui/material/Popper'
import Fade from '@mui/material/Fade'

import { Chip } from '@mui/material'
import { StatefulControlledTextField } from 'components/Core/StatefulControlledTextField'
import { Stack } from '@mui/system'

export function PromptDialog({
    title,
    prompt,
    promptDetail,
    open,

    onClickConfirm,
    onClickCancel
}) {

    const [inputValue, setInputValue] = useState(null);

    const handleTextFieldOnChange = (e) => {
        setInputValue(e?.target?.value);
    }

    return (
        <Dialog open={open}>
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>

            <DialogContent>
                <Typography variant='body1'>
                    {prompt}
                </Typography>

                <StatefulControlledTextField fullWidth onChange={handleTextFieldOnChange} />
            </DialogContent>

            {promptDetail &&
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {promptDetail}
                    </DialogContentText>
                </DialogContent>
            }

            <DialogActions>
                <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap" justifyContent='flex-end'>
                    <Button variant='contained' onClick={() => onClickConfirm(inputValue)}>OK</Button>
                    <Button variant='contained' color='secondary' autoFocus onClick={onClickCancel}>Cancel</Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}
