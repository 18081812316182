import { AppContext } from 'App';
import { ApiHelper } from 'api/ApiHelper';
import { ArcPlcService, PRACTICE_NO_PRACTICE_SPECIFIED } from 'components/ArcPlc/ArcPlcService';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useMemo, useState } from 'react';
import { calculateArcCoPayment, calculatePlcPayment } from './ArcPlcHelper';

export const useArcPlc = ({
    baseAcres
}) => {
    const { globalState } = useContext(AppContext);
    const { enqueueSnackbar } = useSnackbar();

    const { actions: arcPlcActions } = new ArcPlcService({
        apiConfig: globalState,
        crop_year: baseAcres?.length ?
            baseAcres[0]?.crop_year
            : null,
        baseAcres: baseAcres,
    });

    // --- --- ---
    // Datanac

    const [plcPaymentRates, setPlcPaymentRates] = useState(null);
    const _loadPlcPaymentRates = async () => {
        const _plcPaymentRates = await arcPlcActions.getPLCPaymentRates();
        setPlcPaymentRates(_plcPaymentRates);
    };

    useEffect(() => {
        if (baseAcres?.length) {
            _loadPlcPaymentRates();
        }
    }, [baseAcres?.length || 0]);

    // ---

    const [arccoYields, setARCCOYields] = useState(null);
    const _loadARCCOYields = async () => {
        const _ARCCOYields = (await arcPlcActions.getARCCOYields());
        setARCCOYields(_ARCCOYields);
    };

    useEffect(() => {
        if (baseAcres?.length) {
            _loadARCCOYields();
        }
    }, [baseAcres?.length || 0]);

    // --- --- ---

    const selectArcPlc = async (currentBaseAcres) => {
        if (
            currentBaseAcres?.crop_year
            && currentBaseAcres?.commodity_name
            && currentBaseAcres?.base_acreage && currentBaseAcres?.plc_yield
            && (currentBaseAcres?.state_name || currentBaseAcres?.location_state_name)
            && (currentBaseAcres?.county_name || currentBaseAcres?.location_county_name)
        ) {
            try {
                const _currentPlcPaymentRate = plcPaymentRates?.find(plc =>
                    plc?.commodity_name?.toLowerCase() == currentBaseAcres?.commodity_name?.toLowerCase()
                    && plc?.crop_year == currentBaseAcres?.crop_year
                );

                const _currentArcCoYield = arccoYields?.find(arc =>
                    arc?.state_name?.toLowerCase() == (currentBaseAcres?.state_name || currentBaseAcres?.location_state_name)?.toLowerCase()
                    && arc?.county_name?.toLowerCase() == (currentBaseAcres?.county_name || currentBaseAcres?.location_county_name)?.toLowerCase()
                    && arc?.commodity_name?.toLowerCase() == currentBaseAcres?.commodity_name?.toLowerCase()
                    && arc?.crop_year == currentBaseAcres?.crop_year
                );

                const _arcPlcRequest = {
                    crop_year: currentBaseAcres?.crop_year,
                    state_name: (currentBaseAcres?.state_name || currentBaseAcres?.location_state_name),
                    county_name: (currentBaseAcres?.county_name || currentBaseAcres?.location_county_name),
                    commodity_name: currentBaseAcres?.commodity_name,

                    base_acreage: currentBaseAcres?.base_acreage,
                    plc_yield: currentBaseAcres?.plc_yield,

                    share_percent: currentBaseAcres?.share_percent,

                    // PLCPaymentRates
                    effective_reference_price: _currentPlcPaymentRate?.effective_reference_price,
                    effective_price: _currentPlcPaymentRate?.market_year_average_price,
                    market_year_average_price: _currentPlcPaymentRate?.market_year_average_price,
                    national_loan_rate: _currentPlcPaymentRate?.national_loan_rate,

                    // ARCCoPrices
                    benchmark_price: _currentArcCoYield?.benchmark_price,
                    benchmark_yield: _currentArcCoYield?.benchmark_yield,

                    apiHelper: ApiHelper,
                };
                const _arccoPayment = calculateArcCoPayment(_arcPlcRequest);
                const _plcPayment = calculatePlcPayment(_arcPlcRequest);

                const arcPlcResponse = {
                    arcPayment: _arccoPayment.arcPayment,
                    plcPayment: _plcPayment.plcPayment,
                }

                if (arcPlcResponse) {
                    if (arcPlcResponse?.arcPayment?.error) {
                        setIsErrorArcPlc(true);
                        enqueueSnackbar("Could not load ARC-CO projections.", { preventDuplicate: true, variant: 'warning' });
                        console.warn(arcPlcResponse);
                    }
                    if (arcPlcResponse?.plcPayment?.error) {
                        setIsErrorArcPlc(true);
                        enqueueSnackbar("Could not load PLC projections.", { preventDuplicate: true, variant: 'warning' });
                        console.warn(arcPlcResponse);
                    }

                    return arcPlcResponse;
                }
            } catch (err) {
                console.warn(err);
                enqueueSnackbar("Could not load PLC projections.", { preventDuplicate: true, variant: 'warning' });
            }
        }
    }

    // --- --- ---

    const [arcPlc, setArcPlc] = useState({});
    const [isLoadingArcPlc, setIsLoadingArcPlc] = useState(true);
    const [isErrorArcPlc, setIsErrorArcPlc] = useState(false);

    const [totals, setTotals] = useState({});
    const [crops, setCrops] = useState([]);

    const loadArcPlc = async () => {
        try {
            setIsLoadingArcPlc(true);

            const _arcPlcResponses_async = [];
            for (var currentBaseAcre of baseAcres) {
                const _arcPlc = await selectArcPlc(currentBaseAcre);
                _arcPlcResponses_async.push({
                    baseAcres: currentBaseAcre,
                    arcPlcResponse: _arcPlc,
                });
            }

            const _arcPlcResponses = (await Promise.all(_arcPlcResponses_async))
                .filter(_ap => _ap);
            setArcPlc(_arcPlcResponses);

            const _totals = {
                arcPayment: 0,
                plcPayment: 0,
                base_acreage: 0,
                plc_yield: 0,
            };
            _arcPlcResponses.forEach(_currentArcPlc => {
                if (_currentArcPlc?.arcPlcResponse?.arcPayment) _totals.arcPayment += _currentArcPlc.arcPlcResponse.arcPayment
                if (_currentArcPlc?.arcPlcResponse?.plcPayment) _totals.plcPayment += _currentArcPlc.arcPlcResponse.plcPayment
                if (_currentArcPlc?.baseAcres) _totals.base_acreage += _currentArcPlc.baseAcres.base_acreage
                if (_currentArcPlc?.baseAcres) _totals.plc_yield += _currentArcPlc.baseAcres.plc_yield * _currentArcPlc.baseAcres.base_acreage
            });
            _totals.plc_yield = _totals.plc_yield / _.sumBy(_arcPlcResponses, arcplc => arcplc.baseAcres.base_acreage);
            setTotals(_totals);

            const _crops = _.uniq(_arcPlcResponses.map(_arcplc => _arcplc?.baseAcres?.commodity_name))
                .map(_commodityName => ({ commodity_name: _commodityName }))
            for (const _currentCrop of _crops) {
                const _cropArcPlcResponses = _arcPlcResponses.filter(_arcPlc =>
                    _arcPlc?.baseAcres?.commodity_name == _currentCrop?.commodity_name
                );
                const _cropTotals = {
                    arcPayment: 0,
                    plcPayment: 0,
                    base_acreage: 0,
                    plc_yield: 0,
                };
                _cropArcPlcResponses.forEach(_currentArcPlc => {
                    if (_currentArcPlc?.arcPlcResponse?.arcPayment) _cropTotals.arcPayment += _currentArcPlc.arcPlcResponse.arcPayment
                    if (_currentArcPlc?.arcPlcResponse?.plcPayment) _cropTotals.plcPayment += _currentArcPlc.arcPlcResponse.plcPayment
                    if (_currentArcPlc?.baseAcres) _cropTotals.base_acreage += _currentArcPlc.baseAcres.base_acreage
                    if (_currentArcPlc?.baseAcres) _cropTotals.plc_yield += _currentArcPlc.baseAcres.plc_yield * _currentArcPlc.baseAcres.base_acreage
                });
                _cropTotals.plc_yield = _cropTotals.plc_yield / _.sumBy(_cropArcPlcResponses, arcplc => arcplc.baseAcres.base_acreage);

                _currentCrop.arcPayment = _cropTotals.arcPayment;
                _currentCrop.plcPayment = _cropTotals.plcPayment;
                _currentCrop.base_acreage = _cropTotals.base_acreage;
                _currentCrop.plc_yield = _cropTotals.plc_yield;
            }
            setCrops(_crops);

            setIsLoadingArcPlc(false);

            return _totals;
        } catch (err) {
            setIsLoadingArcPlc(false);
            setIsErrorArcPlc(true);
            setArcPlc({});
            console.warn(err);
            enqueueSnackbar("Could not load ARC/PLC projections.", { preventDuplicate: true, variant: 'error' });
        }
    }

    useEffect(() => {
        if (baseAcres?.length && plcPaymentRates?.length && arccoYields?.length) {
            loadArcPlc();
        }
    }, [baseAcres?.length || 0, plcPaymentRates?.length || 0, arccoYields?.length || 0]);

    // --- --- ---


    return ({
        responses: arcPlc,
        crops: crops,

        isLoading: isLoadingArcPlc,
        isError: isErrorArcPlc,

        totals: totals
    });

}