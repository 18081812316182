import { DeleteForeverOutlined, InfoOutlined } from "@mui/icons-material"
import { Box, Button, IconButton, Stack, Typography } from "@mui/material"

export const ButtonLargeSquare = ({
    icon,
    title,
    subHeader,
    onClick,

    action,
    actionIcon,

    sx,
    disabled,

    props
}) => {
    return <Box sx={{ position: "relative" }}>
        <Button fullWidth {...props} onClick={onClick} className="buttonLargeSquare" variant="text" sx={{ ...sx }} disabled={disabled}>
            <Stack display="flex" direction="column" spacing={1} alignItems="center" justifyItems="center">
                {icon || <InfoOutlined />}

                <Box alignContent="center">
                    <Typography variant="button" color="primary">
                        {title}
                    </Typography>
                </Box>
                {subHeader &&
                    <Box alignContent="center">
                        <Typography variant="caption" color="GrayText" sx={{ whiteSpace: "pre-wrap" }}>
                            {subHeader || " "}
                        </Typography>
                    </Box>}
            </Stack>
        </Button>

        {(action || actionIcon) &&
            <Box sx={{ position: "absolute", top: 0, right: 0, p: "2px" }}>
                <IconButton onClick={(e) => { e.stopPropagation(); action && action(); }} disabled={disabled && !action}>
                    {actionIcon || <DeleteForeverOutlined />}
                </IconButton>
            </Box>}

    </Box>
}