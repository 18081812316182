import { getComboTitle } from "@datanac/datanac-api-toolkit/dist/insurance/optimizer/optimizer";
import { EastOutlined } from "@mui/icons-material";
import { Box, Button, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery } from "@mui/material";
import _ from "lodash";
import { formatPrice } from "utility/formatters";
import InsuranceOptimizeDisplayGroup from "./InsuranceOptimizeDisplayGroup";


export function InsurancePlanComboDetailsPanel({
    countyPractice,
    displayInsurancePlanCombo,
    isSplitScreen,
    insurancePlanCount,

    onChange
}) {
    const isXXL = useMediaQuery('(min-width:1537px)');

    return (<>
        <Box className="denseContainer" sx={{ p: { xs: 0, xl: 0 }, pt: 0, pb: 0 }}>
            <Stack direction='column'>
                <Table size="small" sx={{ backgroundColor: 'white' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Plan</TableCell>
                            <TableCell align="right">Producer Premium /ac</TableCell>
                            <TableCell align="right">Liability /ac</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {displayInsurancePlanCombo?.comboStressTestResults?.insurancePlans?.map((p, index) =>
                            <TableRow key={index}>
                                <TableCell>
                                    <Typography variant="body1">
                                        {getComboTitle([p.insurancePlanParameters])}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography variant="body1">
                                        {formatPrice(p.insurancePlanParameters?.producer_premium_amount_per_acre, 2)}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography variant="body1">
                                        {formatPrice(p.insurancePlanParameters?.liability_amount / p.insurancePlanParameters?.reported_acreage, 2)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                        {insurancePlanCount > 0
                            && insurancePlanCount > displayInsurancePlanCombo?.comboStressTestResults?.insurancePlans?.length
                            && Array(insurancePlanCount - displayInsurancePlanCombo?.comboStressTestResults?.insurancePlans?.length).fill().map((r, index) =>
                                <TableRow key={index}>
                                    <TableCell>
                                        <Typography>-</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography>-</Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography>-</Typography>
                                    </TableCell>
                                </TableRow>
                            )}


                        {insurancePlanCount > 1 && displayInsurancePlanCombo?.comboStressTestResults?.insurancePlans?.length == 1 &&
                            <TableRow>
                                <TableCell>
                                    <Typography>-</Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography>-</Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography>-</Typography>
                                </TableCell>
                            </TableRow>
                        }
                        {insurancePlanCount > 1 && displayInsurancePlanCombo?.comboStressTestResults?.insurancePlans?.length > 1 &&
                            <TableRow sx={{ backgroundColor: "#f8f8f8" }}>
                                <TableCell>
                                    <Typography variant="body1">
                                        Total
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography variant="body1">
                                        {formatPrice(_.sum(displayInsurancePlanCombo?.comboStressTestResults?.insurancePlans.map(p => p.insurancePlanParameters?.producer_premium_amount_per_acre)), 2)}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography variant="body1">
                                        {formatPrice(
                                            _.sum(displayInsurancePlanCombo?.comboStressTestResults?.insurancePlans.map(p => p.insurancePlanParameters?.liability_amount / p.insurancePlanParameters?.reported_acreage))
                                            , 2)}
                                    </Typography>
                                </TableCell>
                            </TableRow>}
                    </TableBody>
                </Table>

                <InsuranceOptimizeDisplayGroup
                    farmParameters={countyPractice}
                    insurancePlans={displayInsurancePlanCombo?.comboStressTestResults?.insurancePlans}
                    stressTestResults={displayInsurancePlanCombo?.comboStressTestResults}
                    historicalStressTestResults={displayInsurancePlanCombo?.historicalStressTestResults}
                    stressTestParameters={{}}
                    ceclResults={displayInsurancePlanCombo?.comboStressTestResults?.cecl}
                    isSplitScreen={isSplitScreen}

                    headerButtonContent={!isXXL && isSplitScreen &&
                        <Button variant="contained"
                            onClick={() => onChange && onChange(displayInsurancePlanCombo)}>
                            Select this Plan <EastOutlined sx={{ ml: 1 }} />
                        </Button>
                    }
                />

                {isXXL && isSplitScreen &&
                    <Box display="flex" justifyContent="flex-end" sx={{ pt: 1 }}>
                        <Button variant="contained" sx={{ justifySelf: "flex-end" }}
                            onClick={() => onChange && onChange(displayInsurancePlanCombo)}>
                            Select this Plan <EastOutlined sx={{ ml: 1 }} />
                        </Button>
                    </Box>}
            </Stack>
        </Box>
    </>);
}
