import { useDialog } from '@datanac/datanac-mui-toolkit'
import DeleteForeverOutlined from '@mui/icons-material/DeleteForeverOutlined'
import EastOutlined from '@mui/icons-material/EastOutlined'
import { Box, Card, CardActions, CardContent, CardHeader, Chip, Divider, IconButton, Stack, Typography } from '@mui/material'
import { AppContext } from 'App'
import { BUDGET_STATUS_ACTIVE, BUDGET_STATUS_DRAFT } from 'components/Budget/helpers'
import { InsuranceIcon } from 'components/Icons/Icons'
import { SkeletonWrapper } from 'mui-toolkit/SkeletonWrapper'
import { useSnackbar } from 'notistack'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatPrice } from 'utility/formatters'

export default function InsuranceSummaryCard({
    insuranceScenario,
    isLoading,

    onChange
}) {

    const { globalState } = useContext(AppContext);

    const navigate = useNavigate();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // --- --- ---

    const toggleStatus = () => {
        const _newState = { ...insuranceScenario };
        _newState.status = (insuranceScenario.status == BUDGET_STATUS_ACTIVE ? BUDGET_STATUS_DRAFT : BUDGET_STATUS_ACTIVE);
        onChange && onChange(_newState);
    }

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const handleDeleteOnClick = () => {
        confirmDialogActions.confirm({
            title: "Delete this entry?",
            prompt: "Are you sure you want to delete this entry?",
            promptDetail: null,
            callback: () => {
                const _newState = { ...insuranceScenario };
                _newState.is_active = false;
                onChange && onChange(_newState);
            }
        })
    }

    return (
        <>
            <Card>
                <CardHeader
                    avatar={<InsuranceIcon />}
                    title={insuranceScenario?.name}
                    action={
                        <IconButton onClick={handleDeleteOnClick}>
                            <DeleteForeverOutlined sx={{ color: 'black' }} />
                        </IconButton>
                    }
                />

                <CardContent>
                    <Box height="100%">
                        <Stack direction="column" spacing={1} height="100%">
                            <Stack direction='row' spacing={0} alignItems='center' justifyItems='center'
                                sx={{ flexWrap: 'wrap', gap: 1 }}
                            >
                                {/* {cropProfiles?.map((currentCropProfile, index) =>
                                    <Chip key={index} label={currentCropProfile?.commodity_name + ' ' + formatPracticeName(currentCropProfile?.practice_name)} />
                                )} */}
                            </Stack>

                            <Stack direction='row' flexWrap='wrap' spacing={1}>
                                <Box>
                                    <SkeletonWrapper isLoading={(insuranceScenario?.totals?.producer_premium_amount_per_acre || 0) == 0 && isLoading}>
                                        <Typography variant='h6'>
                                            {formatPrice(insuranceScenario?.totals?.producer_premium_amount_per_acre, 2)}
                                        </Typography>
                                    </SkeletonWrapper>
                                    <Typography variant='body1' sx={{ fontSize: '8pt', leading: '0px', lineHeight: '9pt', height: '100%' }}>
                                        PRODUCER<br />
                                        PREMIUM /ac
                                    </Typography>
                                </Box>

                                <Divider orientation='vertical' flexItem />

                                <Box>
                                    <SkeletonWrapper isLoading={(insuranceScenario?.totals?.liability_amount_per_acre || 0) == 0 && isLoading}>
                                        <Typography variant='h6'>
                                            {formatPrice(insuranceScenario?.totals?.liability_amount_per_acre, 0)}
                                        </Typography>
                                    </SkeletonWrapper>
                                    <Typography variant='body1' sx={{ fontSize: '8pt', leading: '0px', lineHeight: '9pt', height: '100%' }}>
                                        COVERAGE /ac
                                    </Typography>
                                </Box>
                            </Stack>

                        </Stack>
                    </Box>
                </CardContent>

                <CardActions>
                    <Chip
                        label={insuranceScenario?.status == BUDGET_STATUS_ACTIVE ? 'Active' : 'Draft'}
                        color={insuranceScenario?.status == BUDGET_STATUS_ACTIVE ? 'secondary' : 'default'}
                        onClick={toggleStatus} />

                    <IconButton onClick={() => { navigate(`/insurance/${insuranceScenario?.id}`) }}>
                        <EastOutlined sx={{ color: 'black' }} />
                    </IconButton>
                </CardActions>
            </Card>

            {confirmDialogComponent}
        </>
    )
}
