import { UsersApiHelper } from "api/ApiHelper";
import { useState } from "react";

export const USER_ROLE_ADMIN = "admin";
export const USER_ROLE_AGENT = "agent";

export const useSession = ({
    globalState
}) => {
    const [insurancePlanStoreTimestamp, setInsurancePlanStoreTimestamp] = useState(new Date());
    // const insurancePlansStore = createStore('insurancePlans', 'insurancePlans');

    const [stressTestResultsStoreTimeStamp, setStressTestResultsStoreTimeStamp] = useState(new Date());
    // const stressTestResultsStore = createStore('stressTestResults', 'stressTestResults');

    // --- --- ---
    // InsurancePlans:

    const setInsurancePlans = async (value) => {
        setInsurancePlanStoreTimestamp(Date());
        // set(farm_uid, value, insurancePlansStore);

        UsersApiHelper.insurancePlansUpdate(value, globalState);
    }

    const getInsurancePlans = async (farm_uid) => {
        // return get(uuid, insurancePlansStore);

        const parameters = {
            farm_uid: farm_uid,
            is_active: true,
            unit_is_active: true
        };
        return UsersApiHelper.users_selectObject("UnitInsurancePlans", parameters, globalState);
    }

    const getAllInsurancePlans = async () => {
        // return entries(insurancePlansStore);

        const parameters = {
            is_active: true,
            unit_is_active: true
        };
        return UsersApiHelper.users_selectObject("UnitInsurancePlans", parameters, globalState);
    }

    const getStressTestResultsReport = async () => {
        return UsersApiHelper.reportStressTestResults(globalState.myFarms, globalState);
    }

    const getInsurancePlansByCountyPractice = async ({
        location_state_name,
        location_county_name,
        commodity_name,
        type_name,
        practice_name
    }) => {
        const parameters = {
            state_name: location_state_name,
            county_name: location_county_name,
            commodity_name,
            type_name,
            practice_name,
            is_active: true,
            unit_is_active: true
        };
        return UsersApiHelper.users_selectObject("UnitInsurancePlans", parameters, globalState);
    }

    // --- --- ---
    // StressTestParameters:

    const setStressTestParameters = async (farm_uid, value) => {
        const _stressTestParameters = { ...value, farm_uid: farm_uid };
        UsersApiHelper.stressTestParametersUpdate(_stressTestParameters, globalState);
    }

    const getStressTestParameters = async (farm_uid) => {
        const parameters = {
            farm_uid: farm_uid,
            unit_is_active: true
        };

        return await UsersApiHelper.users_selectObject("UnitStressTestParameters", parameters, globalState);
    }

    const getAllStressTestParameters = async () => {
        const parameters = {
            unit_is_active: true
        };

        return UsersApiHelper.users_selectObject("UnitStressTestParameters", parameters, globalState);
    }

    // --- --- ---
    // StressTestResults:

    const setStressTestResults = async (farm_uid, value) => {
        // setStressTestResultsStoreTimeStamp(new Date());
        // set(farm_uid, value, stressTestResultsStore);

        const _stressTestResults = { results: value, farm_uid: farm_uid };
        UsersApiHelper.stressTestResultsUpdate(_stressTestResults, globalState);
    }

    const getStressTestResults = async (filter) => {
        // return get(uuid, stressTestResultsStore);
        const parameters = {
            ...filter,
            unit_is_active: true
        };

        const _stressTestResults = await UsersApiHelper.users_selectObject("UnitStressTestResults", parameters, globalState);
        // console.log(_stressTestResults);
        if (_stressTestResults && _stressTestResults[0] && _stressTestResults[0].results) {
            _stressTestResults[0].results = JSON.parse(_stressTestResults[0].results);
            return _stressTestResults[0];
        }
    }

    const getAllStressTestResults = async () => {
        // return entries(stressTestResultsStore);
        const parameters = {
            unit_is_active: true
        };

        return UsersApiHelper.users_selectObject("UnitStressTestResults", parameters, globalState);
    }

    return {
        state: {
            insurancePlanStoreTimestamp,
            stressTestResultsStoreTimeStamp
        },
        actions: {
            // InsurancePlans:
            setInsurancePlans,
            getInsurancePlans,
            getInsurancePlansByCountyPractice,
            getAllInsurancePlans,

            // StressTestParameters:
            setStressTestParameters,
            getStressTestParameters,
            getAllStressTestParameters,

            // StressTestResults:
            setStressTestResults,
            getStressTestResults,
            getAllStressTestResults,

            getStressTestResultsReport
        }
    };
};
