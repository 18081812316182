import { Authenticator, Image, View, withAuthenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import { USER_ROLE_ADMIN, USER_ROLE_PRODUCER } from '@datanac/datanac-api-toolkit/dist/api-helper'
import { getCurrentReinsuranceYear } from '@datanac/datanac-api-toolkit/dist/utility/InsurancePlanHelper'
import { Box, Divider, Stack, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Auth, Hub } from 'aws-amplify'
import NavigationRoutes from 'components/Menu/NavigationRoutes'
import { getStorageValue, setStorageValue } from 'core/useLocalStorage'
import { useSession } from 'core/useSession'
import { createContext, useEffect, useReducer, useState } from 'react'
import { IconContext } from 'react-icons'
import './global.css'
import { AmplifySessionManager, GROUP_TILLEY_BROKERS, GROUP_TILLEY_LENDERS } from 'api/AmplifySessionManager'
import { jwtDecode } from 'jwt-decode'
import { set } from 'lodash'
import EnableMfaTotp from 'EnableMfaTotp'

export const datanacTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#3d3f25',
      contrastText: '#fff',
    },
    secondary: {
      main: 'rgba(0,0,0,0.5)',
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
    info: {
      main: '#8cc63f',
    },
    divider: '#8cc63f',
  },

  typography: {
    fontSize: 12,
    htmlFontSize: 16,
    fontFamily: 'Montserrat, Calibri',
    fontWeightRegular: 600,
    fontWeightMedium: 600
  },

  props: {
    MuiAppBar: {
      color: 'default',
    },

    MuiButton: {
      size: 'large',
    },
    MuiButtonGroup: {
      size: 'small',
    },
    MuiCheckbox: {
      size: 'small',
    },
    MuiFab: {
      size: 'small',
    },
    MuiFormControl: {
      margin: 'dense',
      size: 'small',
    },
    MuiFormHelperText: {
      margin: 'dense',
    },
    MuiIconButton: {
      size: 'small'
    },
    MuiInputBase: {
      margin: 'dense',
    },
    MuiInputLabel: {
      margin: 'dense',
    },
    MuiRadio: {
      size: 'small',
    },
    MuiSwitch: {
      size: 'small',
    },
    MuiTextField: {
      margin: 'dense',
      size: 'small',
    },
    MuiList: {
      dense: true,
    },
    MuiMenuItem: {
      dense: true,
    },
    MuiTable: {
      size: 'small',
    },
  },

  shape: {
    borderRadius: 7,
  },

  root: {
    display: 'none'
  },

  components: {
    MuiMenu: {
      defaultProps: {
        disableScrollLock: true,
        disabled: true,
      }
    },

    MuiDialog: {
      defaultProps: {
        disableScrollLock: true,
      }
    },

    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'contained' &&
          {
          }),
          ...(ownerState.variant === 'outlined' &&
          {
          }),
        })
      }
    },
  }
});

export const AppContext = createContext();

/// Reducer for AppContext state.
const reducer = (state, action) => {
  // console.log("globalDispatch", state, action);

  const LOCALSTORAGE_KEYS = [
    "crop_year",
    "subscription_payment_timestamp",
  ];

  switch (action.type) {
    case 'update':
      if (LOCALSTORAGE_KEYS.includes(action.payload.key)) {
        setStorageValue(action.payload.key, action.payload.value);
      }

      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case 'updateMyFarms':
      // console.log("globalDispatch / updateMyFarms", action.payload.value);
      return {
        ...state,
        myFarms: action.payload.value || [],
        myFarmsTimestamp: new Date()
      };

    case 'updateFarm':
      const _selectedFarm = action.payload.value;
      const __myFarms = state.myFarms.map(ff => {
        if (ff.uid == _selectedFarm.uid) {
          return _selectedFarm;
        } else {
          return ff;
        }
      });

      return {
        ...state,
        myFarms: __myFarms || [],
        myFarmsTimestamp: new Date()
      };

    case 'updateFarms':
      const _farmsToUpdate = action.payload.value;
      const ___myFarms = state.myFarms.map(ff => {
        const _findFarm = _farmsToUpdate.find(f => f.uid == ff.uid);
        return _findFarm || ff;
      });

      return {
        ...state,
        myFarms: ___myFarms || [],
        myFarmsTimestamp: new Date()
      };

    case 'updateMyFarmsInsurance':
      return {
        ...state,
        myFarmsInsurance: action.payload.value,
      };

    case 'setProducer':
      setStorageValue("producer_token", action.payload.producer_token);

      return {
        ...state,
        producer_token: action?.payload?.producer_token,
        agent_token: action?.payload?.agent_token
      }

    case 'login':
      setStorageValue("user_role", action.payload.user_role);
      setStorageValue("producer_token", action.payload.producer_token);
      setStorageValue("agent_token", action.payload.agent_token);

      setStorageValue("user_groups", action.payload.user_groups);
      setStorageValue("is_lender", action.payload.is_lender);
      setStorageValue("is_broker", action.payload.is_broker);
      setStorageValue("is_admin", action.payload.is_admin);

      // setStorageValue("bearer_token", action.payload.bearer_token);
      // setStorageValue("refresh_token", action.payload.refresh_token);

      return {
        ...state,
        user_role: action.payload.user_role,
        user_groups: action.payload.user_groups,
        is_lender: Boolean(action.payload.is_lender),
        is_broker: Boolean(action.payload.is_broker),
        is_admin: Boolean(action.payload.is_admin),

        producer_token: action.payload.producer_token,
        agent_token: action.payload.agent_token,

        isDatanacAuthenticated: true,

        bearer_token: action.payload.bearer_token,
        refresh_token: action.payload.refresh_token
      }

    case 'logout':
      setStorageValue("producer_token", null);
      setStorageValue("agent_token", null);

      // setStorageValue("bearer_token", null);
      // setStorageValue("refresh_token", null);

      Auth.signOut();

      return {
        ...state,
        user_role: null,
        producer_token: null,
        agent_token: null,

        bearer_token: null,
        refresh_token: null,

        isDatanacAuthenticated: false,

        user_groups: [],
        is_lender: false,
        is_broker: false,
        is_admin: false,
      }

    case 'resetAuthentication':
      //setStorageValue("bearer_token", null);
      //setStorageValue("refresh_token", null);

      return {
        ...state,
        bearer_token: null,
        refresh_token: null
      }

    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

const initializer = initialState => {
  return {
    user_role: getStorageValue("user_role"),
    user_groups: [],

    producer_token: getStorageValue("producer_token"),
    agent_token: getStorageValue("agent_token"),

    user_groups: getStorageValue("user_groups"),
    is_lender: Boolean(getStorageValue("is_lender")) || false,
    is_broker: Boolean(getStorageValue("is_broker")) || false,
    is_admin: Boolean(getStorageValue("is_admin")) || false,

    bearer_token: '', //getStorageValue("bearer_token"),
    refresh_token: '', //getStorageValue("refresh_token"),

    isMenuOpen: true,
    crop_year: getStorageValue("crop_year") || getCurrentReinsuranceYear(),

    subscription_payment_timestamp: getStorageValue("subscription_payment_timestamp"),

    myFarms: [],
    myFarmsTimestamp: '',
    myFarmsInsurance: []
  };
};

// export const neutralizeBack = () => {
//   window.history.pushState(null, "", window.location.href);
//   window.onpopstate = () => {
//     window.history.pushState(null, "", window.location.href);
//   };
// };
// neutralizeBack();

function App() {
  const [globalState, globalDispatch] = useReducer(reducer, {}, initializer);
  const session = useSession({ globalState });

  useEffect(() => {
    const loadMyUser = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        checkMfaStatus(currentUser);

        const session = await Auth.currentSession();
        const token = session.getIdToken();
        const jwt = token.getJwtToken();

        const user_role = token?.payload['custom:datanac_user_role'];
        const producer_token = (user_role == USER_ROLE_PRODUCER) ?
          token?.payload['custom:producer_token']
          : getStorageValue("producer_token")?.toUpperCase();// Normalize to upper
        const agent_token = (token?.payload['custom:agent_token'] ?
          token?.payload['custom:agent_token']
          : getStorageValue("agent_token"))?.toUpperCase();// Normalize to upper

        const decodedToken = jwtDecode(jwt);
        const _user_groups = decodedToken['cognito:groups'] || [];
        const _is_lender = _user_groups?.includes(GROUP_TILLEY_LENDERS) || false;
        const _is_broker = _user_groups?.includes(GROUP_TILLEY_BROKERS) || false;
        const _is_admin = (user_role === USER_ROLE_ADMIN) || false;

        globalDispatch({
          type: 'login',
          payload: {
            producer_token: producer_token,
            agent_token: agent_token,
            user_role: user_role,

            user_groups: _user_groups,
            is_admin: Boolean(_is_admin),
            is_lender: Boolean(_is_lender),
            is_broker: Boolean(_is_broker),
          },
        });
      } catch (error) {
        console.error("Error loading user:", error);
      }
    }

    Hub.listen('auth', loadMyUser);
  }, []);

  // useEffect(() => {
  //   if (globalState.myFarmsTimestamp) {
  //     // console.log("updateUserFarmApi", state.myFarmsTimestamp, state.myFarms);
  //     updateUserFarmApi({
  //       user_role: globalState.user_role,
  //       agent_token: globalState.agent_token,
  //       producer_token: globalState.producer_token,
  //       my_farms: globalState.myFarms
  //     });
  //   }
  // }, [globalState.myFarms, globalState.myFarmsTimestamp])

  const checkMfaStatus = async (user) => {
    try {
      const mfaType = await Auth.getPreferredMFA(user);
      setStorageValue("mfaType", mfaType);
    } catch (error) {
      console.error("Error checking MFA status:", error);
    }
  };

  const handleLogoutClick = () => {
    globalDispatch({ type: "logout" });
  }

  return (
    <>
      <Authenticator
        hideSignUp={true}
        formFields={{
          signIn: {
            username: {
              placeholder: 'Typically your email address',
            },
          },
        }}
        components={{
          Header() {
            return (
              <Box textAlign="center" padding={3} paddingTop={6}>
                <Image alt="Tilley" src="/images/Tilley_logo_RGB_300.png" />
              </Box>
            );
          },

          Footer() {
            return (<Stack paddingTop={4} paddingBottom={2} spacing={2} alignItems="center"
              sx={{ marginTop: "auto" }}>
              <Divider flexItem />
              <Typography>
                Customer Support: <a href="tel:+1-800-823-5572">800-823-5572</a>
              </Typography>
              <Typography>
                © 2023 Tilley by Daitaas
              </Typography>
            </Stack>)
          }
        }}
      >

        {getStorageValue('user_role') != USER_ROLE_PRODUCER && getStorageValue('mfaType') === 'NOMFA' ? (
          <EnableMfaTotp handleLogoutClick={handleLogoutClick} type={'login'} />
        ) : (
          <AppContext.Provider
            value={{ globalState: globalState, globalDispatch: globalDispatch, session: session }}
          >
            <ThemeProvider
              theme={datanacTheme}
            >
              <IconContext.Provider value={{ className: 'react-icons', size: 'auto' }}>
                <NavigationRoutes />
              </IconContext.Provider>
            </ThemeProvider>
          </AppContext.Provider>
        )}
      </Authenticator>
    </>
  )
}

export default App;
