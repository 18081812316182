import { UsersApiHelper } from "api/ApiHelper";
import { AppContext } from "App";
import { propUpdateReducer } from "components/Core/propUpdateReducer";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useReducer, useState } from "react";
import { v4 } from "uuid";
import { CONTAINER_NAME_BALANCE_SHEET_ASSETS_CURRENT, ITEM_NAME_BALANCE_SHEET_ASSETS_ACCOUNTS_RECEIVABLE_CROPS, OBJECT_NAME_BALANCE_SHEET } from "../forms/BalanceSheetHelper";
import { DOCUMENT_FORM_NAME_BALANCE_SHEET } from "@datanac/datanac-api-toolkit/dist/import/importHelpers";
import { MODULE_FINANCIAL } from "components/Menu/NavigationMenu";

export const schedule_financial_statement_accounts_liquid = "schedule_financial_statement_accounts_liquid";
export const schedule_financial_statement_accounts_receivable = "schedule_financial_statement_accounts_receivable";
export const schedule_financial_statement_accounts_payable = "schedule_financial_statement_accounts_payable";
export const schedule_financial_statement_inventories = "schedule_financial_statement_inventories";
export const schedule_financial_statement_prepaids = "schedule_financial_statement_prepaids";
export const schedule_financial_statement_notes_receivable = "schedule_financial_statement_notes_receivable";
export const schedule_financial_statement_equities = "schedule_financial_statement_equities";
export const schedule_financial_statement_realestate = "schedule_financial_statement_real_estate";

export const schedule_financial_statement_confirmation = "schedule_financial_statement_confirmation";

export const SCHEDULE_FINANCIAL_CATEGORIES = [
    schedule_financial_statement_accounts_liquid,
    schedule_financial_statement_accounts_receivable,
    schedule_financial_statement_accounts_payable,
    schedule_financial_statement_inventories,
    schedule_financial_statement_prepaids,
    schedule_financial_statement_notes_receivable,
    schedule_financial_statement_equities,
    schedule_financial_statement_realestate
]

// ---

export const FILTER_TERMS_ASSETS_CURRENT_SCHEDULES = [
    schedule_financial_statement_accounts_liquid,
    schedule_financial_statement_accounts_receivable
];
export const FILTER_TERMS_LIABILITIES_CURRENT_SCHEDULES = [
    schedule_financial_statement_accounts_payable
];

export const FILTER_TERMS_ASSETS_INTERMEDIATE_SCHEDULES = [// aka non-current
    schedule_financial_statement_inventories,
    schedule_financial_statement_prepaids,
    schedule_financial_statement_notes_receivable,

    schedule_financial_statement_equities,
];
export const FILTER_TERMS_LIABILITIES_INTERMEDIATE_SCHEDULES = [
];

export const FILTER_TERMS_ASSETS_LONGTERM_SCHEDULES = [
    schedule_financial_statement_realestate
];
export const FILTER_TERMS_LIABILITIES_LONGTERM_SCHEDULES = [
];

// ---

export const useFinancialStatementSchedule = ({
    year,
    category
}) => {
    const { globalState } = useContext(AppContext);
    const { enqueueSnackbar } = useSnackbar();

    // ---

    const [financialStatementScheduleAccounts, setFinancialStatementScheduleAccounts] = useState([]);

    useEffect(() => {
        if (year && category) {
            loadFinancialStatementScheduleAccounts(/*no filter*/);
        }
    }, [year, category])

    const loadFinancialStatementScheduleAccounts = async (filter) => {
        const _filter = filter || {
            year,
            category,
            is_active: true
        };
        const _results = await UsersApiHelper.users_selectObject("FinancialStatementScheduleAccount", _filter, globalState);
        setFinancialStatementScheduleAccounts(_results);
        return _results;
    }

    // --- --- ---

    const updateFinancialStatementScheduleAccounts = async (account) => {
        if (!account.id) {
            account.id = v4();
        }
        if (year) {
            account.year = year;
        }
        if (!account.category && category) {
            account.category = category;
        }
        account.producer_token = globalState.producer_token;
        await UsersApiHelper.updateFinancialStatementScheduleAccounts(account, globalState);
    }

    return {

        financialStatementScheduleAccounts,
        actions: {

            updateFinancialStatementScheduleAccounts,
            loadFinancialStatementScheduleAccounts
        }
    }
}