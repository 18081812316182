import { getCurrentReinsuranceYear } from '@datanac/datanac-api-toolkit/dist/utility/InsurancePlanHelper'
import { useDialog } from '@datanac/datanac-mui-toolkit'
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography, useMediaQuery } from '@mui/material'
import { AppContext } from 'App'
import { getBudgetYears } from 'components/Budget/helpers'
import DataFormSelect from 'components/Core/DataFormSelect'
import { propUpdateReducer } from 'components/Core/propUpdateReducer'
import { StatefulControlledTextField } from 'components/Core/StatefulControlledTextField'
import { FarmVaultIcon } from 'components/Icons/Icons'
import { useBlockerPrompt } from 'components/Menu/useBlockerPrompt'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { UsersApiHelper } from '../../../api/ApiHelper'
import { CROP_YEAR_POTENTIAL } from './helpers'
import { useFormStatus } from 'mui-toolkit/useFormStatus'


export default function FarmBaseAcreWorkspace() {
    const { globalState, globalDispatch, session } = useContext(AppContext);

    const {
        crop_year,
        location_state_name, location_county_name,
        producer_farm_id, producer_farm_base_acre_id
    } = useParams();
    const navigate = useNavigate();

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const [isLoading, setIsLoading] = useState(true);
    const { component: formStatusComponent, actions: formStatusActions } = useFormStatus();

    const [producerFarm, dispatchProducerFarm] = useReducer(propUpdateReducer, {});

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    const loadProducerFarm = async () => {
        try {
            if (location_state_name && location_county_name && producer_farm_id != 0) {
                const producerFilter = {
                    producer_token: globalState.producer_token,
                    id: producer_farm_id,
                    ...(crop_year != CROP_YEAR_POTENTIAL && { crop_year }),
                    is_active: true
                };
                const farms = await UsersApiHelper.selectProducerFarms(producerFilter, globalState);
                if (farms?.length) {
                    dispatchProducerFarm({
                        type: 'updateMany',
                        payload: farms[0]
                    });
                }
            }
        } catch (err) {
            dispatch({
                type: 'replace',
                payload: {}
            });
            handleError(err);
        }
    }

    useEffect(() => {
        if (globalState?.producer_token && producer_farm_id != 0) {
            loadProducerFarm();
        }
    }, [location_state_name, location_county_name, producer_farm_id,
        globalState?.producer_token]);

    // --- --- ---

    const [state, dispatch] = useReducer(propUpdateReducer, {
        crop_year: (crop_year != CROP_YEAR_POTENTIAL) ? crop_year : globalState?.crop_year,
        is_active: true
    });
    const loadBaseAcres = async () => {
        try {
            const baseAcresFilter = {
                producer_token: globalState.producer_token,
                id: state?.id,
                ...(crop_year != CROP_YEAR_POTENTIAL && { crop_year }),
                is_active: true
            };
            const baseAcres = await UsersApiHelper.selectProducerFarmBaseAcres(baseAcresFilter, globalState);
            if (baseAcres?.length) {
                dispatch({
                    type: 'updateMany',
                    payload: baseAcres[0]
                });
            }

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            handleError(err);
        }
    }

    useEffect(() => {
        if (state?.id && globalState?.producer_token) {
            loadBaseAcres();
        }
    }, [state?.id, globalState?.producer_token])

    useEffect(() => {
        dispatch({
            type: 'update',
            payload: { key: "producer_farm_id", value: producer_farm_id },
        });

        if (producer_farm_base_acre_id == 0) {
            const _producer_farm_base_acre_id = uuidv4();
            dispatch({
                type: 'update',
                payload: { key: "id", value: _producer_farm_base_acre_id },
            });
        } else {
            dispatch({
                type: 'update',
                payload: { key: "id", value: producer_farm_base_acre_id },
            });
        }
    }, [producer_farm_id, producer_farm_base_acre_id]);

    // --- --- ---

    const handleChangeStateSelect = (event) => {
        formStatusActions.setIsFormDirty(true, false);

        dispatch({
            type: 'update',
            payload: { key: event.target.name, value: event.target.value },
        });
    }

    const handleChangeState = (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        if (state[event.target.name] != value) {
            formStatusActions.setIsFormDirty(true, false);

            dispatch({
                type: 'update',
                payload: { key: event.target.name, value: value },
            });
        }
    }

    const isValid = () => {
        return (
            state?.id
            && state.id != 0
            && state?.crop_year
            && state?.commodity_name
            && state?.base_acreage
            && state?.share_percent
            && state?.plc_yield
        );
    }
    useBlockerPrompt(!isValid(), "ProducerFarmBaseAcres");

    useEffect(() => {
        if (isValid() && globalState.producer_token) {
            formStatusActions.setIsFormDirty(true, true);

            UsersApiHelper.updateProducerFarmBaseAcres({
                ProducerFarmBaseAcres: [{
                    ...state,
                    producer_token: globalState.producer_token,
                    agent_token: globalState?.agent_token
                }]
            }, globalState)
                .then(() => {
                    formStatusActions.setIsSaving(false);
                })
                .catch(err => {
                    console.warn(err);
                    formStatusActions.setIsFormDirty(true, false);
                    enqueueSnackbar(err.detail, { preventDuplicate: true, variant: 'error' });
                });
        }
    }, [state])

    // --- --- ---

    const handleAddNewClick = () => {
        // navigate(`/`);
    }

    const formMaxWidthShort = '800px';
    const formMaxWidth = '1000px';

    const commodityDataFormSelect = useMemo(() => {
        return (<DataFormSelect
            value={state.commodity_name || ""}
            name="commodity_name"
            isRequired={false}
            onChange={handleChangeState}
            objectName="Commodity"
            itemFilter={{ reinsurance_year: String(state.crop_year) }}
            itemGetKey={(item) => item.commodity_name}
            itemGetLabel={(item) => item.commodity_name}
            doFetch={state.crop_year != null}
            isLoading={isLoading}
        />);
    }, [state.crop_year, state.commodity_name, isLoading]);

    return (
        <>
            <WorkspaceHeader
                title={!(state?.commodity_name) ? "Base Acres"
                    : state?.commodity_name + (" - " + state?.crop_year) || ""
                }
                breadCrumbs={[
                    {
                        title: (crop_year != CROP_YEAR_POTENTIAL ? 'Planted Acres' : 'Farm Potential')
                            + ((crop_year != CROP_YEAR_POTENTIAL && " - " + crop_year) || ''),
                        path: '/vault' + (crop_year == CROP_YEAR_POTENTIAL ? "/potential" : ""),
                        icon: <FarmVaultIcon fontSize='3pt' />
                    },
                    {
                        title: location_county_name + ", " + location_state_name,
                        path: `/vault/${crop_year}/${location_state_name}/${location_county_name}`,
                        icon: null
                    },
                    {
                        title: producerFarm?.fsa_farm_number
                            + (producerFarm?.farm_name ? (" - " + producerFarm?.farm_name) : ""),
                        path: `/vault/${crop_year}/${location_state_name}/${location_county_name}/${producerFarm?.id}`,
                        icon: null
                    },
                ]}
            />

            <Box sx={{ width: '100%', maxWidth: formMaxWidth, minHeight: '6in' }}>
                <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton'>
                    <Typography variant='h1'>
                        Base Acres
                    </Typography>
                    {formStatusComponent}
                </Stack>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={4} lg={3}>
                        <FormControl fullWidth>
                            <InputLabel id='selectYearLabel'>Program Year</InputLabel>
                            <Select
                                labelId='selectYearLabel'
                                value={state?.crop_year || ''}
                                name={"crop_year"}
                                onChange={handleChangeStateSelect}
                                label={'Program Year'}
                                disabled={crop_year != CROP_YEAR_POTENTIAL}
                            >
                                {getBudgetYears()?.map((currentItem, index) => {
                                    return <MenuItem key={index} value={currentItem}>{currentItem}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        {commodityDataFormSelect}
                    </Grid>

                    <Grid item xs={0} md={12}>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Base Acres"
                                name="base_acreage"
                                isRequired={true}
                                is
                                defaultValue={state.base_acreage || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Share %"
                                name="share_percent"
                                isRequired={true}
                                is
                                defaultValue={state.share_percent || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={0} md={12}>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="PLC Yield"
                                name="plc_yield"
                                isRequired={true}
                                is
                                defaultValue={state.plc_yield || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>

            {confirmDialogComponent}
        </>
    )
}
