import { getCurrentReinsuranceYear } from '@datanac/datanac-api-toolkit/dist/utility/InsurancePlanHelper';
import { ChartBase, HistoricalYieldTrendChart } from '@datanac/datanac-visualization-toolkit';
import { CHART_TYPE_BOXPLOT, CHART_TYPE_DOWNLOAD_CSV, CHART_TYPE_TABLE_FULL, CHART_TYPE_TIMESERIES_COUNTY } from '@datanac/datanac-visualization-toolkit/dist/Components/chart/ChartBase';
import { Box, Grid, Table, TableBody, TableCell, TableRow, Typography, useMediaQuery } from '@mui/material';
import { AppContext } from 'App';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { formatNumber } from 'utility/formatters';
import { ApiHelper, RmaApiHelper } from '../../api/ApiHelper';

export default function CountyDataView({
    countyPractice,
    onClose
}) {
    const { globalState, globalDispatch } = useContext(AppContext);

    const [priceData, setPriceData] = useState([]);
    const [historicalYieldTrendData, setHistoricalYieldTrendData] = useState([]);

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'), { noSsr: true });

    useEffect(() => {
        const _getPriceDataAsync = async () => {
            try {
                const _countyParams = {
                    ...countyPractice,
                    // county_name: countyPractice?.location_county_name,
                    state_name: countyPractice?.location_state_name
                };
                delete _countyParams.county_name;
                delete _countyParams.location_county_name;
                delete _countyParams.reinsurance_year;

                const __data = await RmaApiHelper.selectPricesHistory(_countyParams);

                const current_projected_price = (__data.find(r =>
                    r?.state_name == countyPractice?.location_state_name
                    && r?.county_name == countyPractice?.location_county_name
                    && r?.reinsurance_year == getCurrentReinsuranceYear()
                ) || {})?.projected_price;

                const _data = __data?.map(y => {
                    return ({
                        ...y,
                        date: new Date(y.reinsurance_year, 0, 1),
                        current_projected_price: current_projected_price
                    })
                });

                setPriceData(_data);
            } catch (ex) {
                console.warn(ex?.error?.message || ex);
            }
        }
        _getPriceDataAsync();
    }, [countyPractice]);

    useEffect(() => {
        const _getHistoricalYieldTrendDataAsync = async () => {
            try {
                const _countyParams = {
                    ...countyPractice,
                    // county_name: countyPractice?.location_county_name,
                    state_name: countyPractice?.location_state_name
                };
                delete _countyParams.county_name;
                delete _countyParams.location_county_name;

                const __data = await RmaApiHelper.selectHistoricalYieldTrend(_countyParams);
                const _data = __data?.map(y => {
                    return ({
                        ...y,
                        date: new Date(y.reinsurance_year, 0, 1),
                    })
                });

                setHistoricalYieldTrendData(_data);
                // console.log(_data);
            } catch (ex) {
                console.warn(ex?.error?.message || ex);
            }
        }
        _getHistoricalYieldTrendDataAsync();
    }, [countyPractice]);

    const getHistoricalMean = (rows, thresholdYears, yearFieldName, valueFieldName) => {
        if (rows && rows?.length > 0) {
            const year_threshold = (new Date()).getFullYear() - thresholdYears;
            const _filterRows = rows.filter(r =>
                r[yearFieldName] > year_threshold
                && r.county_name == countyPractice.location_county_name
            );
            const rowMax = _(_filterRows).meanBy(r => r[valueFieldName]);
            return {
                value: rowMax
            };
        }
    }

    const getHistoricalHigh = (rows, thresholdYears, yearFieldName, valueFieldName) => {
        if (rows && rows?.length > 0) {
            const year_threshold = (new Date()).getFullYear() - thresholdYears;
            const _filterRows = rows.filter(r =>
                r
                && r[yearFieldName] > year_threshold
                && r?.county_name == countyPractice.location_county_name
            );
            const rowMax = _(_filterRows).maxBy(r => r && r[valueFieldName]);
            if (rowMax) {
                return {
                    year: rowMax[yearFieldName],
                    value: rowMax[valueFieldName]
                };
            }
        }
    }

    const getHistoricalLow = (rows, thresholdYears, yearFieldName, valueFieldName) => {
        if (rows && rows?.length > 0) {
            const year_threshold = (new Date()).getFullYear() - thresholdYears;
            const _filterRows = rows.filter(r =>
                r
                && r[yearFieldName] > year_threshold
                && r?.county_name == countyPractice.location_county_name
            );
            const rowMin = _(_filterRows).minBy(r => r && r[valueFieldName]);
            if (rowMin) {
                return {
                    year: rowMin[yearFieldName],
                    value: rowMin[valueFieldName]
                };
            }
        }
    }

    return (
        <>
            <Typography variant='h6'>
                {countyPractice.location_county_name}, {countyPractice.location_state_name} - {countyPractice.commodity_name} / {countyPractice.type_name == "No Type Specified" ? "" : countyPractice.type_name + " /"} {countyPractice.practice_name}
            </Typography>
            <Box sx={{ pb: 1 }} />

            <Grid container rowSpacing={1} columnSpacing={1} padding={0}>
                <Grid item xs={12} lg={6}>
                    <div sx={{ overflow: 'clip' }}>
                        {historicalYieldTrendData?.length > 0 &&
                            <HistoricalYieldTrendChart countyPractice={countyPractice} api={ApiHelper} size={isMobile ? 'small' : null} />
                        }
                        {!(historicalYieldTrendData?.length > 0) &&
                            <Box sx={{ p: 1 }}>
                                <Typography>
                                    (No yield trend available for this commodity.)
                                </Typography>
                            </Box>
                        }
                    </div>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <div sx={{ overflow: 'clip' }}>
                        <ChartBase
                            title='Harvest Price'
                            fieldNameX='reinsurance_year'
                            fieldNameY={'harvest_price'}
                            fieldNameY2={'projected_price'}
                            fieldNameY3={null}
                            fieldNameYHighlight={'current_projected_price'}
                            fieldNameFilter={'county_name'}
                            filterValue={countyPractice?.county_name || countyPractice?.location_county_name}
                            allowedChartTypes={[CHART_TYPE_TIMESERIES_COUNTY, CHART_TYPE_BOXPLOT, CHART_TYPE_TABLE_FULL, CHART_TYPE_DOWNLOAD_CSV]}
                            size={isMobile ? "small" : null}
                            data={priceData}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell colspan='6'>
                                    Yield (Detrended)
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>&nbsp;</TableCell>
                                <TableCell align='right' sx={{ color: 'darkblue' }}>Avg</TableCell>
                                <TableCell align='right' sx={{ color: 'green' }}>Year</TableCell>
                                <TableCell align='right' sx={{ color: 'green' }}>High</TableCell>
                                <TableCell align='right' sx={{ color: '#ff5349' }}>Low</TableCell>
                                <TableCell align='right' sx={{ color: '#ff5349' }}>Year</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>10-year History</TableCell>
                                <TableCell align='right' sx={{ color: 'darkblue' }}>
                                    {formatNumber(getHistoricalMean(historicalYieldTrendData, 10, "yield_year", "detrended_yield_amount")?.value, 2)}
                                </TableCell>
                                <TableCell align='right' sx={{ color: 'green' }}>
                                    {getHistoricalHigh(historicalYieldTrendData, 10, "yield_year", "detrended_yield_amount")?.year}
                                </TableCell>
                                <TableCell align='right' sx={{ color: 'green' }}>
                                    {formatNumber(getHistoricalHigh(historicalYieldTrendData, 10, "yield_year", "detrended_yield_amount")?.value, 2)}
                                </TableCell>
                                <TableCell align='right' sx={{ color: '#ff5349' }}>
                                    {formatNumber(getHistoricalLow(historicalYieldTrendData, 10, "yield_year", "detrended_yield_amount")?.value, 2)}
                                </TableCell>
                                <TableCell align='right' sx={{ color: '#ff5349' }}>
                                    {getHistoricalLow(historicalYieldTrendData, 10, "yield_year", "detrended_yield_amount")?.year}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>5-year History</TableCell>
                                <TableCell align='right' sx={{ color: 'darkblue' }}>
                                    {formatNumber(getHistoricalMean(historicalYieldTrendData, 5, "yield_year", "detrended_yield_amount")?.value, 2)}
                                </TableCell>
                                <TableCell align='right' sx={{ color: 'green' }}>
                                    {getHistoricalHigh(historicalYieldTrendData, 5, "yield_year", "detrended_yield_amount")?.year}
                                </TableCell>
                                <TableCell align='right' sx={{ color: 'green' }}>
                                    {formatNumber(getHistoricalHigh(historicalYieldTrendData, 5, "yield_year", "detrended_yield_amount")?.value, 2)}
                                </TableCell>
                                <TableCell align='right' sx={{ color: '#ff5349' }}>
                                    {formatNumber(getHistoricalLow(historicalYieldTrendData, 5, "yield_year", "detrended_yield_amount")?.value, 2)}
                                </TableCell>
                                <TableCell align='right' sx={{ color: '#ff5349' }}>
                                    {getHistoricalLow(historicalYieldTrendData, 5, "yield_year", "detrended_yield_amount")?.year}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell colspan='6'>
                                    Harvest Price
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>&nbsp;</TableCell>
                                <TableCell align='right' sx={{ color: 'darkblue' }}>Avg</TableCell>
                                <TableCell align='right' sx={{ color: 'green' }}>Year</TableCell>
                                <TableCell align='right' sx={{ color: 'green' }}>High</TableCell>
                                <TableCell align='right' sx={{ color: '#ff5349' }}>Low</TableCell>
                                <TableCell align='right' sx={{ color: '#ff5349' }}>Year</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>10-year History</TableCell>
                                <TableCell align='right' sx={{ color: 'darkblue' }}>
                                    {formatNumber(getHistoricalMean(priceData, 10, "reinsurance_year", "harvest_price")?.value, 2)}
                                </TableCell>
                                <TableCell align='right' sx={{ color: 'green' }}>
                                    {getHistoricalHigh(priceData, 10, "reinsurance_year", "harvest_price")?.year}
                                </TableCell>
                                <TableCell align='right' sx={{ color: 'green' }}>
                                    {formatNumber(getHistoricalHigh(priceData, 10, "reinsurance_year", "harvest_price")?.value, 2)}
                                </TableCell>
                                <TableCell align='right' sx={{ color: '#ff5349' }}>
                                    {formatNumber(getHistoricalLow(priceData, 10, "reinsurance_year", "harvest_price")?.value, 2)}
                                </TableCell>
                                <TableCell align='right' sx={{ color: '#ff5349' }}>
                                    {getHistoricalLow(priceData, 10, "reinsurance_year", "harvest_price")?.year}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>5-year History</TableCell>
                                <TableCell align='right' sx={{ color: 'darkblue' }}>
                                    {formatNumber(getHistoricalMean(priceData, 5, "reinsurance_year", "harvest_price")?.value, 2)}
                                </TableCell>
                                <TableCell align='right' sx={{ color: 'green' }}>
                                    {getHistoricalHigh(priceData, 5, "reinsurance_year", "harvest_price")?.year}
                                </TableCell>
                                <TableCell align='right' sx={{ color: 'green' }}>
                                    {formatNumber(getHistoricalHigh(priceData, 5, "reinsurance_year", "harvest_price")?.value, 2)}
                                </TableCell>
                                <TableCell align='right' sx={{ color: '#ff5349' }}>
                                    {formatNumber(getHistoricalLow(priceData, 5, "reinsurance_year", "harvest_price")?.value, 2)}
                                </TableCell>
                                <TableCell align='right' sx={{ color: '#ff5349' }}>
                                    {getHistoricalLow(priceData, 5, "reinsurance_year", "harvest_price")?.year}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>

            </Grid>
        </>
    );
}
