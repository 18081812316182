import { Stack, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';

export default function WorkspaceActionBar({
    content,

    allowPrint
}) {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });

    return (
        <Box className='navigation workspaceActionBar' component={Stack} direction="row"
            position="fixed"
            sx={{
                ...({ right: 0, }),
                ...(isSmall && { bottom: "1rem", width: "100%", pr: 1, pl: 1 }),
                ...(!isSmall && { top: "1rem", mr: 2 }),
            }}>

            <Stack direction="row" spacing={1} justifyContent={"flex-end"}
                sx={{
                    ...(isSmall && { width: "100%" }),
                }}>
                {content}
            </Stack>
        </Box>
    )
}
