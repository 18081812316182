import EastOutlined from '@mui/icons-material/EastOutlined'
import { Card, CardActions, CardContent, CardHeader, Chip, Divider, IconButton, Stack, Typography } from '@mui/material'
import { AppContext } from 'App'
import { FarmIcon, FarmVaultIcon } from 'components/Icons/Icons'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatNumber } from 'utility/formatters'


export default function FarmCountyCard({
    currentCounty,
    crop_year = 'potential',

    isNavigation,
    icon
}) {
    const { globalState, globalDispatch, session } = useContext(AppContext);
    const navigate = useNavigate();

    return (
        <>
            <Card>
                <CardHeader
                    avatar={icon || <FarmVaultIcon />}
                    title={<>
                        {currentCounty?.location_county_name}, {currentCounty?.location_state_name}
                    </>}
                />

                <CardContent>
                    <Stack direction="column" spacing={1}>
                        <Stack direction='row' spacing={1} alignItems='center' justifyItems='center'>
                            <Typography variant='h6'>
                                {formatNumber(currentCounty?.reported_acreage, 0)}
                            </Typography>
                            <Typography variant='body1' sx={{ fontSize: '8pt', leading: '0px', lineHeight: '9pt', height: '100%' }}>
                                ACRES
                            </Typography>

                            <Divider orientation="vertical" flexItem />

                            <Typography variant='h6'>
                                {formatNumber(currentCounty?.base_acreage, 0)}
                            </Typography>
                            <Typography variant='body1' sx={{ fontSize: '8pt', leading: '0px', lineHeight: '9pt', height: '100%' }}>
                                BASE<br />
                                ACRES
                            </Typography>
                        </Stack>
                    </Stack>
                </CardContent>

                <CardActions>
                    <Stack direction="row" spacing={1}>
                        <Chip icon={<FarmIcon />}
                            label={formatNumber(currentCounty?.unit_count, 0) + ' farm' + (currentCounty?.unit_count == 1 ? "" : "s")} />
                    </Stack>

                    {isNavigation != false &&
                        <IconButton onClick={() => { navigate(`/vault/${crop_year}/${currentCounty.location_state_name}/${currentCounty.location_county_name}`) }}>
                            <EastOutlined />
                        </IconButton>}
                </CardActions>
            </Card>
        </>
    )
}
