import { Box, Button, Checkbox, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { MASK_NUMERIC, StatefulControlledTextField } from "components/Core/StatefulControlledTextField";
import { useFormStatus } from "mui-toolkit/useFormStatus";
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from "react";
import { EQUIPMENT_CATEGORIES, EQUIPMENT_CONDITIONS, EQUIPMENT_TERMS } from "./EquipmentCard";
import { Camera } from "@mui/icons-material";
import _ from "lodash";
import { _getUniqueCountyPractices } from "@datanac/datanac-api-toolkit/dist/utility/ProducerFarmHelper";
import { USER_ROLE_ADMIN } from "@datanac/datanac-api-toolkit/dist/api-helper";
import { AppContext } from "App";
import { formatPracticeName } from "utility/formatters";
import { UsersApiHelper } from "api/ApiHelper";
import { v4 } from "uuid";
import { SkeletonWrapper } from "mui-toolkit/SkeletonWrapper";

// ---

export const EquipmentCard_Edit = ({
    onChange,
    onClose,
    equipment,
    is_lender,

    countyPractices
}) => {
    const { globalState } = useContext(AppContext);
    const { component: formStatusComponent, actions: formStatusActions, state: formStatusState } = useFormStatus();

    const [state, setState] = useState(equipment || {});
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setState(equipment);
    }, [equipment]);

    // --- --- ---

    const [isLoadingEquipmentInventoryCountyPractices, setIsLoadingEquipmentInventoryCountyPractices] = useState(true);
    const [equipmentInventoryCountyPractices, setEquipmentInventoryCountyPractices] = useState(countyPractices || []);

    useEffect(() => {
        setEquipmentInventoryCountyPractices(countyPractices);
        setIsLoadingEquipmentInventoryCountyPractices(false);
    }, [countyPractices]);

    const toggleCommodityPracticeState = (currentItem, isChecked) => {
        const _newState = {
            ...currentItem,
            equipment_inventory_id: equipment?.id,
            producer_token: globalState?.producer_token,
        };
        _newState.is_active = isChecked;
        if (!_newState?.id) {
            _newState.id = v4();
        }

        // Update local state
        const _newList = [...equipmentInventoryCountyPractices];
        const _foundCPIndex = _newList?.findIndex(_cp =>
            _cp?.commodity_name === currentItem?.commodity_name
            && _cp?.practice_name === currentItem?.practice_name
        );
        if (_foundCPIndex > -1) {
            _newList[_foundCPIndex].is_active = isChecked;
        }
        setEquipmentInventoryCountyPractices(_newList);

        // Update DB
        UsersApiHelper.updateEquipmentInventoryCountyPractices({ EquipmentInventoryCountyPractices: [_newState] }, globalState)
            .catch(err => {
                console.warn(err);
                enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
            });
    }

    const onSubmit = (e) => {
        onChange && onChange(state);

        // if (equipment == null || Object.keys(equipment).length === 0) {
        //     enqueueSnackbar("Equipment added", { preventDuplicate: true, variant: 'info' });
        // } else {
        //     enqueueSnackbar("Equipment updated", { preventDuplicate: true, variant: 'info' });
        // }

        onClose && onClose();
    }

    // ---

    const handleChangeStateSelect = (event) => {
        formStatusActions.setIsFormDirty(true, false);

        if (state[event.target.name] != event.target.value) {
            formStatusActions.setIsFormDirty(true, false);

            const _newState = { ...state };
            _newState[event.target.name] = event.target.value;
            setState(_newState);
        }
    }

    const handleChangeState = async (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        if (state[event.target.name] != value) {
            formStatusActions.setIsFormDirty(true, false);

            const _newState = { ...state };
            _newState[event.target.name] = value;
            setState(_newState);
        }
    }

    // ---

    const formMaxWidthShort = '800px';
    const formMaxWidth = '1000px';

    return (<>
        <DialogTitle>
            {state?.name}
        </DialogTitle>

        <DialogContent>
            <Box sx={{ width: '100%', maxWidth: formMaxWidthShort }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Name"
                                name="name"
                                isRequired={true}
                                defaultValue={state?.name || ''}
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <InputLabel id='categoryLabel'>Category</InputLabel>
                            <Select
                                labelId='categoryLabel'
                                value={state?.category || ''}
                                name={"category"}
                                onChange={handleChangeStateSelect}
                                label={'Category'}
                                disabled={false}
                            >
                                {EQUIPMENT_CATEGORIES?.map((currentItem, index) => {
                                    return <MenuItem key={index} value={currentItem}>{currentItem}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <InputLabel id='conditionLabel'>Condition</InputLabel>
                            <Select
                                labelId='conditionLabel'
                                value={state?.condition || ''}
                                name={"condition"}
                                onChange={handleChangeStateSelect}
                                label={'Condition'}
                                disabled={false}
                            >
                                {EQUIPMENT_CONDITIONS?.map((currentItem, index) => {
                                    return <MenuItem key={index} value={currentItem}>{currentItem}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Serial Number"
                                name="serial_number"
                                isRequired={true}
                                defaultValue={state?.serial_number || ''}
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                endAdornment={<InputAdornment position="end" className='delete'>
                                    <IconButton onClick={() => handleDeleteLineItem(currentLineItem)} edge="end" color="secondary">
                                        <Camera />
                                    </IconButton>
                                </InputAdornment>}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Value $"
                                name="value"
                                isRequired={true}
                                defaultValue={state?.value || 0}
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                maskType={MASK_NUMERIC}
                                disabled={is_lender == true && globalState?.user_role != USER_ROLE_ADMIN}
                            />
                        </FormControl>
                    </Grid>
                    {is_lender == true || globalState?.user_role == USER_ROLE_ADMIN &&
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth={true}>
                                <StatefulControlledTextField
                                    label="Adjusted Value $"
                                    name="adjusted_value"
                                    isRequired={true}
                                    defaultValue={state?.adjusted_value || 0}
                                    onChange={handleChangeState}
                                    InputLabelProps={{ shrink: true }}
                                    maskType={MASK_NUMERIC}
                                />
                            </FormControl>
                        </Grid>}
                    {is_lender == true && <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Prior Value $"
                                name="prior_value"
                                isRequired={true}
                                defaultValue={state?.prior_value || 0}
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                maskType={MASK_NUMERIC}
                            />
                        </FormControl>
                    </Grid>}
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <InputLabel id='termLabel'>Term</InputLabel>
                            <Select
                                labelId='termLabel'
                                value={state?.term || ''}
                                name={"term"}
                                onChange={handleChangeStateSelect}
                                label={'Term'}
                                disabled={false}
                            >
                                {EQUIPMENT_TERMS?.map((currentItem, index) => {
                                    return <MenuItem key={index} value={currentItem}>{currentItem}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>

            <Divider />

            <Grid container spacing={2}>
                {equipmentInventoryCountyPractices
                    ?.sort((a, b) =>
                        a.commodity_name?.localeCompare(b.commodity_name)
                    )
                    ?.map((currentItem, index) => {
                        return <Grid item xs={12} md={6} key={index}>
                            <FormControl fullWidth={true}>
                                <FormControlLabel control={<Checkbox id="chk"
                                    checked={Boolean(currentItem?.is_active)}
                                    onChange={e => toggleCommodityPracticeState(currentItem, e?.target?.checked)} />}
                                    label={currentItem?.commodity_name + ' ' + formatPracticeName(currentItem?.practice_name)} />
                            </FormControl>
                        </Grid>
                    })}

                {!Boolean(equipmentInventoryCountyPractices?.length) && <>
                    <Grid item xs={12} md={6}>
                        <SkeletonWrapper isLoading={!equipmentInventoryCountyPractices?.length}>
                            <Box>
                                <Typography variant='h6'>Loading...</Typography>
                            </Box>
                        </SkeletonWrapper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SkeletonWrapper isLoading={!equipmentInventoryCountyPractices?.length}>
                            <Box>
                                <Typography variant='h6'>Loading...</Typography>
                            </Box>
                        </SkeletonWrapper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SkeletonWrapper isLoading={!equipmentInventoryCountyPractices?.length}>
                            <Box>
                                <Typography variant='h6'>Loading...</Typography>
                            </Box>
                        </SkeletonWrapper>
                    </Grid>
                </>}
            </Grid>
        </DialogContent>

        <DialogActions>
            <Stack direction="row-reverse" spacing={1} useFlexGap flexWrap="wrap" justifyContent='flex-end'>
                <Button variant='contained' onClick={onSubmit}>OK</Button>
                <Button variant='contained' color='secondary' autoFocus onClick={onClose}>Cancel</Button>
            </Stack>
        </DialogActions>
    </>);
}