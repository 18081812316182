export const getCellColor = (
    displayValue,
    maxLossValue,
    doMarkNegativeValues
) => {
    const maxProfitValue = 1000;
    const minProfitValue = 0;

    const minLossValue = 0;
    maxLossValue = maxLossValue || 0;

    let rgb;
    let alpha;
    if (displayValue > 0) {
        // Green:
        rgb = '153, 204, 178';
        if (minProfitValue == maxProfitValue) {
            alpha = 0.5;
        } else {
            alpha = ((displayValue - minProfitValue) / (maxProfitValue - minProfitValue)) * 0.8 + 0.2;
        }
    } else {
        // Red:
        if (doMarkNegativeValues == null || doMarkNegativeValues == true) {
            rgb = '255,49,45';
            if (maxLossValue == minLossValue) {
                alpha = 0.6;
            } else {
                alpha = ((displayValue - maxLossValue) / (maxLossValue - minLossValue)) * 0.4 + 0.6;
            }
        }
    }

    return 'rgba(' + rgb + ', ' + alpha + ')';
}