import { useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { getCellColor } from './StressTestVisualization/getCellColor'

export default function InsuranceOptimizeDataGrid({
  title,

  insurance_plan_uuid,
  displayFieldName,

  xAxisFieldName,
  xAxisDisplayName,

  yAxisFieldName,
  yAxisDisplayName,

  doMarkNegativeValues,

  stressTestResults,

  maxLossValue
}) {
  const isXXL = useMediaQuery('(min-width:1537px)');

  const getXAxis = () => {
    if (stressTestResults) {
      const xAxisElements = stressTestResults[0]?.map(row => {
        return row[xAxisFieldName || 'yield']
      })

      return xAxisElements;
    }
  }

  const _priceFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    currencySign: "accounting",
    maximumFractionDigits: 0
  });
  const _priceFormatter2 = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    currencySign: "accounting",
    maximumFractionDigits: 2
  });

  const formatPrice = (value) => {
    return (value || value == 0) ? _priceFormatter.format(value)?.replace("$", "$ ") : '';
  }

  const formatPrice2 = (value) => {
    return (value || value == 0) ? _priceFormatter2.format(value)?.replace("$", "$ ") : '';
  }

  const getDisplayValue = (cell, insurance_plan_uuid, displayFieldName) => {
    let displayValue;
    if (insurance_plan_uuid) {
      const insurancePlan = cell?.indemnityResults?.find(r => r.insurance_plan_uuid == insurance_plan_uuid);
      if (insurancePlan) {
        displayValue = insurancePlan[displayFieldName];
      }
    } else {
      displayValue = cell[displayFieldName];
    }
    return displayValue;
  }

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }}
      enterDelay={1000} enterTouchDelay={1000} enterNextDelay={1000}
    />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: "none",
    },
  });

  return (
    <>
      <TableContainer sx={{ boxSizing: "border-box" }} className='insuranceOptimizeDataGrid'>
        {title &&
          <Box sx={{ p: 1 }}>
            <Typography>
              {title}
            </Typography>
          </Box>}
        <Table
          padding="none"
          spacing={0}
          size="small"
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: 'none',
            },
          }}
        >
          <TableBody>
            <TableRow>
              <TableCell colSpan={2} sx={{
                padding: '0 !important',
              }}>
                &nbsp;
              </TableCell>
              <TableCell colSpan="10" sx={{
                textAlign: 'center',
              }}>
                {xAxisDisplayName || 'Farm Yields'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={(isXXL && yAxisDisplayName) ? 2 : 1} sx={{
                padding: '0 !important'
              }}>
                &nbsp;
              </TableCell>
              {getXAxis()?.map((x, index) => {
                return (
                  <TableCell key={index} sx={{
                    textAlign: 'right',
                    padding: '0 !important',
                    paddingTop: '4pt !important',
                    paddingBottom: '4pt !important',
                    paddingRight: '4pt !important',
                    borderBottom: '1px solid #c0c0c0 !important',
                  }}>
                    {x}
                  </TableCell>
                )
              })}
            </TableRow>

            {isXXL && yAxisDisplayName && <TableRow>
              <TableCell rowSpan={11} sx={{
                padding: '0 !important',
                border: 'none !important',
                maxWidth: "14px",
              }}>
                <Typography
                  sx={{
                    writingMode: 'vertical-lr',
                    transform: 'rotate(180deg)',
                    fontSize: 'small',
                    p: 0,
                    m: 0,
                  }}
                >
                  {yAxisDisplayName || ""}
                </Typography>
              </TableCell>
            </TableRow>}

            {stressTestResults && stressTestResults?.length > 0 &&
              stressTestResults?.filter((row, index) =>
                (stressTestResults[0][0].price != stressTestResults[stressTestResults.length - 1][stressTestResults.length - 1].price) ?
                  true : (index == 0) ? true : false
              )
                .map((row, rowIndex) => {
                  return (
                    <TableRow key={rowIndex}>
                      <TableCell
                        sx={{
                          borderRight: '1px solid #c0c0c0',
                          p: 0,
                          textAlign: 'right',
                          paddingTop: '0 !important',
                          paddingBottom: '0 !important',
                          paddingLeft: '0px !important',
                          paddingRight: '8px !important',
                          maxWidth: "40px"
                        }}
                      >
                        {yAxisFieldName == "price" ? formatPrice2(row[0]?.[yAxisFieldName])
                          : row[0]?.[yAxisFieldName]
                        }
                      </TableCell>
                      {row.map((cell, index) => {
                        return (
                          <CustomWidthTooltip
                            key={"tooltip" + index}
                            sx={{ maxWidth: 1000 }} arrow title={
                              <React.Fragment>
                                <Typography>
                                  Yield: {cell["yield"]}<br />
                                  County Yield: {cell["county_yield"]}<br />
                                  Price: {formatPrice2(cell["price"])}<br />
                                  {Boolean(cell["price_cottonseed"]) && <>Cottonseed Price: {cell["price_cottonseed"]?.toFixed(4)}<br /></>}
                                  Net Profit without Insurance: <u>{formatPrice(cell["net_profit_simple"])}</u><br />
                                </Typography>
                                <Divider />
                                <Typography>
                                  {cell.indemnityResults && cell.indemnityResults.length > 0 && cell.indemnityResults.map((r, index) => {
                                    let current_insurance_plan_name = r.insurance_plan_name;
                                    if (Array.isArray(r.insurance_option_codes) && r.insurance_option_codes.includes('SE')) {
                                      current_insurance_plan_name += ' (SE)'
                                    }
                                    return (<React.Fragment key={index}>
                                      <>Indemnity: {current_insurance_plan_name}</>: {formatPrice(r.indemnity_total_per_acre)}
                                      <br />
                                    </React.Fragment>);
                                  })}
                                  <br />
                                  Indemnity: Total: {formatPrice(cell["indemnity_total_per_acre"])}<br />
                                </Typography>
                                <Divider />
                                <Typography>
                                  Insured Net Profit: <u>{formatPrice(cell["net_profit_insured"])}</u><br />
                                </Typography>
                              </React.Fragment>
                            }>
                            <TableCell
                              key={"cell" + index}
                              sx={{
                                paddingLeft: '4pt !important',
                                paddingRight: '8pt !important',
                                paddingTop: '8pt !important',
                                paddingBottom: '8pt !important',
                                textAlign: 'right',
                                backgroundColor:
                                  getCellColor(getDisplayValue(cell, insurance_plan_uuid, displayFieldName), maxLossValue, doMarkNegativeValues)
                              }}
                            >
                              {formatPrice(getDisplayValue(cell, insurance_plan_uuid, displayFieldName)) || ' '}<br />
                            </TableCell>
                          </CustomWidthTooltip>
                        )
                      })}
                    </TableRow>
                  )
                })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
