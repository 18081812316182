import { USER_ROLE_PRODUCER } from '@datanac/datanac-api-toolkit/dist/api-helper'
import { useCardController } from '@datanac/datanac-mui-toolkit/dist/hooks'
import EastOutlined from '@mui/icons-material/EastOutlined'
import { Alert, Button, Checkbox, FormControlLabel, FormGroup, Grid, Paper, Skeleton, Stack, useMediaQuery } from '@mui/material'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { AppContext } from 'App'
import { StatefulControlledTextField } from 'components/Core/StatefulControlledTextField'
import { ArcPlcIcon, BudgetIcon, FarmVaultIcon, FinancialIcon, InsuranceIcon, MemberManagementIcon, SecurityIcon } from 'components/Icons/Icons'
import { MODULE_ARC_PLC, MODULE_BUDGET, MODULE_FARM_VAULT, MODULE_FINANCIAL, MODULE_INSURANCE, MODULE_MARKETING } from 'components/Menu/NavigationMenu'
import { useBlockerPrompt } from 'components/Menu/useBlockerPrompt'
import UserGrantItemCard from 'components/UserGrants/components/UserGrantItemCard'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { USER_ROLE_ADMIN, USER_ROLE_AGENT } from 'core/useSession'
import { SkeletonWrapper } from 'mui-toolkit/SkeletonWrapper'
import { useSnackbar } from 'notistack'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { AipPassApiHelper, UsersApiHelper } from '../../api/ApiHelper'

export default function UserGrantItemWorkspace({
}) {
    const { globalState, globalDispatch } = useContext(AppContext);

    const { producer_token, user_grant_id } = useParams();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [producer, setProducer] = useState([]);
    const selectProducer = async () => {
        if (producer_token != 0) {
            const _producer = await AipPassApiHelper.pass_selectObject("ProducerTokens",
                { producer_token: producer_token },
                { user_role: globalState?.user_role, agent_token: globalState?.agent_token });
            if (_producer?.length) {
                setProducer(_producer[0]);
            }
        }
    };

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    const [userGrant, setUserGrant] = useState();
    const selectUserGrant = async () => {
        if (user_grant_id && user_grant_id != 0) {
            const _userGrant = await UsersApiHelper.selectUserGrants(
                {
                    id: user_grant_id,
                    ...(producer_token == 0 && { is_active: false })
                },
                {
                    user_role: globalState?.user_role,
                    ...(globalState?.user_role != USER_ROLE_PRODUCER && { agent_token: globalState?.agent_token }),
                    ...(globalState?.user_role == USER_ROLE_PRODUCER && { producer_token: globalState?.producer_token }),
                    ...(globalState?.user_role == USER_ROLE_ADMIN && { producer_token: globalState?.producer_token }),
                }
            );

            if (_userGrant?.length) {
                setUserGrant(_userGrant[0]);
            }
        }
    };

    const { state: userGrantItems, actions: userGrantItemActions } = useCardController();
    const selectUserGrantItems = async () => {
        if (user_grant_id && user_grant_id != 0) {
            const _userGrantItems = await UsersApiHelper.selectUserGrantItems(
                { user_grant_id: user_grant_id },
                {
                    user_role: globalState?.user_role,
                    ...(globalState?.user_role != USER_ROLE_PRODUCER && { agent_token: globalState?.agent_token }),
                    ...(globalState?.user_role == USER_ROLE_PRODUCER && { producer_token: globalState?.producer_token }),
                },
            );

            const _editableItems = modules.map(currentModule => {
                const _userGrantItem = _userGrantItems.find(ugi => ugi.module == currentModule.module);
                const _editableItem = {
                    id: uuidv4(),
                    user_grant_id: user_grant_id,

                    ...EMPTY_ITEM,
                    ...currentModule,
                    ..._userGrantItem
                };
                return _editableItem;
            });
            userGrantItemActions.replace(_editableItems);
        }
    };

    const [activeAgent, setActiveAgent] = useState();
    const selectActiveAgent = async () => {
        if (globalState.user_role == USER_ROLE_AGENT) {
            let _agent = await AipPassApiHelper.pass_selectObject("AgentTokens",
                { agent_token: globalState.agent_token },
                { user_role: globalState?.user_role, agent_token: globalState?.agent_token });
            if (_agent?.length) {
                setActiveAgent(_agent[0]);
            }
        }
    };

    const [isLoading, setIsLoading] = useState(true);
    const loadData = async () => {
        try {
            const _promises = [];
            _promises.push(selectProducer());
            _promises.push(selectUserGrant());
            _promises.push(selectUserGrantItems());
            _promises.push(selectActiveAgent());

            await Promise.all(_promises);

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setProducer(null);
            setUserGrant(null);
            userGrantItemActions.replace([]);
            setActiveAgent(null);

            handleError(err);
        }
    }

    useEffect(() => {
        loadData();
    }, [globalState.user_role, globalState.agent_token, globalState.producer_token, producer_token, user_grant_id]);

    const modules = [
        { module: MODULE_FARM_VAULT, title: "Farm Vault", icon: <FarmVaultIcon /> },
        { module: MODULE_BUDGET, title: "Budget", icon: <BudgetIcon /> },
        { module: MODULE_ARC_PLC, title: "ARC/PLC", icon: <ArcPlcIcon /> },
        { module: MODULE_INSURANCE, title: "Insurance", icon: <InsuranceIcon /> },
        { module: MODULE_FINANCIAL, title: "Financial Reports", icon: <FinancialIcon /> },
        { module: MODULE_MARKETING, title: "Marketing", icon: <FarmVaultIcon /> },
    ];

    const EMPTY_ITEM = {
        module: null,
        is_read: false,
        is_write: false,
        is_delete: false,
        is_active: true,
    };

    const isFormDisabled = () => {
        return Boolean(
            userGrant?.id == null || userGrant?.is_active == null
        );
    }

    const isValid = useCallback(() => {
        return (Boolean(
            userGrant?.id
            && !isFormDisabled()
        ));
    }, [userGrant?.id]);
    useBlockerPrompt(!isFormDisabled() && !isValid(), "UserGrantItem");

    const handleCheckboxOnChange = (e) => {
        const _state = { ...userGrant };
        _state[e.target.name] = e.target.checked;
        setUserGrant(_state);
    }

    const handleOnChange = (e) => {
        const _state = { ...userGrant };
        _state[e.target.name] = e.target.value;
        setUserGrant(_state);
    }

    const handleUserGrantItemOnChange = (currentUserGrantItem) => {
        if (!currentUserGrantItem.id) {
            currentUserGrantItem.id = uuidv4();
            currentUserGrantItem.user_grant_id = userGrant?.id;

            userGrantItemActions.add(currentUserGrantItem);
        } else {
            userGrantItemActions.update(currentUserGrantItem);
        }
    }

    const handleAccept = (is_active) => {
        const _userGrant = { ...userGrant };
        _userGrant.producer_token = globalState?.producer_token;
        _userGrant.is_active = is_active;
        UsersApiHelper.updateUserGrant(_userGrant, globalState)
            .then(() => {
                selectUserGrant();
                selectUserGrantItems();
            });
    }

    useEffect(() => {
        if (isValid()) {
            UsersApiHelper.updateUserGrant(userGrant, globalState);
        }
    }, [userGrant]);

    useEffect(() => {
        if (isValid()) {
            const _userGrantItems = userGrantItems.map(ugi => ({
                ...ugi,
                icon: null
            }));
            UsersApiHelper.updateUserGrantItems({ UserGrantItems: _userGrantItems }, globalState);
        }
    }, [userGrantItems]);

    // ---

    return (
        <>
            {!isFormDisabled() &&
                <WorkspaceHeader
                    isLoading={isLoading}
                    title={userGrant?.user_description}
                    breadCrumbs={[
                        {
                            title: 'Security',
                            path: '/userGrant',
                            icon: <SecurityIcon />
                        },
                        {
                            title: (producer?.first_name?.toLowerCase() || '')
                                + ' ' + (producer?.middle_name?.toLowerCase() || '')
                                + ' ' + (producer?.last_name?.toLowerCase() || '')
                                + ' ' + (producer?.business_name?.toLowerCase() || ''),
                            path: `/userGrant/${producer?.producer_token}`,
                            icon: null
                        },
                    ]}
                />}
            {isFormDisabled() &&
                <WorkspaceHeader
                    isLoading={isLoading}
                    title={(producer?.first_name?.toLowerCase() || '')
                        + ' ' + (producer?.middle_name?.toLowerCase() || '')
                        + ' ' + (producer?.last_name?.toLowerCase() || '')
                        + ' ' + (producer?.business_name?.toLowerCase() || '')}
                    breadCrumbs={[
                        {
                            title: 'Members',
                            path: '/account',
                            icon: <MemberManagementIcon />
                        },
                    ]}
                />}

            {Boolean(producer_token == 0) && <>
                <Typography variant='h1'>
                    Member
                </Typography>
                <StatefulControlledTextField
                    fullWidth
                    label="Producer Email"
                    defaultValue={userGrant?.producer_email || ''}
                    onChange={handleOnChange}
                    name="producer_email"
                    variant="outlined"
                    disabled={true}
                    InputLabelProps={{ shrink: true }}
                />

                <Divider sx={{ mt: 2, mb: 2 }} />
            </>}

            {Boolean(userGrant?.id != null && userGrant?.is_active == null
            ) && <>
                    <Alert severity='info'>
                        <Typography>This is a pending invitation.</Typography>

                        {globalState.user_role == USER_ROLE_AGENT && <>
                            <Typography>
                                Waiting on the producer to accept this request...
                            </Typography>
                        </>}

                        {[USER_ROLE_ADMIN, USER_ROLE_PRODUCER].includes(globalState.user_role) &&
                            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                                <Button variant="contained" color="primary"
                                    onClick={() => handleAccept(true)}
                                >
                                    Accept
                                </Button>
                                <Button variant="contained" color="secondary"
                                    onClick={() => handleAccept(false)}>
                                    Decline
                                </Button>
                            </Stack>}
                    </Alert>
                    <br />
                </>}

            <Typography variant='h1'>
                Master Permissions
            </Typography>
            <SkeletonWrapper isLoading={isLoading}>
                <Stack direction='row' alignItems='center' spacing={1}>
                    <Typography variant='body2' color='secondary'>
                        {Boolean(globalState.user_role == USER_ROLE_PRODUCER) ? "Share" : "Request"}
                    </Typography>
                    <Typography variant='body1' color='secondary' sx={{ textTransform: 'capitalize' }}>
                        {Boolean(producer_token == 0) && <>
                            {userGrant?.producer_email}
                        </>}

                        {Boolean(globalState.user_role != USER_ROLE_PRODUCER) && <>
                            {(producer?.first_name?.toLowerCase() || '')
                                + ' ' + (producer?.middle_name?.toLowerCase() || '')
                                + ' ' + (producer?.last_name?.toLowerCase() || '')
                                + ' ' + (producer?.business_name?.toLowerCase() || '')}
                        </>}
                    </Typography>
                    <EastOutlined />
                    <Typography variant='body2' color='secondary'>
                        for access by
                    </Typography>
                    <Typography variant='body1' color='secondary' sx={{ textTransform: "capitalize" }}>
                        {Boolean((producer_token || 0) == 0 && activeAgent) && <>
                            {activeAgent?.first_name?.toLowerCase()}
                            <> </>
                            {activeAgent?.middle_name?.toLowerCase()}
                            <> </>
                            {activeAgent?.last_name?.toLowerCase()}
                            <> </>
                            {activeAgent?.business_name?.toLowerCase()}
                        </>}

                        {Boolean(producer_token != 0) && <>
                            {userGrant?.user_description?.toLowerCase()}
                        </>}
                    </Typography>
                </Stack>
            </SkeletonWrapper>

            <Paper sx={{ p: 1, pl: 2.5, pr: 2.5, backgroundColor: 'white', border: 'none' }}>
                <FormGroup>
                    <Stack direction='column'>
                        <SkeletonWrapper isLoading={isLoading}>
                            <FormControlLabel name="is_master_read" label="View" control={
                                <Checkbox disabled={isFormDisabled()} checked={userGrant?.is_master_read || false} onChange={handleCheckboxOnChange} />} />
                            <FormControlLabel name="is_master_write" label="Edit" control={
                                <Checkbox disabled={isFormDisabled()} checked={userGrant?.is_master_write || false} />} onChange={handleCheckboxOnChange} />
                            {/* <FormControlLabel name="is_master_delete" label="Delete" control={
                                <Checkbox disabled={isFormDisabled()} checked={userGrant?.is_master_delete || false} onChange={handleCheckboxOnChange} />} /> */}
                        </SkeletonWrapper>
                    </Stack>
                </FormGroup>
            </Paper>

            <Divider sx={{ mt: 2, mb: 2 }} />

            <Typography variant='h1'>
                Section Access
            </Typography>

            <Grid container spacing={2}>
                {!isLoading && userGrantItems.map((currentUserGrantItem, index) => {
                    return (<Grid item key={index} xs={12} sm={6} lg={5}>
                        <UserGrantItemCard disabled={isFormDisabled()}
                            userGrant={userGrant} userGrantItem={currentUserGrantItem}
                            onChange={handleUserGrantItemOnChange} />
                    </Grid>);
                })}

                {isLoading && Array(4).fill("").map((item, index) =>
                    <Grid item key={index} xs={12} sm={6} lg={5}>
                        <Skeleton sx={{ maxWidth: "100%" }}>
                            <UserGrantItemCard />
                        </Skeleton>
                    </Grid>
                )}
            </Grid>

        </>
    )
}
