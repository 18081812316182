import { propUpdateReducer } from "components/Core/propUpdateReducer";
import { useEffect, useReducer } from "react";

export const useReportByYear = ({
    currentYear,

    loadReportCallback,
    onError
}) => {

    const initializeState = () => {
        const initial = {};
        initial[String(currentYear)] = { isLoading: true, data: null, };
        initial[String(currentYear - 1)] = { isLoading: true, data: null, };
        initial[String(currentYear - 2)] = { isLoading: true, data: null, };
        initial[String(currentYear - 3)] = { isLoading: true, data: null, };
        initial[String(currentYear - 4)] = { isLoading: true, data: null, };

        return initial;
    }

    const [state, dispatch] = useReducer(propUpdateReducer, {}, initializeState);

    const loadReport = (year) => {
        loadReportCallback && loadReportCallback(year).then(r => {
            dispatch({
                type: 'update',
                payload: {
                    key: String(year), value: {
                        isLoading: false,
                        data: r
                    }
                },
            });
        });
    }

    useEffect(() => {
        loadReport(currentYear);
        loadReport(currentYear - 1);
        loadReport(currentYear - 2);
        loadReport(currentYear - 3);
        loadReport(currentYear - 4);
    }, [currentYear])

    return {
        state
    }
}