import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from 'App';
import { Grid, Typography, Button, CircularProgress, Stack, MenuItem, Menu, useMediaQuery } from '@mui/material';
import { ArrowDropDownOutlined } from '@mui/icons-material';
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader';
import { FarmVaultIcon } from 'components/Icons/Icons';
import { getBudgetYears } from 'components/Budget/helpers'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { DataGrid } from '@mui/x-data-grid'
import { UsersApiHelper } from 'api/ApiHelper';

export default function BuyerWorkspace() {
    const { globalState } = useContext(AppContext);
    
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'), { noSsr: true });

    // --- --- ---

    const [buyers, setBuyers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    useEffect(() => {
        try {
            const _loadBuyers = async () => {
                // Get Member:
                const _buyers = await UsersApiHelper.users_selectObject("Buyer", {}, globalState);
                setBuyers(_buyers);
            };
        } catch (err) {
            handleError(err);
        }
        _loadBuyers();
    }, [])

    // --- --- ---

    const columns = useMemo(() => [
        {
            field: 'financial_institution_name',
            headerName: 'Name',
            minWidth: 200,
            flex: 3
        },
        {
            field: 'city_name',
            headerName: 'City',
            minWidth: 150,
            flex: 3
        },
        {
            field: 'state_name',
            headerName: 'State',
            minWidth: 120,
            flex: 3
        }
    ], []);

    return (
        <>
            <WorkspaceHeader
                title='Buyers'
                breadCrumbs={[{
                    title: 'Marketing',
                    icon: <FarmVaultIcon />
                }]}
            />

            <DataGrid
                rows={buyers || []}
                columns={columns}
                initialState={{
                    pagination: { paginationModel: { pageSize: isMobile ? 5 : 10 } },
                }}
                pageSizeOptions={[5, 10, 25]}
                autoHeight
            />
        </>
    );
}