import { CallMissedOutgoing, CallReceived, Checklist, EastOutlined, FeedOutlined, Inventory, OtherHouses, Paid, ReportOutlined, ShowChart, WestOutlined } from "@mui/icons-material";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Stack, Step, StepLabel, Stepper, Typography, useMediaQuery } from "@mui/material";
import { AppContext } from "App";
import WorkspaceActionBar from "components/Workspaces/WorkspaceActionBar";
import { ChatAssistantButton } from "mui-toolkit/ChatAssistantButton";
import { Component, useContext, useEffect, useState } from "react";
import { FinancialStatementSchedule_Accounts_Liquid_View, SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_OTHER, SYSTEM_PROMPT_ACCOUNT_PARSER_LIQUID } from "./schedule_financial_statement/schedule_financial_statement_accounts_liquid";
import { FinancialStatementSchedule_Accounts_Payable_View } from "./schedule_financial_statement/schedule_financial_statement_accounts_payable";
import { FinancialStatementSchedule_Accounts_Receivable_View, SYSTEM_PROMPT_ACCOUNT_PARSER_TERMS } from "./schedule_financial_statement/schedule_financial_statement_accounts_receivable";
import { FinancialStatementSchedule_Equities_View } from "./schedule_financial_statement/schedule_financial_statement_equities";
import { FinancialStatementSchedule_Inventories_View, SYSTEM_PROMPT_ACCOUNT_PARSER_OTHER } from "./schedule_financial_statement/schedule_financial_statement_inventories";
import { FinancialStatementSchedule_Notes_Receivable_View } from "./schedule_financial_statement/schedule_financial_statement_notes_receivable";
import { FinancialStatementSchedule_Prepaids_View } from "./schedule_financial_statement/schedule_financial_statement_prepaids";
import { FinancialStatementSchedule_RealEstate_View } from "./schedule_financial_statement/schedule_financial_statement_realestate";
import { schedule_financial_statement_accounts_liquid, schedule_financial_statement_accounts_payable, schedule_financial_statement_accounts_receivable, schedule_financial_statement_confirmation, schedule_financial_statement_equities, schedule_financial_statement_inventories, schedule_financial_statement_notes_receivable, schedule_financial_statement_prepaids, schedule_financial_statement_realestate, useFinancialStatementSchedule } from "./schedule_financial_statement/useFinancialStatementSchedule";
import { FinancialStatementSchedule_Confirmation } from "./schedule_financial_statement/schedule_financial_statement_confirmation";

export const InterviewDialog = ({
    year
}) => {
    const { globalState } = useContext(AppContext);

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const isLg = useMediaQuery('(min-width:1440px)');

    const [activeStep, setActiveStep] = useState(0);

    // useFinancialStatementSchedule
    const { actions: financialStatementScheduleAccountsActions } = useFinancialStatementSchedule({
        year: year,
        category: null,
        is_active: true,
    }, []);

    const [currentCategory, setCurrentCategory] = useState(null);
    useEffect(() => {
        if (steps[activeStep]?.key) {
            setCurrentCategory(steps[activeStep]?.key);
        }
    }, [activeStep])

    const _loadFilteredAccounts = async () => {
        // Get current records from API, filtered by category
        let _filter = { year, is_active: true };
        if (currentCategory != schedule_financial_statement_confirmation) {
            _filter.category = currentCategory;
        } else {
            delete _filter.category;
        }
        const _currentAccounts = await financialStatementScheduleAccountsActions.loadFinancialStatementScheduleAccounts(_filter);
        setCurrentAccounts(_currentAccounts);
    }

    const [currentAccounts, setCurrentAccounts] = useState([]);
    useEffect(() => {
        if (currentCategory) {
            _loadFilteredAccounts();
        }
    }, [currentCategory])

    // --- --- ---

    const handleOnChangeObject = async (account) => {
        account.category = steps[activeStep]?.key;
        account.year = year;
        if (!account.financial_institution_name) account.financial_institution_name = "Institution Name";
        if (!account.account_name) account.account_name = "New Account";
        if (!account.account_type) account.account_type = SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_OTHER;

        await financialStatementScheduleAccountsActions.updateFinancialStatementScheduleAccounts(account);
        _loadFilteredAccounts();
    }

    // --- --- ---

    const steps = [
        {
            category: 'Financial Schedule',
            description: '',
            label: 'Cash, Savings, CDs',
            icon: FeedOutlined,
            key: schedule_financial_statement_accounts_liquid,
            systemPrompt: SYSTEM_PROMPT_ACCOUNT_PARSER_LIQUID,
            isValid: () => { return true }
        },
        {
            category: 'Financial Schedule',
            description: '',
            label: 'Accounts Receivable',
            icon: CallReceived,
            key: schedule_financial_statement_accounts_receivable,
            systemPrompt: SYSTEM_PROMPT_ACCOUNT_PARSER_TERMS,
            isValid: () => { return true }
        },
        {
            category: 'Financial Schedule',
            description: '',
            label: 'Accounts Payable',
            icon: CallMissedOutgoing,
            key: schedule_financial_statement_accounts_payable,
            systemPrompt: SYSTEM_PROMPT_ACCOUNT_PARSER_TERMS,
            isValid: () => { return true }
        },
        {
            category: 'Financial Schedule',
            description: '',
            label: 'Inventories',
            icon: Inventory,
            key: schedule_financial_statement_inventories,
            systemPrompt: SYSTEM_PROMPT_ACCOUNT_PARSER_OTHER,
            isValid: () => { return true }
        },
        {
            category: 'Financial Schedule',
            description: '',
            label: 'Prepaids',
            icon: Paid,
            key: schedule_financial_statement_prepaids,
            systemPrompt: SYSTEM_PROMPT_ACCOUNT_PARSER_OTHER,
            isValid: () => { return true }
        },
        {
            category: 'Financial Schedule',
            description: '',
            label: 'Notes Receivable',
            icon: CallReceived,
            key: schedule_financial_statement_notes_receivable,
            systemPrompt: SYSTEM_PROMPT_ACCOUNT_PARSER_OTHER,
            isValid: () => { return true }
        },
        {
            category: 'Financial Schedule',
            description: '',
            label: 'Equities',
            icon: ShowChart,
            key: schedule_financial_statement_equities,
            systemPrompt: SYSTEM_PROMPT_ACCOUNT_PARSER_OTHER,
            isValid: () => { return true }
        },
        {
            category: 'Financial Schedule',
            description: '',
            label: 'Real Estate',
            icon: OtherHouses,
            key: schedule_financial_statement_realestate,
            systemPrompt: SYSTEM_PROMPT_ACCOUNT_PARSER_OTHER,
            isValid: () => { return true }
        },
        {
            category: 'Financial Schedule',
            description: '',
            label: 'Confirmation',
            icon: ReportOutlined,
            key: schedule_financial_statement_confirmation,
            systemPrompt: null,
            isValid: () => { return false }
        }
    ];


    class HorizontalStepContent extends Component {
        render() {
            const thisStep = this?.props?.step;

            return (
                <>
                    {steps[activeStep]?.key == thisStep?.key &&
                        <Typography>{thisStep?.description}</Typography>}
                </>
            );
        }
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleComplete = () => {
    }

    // ---

    Array.prototype.indexOfId = function (id) {
        for (var i = 0; i < this.length; i++)
            if (this[i].id === id)
                return i;
        return -1;
    }

    // --- --- ---

    return (<>
        <DialogTitle>
            <Stack direction="row" spacing={2}>
                <Stack>
                    <Box>
                        {steps[activeStep]?.category}
                    </Box>
                    <Box>
                        <strong>{steps[activeStep]?.label}</strong>
                    </Box>
                </Stack>

                {Boolean(steps[activeStep].systemPrompt) &&
                    <Stack>
                        <ChatAssistantButton systemPrompt={steps[activeStep]?.systemPrompt} onChangeObject={handleOnChangeObject} />
                    </Stack>}
            </Stack>
        </DialogTitle>

        <DialogContent>
            {steps[activeStep].key == schedule_financial_statement_accounts_liquid && <>
                <Stack direction={isSmall ? "column" : "row"} spacing={2}
                    alignItems="center"
                    flexWrap="wrap" justifyContent="space-around" justifyItems="stretch"
                    sx={{ mt: (isSmall ? "4px" : "50px") }}
                >
                    <FinancialStatementSchedule_Accounts_Liquid_View year={year} accounts={currentAccounts} onChange={handleOnChangeObject} is_lender={globalState?.is_lender} />
                </Stack>
            </>}

            {steps[activeStep].key == schedule_financial_statement_accounts_receivable && <>
                <Stack direction={isSmall ? "column" : "row"} spacing={2}
                    alignItems="center"
                    flexWrap="wrap" justifyContent="space-around" justifyItems="stretch"
                    sx={{ mt: (isSmall ? "4px" : "50px") }}
                >
                    <FinancialStatementSchedule_Accounts_Receivable_View year={year} accounts={currentAccounts} onChange={handleOnChangeObject} is_lender={globalState?.is_lender} />
                </Stack>
            </>}

            {steps[activeStep].key == schedule_financial_statement_accounts_payable && <>
                <Stack direction={isSmall ? "column" : "row"} spacing={2}
                    alignItems="center"
                    flexWrap="wrap" justifyContent="space-around" justifyItems="stretch"
                    sx={{ mt: (isSmall ? "4px" : "50px") }}
                >
                    <FinancialStatementSchedule_Accounts_Payable_View year={year} accounts={currentAccounts} onChange={handleOnChangeObject} is_lender={globalState?.is_lender} />
                </Stack>
            </>}

            {steps[activeStep].key == schedule_financial_statement_inventories && <>
                <Stack direction={isSmall ? "column" : "row"} spacing={2}
                    alignItems="center"
                    flexWrap="wrap" justifyContent="space-around" justifyItems="stretch"
                    sx={{ mt: (isSmall ? "4px" : "50px") }}
                >
                    <FinancialStatementSchedule_Inventories_View year={year} accounts={currentAccounts} onChange={handleOnChangeObject} is_lender={globalState?.is_lender} />
                </Stack>
            </>}

            {steps[activeStep].key == schedule_financial_statement_prepaids && <>
                <Stack direction={isSmall ? "column" : "row"} spacing={2}
                    alignItems="center"
                    flexWrap="wrap" justifyContent="space-around" justifyItems="stretch"
                    sx={{ mt: (isSmall ? "4px" : "50px") }}
                >
                    <FinancialStatementSchedule_Prepaids_View year={year} accounts={currentAccounts} onChange={handleOnChangeObject} is_lender={globalState?.is_lender} />
                </Stack>
            </>}
            {steps[activeStep].key == schedule_financial_statement_notes_receivable && <>
                <Stack direction={isSmall ? "column" : "row"} spacing={2}
                    alignItems="center"
                    flexWrap="wrap" justifyContent="space-around" justifyItems="stretch"
                    sx={{ mt: (isSmall ? "4px" : "50px") }}
                >
                    <FinancialStatementSchedule_Notes_Receivable_View year={year} accounts={currentAccounts} onChange={handleOnChangeObject} is_lender={globalState?.is_lender} />
                </Stack>
            </>}
            {steps[activeStep].key == schedule_financial_statement_equities && <>
                <Stack direction={isSmall ? "column" : "row"} spacing={2}
                    alignItems="center"
                    flexWrap="wrap" justifyContent="space-around" justifyItems="stretch"
                    sx={{ mt: (isSmall ? "4px" : "50px") }}
                >
                    <FinancialStatementSchedule_Equities_View year={year} accounts={currentAccounts} onChange={handleOnChangeObject} is_lender={globalState?.is_lender} />
                </Stack>
            </>}
            {steps[activeStep].key == schedule_financial_statement_realestate && <>
                <Stack direction={isSmall ? "column" : "row"} spacing={2}
                    alignItems="center"
                    flexWrap="wrap" justifyContent="space-around" justifyItems="stretch"
                    sx={{ mt: (isSmall ? "4px" : "50px") }}
                >
                    <FinancialStatementSchedule_RealEstate_View year={year} accounts={currentAccounts} onChange={handleOnChangeObject} is_lender={globalState?.is_lender} />
                </Stack>
            </>}


            {steps[activeStep].key == schedule_financial_statement_confirmation && <>
                <Stack direction={isSmall ? "column" : "row"} spacing={2}
                    alignItems="center"
                    flexWrap="wrap" justifyContent="space-around" justifyItems="stretch"
                    sx={{ mt: (isSmall ? "4px" : "50px") }}
                >
                    <FinancialStatementSchedule_Confirmation year={year} accounts={currentAccounts} onChange={handleOnChangeObject} is_lender={globalState?.is_lender} />
                </Stack>
            </>}
        </DialogContent>

        <DialogActions>
            <Box sx={{ display: "flex", flexGrow: 1 }}>
                {!isSmall && <>
                    <Stepper activeStep={activeStep} orientation="horizontal" alternativeLabel>
                        {steps.map((step, index) => (
                            <Step key={index} onClick={() => { setActiveStep(index) }}>
                                <StepLabel optional={null} StepIconComponent={step?.icon || Checklist}>
                                    {step.label || ''}
                                </StepLabel>
                                <HorizontalStepContent step={step}>
                                    <Typography>{step.description}</Typography>
                                </HorizontalStepContent>
                            </Step>))}
                    </Stepper>
                    <Stack direction="row" spacing={1}>
                        <Button
                            variant="contained" color="primary"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                        >
                            <WestOutlined sx={{ mr: 1 }} />
                            Back
                        </Button>
                        <Button
                            variant="contained" color="primary"
                            disabled={activeStep >= steps.length - 1}
                            onClick={handleNext}
                        >
                            <EastOutlined sx={{ mr: 1 }} />
                            Next
                        </Button>

                        {steps[activeStep].key >= steps.length && <>
                            <Button variant="contained"
                                onClick={handleComplete}>
                                Done <EastOutlined sx={{ ml: 1 }} />
                            </Button>
                        </>}
                    </Stack>
                </>}
            </Box>

            {isSmall &&
                <WorkspaceActionBar content={<>
                    <Button
                        variant="contained" color="primary"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                    >
                        <WestOutlined sx={{ mr: 1 }} />
                        Back
                    </Button>
                    <Button
                        variant="contained" color="primary"
                        disabled={activeStep >= steps.length - 1}
                        onClick={handleNext}
                    >
                        <EastOutlined sx={{ mr: 1 }} />
                        Next
                    </Button>

                    {steps[activeStep].key >= steps.length && <>
                        <Button variant="contained"
                            onClick={handleComplete}>
                            Done <EastOutlined sx={{ ml: 1 }} />
                        </Button>
                    </>}
                </>} />
            }

        </DialogActions>
    </>)
}