import EmptyCard from 'components/Cards/EmptyCard'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';

export default function NotFound({
    title
}) {
    return (
        <>
            <WorkspaceHeader
                title={title}
                breadCrumbs={[]}
            />
            <EmptyCard
                text='404 Not found'
                secondaryText='Please select a correct menu or try again.'
                icon={<SentimentDissatisfiedOutlinedIcon color="error" fontSize="large" />} />
        </>
    )
}
