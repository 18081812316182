import { Box, Card, CardHeader, Divider, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import useFinanceReport from "../useFinanceReport";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { AppContext } from "App";
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from "components/Workspaces/helpers";
import { useDialog } from "@datanac/datanac-mui-toolkit/dist/hooks";
import { useFormStatus } from "mui-toolkit/useFormStatus";
import { FILTER_TERMS_ASSETS_CURRENT_SCHEDULES, FILTER_TERMS_ASSETS_INTERMEDIATE_SCHEDULES, FILTER_TERMS_ASSETS_LONGTERM_SCHEDULES, FILTER_TERMS_LIABILITIES_CURRENT_SCHEDULES, FILTER_TERMS_LIABILITIES_INTERMEDIATE_SCHEDULES, FILTER_TERMS_LIABILITIES_LONGTERM_SCHEDULES, SCHEDULE_FINANCIAL_CATEGORIES, schedule_financial_statement_accounts_liquid, schedule_financial_statement_accounts_receivable } from "./useFinancialStatementSchedule";
import { BalanceSheetReportTable } from "../forms/BalanceSheetReportTable";
import { FinancialIcon } from "components/Icons/Icons";
import { DeleteForeverOutlined } from "@mui/icons-material";
import { get } from "lodash";
import { getBalanceSheetFromFinancialStatementScheduleAccounts } from "../FinancialHelpers";
import { MODULE_FINANCIAL } from "components/Menu/NavigationMenu";
import { hydrateBalanceSheetDocumentDictionaries } from "../FinancialService";


export const FinancialStatementSchedule_Confirmation = ({
    accounts
}) => {
    const { globalState, globalDispatch, session } = useContext(AppContext);
    const navigate = useNavigate();
    const {
        producer_token, year
    } = useParams();

    const isPrint = useMediaQuery('print');
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true }) && !isPrint;
    const { enqueueSnackbar } = useSnackbar();
    const [workspaceMode, setWorkspaceMode] = useState(isSmall ? WORKSPACE_VIEW_CARD : WORKSPACE_VIEW_TABLE);

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const { component: formStatusComponent, actions: formStatusActions } = useFormStatus();

    const [currentYear, setCurrentYear] = useState(year || globalState?.crop_year);

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || err, { preventDuplicate: true, variant: 'error' });
    }

    // ---

    const [selectedCategoryTermsClass, setSelectedCategoryTermsClass] = useState(null);
    const handleCategorySelected = (category) => {
        setSelectedCategoryTermsClass(category);
    }

    const [filteredCategories, setFilteredCategories] = useState([]);
    const [filteredAccounts, setFilteredAccounts] = useState(accounts || []);
    useEffect(() => {
        if (selectedCategoryTermsClass) {
            let _FILTER_MASTER_LIST = [];
            if (selectedCategoryTermsClass == "FILTER_TERMS_ASSETS_CURRENT_SCHEDULES") {
                _FILTER_MASTER_LIST = FILTER_TERMS_ASSETS_CURRENT_SCHEDULES;
            } else if (selectedCategoryTermsClass == "FILTER_TERMS_LIABILITIES_CURRENT_SCHEDULES") {
                _FILTER_MASTER_LIST = FILTER_TERMS_LIABILITIES_CURRENT_SCHEDULES;

            } else if (selectedCategoryTermsClass == "FILTER_TERMS_ASSETS_INTERMEDIATE_SCHEDULES") {
                _FILTER_MASTER_LIST = FILTER_TERMS_ASSETS_INTERMEDIATE_SCHEDULES;
            } else if (selectedCategoryTermsClass == "FILTER_TERMS_LIABILITIES_INTERMEDIATE_SCHEDULES") {
                _FILTER_MASTER_LIST = FILTER_TERMS_LIABILITIES_INTERMEDIATE_SCHEDULES;

            } else if (selectedCategoryTermsClass == "FILTER_TERMS_ASSETS_LONGTERM_SCHEDULES") {
                _FILTER_MASTER_LIST = FILTER_TERMS_ASSETS_LONGTERM_SCHEDULES;
            } else if (selectedCategoryTermsClass == "FILTER_TERMS_LIABILITIES_LONGTERM_SCHEDULES") {
                _FILTER_MASTER_LIST = FILTER_TERMS_LIABILITIES_LONGTERM_SCHEDULES;
            }

            setFilteredCategories(_FILTER_MASTER_LIST);

            const _filteredAccounts = accounts.filter(account =>
                _FILTER_MASTER_LIST.includes(account?.category)
            );
            setFilteredAccounts(_filteredAccounts);
        } else {
            setFilteredAccounts(accounts);
        }
    }, [accounts, selectedCategoryTermsClass])

    // ---

    const {
        isLoading,

        isLoadingBudget,
        budget,

        isLoadingArcPlc,
        arcPlc,

        isLoadingInsuranceScenario,
        insuranceScenario,

        isLoadingBalanceSheet,
        balanceSheet,

        isLoadingIncomeStatement,
        incomeStatement,

        isLoadingLoanOriginationMemo,
        loanOriginationMemo,
        loadLoanOriginationMemo,

        isLoadingDebtRatios,
        debtRatios,

        isLoadingRatios,
        ratios,
    } = useFinanceReport({
        onError: handleError,
        currentYear,
        loadModules: [MODULE_FINANCIAL],

        producerState: producer_token ? { ...globalState, producer_token } : null
    })

    // ---

    const [financialStatementScheduleBalanceSheet, setFinancialStatementScheduleBalanceSheet] = useState([]);
    useEffect(() => {
        if (accounts?.length) {
            const _documentDictionary = getBalanceSheetFromFinancialStatementScheduleAccounts(accounts);
            const _balanceSheet = hydrateBalanceSheetDocumentDictionaries(_documentDictionary);
            setFinancialStatementScheduleBalanceSheet(_balanceSheet);
        }
    }, [accounts])

    // --- --- ---

    return <>
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <BalanceSheetReportTable
                    balanceSheet={financialStatementScheduleBalanceSheet} isLoadingBalanceSheet={isLoadingBalanceSheet}
                    debtRatios={debtRatios} isLoadingDebtRatios={isLoadingDebtRatios}
                    ratios={ratios} isLoadingRatios={isLoadingRatios}
                    onCategorySelected={handleCategorySelected} />
            </Grid>
            <Grid item xs={12} md={6}>
                {SCHEDULE_FINANCIAL_CATEGORIES
                    .filter(category => !selectedCategoryTermsClass || filteredCategories?.includes(category))
                    .map((category, index) => <Stack direction="column" spacing={1} key={index}>
                        <Box>
                            <Typography variant="h5" sx={{ textTransform: "capitalize" }}>{category.replace("schedule_financial_statement_accounts_", "")?.replace("schedule_financial_statement_", "")?.replace("_", " ")} Accounts</Typography>
                        </Box>
                        <Box className='cardContainer' sx={{ mb: 1 }}>
                            {filteredAccounts
                                ?.filter(item => item?.category == category)
                                ?.map((item, cardIndex) =>
                                    <Card sx={{ height: "100%" }} key={cardIndex}>
                                        <CardHeader
                                            avatar={<FinancialIcon />}
                                            title={item?.account_name || item?.name}
                                            subheader={item?.financial_institution_name + ' (' + item?.account_type + ')'}
                                            sx={{ mb: 2 }}
                                        />
                                    </Card>
                                )}
                        </Box>
                        <Divider />
                    </Stack>)}
            </Grid>
        </Grid >

        <Divider />
    </>;
}