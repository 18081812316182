import useDialog from "@datanac/datanac-mui-toolkit/dist/hooks/useDialog";
import { Add, DeleteForeverOutlined } from "@mui/icons-material";
import { Box, Card, CardHeader, Dialog, IconButton } from "@mui/material";
import { FinancialIcon } from "components/Icons/Icons";
import { ButtonLargeSquare } from "mui-toolkit/ButtonLargeSquare";
import { useState } from "react";
import { FinancialStatementSchedule_Accounts_Edit } from "./FinancialStatementSchedule_Accounts_EditDialog";
import { v4 } from "uuid";
import { schedule_financial_statement_accounts_payable } from "./useFinancialStatementSchedule";
import { useSnackbar } from "notistack";

export const FinancialStatementSchedule_Accounts_Payable_View = ({
    year,
    onChange,
    accounts,
    is_lender
}) => {

    const { enqueueSnackbar } = useSnackbar();
    const CATEGORY = schedule_financial_statement_accounts_payable;
    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();

    const handleChange = async (account) => {
        if (!account.id) {
            account.id = v4();
        }
        if (!account.year && year) {
            account.year = year;
        }
        if (!account.category) {
            account.category = CATEGORY;
        }

        onChange && onChange(account);
    }

    const handleDeleteOnClick = (account) => {
        confirmDialogActions.confirm({
            title: "Delete this entry?",
            prompt: "Are you sure you want to delete this entry?",
            promptDetail: null,
            callback: () => {
                const _newState = { ...account };
                _newState.is_active = false;
                enqueueSnackbar("Account deleted", { preventDuplicate: true, variant: 'info' });
                handleChange(_newState);

                return false;
            }
        })
    }

    // ---

    const [editingItem, setEditingItem] = useState(null);

    // ---

    return (<>
        <Box className='cardContainer'>
            {(accounts || [])
                ?.filter(item => item.is_active != false)
                ?.map((item, index) =>
                    <Card sx={{ height: "100%" }} key={index} onClick={() => setEditingItem(item)}>
                        <CardHeader
                            avatar={<FinancialIcon />}
                            title={item?.account_name || item?.name}
                            subheader={item?.financial_institution_name + ' (' + item?.account_type + ')'}
                            action={<>
                                <IconButton onClick={(e) => { handleDeleteOnClick(item); e.stopPropagation(); return false; }}>
                                    <DeleteForeverOutlined sx={{ color: 'black' }} />
                                </IconButton>
                            </>}
                            sx={{ mb: 2 }}
                        />
                    </Card>
                )}

            <ButtonLargeSquare
                sx={{ backgroundColor: "#efefef", justifySelf: "flex-end" }}
                icon={<Add />}
                title={"Add New"}
                subHeader={null}
                onClick={() => setEditingItem({})}
            />
        </Box>

        <Dialog open={Boolean(editingItem != null)}>
            <FinancialStatementSchedule_Accounts_Edit
                onChange={handleChange} onClose={() => setEditingItem(null)}
                account={editingItem} is_lender={is_lender} />
        </Dialog>

        {confirmDialogComponent}
    </>);
}
