import Autocomplete from '@mui/material/Autocomplete'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import { useEffect, useState } from 'react'
import { getInputComponentType } from './StatefulControlledTextField';
import _ from 'lodash';

export default function AutoCompleteTextField({
    name,
    value,
    label,
    onChange,

    freeSolo,
    maskType,

    values,
    itemGetLabel,
    itemGetValue,

    isRequired,
    disabled
}) {
    if (isRequired == null) {
        isRequired = true;
    }

    const [isEdited, setIsEdited] = useState(false);

    useEffect(() => {
        if (isRequired) {
            if (values && values.length && values.length == 1) {
                const _value = itemGetValue ? itemGetValue(values[0])
                    : itemGetLabel(values[0]);
                onChange && onChange({ target: { name: name } }, _value);
            }
        }
    }, [values])

    const handleOnBlur = (event) => {
        setIsEdited(true);

        const newEvent = event;
        newEvent.target.name = name;
        
        if (itemGetValue) {
            const _nativeValue = event?.target?.value;
            const _foundItem = values?.find(v => itemGetLabel(v) == _nativeValue);
            if (_foundItem) {
                onChange && onChange(newEvent, itemGetValue(_foundItem));
            } else {
                onChange && onChange(newEvent, null);
            }
        } else {
            onChange && onChange(newEvent, event?.target?.value);
        }
    }

    const getIsError = () => {
        if (isRequired) {
            return (!value)
        }
    }

    const getErrorText = () => {
        if (getIsError() && isEdited) {
            return "Required";
        }
    }

    const calculateUniqueValues = () => {
        if (values) {
            return _.uniqBy(values, v => itemGetLabel(v))
                ?.sort((a, b) =>
                    itemGetLabel(a)?.localeCompare(itemGetLabel(b))
                );
        } else {
            return null;
        }
    }

    return (
        <>
            <FormControl sx={{ m: 0, p: 0, width: "100%" }}>
                <Autocomplete
                    disablePortal
                    disabled={disabled}
                    freeSolo={freeSolo}
                    sx={{ width: "100%" }}
                    name={name}
                    value={value || null}
                    options={calculateUniqueValues() && calculateUniqueValues()?.map((currentItem, index) => {
                        // Bind primitives:
                        return itemGetLabel(currentItem)
                    })}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label={label || ""}
                            InputProps={{
                                ...params.InputProps,
                                id: null,
                                autoComplete: "new-password",
                                form: {
                                    autoComplete: "off"
                                },
                                m: 0, p: 0,
                                // inputComponent: getInputComponentType(maskType)
                            }}
                            sx={{ m: 0, p: 0 }}
                            error={getIsError()}
                            helperText={getErrorText()}
                            autoComplete='new-password'
                        />
                    }
                    onChange={(event, value) => {
                        ////console.log(value);
                        const newEvent = event;
                        newEvent.target.name = name;
                        ////console.log(newEvent);
                        onChange && onChange(newEvent, value);
                    }}
                    autoSelect
                    autoHighlight
                    onBlur={handleOnBlur}
                    onFocus={(e) => e.target.select()}
                />
            </FormControl>
        </>
    )
}
