import { useDialog } from '@datanac/datanac-mui-toolkit'
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import { Box, CircularProgress, Divider, Grid, Menu, MenuItem, Stack, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import { AppContext } from 'App'
import { UsersApiHelper } from 'api/ApiHelper'
import { getBudgetYears } from 'components/Budget/helpers'
import { MASK_NUMERIC, StatefulControlledTextField } from 'components/Core/StatefulControlledTextField'
import { FinancialIcon } from 'components/Icons/Icons'
import InputDropdownPair from 'components/InputDropdownPair'
import InputTextPair from 'components/InputTextPair'
import { MODULE_FINANCIAL } from 'components/Menu/NavigationMenu'
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from 'components/Workspaces/helpers'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { SkeletonWrapper } from 'mui-toolkit/SkeletonWrapper'
import { useFormStatus } from 'mui-toolkit/useFormStatus'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { formatNumber, formatPercentage, formatPrice } from 'utility/formatters'
import { v4 as uuidv4 } from 'uuid'
import { OBJECT_NAME_LOAN_ORIGINATION_MEMO } from '../FinancialHelpers'
import { ITEM_NAME_BALANCE_SHEET_EQUITY_RETAINED_CAPITAL } from '../forms/BalanceSheetHelper'
import useFinanceReport from '../useFinanceReport'
import { FILTER_TERMS_ASSETS_CURRENT_SCHEDULES, FILTER_TERMS_ASSETS_LONGTERM_SCHEDULES, FILTER_TERMS_LIABILITIES_CURRENT_SCHEDULES, FILTER_TERMS_LIABILITIES_LONGTERM_SCHEDULES } from '../schedule_financial_statement/useFinancialStatementSchedule'

export const BalanceSheetReportTable = ({
    isLoadingBalanceSheet,
    balanceSheet,

    isLoadingDebtRatios,
    debtRatios,

    isLoadingRatios,
    ratios,

    onCategorySelected
}) => {
    // --- --- ---

    return <Table className='reportTable'>
        <TableHead>
            <TableRow>
                <TableCell colSpan={3}>
                    <Box sx={{ display: "flex" }}>
                        <Box sx={{ flex: 1 }}>
                            <Typography variant="h6">
                                Farm Balance Sheet
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            &nbsp;
                        </Box>
                    </Box>
                </TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            <TableRow>
                <TableCell width="50%" onClick={() => onCategorySelected && onCategorySelected("FILTER_TERMS_ASSETS_CURRENT_SCHEDULES")} sx={{ cursor: onCategorySelected ? "pointer" : "" }}>
                    Current Assets
                </TableCell>
                <TableCell width="40%">
                    <StatefulControlledTextField label="" isEditable={false}
                        defaultValue={formatPrice(balanceSheet?.assets_current?.total, 0)} isLoading={isLoadingBalanceSheet}
                        className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                </TableCell>
                <TableCell width="10%" align="right">
                    <SkeletonWrapper isLoading={isLoadingBalanceSheet} fullWidth>
                        <Typography variant='subtitle2' noWrap>
                            {formatPercentage(
                                balanceSheet?.assets?.total != 0
                                    ? balanceSheet?.assets_current?.total / balanceSheet?.assets?.total
                                    : 0
                                , 2)}
                        </Typography>
                    </SkeletonWrapper>
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell width="50%" onClick={() => onCategorySelected && onCategorySelected("FILTER_TERMS_ASSETS_LONGTERM_SCHEDULES")} sx={{ cursor: onCategorySelected ? "pointer" : "" }}>
                    Long Term Assets
                </TableCell>
                <TableCell>
                    <StatefulControlledTextField label="" isEditable={false}
                        defaultValue={formatPrice(balanceSheet?.assets_noncurrent?.total, 0)} isLoading={isLoadingBalanceSheet}
                        className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                </TableCell>
                <TableCell align="right">
                    <SkeletonWrapper isLoading={isLoadingBalanceSheet} fullWidth>
                        <Typography variant='subtitle2' noWrap>
                            {formatPercentage(
                                balanceSheet?.assets?.total != 0
                                    ? balanceSheet?.assets_noncurrent?.total / balanceSheet?.assets?.total
                                    : 0
                                , 2)}
                        </Typography>
                    </SkeletonWrapper>
                </TableCell>
            </TableRow>

            {Boolean(balanceSheet?.assets_personal?.total) &&
                <TableRow>
                    <TableCell>
                        Personal Assets
                    </TableCell>
                    <TableCell sx={{ borderBottom: "1px solid #c0c0c0 !important" }}>
                        <StatefulControlledTextField label="" isEditable={false}
                            defaultValue={formatPrice(balanceSheet?.assets_personal?.total, 0)} isLoading={isLoadingBalanceSheet}
                            className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                    </TableCell>
                    <TableCell align="right">
                        <SkeletonWrapper isLoading={isLoadingBalanceSheet} fullWidth>
                            <Typography variant='subtitle2' noWrap>
                                {formatPercentage(
                                    balanceSheet?.assets?.total != 0
                                        ? balanceSheet?.assets_personal?.total / balanceSheet?.assets?.total
                                        : 0
                                    , 2)}
                            </Typography>
                        </SkeletonWrapper>
                    </TableCell>
                </TableRow>}

            <TableRow sx={{ borderBottom: "2px solid #c0c0c0" }}>
                <TableCell>
                    Total Assets
                </TableCell>
                <TableCell>
                    <StatefulControlledTextField label="" isEditable={false}
                        defaultValue={formatPrice(balanceSheet?.assets?.total, 0)} isLoading={isLoadingBalanceSheet}
                        className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                </TableCell>
                <TableCell>
                    &nbsp;
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell width="50%" onClick={() => onCategorySelected && onCategorySelected("FILTER_TERMS_LIABILITIES_CURRENT_SCHEDULES")} sx={{ cursor: onCategorySelected ? "pointer" : "" }}>
                    Current Liabilities
                </TableCell>
                <TableCell>
                    <StatefulControlledTextField label="" isEditable={false}
                        defaultValue={formatPrice(balanceSheet?.liabilities_current?.total, 0)} isLoading={isLoadingBalanceSheet}
                        className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                </TableCell>
                <TableCell align="right">
                    <SkeletonWrapper isLoading={isLoadingBalanceSheet} fullWidth>
                        <Typography variant='subtitle2' noWrap>
                            {formatPercentage(
                                balanceSheet?.liabilities?.total != 0
                                    ? balanceSheet?.liabilities_current?.total / balanceSheet?.liabilities?.total
                                    : 0
                                , 2)}
                        </Typography>
                    </SkeletonWrapper>
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell width="50%" onClick={() => onCategorySelected && onCategorySelected("FILTER_TERMS_LIABILITIES_LONGTERM_SCHEDULES")} sx={{ cursor: onCategorySelected ? "pointer" : "" }}>
                    Long Term Liabilities
                </TableCell>
                <TableCell>
                    <StatefulControlledTextField label="" isEditable={false}
                        defaultValue={formatPrice(balanceSheet?.liabilities_noncurrent?.total, 0)} isLoading={isLoadingBalanceSheet}
                        className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                </TableCell>
                <TableCell align="right">
                    <SkeletonWrapper isLoading={isLoadingBalanceSheet} fullWidth>
                        <Typography variant='subtitle2' noWrap>
                            {formatPercentage(
                                balanceSheet?.liabilities?.total != 0
                                    ? balanceSheet?.liabilities_noncurrent?.total / balanceSheet?.liabilities?.total
                                    : 0
                                , 2)}
                        </Typography>
                    </SkeletonWrapper>
                </TableCell>
            </TableRow>

            {Boolean(balanceSheet?.liabilities_personal?.total) &&
                <TableRow>
                    <TableCell>
                        Personal Liabilities
                    </TableCell>
                    <TableCell>
                        <StatefulControlledTextField label="" isEditable={false}
                            defaultValue={formatPrice(balanceSheet?.liabilities_personal?.total, 0)} isLoading={isLoadingBalanceSheet}
                            className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                    </TableCell>
                    <TableCell align="right">
                        <SkeletonWrapper isLoading={isLoadingBalanceSheet} fullWidth>
                            <Typography variant='subtitle2' noWrap>
                                {formatPercentage(
                                    balanceSheet?.liabilities?.total != 0
                                        ? balanceSheet?.liabilities_personal?.total / balanceSheet?.liabilities?.total
                                        : 0
                                    , 2)}
                            </Typography>
                        </SkeletonWrapper>
                    </TableCell>
                </TableRow>}

            <TableRow>
                <TableCell>
                    Tax Liabilities
                </TableCell>
                <TableCell sx={{ borderBottom: "1px solid #c0c0c0 !important" }}>
                    <StatefulControlledTextField label="" isEditable={false}
                        defaultValue={formatPrice(balanceSheet?.liabilities_tax?.total, 0)} isLoading={isLoadingBalanceSheet}
                        className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                </TableCell>
                <TableCell align="right">
                    <SkeletonWrapper isLoading={isLoadingBalanceSheet} fullWidth>
                        <Typography variant='subtitle2' noWrap>
                            {formatPercentage(
                                balanceSheet?.liabilities?.total != 0
                                    ? balanceSheet?.liabilities_tax?.total / balanceSheet?.liabilities?.total
                                    : 0
                                , 2)}
                        </Typography>
                    </SkeletonWrapper>
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell>
                    Total Liabilities
                </TableCell>
                <TableCell>
                    <StatefulControlledTextField label="" isEditable={false}
                        defaultValue={formatPrice(balanceSheet?.liabilities?.total, 0)} sx={{ fontWeight: "bold !important" }} isLoading={isLoadingBalanceSheet}
                        className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                </TableCell>
                <TableCell>
                    &nbsp;
                </TableCell>
            </TableRow>

            <TableRow sx={{ borderBottom: "2px solid #c0c0c0" }}>
                <TableCell>
                    Total Equity
                </TableCell>
                <TableCell>
                    <StatefulControlledTextField label="" isEditable={false}
                        defaultValue={formatPrice(balanceSheet?.equity?.total, 0)} sx={{ fontWeight: "bold !important" }} isLoading={isLoadingBalanceSheet}
                        className="noMargin" fullWidth inputProps={{ sx: { textAlign: "right" } }} />
                </TableCell>
                <TableCell>
                    &nbsp;
                </TableCell>
            </TableRow>
        </TableBody>
    </Table>;
}