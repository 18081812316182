import { useDialog } from '@datanac/datanac-mui-toolkit'
import { CopyAllOutlined, MoreVertOutlined } from '@mui/icons-material'
import DeleteForeverOutlined from '@mui/icons-material/DeleteForeverOutlined'
import { Box, Card, Dialog, CardActions, CardContent, CardHeader, Chip, Divider, IconButton, ListItemIcon, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { BudgetIcon, EquipmentIcon } from 'components/Icons/Icons'
import PopupState, { bindMenu, bindToggle } from 'material-ui-popup-state'
import { formatNumber, formatPrice } from 'utility/formatters'
import { useState } from "react";
import { EquipmentCard_Edit } from './EquipmentCard_Edit'


export const EQUIPMENT_CATEGORY_CROPS = "Crops";
export const EQUIPMENT_CATEGORY_LIVESTOCK = "Livestock";
export const EQUIPMENT_CATEGORY_VEHICLES = "Vehicles";
export const EQUIPMENT_CATEGORY_GENERAL = "General";

export const EQUIPMENT_CATEGORIES = [
    EQUIPMENT_CATEGORY_CROPS,
    EQUIPMENT_CATEGORY_LIVESTOCK,
    EQUIPMENT_CATEGORY_VEHICLES,
    EQUIPMENT_CATEGORY_GENERAL
];

export const EQUIPMENT_CONDITION_NEW = "New";
export const EQUIPMENT_CONDITION_EXCELLENT = "Excellent";
export const EQUIPMENT_CONDITION_GOOD = "Good";
export const EQUIPMENT_CONDITION_FAIR = "Fair";
export const EQUIPMENT_CONDITION_POOR = "Poor";

export const EQUIPMENT_CONDITIONS = [
    EQUIPMENT_CONDITION_NEW,
    EQUIPMENT_CONDITION_EXCELLENT,
    EQUIPMENT_CONDITION_GOOD,
    EQUIPMENT_CONDITION_FAIR,
    EQUIPMENT_CONDITION_POOR
];

export const EQUIPMENT_TERM_NET_30_DAYS = "Net 30 Days";
export const EQUIPMENT_TERM_NET_60_DAYS = "Net 60 Days";

export const EQUIPMENT_TERMS = [
    EQUIPMENT_TERM_NET_30_DAYS,
    EQUIPMENT_TERM_NET_60_DAYS
];

export default function EquipmentCard({
    equipment,
    onChange,
    editingEquipment,
    handleEditClick,
    is_lender,

    countyPractices
}) {

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const handleDeleteOnClick = async () => {
        confirmDialogActions.confirm({
            title: "Delete this entry?",
            prompt: "Are you sure you want to delete this entry?",
            promptDetail: null,
            callback: () => {
                const _newEquipment = { ...equipment };
                _newEquipment.is_active = false;
                onChange && onChange(_newEquipment);
            }
        })
    }

    // ---

    return (
        <>
            <Card onClick={() => handleEditClick(equipment)}>
                <CardHeader
                    avatar={<EquipmentIcon />}
                    title={equipment?.name}
                    subheader={equipment?.condition + ' (' + equipment?.category + ')'}
                    action={<>
                        <IconButton onClick={(e) => { e.stopPropagation(); handleDeleteOnClick(); return false; }}>
                            <DeleteForeverOutlined sx={{ color: 'black' }} />
                        </IconButton>
                    </>}
                />
                <CardContent>
                    <Stack direction="column" spacing={1} height="100%">
                        <Stack direction='row' flexWrap='wrap' spacing={1}>
                            <Box>
                                <Typography variant='h6'>
                                    {formatPrice(equipment?.value, 0)}
                                </Typography>
                            </Box>
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>

            {confirmDialogComponent}
        </>
    )
}
