export const ERR_RANGE_INVALID = "KPI range could not be located: $KPI ($VALUE). Please ensure KPI name and try again.";
export const ERR_WEIGHT_INVALID = "KPI weight could not be located: $KPI. Please ensure KPI name and try again.";

export const KPI_FICO = "fico";
export const KPI_REVENUE_TO_PAYMENTS_RATIO = "revenue_to_payments_ratio";
export const KPI_EXCESS_CASH_FLOW_RATIO = "excess_cash_flow_ratio";
export const KPI_PRIOR_YEAR_CARRY_OVER_RATIO = "prior_year_carry_over_ratio";
export const KPI_EQUITY_TO_ASSETS_RATIO = "equity_to_assets_ratio";
export const KPI_LOAN_TO_VALUE = "loan_to_value";
export const KPI_DEBT_TO_EQUITY_RATIO = "debt_to_equity_ratio";
export const KPI_WORKING_CAPITAL_TO_GROSS_REVENUE_RATIO = "working_capital_to_gross_revenue_ratio";

export const KPI_EXPECTED_LOSS = "expected_loss";

export const KPI_GRADE_RANGES = {
    "fico": [
        { min: -Infinity, max: 600, grade: 4 },
        { min: 600, max: 700, grade: 3 },
        { min: 700, max: 795, grade: 2 },
        { min: 795, max: Infinity, grade: 1 },
    ],

    "revenue_to_payments_ratio": [
        { min: -Infinity, max: 1.00, grade: 4 },
        { min: 1.00, max: 1.03, grade: 3 },
        { min: 1.03, max: 1.12, grade: 2 },
        { min: 1.12, max: Infinity, grade: 1 },
    ],

    "excess_cash_flow_ratio": [
        { min: -Infinity, max: 1.00, grade: 4 },
        { min: 1.20, max: 1.30, grade: 3 },
        { min: 1.30, max: 1.40, grade: 2 },
        { min: 1.40, max: Infinity, grade: 1 },
    ],

    "prior_year_carry_over_ratio": [
        { min: -Infinity, max: 0.10, grade: 1 },
        { min: 0.10, max: 1.00, grade: 2 },
        { min: 1.00, max: 2.00, grade: 3 },
        { min: 2.00, max: Infinity, grade: 4 },
    ],

    "equity_to_assets_ratio": [
        { min: -Infinity, max: 0.20, grade: 1 },
        { min: 0.20, max: 0.40, grade: 2 },
        { min: 0.40, max: 0.60, grade: 3 },
        { min: 0.60, max: Infinity, grade: 4 },
    ],

    // LGD

    "loan_to_value": [
        { min: -Infinity, max: 0.25, grade: 1 },
        { min: 0.25, max: 0.55, grade: 2 },
        { min: 0.55, max: 0.80, grade: 3 },
        { min: 0.80, max: Infinity, grade: 4 },
    ],
    "debt_to_equity_ratio": [
        { min: -Infinity, max: 0.75, grade: 1 },
        { min: 0.75, max: 1.00, grade: 2 },
        { min: 1.00, max: 1.25, grade: 3 },
        { min: 1.25, max: Infinity, grade: 4 },
    ],
    "working_capital_to_gross_revenue_ratio": [
        { min: -Infinity, max: 0.10, grade: 4 },
        { min: 0.10, max: 0.20, grade: 3 },
        { min: 0.20, max: 0.30, grade: 2 },
        { min: 0.30, max: Infinity, grade: 1 },
    ],

    "expected_loss": [
        { min: -Infinity, max: 0.0040, grade: "1 - Excellent" },
        { min: 0.0040, max: 0.001, grade: "2 - Superior" },
        { min: 0.001, max: 0.010, grade: "3 - Marginal" },
        { min: 0.010, max: Infinity, grade: "4 - Lower" },
    ]
}

export const KPI_WEIGHTS = {
    "fico": { probability_of_default: 0.10 },
    "revenue_to_payments_ratio": { probability_of_default: 0.15 },
    "excess_cash_flow_ratio": { probability_of_default: 0.30 },
    "prior_year_carry_over_ratio": { probability_of_default: 0.15 },
    "equity_to_assets_ratio": { probability_of_default: 0.15 },

    // LGD
    "loan_to_value": { loss_given_default: 0.75 },
    "debt_to_equity_ratio": { loss_given_default: 0.15 },
    "working_capital_to_gross_revenue_ratio": { loss_given_default: 0.10 },

    "expected_loss": { probability_of_default: 1, loss_given_default: 1 }
};

export const KPI_GRADE_CECL_SCORE = {
    1: { probability_of_default: 0.30, loss_given_default: 13.00 },
    2: { probability_of_default: 2.00, loss_given_default: 25.00 },
    3: { probability_of_default: 3.90, loss_given_default: 33.00 },
    4: { probability_of_default: 4.00, loss_given_default: 50.00 },
};

export const calculateKpiGrade = (kpi, value) => {
    const ranges = KPI_GRADE_RANGES[kpi];

    if (value === null) {
        return null;
    }

    const filteredGrades = ranges?.filter(r =>
        value > r?.min
        && value <= r?.max
    );
    if (filteredGrades?.length) {
        const selectedGrade = filteredGrades[0];

        const selectedScore = KPI_GRADE_CECL_SCORE[String(selectedGrade?.grade)];

        const selectedWeight = KPI_WEIGHTS[kpi];
        if (selectedWeight) {
            const kpiGrade = {
                kpi,
                value,
                grade: selectedGrade?.grade,
                weight: selectedWeight?.weight,

                probability_of_default: selectedScore?.probability_of_default,
                probability_of_default_weighted: (selectedScore?.probability_of_default * selectedWeight?.probability_of_default) || 0,

                loss_given_default: selectedScore?.loss_given_default,
                loss_given_default_weighted: (selectedScore?.loss_given_default * selectedWeight?.loss_given_default) || 0,
            };
            return kpiGrade;
        } else {
            console.warn(ERR_WEIGHT_INVALID?.replace("$KPI", kpi));
        }
    } else {
        console.warn(ERR_RANGE_INVALID?.replace("$KPI", kpi)?.replace("$VALUE", value));
    }
}