import { Box, Grid, LinearProgress, Stack, Typography } from "@mui/material"
import { formatFixed1, formatNumber } from "utility/formatters"
import { SkeletonWrapper } from "./SkeletonWrapper";

export const LinearIndicator = ({
    title,

    checkpoint1,
    checkpoint2,

    value,
    precision,

    valueFormatter,

    isLoading
}) => {

    const _valueFormatter = valueFormatter || formatFixed1;

    const getActiveSegment = () => {
        if (checkpoint1 < checkpoint2) {
            if (value < checkpoint1) {
                return 0;
            } else if (value < checkpoint2) {
                return 1;
            } else {
                return 2;
            }
        } else {
            if (value < checkpoint2) {
                return 2;
            } else if (value < checkpoint1) {
                return 1;
            } else {
                return 0;
            }
        }
    }

    return (
        <Box className="linearIndicator">
            <Typography variant="body1" className="title" sx={{ mb: 0, pb: 0 }}>
                {title}
            </Typography>

            <Grid container columnSpacing={1} justifyContent="center" justifyItems="center">
                <Grid item xs={3}>
                    <Stack direction="column" spacing={1}>
                        <Typography variant="h6" className="value" sx={{ m: 0, p: 0, textAlign: "center" }}>
                            {(!isLoading && (getActiveSegment() == 0)) ?
                                _valueFormatter(value)
                                : " "}
                        </Typography>

                        <SkeletonWrapper isLoading={isLoading} sx={{ minWidth: "100%" }}>
                            <Box className={"indicator" + (getActiveSegment() == 0 ? " low" : "")} />
                        </SkeletonWrapper>

                        <Typography variant="body2" sx={{ m: 0, p: 0, textAlign: "center" }}>
                            Vulnerable
                        </Typography>
                    </Stack>
                </Grid>

                <Grid item xs={4}>
                    <Stack direction="row" spacing={1}>
                        <Stack direction="column" spacing={1} >
                            <Typography variant="h6" sx={{ m: 0, p: 0 }}>
                                &nbsp;
                            </Typography>
                            <Typography variant="body2" sx={{ m: 0, p: 0 }} className="checkpoint">
                                {_valueFormatter(checkpoint1)}
                            </Typography>
                        </Stack>

                        <Stack direction="column" spacing={1} sx={{ flexGrow: 1 }}>
                            <SkeletonWrapper isLoading={isLoading} sx={{ minWidth: "100%" }}>
                                <Typography variant="h6" className="value" sx={{ m: 0, p: 0, textAlign: "center" }}>
                                    {getActiveSegment() == 1 ?
                                        _valueFormatter(value)
                                        : " "}
                                </Typography>
                            </SkeletonWrapper>

                            <SkeletonWrapper isLoading={isLoading} sx={{ minWidth: "100%" }}>
                                <Box className={"indicator" + (getActiveSegment() == 1 ? " medium" : "")} />
                            </SkeletonWrapper>
                        </Stack>
                    </Stack>
                </Grid>

                <Grid item xs={4.2}>
                    <Stack direction="row" spacing={1}>
                        <Stack direction="column" spacing={1}>
                            <Typography variant="h6" sx={{ m: 0, p: 0, textAlign: "center" }}>
                                &nbsp;
                            </Typography>
                            <Typography variant="body2" sx={{ m: 0, p: 0 }} className="checkpoint">
                                {_valueFormatter(checkpoint2)}
                            </Typography>
                        </Stack>

                        <Stack direction="column" spacing={1} sx={{ flexGrow: 1 }}>
                            <Typography variant="h6" className="value" sx={{ m: 0, p: 0, textAlign: "center" }}>
                                {(!isLoading && (getActiveSegment() == 2)) ?
                                    _valueFormatter(value)
                                    : " "}
                            </Typography>

                            <SkeletonWrapper isLoading={isLoading} sx={{ minWidth: "100%" }}>
                                <Box className={"indicator" + (getActiveSegment() == 2 ? " high" : "")} />
                            </SkeletonWrapper>

                            <Typography variant="body2" sx={{ m: 0, p: 0, textAlign: "center" }}>
                                Strong
                            </Typography>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    )
}