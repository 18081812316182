import React, { useEffect, useState } from 'react'
import { PromptDialog } from './PromptDialog'

export default function usePrompt() {
    const [state, setState] = useState({
        open: false,
        title: "Confirm",
        prompt: "Are you sure you wish to perform this action?",
        promptDetail: null,
        callback: null,
        dialogState: null,
    });

    const prompt = ({ title, prompt, promptDetail, callback }) => {
        setState({
            ...state,
            open: true,
            title: title,
            prompt: prompt,
            promptDetail: promptDetail,
            callback: callback,
            dialogState: null,
        });
    }

    useEffect(() => {
        if (state.dialogState && state.callback) {
            state.callback(state?.inputValue);
        }
    }, [state.dialogState])

    const actions = {
        prompt
    };

    const component = <>
        <PromptDialog
            title={state.title}
            open={state.open}
            prompt={state.prompt}
            promptDetail={state.promptDetail}
            onClickConfirm={(e) => setState({ ...state, open: false, dialogState: true, inputValue: e })}
            onClickCancel={() => setState({ ...state, ...{ open: false, dialogState: false } })}
        />
    </>;

    return {
        state,
        component,
        actions
    };
}
