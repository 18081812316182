import { UsersApiHelper } from "api/ApiHelper";
import Axios from "axios"
import { FileExcelIcon, FilePdfIcon, FinancialDocumentsIcon } from "components/Icons/Icons";
import fileDownload from "js-file-download";
import { FaFileExcel } from "react-icons/fa";

export const CONTENT_TYPE_EXCEL_XLS = "application/vnd.ms-excel";
export const CONTENT_TYPE_EXCEL_XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
export const CONTENT_TYPE_PDF = "application/pdf";

export const uploadDocument = async (_financialDocument, _file, globalState) => {
    const _signedUrl = await UsersApiHelper.updateFinancialDocument(_financialDocument, globalState);

    await (Axios.create())
        .request({
            method: "PUT",
            url: _signedUrl.url,
            data: (await _file?.arrayBuffer()),
            timeout: 3600 * 1000,
            // onUploadProgress: (progress) => {
            //     // console.log("Progress", progress);
            // },
        });
}

export const downloadDocument = async (_financialDocument, globalState) => {
    if (_financialDocument?.content_type) {
        const _signedUrl = await UsersApiHelper.getFinancialDocument(_financialDocument, globalState);
        if (_signedUrl?.url) {
            return downloadDocumentByUrl(_signedUrl.url, _financialDocument);
        }
    } else {
        return false;
    }
}

export const downloadDocumentByUrl = async (_url, _financialDocument) => {
    var _extension = getFileTypeExtension(_financialDocument);

    try {
        const _blob = await (Axios.create()).get(_url, { responseType: "blob" });
        fileDownload(_blob.data, _financialDocument.document_type_name + _extension);
        return true;
    } catch (err) {
        console.warn(err);
        throw "File download was not available. Select another file.";
    }
}

export const getFileIcon = (file) => {
    switch (file?.type || file?.content_type) {
        case CONTENT_TYPE_PDF:
            return <FilePdfIcon sx={{ fontSize: "3rem" }} />
        case CONTENT_TYPE_EXCEL_XLS, CONTENT_TYPE_EXCEL_XLSX:
            return <FileExcelIcon sx={{ fontSize: "3rem" }} />
        default:
            return <FinancialDocumentsIcon sx={{ fontSize: "3rem" }} />
    }
}

export const getFileTypeName = (file) => {
    switch (file?.type || file?.content_type) {
        case CONTENT_TYPE_PDF:
            return "PDF";
        case CONTENT_TYPE_EXCEL_XLS, CONTENT_TYPE_EXCEL_XLSX:
            return "Excel";
        default:
            return "Other";
    }
}

export const getFileTypeExtension = (file) => {
    switch (file?.type || file?.content_type) {
        case CONTENT_TYPE_PDF:
            return ".pdf";
        case CONTENT_TYPE_EXCEL_XLS:
            return ".xls";
        case CONTENT_TYPE_EXCEL_XLSX:
            return ".xlsx";
        default:
            return "";
    }
}