import { getComboTitle } from '@datanac/datanac-api-toolkit/dist/insurance/optimizer/optimizer';
import { UNIT_STRUCTURE_CODE_BU, UNIT_STRUCTURE_CODE_EU, UNIT_STRUCTURE_CODE_OU } from '@datanac/datanac-api-toolkit/dist/utility/ProducerFarmHelper';
import { Alert, Button, Chip, CircularProgress, TableBody } from '@mui/material';
import Table from '@mui/material/Table';
import TableCell from "@mui/material/TableCell";
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { formatPrice } from 'utility/formatters';

export default function InsuranceCalculatePremiumTableGrid({
    farmParameters,
    insuranceOffer,
    calculatePremiumsTableResult,
    onPremiumPriceSelect
}) {
    return (
        <>
            {!Boolean(calculatePremiumsTableResult?.length) && <>
                <Alert severity='info' sx={{ m: 3 }}>
                    Loading...
                </Alert>
            </>}

            {Boolean(calculatePremiumsTableResult?.length) && <>
                <Table className='reportTable' size='small' spacing={0} sx={{ width: "100%", mt: 1 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ borderBottom: "none" }}>&nbsp;</TableCell>
                            <TableCell sx={{ borderBottom: "none" }}>&nbsp;</TableCell>
                            <TableCell colSpan={3} sx={{ textAlign: "center", backgroundColor: "#f8f8f8" }}>
                                Producer Premium $ / per acre
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ textAlign: "center", }}>
                                <Typography>Coverage %</Typography>
                            </TableCell>
                            <TableCell sx={{ textAlign: "center", }}>
                                <Typography>Liability</Typography>
                            </TableCell>
                            <TableCell sx={{ textAlign: "center", }}>
                                <Typography>Basic Units</Typography>
                            </TableCell>
                            <TableCell sx={{ textAlign: "center", }}>
                                <Typography>Optional Units</Typography>
                            </TableCell>
                            <TableCell sx={{ textAlign: "center", }}>
                                <Typography>Enterprise</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Boolean(calculatePremiumsTableResult?.length) &&
                            calculatePremiumsTableResult?.map((currentCalculatePremiumsTableResult, rowIndex) => {
                                return (
                                    <TableRow key={rowIndex}>
                                        <TableCell sx={{ textAlign: "center" }}>
                                            <Chip label={
                                                getComboTitle([{
                                                    ...insuranceOffer,
                                                    coverage_level_percent: currentCalculatePremiumsTableResult?.coverage_level_percent
                                                }])
                                            } />
                                        </TableCell>
                                        <TableCell sx={{ textAlign: "center" }}>
                                            <Typography>
                                                {formatPrice(currentCalculatePremiumsTableResult?.bu?.liability_amount)}<br />
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ textAlign: "center", }}>
                                            <Button variant="text" onClick={() => onPremiumPriceSelect && onPremiumPriceSelect({
                                                coverage_level_percent: currentCalculatePremiumsTableResult?.coverage_level_percent,
                                                unit_structure_code: UNIT_STRUCTURE_CODE_BU,
                                                ...currentCalculatePremiumsTableResult?.bu
                                            })}>
                                                {formatPrice(currentCalculatePremiumsTableResult?.bu?.producer_premium_amount / farmParameters?.reported_acreage, 2)}
                                            </Button>
                                        </TableCell>
                                        <TableCell sx={{ textAlign: "center", }}>
                                            <Button variant="text" onClick={() => onPremiumPriceSelect && onPremiumPriceSelect({
                                                coverage_level_percent: currentCalculatePremiumsTableResult?.coverage_level_percent,
                                                unit_structure_code: UNIT_STRUCTURE_CODE_OU,
                                                ...currentCalculatePremiumsTableResult?.ou
                                            })}>
                                                {formatPrice(currentCalculatePremiumsTableResult?.ou?.producer_premium_amount / farmParameters?.reported_acreage, 2)}
                                            </Button>
                                        </TableCell>
                                        <TableCell sx={{ textAlign: "center", }}>
                                            <Button variant="text" onClick={() => onPremiumPriceSelect && onPremiumPriceSelect({
                                                coverage_level_percent: currentCalculatePremiumsTableResult?.coverage_level_percent,
                                                unit_structure_code: UNIT_STRUCTURE_CODE_EU,
                                                ...currentCalculatePremiumsTableResult?.eu
                                            })}>
                                                {formatPrice(currentCalculatePremiumsTableResult?.eu?.producer_premium_amount / farmParameters?.reported_acreage, 2)}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </>}
        </>
    );
}
