import { v4 } from "uuid";

export const propUpdateReducer = (state, action) => {
  let _newState = {
  };

  switch (action.type) {
    case 'update':
      _newState = {
        ...state,
        [action.payload.key]: action.payload.value,
      };
      break;
    case 'updateMany':
      _newState = {
        ...state,
        ...action?.payload
      };
      break;
    case 'updateList':
      const _farm_id = action.payload.farm_id;

      if (!_farm_id) {
        throw new Error(`Farm not specified.  Select a farm and try again.`);
      }

      const _key = action.payload.key || '0';
      const _updateList = action.payload.value || [];
      const _doUpdateTimestamp = action.payload.doUpdateTimestamp;

      if (!state[_farm_id]) {
        state[_farm_id] = [];
      }

      const _priorStateList = state[_farm_id][_key] || [];
      // Update:
      const _newList = _priorStateList.map(item => {
        const _lookupItem = _updateList.find(i => (i.id === item.id));
        if (_lookupItem) {
          Object.keys(_lookupItem).forEach(key => {
            item[key] = _lookupItem[key];
          });
          return item;
        } else {
          return item;
        }
      });
      // Insert:
      _updateList.forEach(updateItem => {
        const _locatedItem = _priorStateList.find(i => (i.id == updateItem.id));
        if (!_locatedItem && updateItem.is_active) {
          _newList.push(updateItem);
        }
      });

      _newState = {
        ...state,
        ...(action.payload.props || {}),
        timestamp: (_doUpdateTimestamp) ? v4() : (state.timestamp || v4())
      }
      _newState[_farm_id].insurancePlans = _newList;

      break;
    case 'destructure':
      _newState = {
        ...state,
        ...action?.payload
      };

      break;
    case 'replace':
      _newState = {
        ...action?.payload
      };
      break;
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }

  if (action?.callback) {
    const invokeCallbackAsync = async () => {
      action.callback(_newState);
    }
    invokeCallbackAsync();
  }

  // console.log("propUpdateReducer", _newState);

  return _newState;
};