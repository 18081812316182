import SearchOffOutlined from '@mui/icons-material/SearchOffOutlined'
import { Box, Card, CardContent, Skeleton, Stack, Typography } from '@mui/material'

export default function EmptyCard({
    text,
    secondaryText,

    icon,

    isLoading
}) {
    return (
        <>
            <Card className='empty'>
                <CardContent>
                    <Stack direction='column'
                        justifyContent='center' alignItems='center'
                        sx={{ minHeight: '1.85in' }}
                    >
                        {!isLoading && <>
                            {icon ||
                                <SearchOffOutlined fontSize='large' color='secondary' sx={{ fontSize: "20pt", mb: 2 }} />
                            }
                            <Typography variant='h5' sx={{ display: 'inline' }}>
                                {text || "No data is available yet"}
                            </Typography>

                            {Boolean(secondaryText) &&
                                <Typography variant='body1' sx={{ display: 'inline' }}>
                                    {secondaryText}
                                </Typography>
                            }
                        </>}

                        {isLoading == true && <>
                            <Box alignItems="left" display="flex" sx={{ width: "100%", height: "100%" }}>
                                <Skeleton variant="rounded" width="64px" height="64px" sx={{ m: 1 }} />
                                <Skeleton variant="rectangular" width="100%" height="161px" sx={{ m: 1 }} />
                            </Box>
                        </>}
                    </Stack>
                </CardContent>
            </Card>
        </>
    )
}
