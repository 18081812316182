import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'


import DataFormSelect from './Core/DataFormSelect'

const reducer = (state, action) => {
    switch (action.type) {
        case 'update':
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            }
        default:
            throw new Error(`Unknown action type: ${action.type}`)
    }
}

export default function InputDropdownPair({
    label,

    name,
    value,
    values,
    doFetch,

    itemFilter,
    itemGetKey,
    itemGetLabel,

    isRequired,

    onChange,
}) {
    const [selectedValue, setSelectedValue] = useState(value || '');
    useEffect(() => {
        setSelectedValue(value || '');
    }, [value])

    const handleSelectChange = (event, _value) => {
        setSelectedValue(event?.target?.value || '');

        onChange && onChange(event, _value);
    }

    return (
        <Box className='mui-InputPair mui-InputDropdownPair'>
            <Box
                sx={{
                    p: 0, m: 0,
                    display: "flex",
                    //border: "1px solid #bebebe",
                    //borderRadius: "5px",
                    alignItems: "center", justifyContent: "center",
                    // '&:focus-within': { boxShadow: "0 0 0 3px #8CC63F" }
                }}>
                <Box sx={{
                    display: "flex",
                    m: 0, height: "100%",
                    boxSizing: "border-box",
                    // justifyContent: "center",
                    width: "50%",
                    //p: "9pt", 
                    //borderRadius: 0, 
                    //borderRight: "1px solid #bebebe",
                }}
                >
                    <Typography sx={{ m: 0, p: 0 }}>
                        {label}
                    </Typography>
                </Box>
                <Box sx={{ width: "50%" }}>
                    <DataFormSelect
                        name={name}
                        value={value || ""}
                        onChange={handleSelectChange}
                        objectName={name}
                        itemFilter={itemFilter}
                        itemGetKey={itemGetKey}
                        itemGetLabel={itemGetLabel}
                        isRequired={isRequired}
                        doFetch={doFetch || false}
                        values={values}
                        doShowLabel={false}
                    />
                </Box>
            </Box>
        </Box>
    );
}
