import { useEffect, useRef } from "react";
import { useBeforeunload } from "react-beforeunload";
import { useBlocker } from "react-router-dom";

// https://claritydev.net/blog/display-warning-for-unsaved-form-data-on-page-exit

export function useBlockerPrompt(hasUnsavedChanges, tag) {
    const handleUnsavedChangesPrompt = () => {
        return !window.confirm("It looks like you have been editing something"
            // + (tag ? " [" + tag + "]" : "")
            + ".\n\nIf you leave now, your changes will be lost.");
    }

    const handleBeforeUnload = (event) => {
        if (hasUnsavedChanges) {
            if (handleUnsavedChangesPrompt()) {
                event.preventDefault();
                event.returnValue = "";
            }
        }
    }

    const blocker = useBlocker(hasUnsavedChanges ? handleUnsavedChangesPrompt : false);
    const prevState = useRef(blocker.state);
    useBeforeunload(handleBeforeUnload);

    useEffect(() => {
        if (blocker.state === "blocked") {
            blocker.reset();
        }
        prevState.current = blocker.state;
    }, [blocker]);
}
