// src/DraggableContainer.js
import React, { useState, useRef, useEffect } from "react";
import "./DraggableContainer.css"; // CSS for styling

const DraggableContainer = ({ children }) => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const offset = useRef({ x: 0, y: 0 });

    const handleMouseDown = (e) => {
        if (e.button === 0) {
            setIsDragging(true);
            offset.current = {
                x: e.clientX - position.x,
                y: e.clientY - position.y,
            };
            e.preventDefault();
        }
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            setPosition({
                x: e.clientX - offset.current.x,
                y: e.clientY - offset.current.y,
            });
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        if (isDragging) {
            window.addEventListener("mousemove", handleMouseMove);
            window.addEventListener("mouseup", handleMouseUp);
        }
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
        };
    }, [isDragging]);

    return (
        <div
            className="iframe-container"
            style={{
                left: position.x,
                top: position.y,
                position: "relative",
                zIndex: 1000,
            }}
            onMouseDown={handleMouseDown}
        >
            {children}
        </div>
    );
};

export default DraggableContainer;
