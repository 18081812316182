import GridViewOutlined from '@mui/icons-material/GridViewOutlined'
import TableRowsOutlined from '@mui/icons-material/TableRowsOutlined'
import { IconButton } from '@mui/material'
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from './helpers'

export default function WorkspaceViewSelector({
    workspaceMode,
    setWorkspaceMode
}) {

    return (
        <>
            {/* <ButtonGroup>
                <Tooltip title='View records as grid'>
                    <Button onClick={() => setWorkspaceMode(WORKSPACE_VIEW_CARD)}
                        variant={workspaceMode == WORKSPACE_VIEW_CARD ? "contained" : "outlined"}
                        color={workspaceMode == WORKSPACE_VIEW_CARD ? "info" : "success"}>
                        <GridViewOutlined />
                    </Button>
                </Tooltip>
                <Tooltip title='View records in table'>
                    <Button onClick={() => setWorkspaceMode(WORKSPACE_VIEW_TABLE)}
                        variant={workspaceMode == WORKSPACE_VIEW_TABLE ? "contained" : "outlined"}
                        color={workspaceMode == WORKSPACE_VIEW_TABLE ? "info" : "success"}>
                        <TableRowsOutlined />
                    </Button>
                </Tooltip>
            </ButtonGroup> */}

            {workspaceMode == WORKSPACE_VIEW_CARD && <>
                <IconButton onClick={() => setWorkspaceMode(WORKSPACE_VIEW_TABLE)}
                    className='hiddenButton'
                    variant='text' color='primary'>
                    <TableRowsOutlined />
                </IconButton>
            </>}

            {workspaceMode == WORKSPACE_VIEW_TABLE && <>
                <IconButton onClick={() => setWorkspaceMode(WORKSPACE_VIEW_CARD)}
                    className='hiddenButton'
                    variant='text' color='primary'>
                    <GridViewOutlined />
                </IconButton>
            </>}
        </>
    )
}
