export const OBJECT_NAME_EQUIPMENT_INVENTORY = "equipment_inventory";

// ---

export const CONTAINER_NAME_EQUIPMENT_INVENTORY_HEADER = "header";

// ---

export const ITEM_NAME_EQUIPMENT_INVENTORY_VEHICLES = "vehicles";
export const ITEM_NAME_EQUIPMENT_INVENTORY_CROP = "crop";
export const ITEM_NAME_EQUIPMENT_INVENTORY_LIVESTOCK = "livestock";
export const ITEM_NAME_EQUIPMENT_INVENTORY_GENERAL = "general";

// --- --- ---

// export const DOCUMENT_DICTIONARY_DEFAULT_VALUES_EQUIPMENT_INVENTORY = [
// ]
