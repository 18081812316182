import { getCurrentReinsuranceYear } from '@datanac/datanac-api-toolkit/dist/utility/InsurancePlanHelper';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, Stack, Typography } from "@mui/material";
import { _sessionManager, UsersApiHelper } from 'api/ApiHelper';
import { AppContext } from "App";
import DataFormSelect from 'components/Core/DataFormSelect';
import { StatefulControlledTextField } from 'components/Core/StatefulControlledTextField';
import { useFormStatus } from "mui-toolkit/useFormStatus";
import { useSnackbar } from 'notistack';
import { Fragment, useContext, useEffect, useState } from "react";
import { ApiHelper } from '@datanac/datanac-api-toolkit/dist/api-helper';
import BuyersGrid from './BuyersGrid';
import CropProfileGrid from './CropProfileGrid';

// ---

export const ClientDialog = ({
    onChange,
    onClose,
    member,
}) => {
    const { globalState } = useContext(AppContext);
    const { component: formStatusComponent, actions: formStatusActions, state: formStatusState } = useFormStatus();

    const [state, setState] = useState({});
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState(false);

    // ---

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    const onSubmit = (e) => {
        onChange && onChange(state);
        onClose && onClose();
    }

    const [dataDictionary, setDataDictionary] = useState([]);
    useEffect(() => {
        setState(member);

        const _loadDataDictionary = async () => {
            try {
                if (member?.producer_token) {
                    const _filter = { producer_token: member?.producer_token };

                    const api = new ApiHelper({
                        baseUrl: process.env.REACT_APP_DATANAC_USERS_API_URL_BASE,
                        config: {},
                        sessionManager: _sessionManager,
                        // No caching:
                        _instance: null
                    });
                    const _dataDictionary = await api.callPost("DataDictionary/Search", _filter, globalState);

                    setDataDictionary(_dataDictionary);
                }
            } catch (err) {
                handleError(err);
            }
        }
        _loadDataDictionary();
    }, [member]);

    // ---

    const handleChangeState = async (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        if (state[event.target.name] != value) {
            formStatusActions.setIsFormDirty(true, false);

            const _newState = { ...state };
            _newState[event.target.name] = value;
            setState(_newState);
        }
    }

    const formatProducerName = () => {
        return (state?.business_name || state?.first_name) ? (state?.business_name ? state?.business_name : (state?.first_name + ' ' + state?.last_name)) : '';
    }

    // ---


    return (<>
        <DialogTitle>
            {formatProducerName(state)}
        </DialogTitle>

        <DialogContent>
            <Box sx={{ width: '100%' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="First Name"
                                name="first_name"
                                isRequired={true}
                                defaultValue={state?.first_name || ''}
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Last Name"
                                name="last_name"
                                isRequired={true}
                                defaultValue={state?.last_name || ''}
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Business Name"
                                name="business_name"
                                isRequired={true}
                                defaultValue={state?.business_name || ''}
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="City"
                                name="city"
                                isRequired={true}
                                defaultValue={state?.city || ''}
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} className="clientState">
                        <DataFormSelect
                            value={state?.state_abbreviation}
                            name="state_abbreviation"
                            onChange={handleChangeState}
                            objectName="State"
                            itemFilter={{ reinsurance_year: getCurrentReinsuranceYear() }}
                            itemGetKey={item => item.state_abbreviation}
                            itemGetLabel={item => item.state_abbreviation}
                            doFetch={true}
                            isLoading={isLoading}
                            isSmall={true}
                            multiple={false}
                        />
                    </Grid>
                </Grid>

                <Divider />

                {Boolean(member?.producer_token) && <>
                    <Typography variant='h6' sx={{ mb: 2 }}>Accounts</Typography>
                    <Grid container spacing={2}>
                        {(dataDictionary || [])
                            ?.map((data, index) => <Fragment key={index}>
                                {data.object == 'office_code' &&
                                    <Grid item xs={12} md={6} key={index}>
                                        <FormControl fullWidth={true}>
                                            <StatefulControlledTextField
                                                label={"Office Code (" + data?.container + ")"}
                                                name="office_code"
                                                isRequired={true}
                                                defaultValue={data?.value || ''}
                                                onChange={handleChangeState}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </FormControl>
                                    </Grid>
                                }

                                {data?.object == 'account_number' &&
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth={true}>
                                            <StatefulControlledTextField
                                                label={"Account Number (" + data?.container + ")"}
                                                name="account_number"
                                                isRequired={true}
                                                defaultValue={data?.value || ''}
                                                onChange={handleChangeState}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </FormControl>
                                    </Grid>
                                }
                            </Fragment>
                            )}
                    </Grid>

                    <Divider />
                </>}

                {Boolean(member?.producer_token) && <>
                    <Typography variant='h6' sx={{ mb: 1 }}>Crop Profiles</Typography>
                    <CropProfileGrid producer_token={member?.producer_token} />

                    <Divider />

                    <Typography variant='h6' sx={{ mb: 1 }}>Buyers</Typography>
                    <BuyersGrid producer_token={member?.producer_token} />
                </>}
            </Box>
        </DialogContent>

        <DialogActions>
            <Stack direction="row-reverse" spacing={1} useFlexGap flexWrap="wrap" justifyContent='flex-end'>
                <Button variant='contained' onClick={onSubmit}>OK</Button>
                <Button variant='contained' color='secondary' autoFocus onClick={onClose}>Cancel</Button>
            </Stack>
        </DialogActions>
    </>);
}