import { Skeleton } from "@mui/material"
import React from "react";

export const SkeletonWrapper = ({
    children,
    sx,
    isLoading,
    count
}) => {
    return isLoading ?
        Array(count || 1).fill("").map((item, index) =>
            React.Children.map(children, (child, childIndex) =>
                <Skeleton sx={sx} key={index + "/" + childIndex}>
                    {child}
                </Skeleton>
            ))
        : <>
            {children}
        </>
}