import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import CheckOutlined from '@mui/icons-material/CheckOutlined'
import { Button, Grid, Icon, Menu, MenuItem, Stack, useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import InsuranceOptimizeDataGrid from 'components/InsuranceOptimizeDataGrid'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { useState } from 'react'
import { formatPercentage, formatPrice } from 'utility/formatters'
import { STRESS_TEST_FIELD_INDEMNITY_TOTAL_PER_ACRE, STRESS_TEST_FIELD_NET_INSURANCE_BENEFIT_PER_ACRE, STRESS_TEST_FIELD_NET_PROFIT_INSURED, STRESS_TEST_FIELD_NET_PROFIT_SIMPLE, calculateStressTestDisplayFieldLabel } from '../helpers'

export default function InsuranceOptimizeDisplayGroup({
    farmParameters,
    insurancePlans,

    stressTestResults,
    stressTestParameters,
    ceclResults,

    historicalStressTestResults,

    isSplitScreen,

    headerButtonContent,
}) {

    const isXXL = useMediaQuery('(min-width:1530px)');
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const isXsSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'), { noSsr: true });

    // --- --- ---

    const [stressTestDisplayField, setStressTestDisplayField] = useState(STRESS_TEST_FIELD_NET_PROFIT_INSURED);
    const [isStressTestOptionsDialogOpen, setIsStressTestOptionsDialogOpen] = useState(false);

    const handleStressTestMenuClick = (_stressTestDisplayField, popupState) => {
        setStressTestDisplayField(_stressTestDisplayField);
        popupState?.close && popupState.close();
    }

    // --- --- ---

    const calculateBreakeven = (_loanAmount) => {
        return (_loanAmount / farmParameters?.reported_acreage) / farmParameters?.yield;
    }

    // --- --- ---

    return (
        <>
            <Divider />

            <Stack direction={isXXL ? "row" : "column"}>
                <div>
                    {Boolean(stressTestResults?.results?.length) && <>
                        <Box sx={{ width: "100%", backgroundColor: 'white' }}>
                            <Typography variant='h1' sx={{ whiteSpace: "nowrap", mt: isXXL ? 1.3 : 0, pb: isXXL ? 4 : null }}>
                                Credit Summary
                            </Typography>

                            <Grid container direction={isXXL ? "column" : "row"} justifyItems='flex-start' spacing={1}>
                                <Grid item xs={12} sm={3} lg={3} xl={3}>
                                    <Stack direction={isXsSmall ? 'row-reverse' : (isXXL ? "column-reverse" : 'column')} justifyContent='space-between'>
                                        <Typography variant='h5'>
                                            {formatPrice(stressTestResults?.cecl?.loan_amount_insured / farmParameters?.reported_acreage, 0)}
                                        </Typography>
                                        <Typography variant='body1' sx={{ textTransform: 'uppercase' }}>Total Costs /ac</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={3} lg={3} xl={3}>
                                    <Stack direction={isXsSmall ? 'row-reverse' : (isXXL ? "column-reverse" : 'column')} justifyContent='space-between'>
                                        <Typography variant='h5'>
                                            {formatPrice(calculateBreakeven(stressTestResults?.cecl?.loan_amount_insured), 2)}
                                        </Typography>
                                        <Typography variant='body1' sx={{ textTransform: 'uppercase' }}>Breakeven</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={3} lg={3} xl={3}>
                                    <Stack direction={isXsSmall ? 'row-reverse' : (isXXL ? "column-reverse" : 'column')} justifyContent='space-between'>
                                        <Typography variant='h5'>
                                            {formatPrice(stressTestResults?.cecl?.expected_credit_loss_insured / farmParameters?.reported_acreage, 0)}
                                        </Typography>
                                        <Typography variant='body1' sx={{ textTransform: 'uppercase' }}>Expected Loss /ac</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={3} lg={3} xl={3}>
                                    <Stack direction={isXsSmall ? 'row-reverse' : (isXXL ? "column-reverse" : 'column')} justifyContent='space-between'>
                                        <Typography variant='h5'>
                                            {formatPercentage(stressTestResults?.cecl?.cecl_insured, 2)}
                                        </Typography>
                                        <Typography variant='body1' sx={{ textTransform: 'uppercase' }}>% ECL</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                    </>}
                </div>

                <Divider orientation={isXXL ? "vertical" : "horizontal"} flexItem />

                <Box sx={{ flexGrow: "1" }}>
                    <Stack direction="row" spacing={1} alignItems='center' justifyContent={isSplitScreen ? 'space-between' : ''} className='headerWithMenuButton'>
                        <Typography variant='h1'>
                            Stress Test
                        </Typography>
                        <PopupState variant="popover" popupId="stress-test-popup-menu">
                            {(popupState) => (
                                <>
                                    <Button variant="outlined" {...bindTrigger(popupState)}>
                                        {calculateStressTestDisplayFieldLabel(stressTestDisplayField)} <ArrowDropDownOutlined />
                                    </Button>
                                    <Menu {...bindMenu(popupState)}>
                                        <MenuItem onClick={() => handleStressTestMenuClick(STRESS_TEST_FIELD_NET_PROFIT_SIMPLE, popupState)}>
                                            {stressTestDisplayField == STRESS_TEST_FIELD_NET_PROFIT_SIMPLE ?
                                                <CheckOutlined sx={{ mr: 1 }} />
                                                : <Icon sx={{ mr: 1 }} />}
                                            Net Profit Before Insurance
                                        </MenuItem>
                                        <MenuItem onClick={() => handleStressTestMenuClick(STRESS_TEST_FIELD_NET_PROFIT_INSURED, popupState)}>
                                            {stressTestDisplayField == STRESS_TEST_FIELD_NET_PROFIT_INSURED ?
                                                <CheckOutlined sx={{ mr: 1 }} />
                                                : <Icon sx={{ mr: 1 }} />}
                                            Insured Net Profit
                                        </MenuItem>

                                        <Divider />

                                        <MenuItem onClick={() => handleStressTestMenuClick(STRESS_TEST_FIELD_INDEMNITY_TOTAL_PER_ACRE, popupState)}>
                                            {stressTestDisplayField == STRESS_TEST_FIELD_INDEMNITY_TOTAL_PER_ACRE ?
                                                <CheckOutlined sx={{ mr: 1 }} />
                                                : <Icon sx={{ mr: 1 }} />}
                                            Insurance Benefit
                                        </MenuItem>

                                        <MenuItem onClick={() => handleStressTestMenuClick(STRESS_TEST_FIELD_NET_INSURANCE_BENEFIT_PER_ACRE, popupState)}>
                                            {stressTestDisplayField == STRESS_TEST_FIELD_NET_INSURANCE_BENEFIT_PER_ACRE ?
                                                <CheckOutlined sx={{ mr: 1 }} />
                                                : <Icon sx={{ mr: 1 }} />}
                                            Net Insurance Benefit
                                        </MenuItem>
                                    </Menu>
                                </>
                            )}
                        </PopupState>

                        {(isSplitScreen && headerButtonContent) || <></>}
                    </Stack>

                    <Stack direction="row" spacing={0} justifyContent="space-between" alignContent="flex-end">
                        <Box sx={{ flexGrow: "1", maxWidth: "1050px" }}>
                            <InsuranceOptimizeDataGrid
                                displayFieldName={stressTestDisplayField}
                                yAxisFieldName={"price"}
                                yAxisDisplayName={"Price"}
                                stressTestResults={stressTestResults?.results}
                                doMarkNegativeValues={true}
                                maxLossValue={-1 * farmParameters?.production_cost_per_acre}
                            />
                        </Box>

                        {(!isSplitScreen && headerButtonContent) || <></>}
                    </Stack>
                </Box>
            </Stack>
        </>
    )
}
