import { ApiHelper, USER_ROLE_ADMIN } from '@datanac/datanac-api-toolkit/dist/api-helper';
import { useDialog } from '@datanac/datanac-mui-toolkit/dist/hooks';
import { AddCircleOutline, Approval, ArrowDropDownOutlined, Delete, Refresh } from '@mui/icons-material';
import { Autocomplete, Box, Button, CircularProgress, Dialog, Divider, FormControl, IconButton, Menu, MenuItem, Paper, Stack, TextField, Tooltip, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { _sessionManager, UsersApiHelper } from 'api/ApiHelper';
import { AppContext } from 'App';
import EmptyCard from 'components/Cards/EmptyCard';
import { propUpdateReducer } from 'components/Core/propUpdateReducer';
import { FarmVaultIcon } from 'components/Icons/Icons';
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader';
import _ from 'lodash';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { formatDateLong, formatNumber, formatProducerName } from 'utility/formatters';
import { CropProfileEditDialog } from './CropProfileEditDialog';
import { v4 } from 'uuid';


export default function TransactionImportWorkspace() {
    const { globalState } = useContext(AppContext);
    const { enqueueSnackbar } = useSnackbar();

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'), { noSsr: true });
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();

    // ---

    const [brokers, setBrokers] = useState(null);
    const [isLoadingBrokers, setIsLoadingBrokers] = useState(false);

    const [editingCropProfile, setEditingCropProfile] = useState(null);

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    const loadBrokers = async () => {
        try {
            if (globalState?.agent_token) {
                setIsLoadingBrokers(true);

                const _brokersFilter = {
                    agent_token: globalState?.agent_token,
                };
                // This is agent function. Do not specify producer_token or you will get permissions error.
                const _apiConfig = {
                    agent_token: globalState?.agent_token,
                };
                const _brokers = await UsersApiHelper.users_search("AgencyAgent", _brokersFilter, _apiConfig);
                setBrokers(_brokers);
                setIsLoadingBrokers(false);
            } else if (globalState?.user_role == USER_ROLE_ADMIN) {
                setIsLoadingBrokers(true);

                const _brokersFilter = {};
                // This is ADMIN function. Do not specify producer_token or agent_token or you will get permissions error.
                const _apiConfig = {};
                const _brokers = await UsersApiHelper.users_search("AgencyAgent", _brokersFilter, _apiConfig);
                setBrokers(_brokers);
                setIsLoadingBrokers(false);
            }
        } catch (err) {
            setIsLoadingBrokers(false);
            setBrokers(null);
            handleError(err);
        }
    }

    useEffect(() => {
        if (globalState?.agent_token || globalState?.user_role == USER_ROLE_ADMIN) {
            setIsLoadingBrokers(true);
            loadBrokers();
        }
    }, [globalState?.agent_token, globalState?.user_role]);

    // ---

    const [cropProfiles, setCropProfiles] = useState(null);
    const [isLoadingCropProfiles, setIsLoadingCropProfiles] = useState(false);

    const buildCropProfilesDictionaryByProducer = (_cropProfiles) => {
        const _dictionary = {};
        // Populate: _dictionary[producer_token] should contain all bcp {year, commodity_name, crop_profile}
        _cropProfiles.forEach(cp => {
            if (!_dictionary[cp.producer_token]) {
                _dictionary[cp.producer_token] = [];
            }

            const _entry = {
                year: cp.year,
                commodity_name: cp.commodity_name,
                crop_profile: cp.year + " - " + cp.commodity_name,
            };

            _dictionary[cp.producer_token].push(_entry);
        });
        return _dictionary;
    }

    // Memoize crop_profile from cropProfiles
    const cropProfilesDictionary = useMemo(() =>
        (cropProfiles?.length &&
            buildCropProfilesDictionaryByProducer(cropProfiles)
        ) || null,
        [cropProfiles]);

    const getCropProfileLabelsByProducerToken = useCallback(
        (producer_token, commodity_name) => {
            const _producerCropProfiles = cropProfilesDictionary?.[producer_token] || [];
            if (_producerCropProfiles?.length) {
                return _producerCropProfiles
                    ?.filter(cp => cp?.commodity_name === commodity_name) // Strict equality check
                    ?.map(cp => cp?.crop_profile) || [];
            } else {
                return [];
            }
        },
        [cropProfilesDictionary]);

    const loadCropProfiles = async () => {
        try {
            if (globalState?.agent_token) {
                setIsLoadingCropProfiles(true);

                const _filter = {
                    agent_token: globalState?.agent_token,
                    is_posted: filter?.filter_is_posted || false,
                };
                if (filter?.filter_agent_token) {
                    _filter.filter_agent_token = filter?.filter_agent_token;
                }
                // This is agent function. Do not specify producer_token or you will get permissions error.
                const _apiConfig = {
                    agent_token: globalState?.agent_token,
                };
                const _cropProfiles = await UsersApiHelper.users_search("MarketTransactionHistoryCropProfile", _filter, _apiConfig);

                setCropProfiles(_cropProfiles);
                setIsLoadingCropProfiles(false);
            } else if (globalState?.user_role == USER_ROLE_ADMIN && filter?.filter_agent_token) {
                setIsLoadingCropProfiles(true);

                const _filter = {
                    agent_token: filter?.filter_agent_token || null,
                    filter_agent_token: filter?.filter_agent_token || null,
                    is_posted: filter?.filter_is_posted || false,
                };
                if (filter?.filter_agent_token) {
                    _filter.filter_agent_token = filter?.filter_agent_token;
                }
                // This is ADMIN function. Do not specify producer_token or agent_token or you will get permissions error.
                const _apiConfig = {};
                const _cropProfiles = await UsersApiHelper.users_search("MarketTransactionHistoryCropProfile", _filter, _apiConfig);

                setCropProfiles(_cropProfiles);
                setIsLoadingCropProfiles(false);
            }
        } catch (err) {
            setIsLoadingCropProfiles(false);
            setCropProfiles(null);
            handleError(err);
        }
    }

    useEffect(() => {
        if (globalState?.agent_token ||
            (globalState?.user_role == USER_ROLE_ADMIN && filter?.filter_agent_token)
        ) {
            setIsLoadingCropProfiles(true);
            loadCropProfiles();
        }
    }, [globalState?.agent_token, globalState?.user_role]);

    // ---

    const [transactionHistory, setTransactionHistory] = useState([]);
    const [isLoadingTransactionHistory, setIsLoadingTransactionHistory] = useState(false);

    const transactionHistoryTradeDates = useMemo(() =>
        (transactionHistory?.length &&
            // Sort by date asc
            _.uniq(transactionHistory?.map(hist => hist?.trade_date))
                .sort((a, b) => new Date(a) - new Date(b))
        ) || null,
        [transactionHistory]);

    const loadTransactionHistory = async () => {
        try {
            if (globalState?.agent_token) {
                setIsLoadingTransactionHistory(true);

                const _transactionHistoryFilter = {
                    agent_token: globalState?.agent_token,
                    is_posted: filter?.filter_is_posted || false,
                };
                if (filter?.filter_agent_token) {
                    _transactionHistoryFilter.filter_agent_token = filter?.filter_agent_token;
                }
                // This is agent function. Do not specify producer_token or you will get permissions error.
                const _apiConfig = {
                    agent_token: globalState?.agent_token,
                };
                const transactionHistory = await UsersApiHelper.selectMarketTransactionHistory(_transactionHistoryFilter, _apiConfig);
                // Assign crop_profile = getDefaultCropProfile(params?.row)
                transactionHistory.forEach(hist => hist.crop_profile = hist.crop_profile || getDefaultCropProfile(hist));

                // Sort by trade date ASC:
                const _sortedTransactionHistory = transactionHistory.sort((a, b) => new Date(a.trade_date) - new Date(b.trade_date));

                setTransactionHistory(_sortedTransactionHistory);
                setIsLoadingTransactionHistory(false);
            } else if (globalState?.user_role == USER_ROLE_ADMIN && filter?.filter_agent_token) {
                setIsLoadingTransactionHistory(true);

                const _transactionHistoryFilter = {
                    agent_token: filter?.filter_agent_token || null,
                    filter_agent_token: filter?.filter_agent_token || null,
                    is_posted: filter?.filter_is_posted || false,
                };
                // This is ADMIN function. Do not specify producer_token or agent_token or you will get permissions error.
                const _apiConfig = {};
                const transactionHistory = await UsersApiHelper.selectMarketTransactionHistory(_transactionHistoryFilter, _apiConfig);
                // Assign crop_profile = getDefaultCropProfile(params?.row)
                transactionHistory.forEach(hist => hist.crop_profile = hist.crop_profile || getDefaultCropProfile(hist));

                // Sort by trade date ASC:
                const _sortedTransactionHistory = transactionHistory.sort((a, b) => new Date(a.trade_date) - new Date(b.trade_date));

                setTransactionHistory(_sortedTransactionHistory);
                setIsLoadingTransactionHistory(false);
            }
        } catch (err) {
            setIsLoadingTransactionHistory(false);
            setTransactionHistory(null);
            handleError(err);
        }
    }

    const handleDispatchRowChange = useCallback(async (id, key, value) => {
        const _transactionHistory = [...transactionHistory];
        const _index = _transactionHistory.findIndex(hist => hist.id == id);
        if (_index > -1) {
            _transactionHistory[_index][key] = value;
            setTransactionHistory(_transactionHistory);
        }
    }, [transactionHistory, setTransactionHistory]);

    // ---

    const [filter, dispatchFilter] = useReducer(propUpdateReducer, {
        filter_agent_token: globalState?.agent_token || null,
        filter_is_posted: false,
    });
    const selectedBroker = useMemo(() =>
        brokers?.find(s =>
            s?.agent_token?.toLowerCase() == (filter?.filter_agent_token || globalState?.agent_token)?.toLowerCase()
        ) || null,
        [brokers, filter?.filter_agent_token, globalState?.agent_token]);

    const filterHandleChangeState = (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        dispatchFilter({
            type: 'update',
            payload: { key: event.target.name, value: value },
        });
    }

    const handleRowApprove = useCallback(async (row) => {
        if (row?.crop_profile) {
            // Lookup crop profile from cropProfiles
            const _cropProfile = cropProfilesDictionary?.[row?.producer_token]?.find(cp => cp?.crop_profile == row?.crop_profile) || null;

            let _cropProfileYear;
            let _cropProfileCommodityName;

            // If not configured, create one:
            if (_cropProfile) {
                _cropProfileYear = _cropProfile?.year;
                _cropProfileCommodityName = _cropProfile?.commodity_name;

                if (_cropProfileYear && _cropProfileCommodityName) {
                    const _row = { ...row };
                    _row.crop_profile_year = _cropProfileYear;
                    _row.crop_profile_commodity_name = _cropProfileCommodityName;
                    _row.is_posted = true;

                    const api = new ApiHelper({
                        baseUrl: process.env.REACT_APP_DATANAC_USERS_API_URL_BASE,
                        config: {},
                        sessionManager: _sessionManager,
                        // No caching:
                        _instance: null
                    });
                    await api.callPost("broker/MarketTransactionHistory", _row, globalState);

                    handleDispatchRowChange(row?.id, 'is_posted', true);
                }
            } else {
                // // Parse
                // const _cropProfileParts = row?.crop_profile.split(" - ");
                // if (_cropProfileParts.length == 2) {
                //     _cropProfileYear = parseInt(_cropProfileParts[0]);
                //     _cropProfileCommodityName = _cropProfileParts[1];
                // }
                enqueueSnackbar("Crop profile not found. Please create a new one.", { preventDuplicate: true, variant: 'error' });
                return;
            }
        }
    }, [cropProfilesDictionary, handleDispatchRowChange]);

    const handleRowDelete = useCallback(async (row) => {
        confirmDialogActions.confirm({
            title: "Delete this entry?",
            prompt: "Are you sure you want to delete this entry?",
            promptDetail: null,
            callback: () => {
                _handleRowDeleteIntern(row);
            }
        })
    }, [confirmDialogActions]);

    const _handleRowDeleteIntern = useCallback(async (row) => {
        const _row = { ...row };
        _row.crop_profile = null;
        _row.crop_profile_year = null;
        _row.crop_profile_commodity_name = null;
        _row.is_posted = false;

        const api = new ApiHelper({
            baseUrl: process.env.REACT_APP_DATANAC_USERS_API_URL_BASE,
            config: {},
            sessionManager: _sessionManager,
            // No caching:
            _instance: null
        });
        await api.callPost("broker/MarketTransactionHistory", _row, globalState);

        handleDispatchRowChange(row?.id, 'crop_profile', null);
        handleDispatchRowChange(row?.id, 'is_posted', false);
    }, [handleDispatchRowChange]);

    useEffect(() => {
        if (globalState?.agent_token ||
            (globalState?.user_role == USER_ROLE_ADMIN && filter?.filter_agent_token)
        ) {
            setIsLoadingTransactionHistory(true);
            loadTransactionHistory();
        }
    }, [globalState?.agent_token, globalState?.user_role, filter]);

    useEffect(() => {
        if (globalState?.agent_token ||
            (globalState?.user_role == USER_ROLE_ADMIN && filter?.filter_agent_token)
        ) {
            setIsLoadingCropProfiles(true);
            loadCropProfiles();
        }
    }, [globalState?.agent_token, globalState?.user_role, filter]);

    // ---

    const getDefaultCropProfile = (row) => {
        if (row?.is_posted == null) {
            if (row?.crop_profile_year && row?.crop_profile_commodity_name) {
                return row?.crop_profile_year + " - " + row?.crop_profile_commodity_name;
                // } 
                // else if (row?.symbol_year && row?.commodity_name) {
                //     let _cropProfileYear = row?.symbol_year;
                //     if (row?.symbol_month && row?.commodity_reference_month && (row?.symbol_month < row?.commodity_reference_month)) {
                //         _cropProfileYear--;
                //     }
                //     const _cropProfile = _cropProfileYear + " - " + row?.commodity_name;
                //     return _cropProfile;
            } else {
                return null;
            }
        } else {
            return row?.crop_profile || null;
        }
    }

    const getCropProfileFromTransactionHistory = (row) => {
        const _cropProfile = {
            id: v4(),
            producer_token: row?.producer_token,
            is_active: true,
        };
        if (row?.symbol_year && row?.commodity_name) {
            let _cropProfileYear = row?.symbol_year;
            if (row?.commodity_reference_month && row?.symbol_month < row?.commodity_reference_month) {
                _cropProfileYear--;
            }
            _cropProfile.year = _cropProfileYear;
            _cropProfile.commodity_name = row?.commodity_name;
        }

        return _cropProfile;
    }

    // ---

    const columns = useMemo(() => [
        {
            field: 'trade_date',
            headerName: 'Trade Date',
            minWidth: 200,
            valueFormatter: (params) => {
                return formatDateLong(params.value);
            },
        },
        {
            field: 'account_name',
            headerName: 'Client',
            minWidth: 200,
        },
        {
            field: 'producer_last_name',
            headerName: 'Producer',
            minWidth: 200,
            flex: 1,
            valueGetter: (params) => {
                return formatProducerName(params.row);
            },
        },
        {
            field: 'bloomberg_root',
            headerName: 'Commodity',
            minWidth: 100,
            valueGetter: (params) => {
                return params?.row?.commodity_name || params?.row?.bloomberg_root || "";
            },
        },
        {
            field: 'trade_price',
            headerName: 'Price',
            minWidth: 100,
        },
        {
            field: 'buy_sell_code',
            headerName: 'Position',
            minWidth: 100,
            valueGetter: (params) => {
                if (params.row.buy_sell_code == 1) {
                    return 'Buy';
                } else if (params.row.buy_sell_code == 2) {
                    return 'Sell';
                }
            },
        },
        {
            field: 'quantity',
            headerName: '# Contracts',
            minWidth: 100,
        },
        {
            field: 'multiplication_factor',
            headerName: '# Units',
            minWidth: 100,
            valueGetter: (params) => {
                return (params?.row?.quantity * params?.row?.multiplication_factor) || 0;
            },
            valueFormatter: (params) => {
                return formatNumber(params.value, 0);
            }
        },
        {
            field: 'tracer_number',
            headerName: 'Tracer',
            minWidth: 200,
        },
        {
            field: 'security_desc_line_1',
            headerName: 'Description',
            minWidth: 200,
        },
        {
            field: 'crop_profile',
            headerName: 'Crop Profile',
            renderCell: (params) => (
                <Box sx={{ mb: 1, width: "100%" }}>
                    <FormControl fullWidth>
                        <Autocomplete
                            name="crop_profile"
                            options={getCropProfileLabelsByProducerToken(params?.row?.producer_token, params?.row?.commodity_name) || []}
                            multiple={false}
                            value={params?.row?.crop_profile || null}
                            disabled={params?.row?.is_posted != null}
                            onChange={(event, value) => {
                                handleDispatchRowChange(params.row.id, 'crop_profile', value);
                            }}
                            autoSelect
                            autoHighlight
                            renderInput={(params) => (
                                <TextField
                                    name="crop_profile"
                                    {...params}
                                    required
                                    onChange={null}
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                            renderOption={(props, option) => {
                                const { key, ...optionProps } = props;
                                return (
                                    <li key={key} {...optionProps}>
                                        <Box>{option}</Box>
                                    </li>
                                );
                            }}
                            PaperComponent={({ children }) => {
                                return (
                                    <Paper>
                                        {children}
                                        <Button
                                            color="primary"
                                            variant="filled"
                                            fullWidth
                                            sx={{ justifyContent: "flex-start", pl: 2 }}
                                            onMouseDown={() => {
                                                setEditingCropProfile(getCropProfileFromTransactionHistory(params.row));
                                            }}
                                        >
                                            + Add New
                                        </Button>
                                    </Paper>
                                );
                            }}
                        />
                    </FormControl>
                </Box>
            ),
            minWidth: 200,
        },
        {
            field: 'id',
            headerName: '',
            flex: 1,
            renderCell: (params) => (
                <Stack direction="row" display="flex" spacing={1} columnGap={2}>
                    <Tooltip title="Approve" placement="top" arrow>
                        <span>
                            <IconButton
                                size="large"
                                disabled={params.row?.crop_profile == null || params?.row?.is_posted != null}
                                onClick={() => handleRowApprove(params?.row)}>
                                <Approval />
                            </IconButton>
                        </span>
                    </Tooltip>

                    <Divider orientation="vertical" flexItem />

                    <Tooltip title="Discard" placement="top" arrow>
                        <span>
                            <IconButton
                                size="large"
                                disabled={params?.row?.is_posted != null}
                                onClick={() => handleRowDelete(params.row)}>
                                <Delete />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Stack>
            ),
            minWidth: 200,
        }
    ], [handleDispatchRowChange, getCropProfileLabelsByProducerToken, handleRowApprove, handleRowDelete]);

    const getFilteredRowsByTradeDate = useCallback(
        (tradeDate) => transactionHistory?.filter(hist => hist?.trade_date === tradeDate) || [],
        [transactionHistory]
    );

    const handleChange_CropProfile = async (_newCropProfile) => {
        const api = new ApiHelper({
            baseUrl: process.env.REACT_APP_DATANAC_USERS_API_URL_BASE,
            config: {},
            sessionManager: _sessionManager,
            // No caching:
            _instance: null
        });
        await api.callPost("marketing/CropProfile", _newCropProfile, globalState);
    }


    // --- --- ---

    return (
        <>
            <WorkspaceHeader
                title='Transactions'
                breadCrumbs={[{
                    title: 'Broker',
                    icon: <FarmVaultIcon />
                }]}
            />
            <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton'>
                <FormControl fullWidth>
                    <Autocomplete
                        name="filter_agent_token"
                        options={brokers || []}
                        multiple={false}
                        value={selectedBroker || null}
                        getOptionLabel={(option) => option?.last_name + ', ' + option?.first_name || ""}
                        disabled={false}
                        onChange={(event, value) => {
                            filterHandleChangeState({ target: { name: 'filter_agent_token', value: value?.agent_token } });
                        }}
                        autoSelect={false}
                        autoHighlight={false}
                        renderInput={(params) => (
                            <TextField
                                name="filter_agent_token"
                                {...params}
                                label="Broker"
                                required
                                // onChange={filterHandleChangeState}
                                InputLabelProps={{ shrink: true }}
                            />
                        )}
                        renderOption={(props, option) => {
                            const { key, ...optionProps } = props;
                            return (
                                <li {...props}>
                                    <Stack direction="row" spacing={1} key={key} sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                        <Box>{option?.last_name + ', ' + option?.first_name}</Box>
                                    </Stack>
                                </li>
                            );
                        }}
                    />
                </FormControl>

                <Stack direction="row" columnGap={2} sx={{ pt: 1.5 }}>
                    <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                            <>
                                <Button variant="outlined" {...bindTrigger(popupState)}>
                                    {filter?.filter_is_posted ? "Posted" : "Unposted"} <ArrowDropDownOutlined />
                                </Button>
                                <Menu {...bindMenu(popupState)}>
                                    <MenuItem onClick={() => {
                                        filterHandleChangeState({ target: { name: 'filter_is_posted', value: false } });
                                        popupState.close();
                                    }}>
                                        Unposted
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        filterHandleChangeState({ target: { name: 'filter_is_posted', value: true } });
                                        popupState.close();
                                    }}>
                                        Posted
                                    </MenuItem>
                                </Menu>
                            </>
                        )}
                    </PopupState>

                    <IconButton className='hiddenButton' size="large"
                        onClick={() => {
                            loadTransactionHistory();
                            loadCropProfiles();
                        }}
                    >
                        <Refresh size='large' />
                    </IconButton>
                </Stack>

                {(isLoadingBrokers || isLoadingCropProfiles || isLoadingTransactionHistory) && <CircularProgress />}
            </Stack>

            {/* {Boolean(transactionHistoryTradeDates?.length) &&
                transactionHistoryTradeDates
                    .slice(0, 3)
                    .map((currentTradeDate, index) => <Box key={currentTradeDate + index}>
                        <Typography variant="h5">
                            {formatDateLong(currentTradeDate)}
                        </Typography>

                        <DataGrid
                            rows={getFilteredRowsByTradeDate(currentTradeDate) || []}
                            columns={columns}
                            initialState={{
                                pagination: { paginationModel: { pageSize: isMobile ? 10 : 100 } },
                            }}
                            pageSizeOptions={[5, 10, 25, 100]}
                            hideFooter={true}
                            autoHeight
                            getRowHeight={() => 64}
                        />

                        <Divider />
                    </Box>)
            } */}


            {Boolean(transactionHistoryTradeDates?.length) &&
                <DataGrid
                    rows={transactionHistory || []}
                    columns={columns}
                    initialState={{
                        pagination: { paginationModel: { pageSize: isMobile ? 10 : 100 } },
                    }}
                    pageSizeOptions={[5, 10, 25, 100]}
                    hideFooter={true}
                    autoHeight
                    getRowHeight={() => 64}
                />
            }

            <Box className='cardContainer'>
                {!Boolean(transactionHistoryTradeDates?.length) && filter?.filter_is_posted &&
                    <EmptyCard
                        text="No transactions found"
                        secondaryText="No posted transactions were found."
                        isLoading={isLoadingTransactionHistory}
                    />
                }

                {!Boolean(transactionHistoryTradeDates?.length) && !filter?.filter_is_posted &&
                    <EmptyCard
                        text="No transactions found"
                        secondaryText="No pending transactions were found."
                        isLoading={isLoadingTransactionHistory}
                    />
                }
            </Box>

            <Dialog open={Boolean(editingCropProfile)}>
                <CropProfileEditDialog cropProfile={editingCropProfile} onClose={() => setEditingCropProfile(null)} onChange={handleChange_CropProfile} />
            </Dialog>

            {confirmDialogComponent}
        </>
    );
}