import { USER_ROLE_AGENT } from '@datanac/datanac-api-toolkit/dist/api-helper'
import EastOutlined from '@mui/icons-material/EastOutlined'
import SearchOutlined from '@mui/icons-material/SearchOutlined'
import SendOutlined from '@mui/icons-material/SendOutlined'
import { Button, Checkbox, DialogActions, DialogContent, FormControlLabel, FormGroup, InputAdornment, List, Paper, Stack, Tooltip, useMediaQuery } from '@mui/material'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { StatefulControlledTextField } from 'components/Core/StatefulControlledTextField'
import { uniqBy } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../../../App'
import { AipPassApiHelper } from '../../../api/ApiHelper'
import { USER_ROLE_ADMIN } from '../../../core/useSession'
import UserGrantItem from './UserGrantItem'

export default function UserGrantInviteDialog({
    producer_token,

    onChange,
    onClose
}) {
    const { globalState, globalDispatch } = useContext(AppContext);

    const navigate = useNavigate();

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));

    // ---

    const EMPTY_ITEM = {
        is_master_read: true,
        is_master_write: true,
        is_master_delete: true,
        is_active: true,
    };
    const [userGrant, setUserGrant] = useState(EMPTY_ITEM);

    const handleCheckboxOnChange = (e) => {
        const _state = { ...userGrant };
        _state[e.target.name] = e.target.checked;
        setUserGrant(_state);
    }

    // --- --- ---

    const [searchText, setSearchText] = useState(null);
    const handleSearchOnChange = (event) => {
        setSearchText(event.target.value);
    }

    const [producers, setProducers] = useState([]);
    const searchProducers = async () => {
        const __authTokens = { ...globalState };
        if (globalState.user_role == USER_ROLE_ADMIN) {
            delete __authTokens.agent_token;
        }
        delete __authTokens.producer_token;

        let _producersLastName = AipPassApiHelper.searchProducers({ last_name: searchText }, __authTokens);
        let _producersBusinessName = AipPassApiHelper.searchProducers({ business_name: searchText }, __authTokens);

        const _producers = [...(await _producersLastName), ...(await _producersBusinessName)];
        const _uniqueProducers = uniqBy(_producers, p => p.producer_token);

        setProducers(_uniqueProducers);
    };

    useEffect(() => {
        if (searchText) {
            searchProducers();
        }
    }, [searchText, globalState.user_role, globalState.agent_token, globalState.producer_token])

    // --- --- ---

    const [activeAgent, setActiveAgent] = useState();

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }
    
    const loadActiveAgent = async () => {
        try {
            let _agent = await AipPassApiHelper.pass_selectObject("AgentTokens",
                { agent_token: globalState.agent_token },
                { agent_token: globalState.agent_token });
            if (_agent?.length) {
                setActiveAgent(_agent[0]);
            }
        } catch (err) {
            handleError(err);
        }
    };

    useEffect(() => {
        if (globalState?.user_role == USER_ROLE_AGENT) {
            loadActiveAgent();
        }
    }, [globalState?.agent_token, globalState?.user_role])

    // --- --- ---

    const isValid = () => {
        return (Boolean(
            selectedProducer?.producer_token
            || isValidEmail(searchText)
        ));
    }

    function isValidEmail(_text) {
        return Boolean(
            /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(_text)
        )
    }


    const [selectedProducer, setSelectedProducer] = useState(null);
    const handleProducerSearchRowOnClick = (currentProducer) => {
        setSelectedProducer(currentProducer);
    }

    // --- --- ---

    const handleInviteOnClick = () => {
        if (isValid()) {
            const _userGrant = { ...userGrant };
            if (selectedProducer) {
                _userGrant.producer_token = selectedProducer?.producer_token;
            } else {
                _userGrant.producer_email = searchText;
            }
            onChange && onChange(_userGrant);
        }
    }

    // --- --- ---

    return (
        <>
            <DialogContent>
                {!Boolean(producer_token > 0) && <>
                    <Typography variant='body1'>
                        Search Producers:
                    </Typography>
                    <StatefulControlledTextField
                        onChange={handleSearchOnChange}
                        defaultValue=''
                        fullWidth={isSmall}
                        sx={{ minWidth: '2in', maxWidth: '100%' }}
                        endAdornment={
                            <Tooltip title='Search'>
                                <InputAdornment position='end'>
                                    <IconButton edge="end" color="primary">
                                        <SearchOutlined />
                                    </IconButton>
                                </InputAdornment>
                            </Tooltip>
                        } />

                    <Divider sx={{ mt: 2, mb: 2 }} />
                </>}

                {Boolean(producers?.length) && <>
                    <Typography variant='h5'>
                        Search Results:
                    </Typography>
                    <List dense sx={{ p: 0 }}>
                        {producers.map(p => (
                            <UserGrantItem key={p.producer_token} producer={p}
                                isSelected={p.producer_token == selectedProducer?.producer_token}
                                onClick={handleProducerSearchRowOnClick} />
                        ))}
                    </List>
                    <Divider sx={{ mt: 1, mb: 2 }} />
                </>}

                <Typography variant='h1'>
                    {Boolean(globalState?.agent_token) ? "Request Permissions" : "Master Permissions"}
                </Typography>
                <Stack direction='row' alignItems='center' spacing={1}>
                    <Typography variant='body2' color='secondary'>
                        {Boolean(globalState?.agent_token) ? "Share" : "Request"}:
                    </Typography>
                    {Boolean(selectedProducer?.producer_token) &&
                        <Typography variant='body1' color='secondary' sx={{ textTransform: 'capitalize' }}>
                            {(selectedProducer?.first_name?.toLowerCase() || '')
                                + ' ' + (selectedProducer?.middle_name?.toLowerCase() || '')
                                + ' ' + (selectedProducer?.last_name?.toLowerCase() || '')
                                + ' ' + (selectedProducer?.business_name?.toLowerCase() || '')
                            }
                        </Typography>}
                    {!Boolean(selectedProducer?.producer_token) &&
                        <Typography variant="body1" color="secondary">
                            (member)
                        </Typography>}

                    <EastOutlined />

                    <Typography variant='body2' color='secondary'>
                        for access by:
                    </Typography>
                    <Typography variant='body1' color='secondary' sx={{ textTransform: "capitalize" }}>
                        {!Boolean(producer_token > 0) && <>
                            {activeAgent?.first_name?.toLowerCase()}
                            <> </>
                            {activeAgent?.middle_name?.toLowerCase()}
                            <> </>
                            {activeAgent?.last_name?.toLowerCase()}
                            <> </>
                            {activeAgent?.business_name?.toLowerCase()}
                        </>}

                        {Boolean(producer_token >= 0) && <>
                            {userGrant?.user_description?.toLowerCase()}
                        </>}
                    </Typography>
                </Stack >

                <Paper sx={{ p: 1, pl: 2.5, pr: 2.5, border: 'none' }}>
                    <FormGroup>
                        <Stack direction='column'>
                            <FormControlLabel name="is_master_read" control={
                                <Checkbox checked={userGrant?.is_master_read || false} onChange={handleCheckboxOnChange} />} label="View" />
                            <FormControlLabel name="is_master_write" control={
                                <Checkbox checked={userGrant?.is_master_write || false} />} onChange={handleCheckboxOnChange} label="Edit" />
                            <FormControlLabel name="is_master_delete" control={
                                <Checkbox checked={userGrant?.is_master_delete || false} onChange={handleCheckboxOnChange} />} label="Delete" />
                        </Stack>
                    </FormGroup>
                </Paper>
            </DialogContent>

            <DialogActions>
                <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap" justifyContent='flex-end'>
                    <Button variant='contained' color='secondary' autoFocus onClick={onClose}>Cancel</Button>
                    <Button variant='contained' color='primary' disabled={!isValid()} onClick={handleInviteOnClick}>
                        Invite
                        <SendOutlined sx={{ ml: 1 }} />
                    </Button>
                </Stack>
            </DialogActions>
        </>
    )
}
