import { getCurrentReinsuranceYear } from '@datanac/datanac-api-toolkit/dist/utility/InsurancePlanHelper'
import { useCardController, useDialog } from '@datanac/datanac-mui-toolkit'
import AddCircleOutline from '@mui/icons-material/AddCircleOutline'
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import CloseOutlined from '@mui/icons-material/CloseOutlined'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { Dialog, DialogActions, DialogContent, FormControl, Grid, InputAdornment, Stack, useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { AppContext } from 'App'
import DataFormSelect from 'components/Core/DataFormSelect'
import { propUpdateReducer } from 'components/Core/propUpdateReducer'
import { MASK_NUMERIC, StatefulControlledTextField } from 'components/Core/StatefulControlledTextField'
import CountyDataView from 'components/County/CountyDataView'
import { BudgetIcon } from 'components/Icons/Icons'
import { useBlockerPrompt } from 'components/Menu/useBlockerPrompt'
import usePrompt from 'components/Utility/usePrompt'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { formatNumber, formatPrice } from 'utility/formatters'
import { v4 as uuidv4 } from 'uuid'
import { ApiHelper, RmaApiHelper, UsersApiHelper } from '../../api/ApiHelper'
import { BUDGET_CATEGORY_DIRECT, BUDGET_CATEGORY_INDIRECT, BUDGET_ITEMS_DIRECT, BUDGET_ITEMS_INDIRECT } from './helpers'
import { useFormStatus } from 'mui-toolkit/useFormStatus'

export default function BudgetCountyPracticeWorkspace() {
    const { globalState, globalDispatch } = useContext(AppContext);
    const navigate = useNavigate();
    const { budget_id, budget_county_practice_id } = useParams();

    const isXL = useMediaQuery((theme) => theme.breakpoints.up('xl'), { noSsr: true });
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const { component: formStatusComponent, actions: formStatusActions, state: formStatusState } = useFormStatus();

    const [state, dispatch] = useReducer(propUpdateReducer, {
        is_active: true,
    });
    const { state: lineItems, actions: lineItemActions } = useCardController();

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const prompt = usePrompt();

    const [futures, setFutures] = useState({});

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    const loadFuturesData = async () => {
        try {
            if (state.year
                && state.state_name && state.county_name
                && state.commodity_name && state.type_name && state.practice_name
            ) {
                const futuresParameters = {
                    reinsurance_year: state.year,
                    state_name: state.state_name,
                    county_name: state.county_name,
                    commodity_name: state.commodity_name,
                    type_name: state.type_name,
                    practice_name: state.practice_name
                };
                ApiHelper.callSmociObject("data/DATANAC", "Markets", "Futures", "Search", futuresParameters, globalState)
                    .then((_futures) => {
                        if (_futures?.length) {
                            setFutures(_futures[0]);
                        } else {
                            setFutures(null);
                        }
                    });
            }
        } catch (err) {
            handleError(err);
        }
    }

    const [prices, setPrices] = useState({});
    const loadPriceData = async () => {
        try {
            if (state.state_name && state.county_name
                && state.commodity_name && state.type_name && state.practice_name
            ) {
                const pricesParameters = {
                    reinsurance_year: String(state.year),
                    state_name: state.state_name,
                    county_name: state.county_name,
                    commodity_name: state.commodity_name,
                    type_name: state.type_name,
                    practice_name: state.practice_name
                };

                RmaApiHelper.selectPrices(pricesParameters).then(_prices => {
                    if (_prices?.length) {
                        setPrices(_prices[0]);
                    }
                });
            }
        } catch (err) {
            handleError(err);
        }
    }

    const [countyData, setCountyData] = useState({});
    const loadCountyData = async () => {
        try {
            if (state.state_name && state.county_name
                && state.commodity_name && state.type_name && state.practice_name
            ) {
                const countyParameters = {
                    reinsurance_year: String(state.year),
                    state_name: state.state_name,
                    county_name: state.county_name,
                    commodity_name: state.commodity_name,
                    type_name: state.type_name,
                    practice_name: state.practice_name
                };

                RmaApiHelper.selectCountyData(countyParameters)
                    .then(_prices => {
                        if (_prices?.length) {
                            setCountyData(_prices[0]);
                        } else {
                            setCountyData(null);
                        }
                    });
            }
        } catch (err) {
            handleError(err);
        }
    }

    const loadData = async () => {
        try {
            setIsLoading(true);

            const budget = await UsersApiHelper.selectBudgets({ id: budget_id, is_active: true, }, globalState);
            if (budget?.length) {
                dispatch({
                    type: 'updateMany',
                    payload: {
                        budget_id: budget[0]?.id,
                        budget_name: budget[0]?.name,
                        year: budget[0]?.year,
                    },
                });
            }

            if (state?.id && state?.id != 0) {
                const budgetCountyPractice = await UsersApiHelper.selectBudgetCountyPractices({
                    budget_id: budget_id, id: state?.id, is_active: true,
                }, globalState);
                if (budgetCountyPractice?.length) {
                    dispatch({
                        type: 'updateMany',
                        payload: budgetCountyPractice[0]
                    });
                }
            }

            if (state?.id && state?.id != 0) {
                const budgetCountyPracticeLineItems = await UsersApiHelper.selectBudgetCountyPracticeLineItems({
                    budget_county_practice_id: state?.id, is_active: true,
                }, globalState);
                lineItemActions.replace(budgetCountyPracticeLineItems);
            }

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            dispatch({
                type: 'replace',
                payload: {}
            });
            lineItemActions.replace([]);

            handleError(err);
        }
    }

    const createDefaultLineItems = () => {
        var _defaultLineItems = [];
        _defaultLineItems = _defaultLineItems.concat(BUDGET_ITEMS_DIRECT.map(ii =>
            createLineItem(BUDGET_CATEGORY_DIRECT, ii[1], 0.00, false/*doOverwrite*/)
        ));

        _defaultLineItems = _defaultLineItems.concat(BUDGET_ITEMS_INDIRECT.map(ii =>
            createLineItem(BUDGET_CATEGORY_INDIRECT, ii[1], 0.00, false/*doOverwrite*/)
        ));

        return _defaultLineItems;
    }

    useEffect(() => {
        if (globalState?.producer_token && state?.budget_id != null && state?.id != null) {
            loadData();
        }
    }, [state?.budget_id, state?.id])

    useEffect(() => {
        dispatch({
            type: 'update',
            payload: { key: "budget_id", value: budget_id },
        });

        if (budget_county_practice_id == 0 && globalState?.producer_token) {
            const _budget_county_practice_id = uuidv4();
            dispatch({
                type: 'update',
                payload: { key: "id", value: _budget_county_practice_id },
            });
        } else {
            dispatch({
                type: 'update',
                payload: { key: "id", value: budget_county_practice_id },
            });
        }
    }, [globalState?.producer_token, budget_id, budget_county_practice_id]);

    useEffect(() => {
        loadFuturesData();
        loadPriceData();
        loadCountyData();
    }, [state?.state_name, state?.county_name,
    state?.commodity_name, state?.type_name, state?.practice_name])

    useEffect(() => {
        if (budget_county_practice_id == 0
            && budget_id != 0
            && state?.id
            && lineItems.filter(li => li.is_active)?.length == 0
        ) {
            // Load default:
            const _defaultLineItems = createDefaultLineItems();
            lineItemActions.replace(_defaultLineItems);
        }
    }, [state?.id, budget_county_practice_id, budget_id, lineItems])

    useEffect(() => {
        if (isValid() && globalState.producer_token && formStatusState?.isFormDirty) {
            formStatusActions.setIsFormDirty(true, true);

            UsersApiHelper.updateBudgetCountyPractice(state, globalState)
                .then(() => {
                    formStatusActions.setIsSaving(false);
                })
                .catch(err => {
                    console.warn(err);
                    formStatusActions.setIsFormDirty(true, false);
                    enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
                });
        }
    }, [state]);

    const isValid = () => {
        return (
            state?.id
            && state?.state_name
            && state?.county_name
            && state?.commodity_name
            && state?.type_name
            && state?.practice_name

            && state?.reported_acreage
            && state?.yield
            && state?.projected_price
        );
    }
    useBlockerPrompt(!isValid(), "BudgetCountyPracticeWorkspace");

    useEffect(() => {
        if (globalState?.producer_token && lineItems && formStatusState?.isFormDirty) {
            formStatusActions.setIsFormDirty(true, true);

            UsersApiHelper.updateBudgetCountyPracticeLineItem({ BudgetCountyPracticeLineItems: lineItems }, globalState)
                .then(() => {
                    formStatusActions.setIsSaving(false);
                })
                .catch(err => {
                    console.warn(err);
                    formStatusActions.setIsFormDirty(true, false);
                    enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
                });
        }
    }, [lineItems]);

    const calculateProductionCost = () => {
        return _.sumBy(lineItems.filter(ii => ii.is_active), i => Number(i.value) || 0);
    }

    const handleChangeState = async (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        if (event?.target?.name == "commodity_name" && value != state.commodity_name) {
            formStatusActions.setIsFormDirty(true, false);

            dispatch({
                type: 'updateMany',
                payload: {
                    commodity_name: value,
                    type_name: '',
                    practice_name: ''
                }
            });
        } else {
            if (state[event.target.name] != value) {
                formStatusActions.setIsFormDirty(true, false);

                dispatch({
                    type: 'update',
                    payload: { key: event.target.name, value: value },
                });
            }
        }
    }

    const handleChangeState_LineItem = async (event, currentLineItem) => {
        formStatusActions.setIsFormDirty(true, false);
        const _newValue = {
            ...currentLineItem,
            value: event?.target?.value || 0
        }
        lineItemActions.update(_newValue);
    }

    const handleClickAddNewLineItem = async (category) => {
        if (state?.id) {
            prompt.actions.prompt({
                title: "Budget Item",
                prompt: "Please enter budget item name:",
                callback: (inputValue) => {
                    if (inputValue) {
                        var newLineItem = createLineItem(category, inputValue, 0.00, false/*doOverwrite*/);
                        lineItemActions.add(newLineItem);
                    }
                },
            });
        }
    }

    const handleDeleteLineItem = async (currentLineItem) => {
        const _newLineItem = { ...currentLineItem };
        _newLineItem.is_active = false;
        lineItemActions.update(_newLineItem);

        formStatusActions.setIsFormDirty(true, true);
    }

    const commodityDataFormSelect = useMemo(() => {
        return (<DataFormSelect
            value={state.commodity_name || ""}
            name="commodity_name"
            isRequired={false}
            onChange={handleChangeState}
            objectName="InsuranceOffer"
            objectNameLabel="Commodity"
            itemFilter={{
                reinsurance_year: String(state.year),
                state_name: state.state_name,
                county_name: state.county_name,
            }}
            itemGetKey={(item) => item.commodity_name}
            itemGetLabel={(item) => item.commodity_name}
            doFetch={
                state.year != null
                && state?.state_name
                && state?.county_name
            }
            isLoading={isLoading}
        />);
    }, [state.year, state?.state_name, state?.county_name, state.commodity_name, isLoading]);

    const typeDataFormSelect = useMemo(() => {
        return (<DataFormSelect
            value={state.type_name || ""}
            name="type_name"
            isRequired={false}
            onChange={handleChangeState}
            objectName="InsuranceOffer"
            objectNameLabel="Commodity"
            itemFilter={{
                reinsurance_year: String(state.year),
                state_name: state.state_name,
                county_name: state.county_name,
                commodity_name: state.commodity_name,
            }}
            itemGetKey={(item) => item.type_name}
            itemGetLabel={(item) => item.type_name}
            doFetch={
                state.year != null
                && state?.state_name
                && state?.county_name
                && state.commodity_name
            }
            isLoading={isLoading}
        />);
    }, [state?.state_name, state?.county_name, state.commodity_name, state.type_name,
        isLoading]);

    const practiceDataFormSelect = useMemo(() => {
        return (<DataFormSelect
            value={state.practice_name || ""}
            name="practice_name"
            isRequired={false}
            onChange={handleChangeState}
            objectName="InsuranceOffer"
            objectNameLabel="Practice"
            itemFilter={{
                reinsurance_year: String(state.year),
                state_name: state.state_name,
                county_name: state.county_name,
                commodity_name: state.commodity_name,
                type_name: state.type_name
            }}
            itemGetKey={(item) => item.practice_name}
            itemGetLabel={(item) => item.practice_name}
            doFetch={
                state.year != null
                && state?.state_name
                && state?.county_name
                && state.commodity_name
                && state.type_name
            }
            isLoading={isLoading}
        />);
    }, [state?.state_name, state?.county_name, state.commodity_name, state.type_name, state.practice_name,
        isLoading]);

    const [budgetTemplateData, setBudgetTemplateData] = useState([]);

    const loadBudgetTemplateData = async () => {
        try {
            const budgetParameters = {
                state_name: state.state_name,
                county_name: state.county_name,
                commodity_name: state.commodity_name
            };

            ApiHelper.selectObject("FSA.Extension", "Budget", budgetParameters)
                .then((budgetTemplates) => {
                    if (budgetTemplates && budgetTemplates?.length > 0) {
                        setBudgetTemplateData(budgetTemplates);
                    }
                });
        } catch (err) {
            handleError(err);
        }
    }

    const loadSelectedTemplate = async (selectedTemplate) => {
        const newValuesDirect = {};
        BUDGET_ITEMS_DIRECT.forEach(i => {
            newValuesDirect[i[1]] = selectedTemplate[i[0]];
        });
        const templateDirectLineItems = Object.keys(newValuesDirect).map(key =>
            createLineItem(BUDGET_CATEGORY_DIRECT, key, newValuesDirect[key], true/*doOverwrite*/)
        );

        const newValuesIndirect = {};
        BUDGET_ITEMS_INDIRECT.forEach(i => {
            newValuesIndirect[i[1]] = selectedTemplate[i[0]];
        });
        const templateIndirectLineItems = Object.keys(newValuesIndirect).map(key =>
            createLineItem(BUDGET_CATEGORY_INDIRECT, key, newValuesIndirect[key], true/*doOverwrite*/)
        );

        lineItemActions.replace([
            ...templateDirectLineItems,
            ...templateIndirectLineItems
        ]);
        formStatusActions.setIsFormDirty(true, true);
    }

    const createLineItem = (category, name, value, doOverwrite) => {
        const _id = doOverwrite && lineItems.find(li => li?.name == name)?.id;

        const _newLineItem = {
            id: _id || uuidv4(),
            producer_token: globalState.producer_token,
            agent_token: globalState.agent_token,
            budget_county_practice_id: state?.id,
            category: category,
            name: name,
            value: value,
            is_active: true
        };

        return _newLineItem;
    }

    const [isCountyDataDialogOpen, setIsCountyDataDialogOpen] = useState(false);

    const [anchorTemplateMenuEl, setAnchorTemplateMenuEl] = useState(null);
    const isAnchorTemplateMenuOpen = Boolean(anchorTemplateMenuEl);
    const handleTemplateMenuOpen = async (event) => {
        setAnchorTemplateMenuEl(event.currentTarget);
    }
    const handleTemplateMenuClose = async () => {
        setAnchorTemplateMenuEl(null);
    }
    const handleTemplateMenuClick = async (selectedTemplate) => {
        loadSelectedTemplate(selectedTemplate);
        handleTemplateMenuClose();
    }

    useEffect(() => {
        if (state.state_name
            && state.county_name
            && state.commodity_name) {
            loadBudgetTemplateData();
        }
    }, [state?.state_name, state?.county_name, state?.commodity_name])

    const formMaxWidthShort = '800px';
    const formMaxWidth = '1000px';

    return (
        <>
            <WorkspaceHeader
                title={Boolean(state?.commodity_name) ?
                    (state?.commodity_name
                        + " " + (state?.type_name && (state?.type_name != "No Type Specified" ? " \u2013 " + state?.type_name : ""))
                        + " " + "\u2013 " + state?.practice_name)
                    : "Budget"}
                breadCrumbs={[
                    {
                        title: 'Budgets',
                        path: '/budget',
                        icon: <BudgetIcon />
                    },
                    {
                        title: state?.budget_name || "Budget",
                        path: `/budget/${budget_id}`,
                        icon: null
                    },
                ]}
            />

            <Box sx={{ width: '100%', maxWidth: formMaxWidthShort }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Stack direction="row" spacing={2} className='headerWithMenuButton'>
                            <Typography variant='h5'>
                                Crop
                            </Typography>
                            {formStatusComponent}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DataFormSelect
                            value={state.state_name}
                            name="state_name"
                            onChange={handleChangeState}
                            objectName="State"
                            itemFilter={{ reinsurance_year: state.year }}
                            itemGetKey={item => item.state_name}
                            itemGetLabel={item => item.state_name}
                            doFetch={state.year != null}
                            isLoading={isLoading}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DataFormSelect
                            value={state.county_name}
                            name="county_name"
                            onChange={handleChangeState}
                            objectName="County"
                            itemFilter={{
                                reinsurance_year: state.year,
                                state_name: state.state_name,
                            }}
                            itemGetKey={item => item.county_name}
                            itemGetLabel={item => item.county_name}
                            doFetch={state.year && state.state_name}
                            isLoading={isLoading}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        {commodityDataFormSelect}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {typeDataFormSelect}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {practiceDataFormSelect}
                    </Grid>
                </Grid>
            </Box>

            <Divider sx={{ mt: 2, mb: 2 }} />

            <Box sx={{ width: '100%', maxWidth: formMaxWidthShort }}>
                <Grid container spacing={2}>
                    <Grid item xs={7}>
                        <Typography variant='h5'>
                            Production
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Box display='flex' justifyContent='flex-end'>
                            <Button variant='text' onClick={() => setIsCountyDataDialogOpen(true)}>
                                {isSmall ? "County" : "County History"}
                                <InfoOutlined sx={{ ml: 1 }} />
                            </Button>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Acres"
                                name="reported_acreage"
                                isRequired={true}
                                defaultValue={state.reported_acreage || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                maskType={MASK_NUMERIC}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Projected Yield/Acre"
                                name="yield"
                                isRequired={true}
                                defaultValue={state.yield || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                maskType={MASK_NUMERIC}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Projected Price"
                                name="projected_price"
                                isRequired={true}
                                defaultValue={state.projected_price || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                maskType={MASK_NUMERIC}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={0} md={4}>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {countyData?.expected_index_value &&
                            <Box sx={{
                                border: "1px solid #f8f8f8",
                                backgroundColor: "#fcfcfc",
                                borderRadius: "7px",
                                p: 1
                            }}>
                                <Typography variant="body2" color="secondary">RMA Expected Yield</Typography>
                                <Typography variant="body1">{formatNumber(countyData?.expected_index_value)}</Typography>
                            </Box>}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {(prices?.projected_price || futures?.projected_price) &&
                            <Box sx={{
                                border: "1px solid #f8f8f8",
                                backgroundColor: "#fcfcfc",
                                borderRadius: "7px",
                                p: 1
                            }}>
                                <Stack direction="column" spacing={2}>
                                    {prices?.projected_price && <Box>
                                        <Typography variant="body2" color="secondary">RMA Projected Price</Typography>
                                        <Typography variant="body1">{formatPrice(prices?.projected_price, 4)}</Typography>
                                    </Box>}

                                    {futures?.projected_price && <Box>
                                        <Typography variant="body2" color="secondary">Current Local Price</Typography>
                                        <Typography variant="body1">{formatPrice(futures?.harvest_price || futures?.projected_price, 4)}</Typography>
                                    </Box>}
                                </Stack>
                            </Box>}
                    </Grid>
                </Grid>
            </Box >

            <Divider sx={{ mt: 2, mb: 2 }} />

            <Box sx={{ width: '100%', maxWidth: formMaxWidth }}>
                <Grid container spacing={2}>
                    <Grid item xs={7}>
                        <Typography variant='h5'>
                            Direct Costs
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Box display='flex' justifyContent='flex-end'>
                            <Stack direction="row" spacing={1}>
                                <Button variant='text' onClick={handleTemplateMenuOpen}>
                                    {isSmall ? "Template" : "Select budget template"}
                                    <ArrowDropDownOutlined sx={{ ml: 1 }} />
                                </Button>

                                <Menu anchorEl={anchorTemplateMenuEl} open={isAnchorTemplateMenuOpen}
                                    onClose={handleTemplateMenuClose}>
                                    {!Boolean(budgetTemplateData?.length) && isLoading &&
                                        <MenuItem>Loading...</MenuItem>
                                    }
                                    {!Boolean(budgetTemplateData?.length) && !isLoading &&
                                        <MenuItem>No budget templates available</MenuItem>
                                    }
                                    {Boolean(budgetTemplateData?.length) &&
                                        budgetTemplateData?.map((t, index) =>
                                            <MenuItem
                                                key={index}
                                                onClick={() => handleTemplateMenuClick(t)}
                                            >
                                                {t.state_name}, {t.area} - {t.practice} - {formatPrice(t.total)}
                                            </MenuItem>)
                                    }
                                </Menu>
                            </Stack>
                        </Box>
                    </Grid>

                    {lineItems
                        .filter(li => li.category == BUDGET_CATEGORY_DIRECT)
                        .filter(ii => ii.is_active)
                        .sort((a, b) =>
                            a.name?.localeCompare(b.name)
                        )
                        .map((currentLineItem, index) =>
                            <Grid item key={index} xs={6} md={4} xl={3}>
                                <FormControl fullWidth={true}>
                                    <StatefulControlledTextField
                                        label={currentLineItem?.name}
                                        name={currentLineItem?.name}
                                        isRequired={true}
                                        defaultValue={currentLineItem?.value}
                                        variant="outlined"
                                        onChange={(e) => handleChangeState_LineItem(e, currentLineItem)}
                                        InputLabelProps={{ shrink: true }}
                                        endAdornment={
                                            <InputAdornment position="end" className='delete'>
                                                <IconButton onClick={() => handleDeleteLineItem(currentLineItem)} edge="end" color="secondary">
                                                    <DeleteOutlined />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        maskType={MASK_NUMERIC}
                                    />
                                </FormControl>
                            </Grid>
                        )}

                    <Grid item xs={6} md={4} xl={3} className="navigation">
                        <Box display="flex" sx={{ height: "100%", pt: 1 }} justifyContent='bottom'>
                            <Button fullWidth sx={{ borderRadius: '4pt', height: '53px', mt: 'auto' }} variant='text' color='secondary'
                                onClick={(() => handleClickAddNewLineItem(BUDGET_CATEGORY_DIRECT))}>
                                <AddCircleOutline />
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box >

            <Divider sx={{ mt: 2, mb: 2 }} />

            <Box sx={{ width: '100%', maxWidth: formMaxWidth }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Typography variant='h5'>
                            Indirect Costs
                        </Typography>
                    </Grid>
                    {lineItems
                        .filter(li => li.category == BUDGET_CATEGORY_INDIRECT)
                        .filter(ii => ii.is_active)
                        .map((currentLineItem, index) =>
                            <Grid item key={index} xs={6} md={4} xl={3}>
                                <FormControl fullWidth={true}>
                                    <StatefulControlledTextField
                                        label={currentLineItem?.name}
                                        name={currentLineItem?.name}
                                        isRequired={true}
                                        defaultValue={currentLineItem?.value}
                                        variant="outlined"
                                        onChange={(e) => handleChangeState_LineItem(e, currentLineItem)}
                                        InputLabelProps={{ shrink: true }}
                                        endAdornment={
                                            <InputAdornment position="end" className='delete'>
                                                <IconButton onClick={() => handleDeleteLineItem(currentLineItem)} edge="end" color="secondary">
                                                    <DeleteOutlined />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        maskType={MASK_NUMERIC}
                                    />
                                </FormControl>
                            </Grid>
                        )}

                    <Grid item xs={6} md={4} xl={3} className="navigation">
                        <Box display="flex" sx={{ height: "100%", pt: 1 }} justifyContent='center'>
                            <Button fullWidth sx={{ borderRadius: '4pt', height: '53px', mt: 'auto' }} variant='text' color='secondary'
                                onClick={(() => handleClickAddNewLineItem(BUDGET_CATEGORY_INDIRECT))}>
                                <AddCircleOutline />
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box className="pageBreakBefore" sx={{
                ...(isXL && {
                    top: 'auto', bottom: 0, pb: 2, position: 'sticky',
                    width: "100%", backgroundColor: 'white', zIndex: 1000
                })
            }}>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Grid container justifyItems='flex-start' spacing={1}>
                    <Grid item xs={12} md={6} xl={1}>
                        <Typography variant='h1'>
                            Totals
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} xl={1.5}>
                        <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                            <Typography variant='h5'>
                                {formatNumber(state?.reported_acreage, 2)}
                            </Typography>
                            <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Acres</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} xl={1.5} sx={{ backgroundColor: '#f5f5f5' }}>
                        <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                            <Typography variant='h5'>
                                {formatPrice(
                                    state?.yield * state?.projected_price
                                    , 0)}
                            </Typography>
                            <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Rev/Acre</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} xl={1.7} sx={{ backgroundColor: '#f5f5f5' }}>
                        <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                            <Typography variant='h5'>
                                {formatPrice(
                                    state?.yield * state?.projected_price * state?.reported_acreage
                                    , 0)}
                            </Typography>
                            <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Revenue</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} xl={1.5}>
                        <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                            <Typography variant='h5'>
                                {formatPrice(
                                    calculateProductionCost()
                                    , 0)}
                            </Typography>
                            <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Cost/Acre</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} xl={1.7}>
                        <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                            <Typography variant='h5'>
                                {formatPrice(
                                    calculateProductionCost() * state?.reported_acreage
                                    , 0)}
                            </Typography>
                            <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Cost</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} xl={1.5} sx={{ backgroundColor: '#f5f5f5' }}>
                        <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                            <Typography variant='h5'>
                                {formatPrice(
                                    (state?.yield * state?.projected_price) - calculateProductionCost()
                                    , 0)}
                            </Typography>
                            <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Net Income/Acre</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} xl={1.5} sx={{ backgroundColor: '#f5f5f5' }}>
                        <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                            <Typography variant='h5' sx={{ fontWeight: 'bold !important' }}>
                                {formatPrice(
                                    ((state?.yield * state?.projected_price) - calculateProductionCost()) * state?.reported_acreage
                                    , 0)}
                            </Typography>
                            <Typography variant='body1' sx={{ fontWeight: 'bold !important', textTransform: isSmall ? '' : 'uppercase' }}>Net Income</Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>

            {state.state_name && state.county_name
                && state.commodity_name && state.type_name && state.practice_name
                && <Dialog open={isCountyDataDialogOpen} fullWidth>
                    <DialogContent>
                        <CountyDataView countyPractice={{
                            reinsurance_year: getCurrentReinsuranceYear(),
                            location_state_name: state?.state_name,
                            location_county_name: state?.county_name,
                            ...state
                        }} />
                    </DialogContent>
                    <DialogActions>
                        <Button sx={{ mr: 1 }} variant="outlined"
                            onClick={() => { setIsCountyDataDialogOpen(false) }}>
                            <CloseOutlined sx={{ mr: 1 }} />
                            Close
                        </Button>
                    </DialogActions>
                </Dialog >
            }

            {prompt?.component}
            {confirmDialogComponent}
        </>
    )
}
