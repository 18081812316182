import { useDialog } from '@datanac/datanac-mui-toolkit'
import { CopyAllOutlined, MoreVertOutlined } from '@mui/icons-material'
import DeleteForeverOutlined from '@mui/icons-material/DeleteForeverOutlined'
import EastOutlined from '@mui/icons-material/EastOutlined'
import { Box, Card, CardActions, CardContent, CardHeader, Chip, Divider, IconButton, ListItemIcon, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { AppContext } from 'App'
import { BudgetIcon } from 'components/Icons/Icons'
import PopupState, { bindMenu, bindToggle } from 'material-ui-popup-state'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatNumber, formatPrice } from 'utility/formatters'
import { BudgetService } from '../BudgetService'
import { BUDGET_STATUS_ACTIVE, BUDGET_STATUS_DRAFT } from '../helpers'


export default function BudgetSummaryCard({
    budget,
    onChange
}) {
    const { globalState } = useContext(AppContext);
    const navigate = useNavigate();

    const toggleStatus = () => {
        const _newBudget = { ...budget };
        _newBudget.status = (budget.status == BUDGET_STATUS_ACTIVE ? BUDGET_STATUS_DRAFT : BUDGET_STATUS_ACTIVE);
        onChange && onChange(_newBudget);
    }

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const handleDeleteOnClick = () => {
        confirmDialogActions.confirm({
            title: "Delete this entry?",
            prompt: "Are you sure you want to delete this entry?",
            promptDetail: null,
            callback: () => {
                const _newBudget = { ...budget };
                _newBudget.is_active = false;
                onChange && onChange(_newBudget);
            }
        })
    }

    const createCopyBudget = async () => {
        const { actions: budgetActions } = new BudgetService({
            apiConfig: globalState,
            year: budget?.year
        });
        await budgetActions.cloneBudget({ budget_id: budget?.id });
        onChange && onChange();
    }

    // ---

    return (
        <>
            <Card>
                <CardHeader
                    avatar={<BudgetIcon />}
                    title={budget?.name}
                    subheader={budget?.year}
                    action={<>
                        <IconButton onClick={handleDeleteOnClick}>
                            <DeleteForeverOutlined sx={{ color: 'black' }} />
                        </IconButton>
                        <PopupState variant="popper" popupId="chat-popper">
                            {(popupState) => (<>
                                <IconButton {...bindToggle(popupState)}>
                                    <MoreVertOutlined sx={{ color: 'black' }} />
                                </IconButton>
                                <Menu {...bindMenu(popupState)}>
                                    <MenuItem onClick={() => {
                                        createCopyBudget()
                                        popupState.close();
                                    }}>
                                        <ListItemIcon>
                                            <CopyAllOutlined />
                                        </ListItemIcon>
                                        Create copy...
                                    </MenuItem>
                                </Menu>
                            </>)}
                        </PopupState>
                    </>}
                />
                <CardContent>
                    <Stack direction="column" spacing={1} height="100%">
                        <Stack direction='row' flexWrap='wrap' spacing={1}>
                            <Box>
                                <Typography variant='h6'>
                                    {formatPrice(budget?.revenue_simple - budget?.production_cost, 0)}
                                </Typography>
                                <Typography variant='body1' sx={{ fontSize: '8pt', leading: '0px', lineHeight: '9pt', height: '100%' }}>
                                    NET INCOME
                                </Typography>
                            </Box>

                            <Divider orientation='vertical' flexItem />

                            <Box>
                                <Typography variant='h6'>
                                    {formatNumber(budget?.reported_acreage, 0)}
                                </Typography>
                                <Typography variant='body1' sx={{ fontSize: '8pt', leading: '0px', lineHeight: '9pt', height: '100%' }}>
                                    ACRES
                                </Typography>
                            </Box>
                        </Stack>
                    </Stack>
                </CardContent>

                <CardActions>
                    <Chip
                        label={budget?.status == BUDGET_STATUS_ACTIVE ? 'Active' : 'Draft'}
                        color={budget?.status == BUDGET_STATUS_ACTIVE ? 'secondary' : 'default'}
                        onClick={toggleStatus} />

                    <IconButton onClick={() => { navigate(`/budget/${budget?.id}`) }}>
                        <EastOutlined sx={{ color: 'black' }} />
                    </IconButton>
                </CardActions>
            </Card>

            {confirmDialogComponent}
        </>
    )
}
