import { INSURANCE_OPTION_CODE_SEED_ENDORSEMENT, INSURANCE_PLAN_FAMILY_CROP } from '@datanac/datanac-api-toolkit/dist/datanac-rma-api-helper'
import { getComboTitle } from '@datanac/datanac-api-toolkit/dist/insurance/optimizer/optimizer'
import { useDialog } from '@datanac/datanac-mui-toolkit'
import DeleteForeverOutlined from '@mui/icons-material/DeleteForeverOutlined'
import { Card, CardActions, CardContent, CardHeader, Divider, IconButton, Stack, Typography } from '@mui/material'
import { BUDGET_STATUS_ACTIVE, BUDGET_STATUS_DRAFT } from 'components/Budget/helpers'
import { InsuranceIcon } from 'components/Icons/Icons'
import { useNavigate, useParams } from 'react-router-dom'
import { formatNumber, formatPercentage, formatPrice } from 'utility/formatters'

export default function InsurancePlanCard({
    countyPractice,
    insurancePlan,
    isLoading,

    onChange
}) {
    const { insurance_scenario_id } = useParams();
    const navigate = useNavigate();

    const toggleStatus = () => {
        const _newState = { ...insurancePlan };
        _newState.status = (insurancePlan.status == BUDGET_STATUS_ACTIVE ? BUDGET_STATUS_DRAFT : BUDGET_STATUS_ACTIVE);
        onChange && onChange(_newState);
    }

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const handleDeleteOnClick = () => {
        confirmDialogActions.confirm({
            title: "Delete this entry?",
            prompt: "Are you sure you want to delete this entry?",
            promptDetail: null,
            callback: () => {
                const _newState = { ...insurancePlan };
                _newState.is_active = false;
                onChange && onChange(_newState);
            }
        })
    }

    return (
        <>
            <Card>
                <CardHeader
                    avatar={<InsuranceIcon />}
                    title={
                        insurancePlan?.insurance_plan_name
                        // + (insurancePlan?.insurance_option_codes?.includes(INSURANCE_OPTION_CODE_SEED_ENDORSEMENT) ? " (SE)" : "")
                        + (insurancePlan?.insurance_plan_family == INSURANCE_PLAN_FAMILY_CROP && (" - " + insurancePlan?.unit_structure_code) || "")
                    }
                    subheader={
                        getComboTitle([insurancePlan])
                        // formatPercentage(insurancePlan?.coverage_level_percent)
                        // + (insurancePlan?.insurance_plan_family == INSURANCE_PLAN_FAMILY_CROP && (" - " + insurancePlan?.unit_structure_code) || "")
                    }
                    action={Boolean(onChange) &&
                        <IconButton onClick={handleDeleteOnClick}>
                            <DeleteForeverOutlined sx={{ color: 'black' }} />
                        </IconButton>
                    }
                />

                <CardContent>
                    <Stack direction="row" spacing={1}>
                        <Stack direction='column' flexWrap='wrap' spacing={1} alignItems='flex-start' justifyItems='center'>
                            <>
                                <Typography variant='h6'>
                                    {formatPrice(insurancePlan?.producer_premium_amount_per_acre, 2)}
                                </Typography>
                                <Typography variant='body1'>
                                    PRODUCER<br />
                                    PREMIUM /ac
                                </Typography>
                            </>
                        </Stack>

                        <Divider orientation='vertical' flexItem />

                        <Stack direction='column' flexWrap='wrap' spacing={1} alignItems='flex-start' justifyItems='center'>
                            <>
                                <Typography variant='h6'>
                                    {formatPrice(insurancePlan?.liability_amount_per_acre, 0)}
                                </Typography>
                                <Typography variant='body1'>
                                    COVERAGE /ac
                                </Typography>
                            </>
                        </Stack>
                    </Stack>

                </CardContent>

                <CardActions>
                </CardActions>
            </Card>

            {confirmDialogComponent}
        </>
    )
}
