import EmptyCard from 'components/Cards/EmptyCard'
import { propUpdateReducer } from 'components/Core/propUpdateReducer'
import { FarmVaultIcon } from 'components/Icons/Icons'
import { useFarmController } from 'components/Vault/Farm/useFarmController'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ReportViewer } from './ReportViewer'
import useInsurance from 'components/Insurance/useInsurance'

export default function InsuredRevenueByFarmReport() {
    const {
        crop_year,
    } = useParams();

    const [currentYear, setCurrentYear] = useState(crop_year);

    const [filter, dispatchFilter] = useReducer(propUpdateReducer, {
        commodity_name: [],
        practice_name: [],
    });

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || err, { preventDuplicate: true, variant: 'error' });
    }

    const [isDetailViewOnParent, setIsDetailViewOnParent] = useState(false);
    const setIsDetailViewOnFunc = (val) => {
        setIsDetailViewOnParent(val);
    }

    // --- --- ---

    const {
        counties,
        // countyPractices,
        // producerFarms,
        producerFarmFieldCrops,
        // producerFarm_union_Crops,
        // isLoading,
        isLoadingCounties,
    } = useFarmController({
        crop_year: currentYear,
        filter: filter,
        onError: handleError
    });

    const { insuranceScenarios, insurancePlans, stressTestResults, stressTestParameters } = useInsurance({
        crop_year,
    });

    // --- --- ---

    const columnsDirective = [
        { field: 'location_county_name', headerText: 'County', width: 100, textAlign: 'Left', visible: true },
        { field: 'location_state_name', headerText: 'State', width: 100, textAlign: 'Left', visible: true },
        { field: 'fsa_farm_number', headerText: 'Farm Number', width: 100, textAlign: 'Right', visible: true },
        { field: 'farm_name', headerText: 'Farm Name', width: 100, textAlign: 'Right', visible: true },
        { field: 'unit_count', headerText: '# Fields', width: 100, textAlign: 'Right', visible: isDetailViewOnParent },
        { field: 'crop_year', headerText: 'Year', width: 150, textAlign: 'Right', visible: isDetailViewOnParent },
        { field: 'reported_acreage', headerText: 'Reported Acreage', width: 150, textAlign: 'Right', visible: true },
        { field: 'share_percent', headerText: 'Share Percent', width: 150, textAlign: 'Right', visible: isDetailViewOnParent, format: 'P0' },
        { field: 'commodity_name', headerText: 'Commodity', width: 150, visible: true },
        { field: 'type_name', headerText: 'Type', width: 150, visible: isDetailViewOnParent },
        { field: 'practice_name', headerText: 'Practice', width: 150, visible: true },
        { field: 'approved_yield_weighted', headerText: 'Approved Yield', width: 150, textAlign: 'Right', visible: true },
    ];

    return (
        <>
            <WorkspaceHeader
                title={"Insured Revenue by Farm"}
                breadCrumbs={[
                    {
                        title: 'Farm Vault ' + crop_year,
                        path: '/vault',
                        icon: <FarmVaultIcon fontSize='3pt' />
                    },
                ]}
            />

            {!isLoadingCounties && !Boolean(counties?.length) && <>
                <EmptyCard text="No search results" secondaryText="Remove some filters or add a new farm in the Farm Vault" />
            </>}

            <ReportViewer
                columnsDirective={columnsDirective}
                counties={counties}
                producerFarmFieldCrops={producerFarmFieldCrops}
                isLoadingCounties={isLoadingCounties}
                reportType="farm"
                setIsDetailViewOnFunc={setIsDetailViewOnFunc}
            />

            ~<pre>{JSON.stringify(stressTestResults, null, 2)}</pre>
        </>
    )
}
