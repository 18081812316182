import { SaveOutlined } from "@mui/icons-material";
import { Alert } from "@mui/material";
import { propUpdateReducer } from "components/Core/propUpdateReducer";
import { useReducer } from "react";

export const useFormStatus = () => {
    const [state, dispatch] = useReducer(propUpdateReducer, {
        isFormDirty: false,
        isSaving: false,
    });

    const setIsFormDirty = (_isFormDirty, _isSaving) => {
        dispatch({
            type: 'updateMany',
            payload: {
                isFormDirty: _isFormDirty,
                isSaving: _isSaving,
            }
        });
    }

    const setIsSaving = (_isSaving) => {
        if (_isSaving) {
            dispatch({
                type: 'update',
                payload: { key: "isSaving", value: _isSaving },
            });
        } else {
            dispatch({
                type: 'updateMany',
                payload: {
                    isFormDirty: false,
                    isSaving: _isSaving,
                }
            });
        }
    }

    const actions = {
        setIsFormDirty,
        setIsSaving
    }

    const component = (<>
        {state.isFormDirty && !state.isSaving && <Alert className="formStatusAlert" severity="warning">
            Changes are not saved...
        </Alert>}

        {state.isSaving && <Alert className="formStatusAlert" severity="info" icon={<SaveOutlined />}>
            Saving...
        </Alert>}
    </>)

    return {
        state,
        actions,
        component
    }
}