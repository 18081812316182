import EastOutlined from '@mui/icons-material/EastOutlined'
import EditNoteOutlined from '@mui/icons-material/EditNoteOutlined'
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined'
import { Box, Card, CardActions, CardContent, Chip, Grid, IconButton, Stack, Typography } from '@mui/material'
import { AppContext } from 'App'
import { SecurityIcon } from 'components/Icons/Icons'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'


export default function UserGrantCard({
    userGrant,
    userGrantItems
}) {
    const { globalState, globalDispatch, session } = useContext(AppContext);

    const { producer_token } = useParams();

    const navigate = useNavigate();

    const [permissionStatus, setPermissionStatus] = useState({});
    useEffect(() => {
        const _permissionStatus = {
            is_read: 0,
            is_write: 0,
            is_delete: 0,
        };

        if (userGrant.is_master_read) {
            _permissionStatus.is_read = 2;
        } else {
            _permissionStatus.is_read = userGrantItems?.find(ugi => ugi.is_read) ? 1 : 0;
        }

        if (userGrant.is_master_write) {
            _permissionStatus.is_write = 2;
        } else {
            _permissionStatus.is_write = userGrantItems?.find(ugi => ugi.is_write) ? 1 : 0;
        }

        if (userGrant.is_master_delete) {
            _permissionStatus.is_delete = 2;
        } else {
            _permissionStatus.is_delete = userGrantItems?.find(ugi => ugi.is_delete) ? 1 : 0;
        }

        setPermissionStatus(_permissionStatus);
    }, [userGrant, userGrantItems])

    return (
        <>
            <Card>
                <CardContent>
                    <Grid container spacing={1} alignItems='center'>
                        <Grid item xs={6}>
                            <Stack direction='row' alignItems='center' spacing={1}>
                                <SecurityIcon fontSize='large' />
                            </Stack>
                        </Grid>
                        <Grid item xs={6} justifyContent={'flex-end'}>
                            <Box display="flex" justifyContent="flex-end">
                                <Stack direction='row' spacing={1} alignItems='center' justifyItems='center'>
                                    {Boolean(permissionStatus?.is_read) &&
                                        <Chip label={<><VisibilityOutlined
                                            sx={{ color: permissionStatus?.is_read == 2 ? "black" : "#c0c0c0" }} /></>}
                                        />}


                                    {Boolean(permissionStatus?.is_write) &&
                                        <Chip label={<><EditNoteOutlined
                                            sx={{ color: permissionStatus?.is_write == 2 ? "black" : "#c0c0c0" }} /></>}
                                        />}


                                    {/* {Boolean(permissionStatus?.is_delete) &&
                                        <Chip label={<><DeleteForeverOutlined
                                            sx={{ color: permissionStatus?.is_delete == 2 ? "black" : "#c0c0c0" }} /></>}
                                        />} */}
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box height="100%">
                        <Stack direction="column" spacing={1} height="100%">
                            <Typography variant='h5' sx={{ textTransform: "capitalize" }}>
                                {userGrant?.user_description?.toLowerCase()}
                            </Typography>
                        </Stack>
                    </Box>
                </CardContent>

                <CardActions>
                    <IconButton onClick={() => { navigate(`/userGrant/${producer_token}/${userGrant?.id}`) }}>
                        <EastOutlined />
                    </IconButton>
                </CardActions>
            </Card>
        </>
    )
}
