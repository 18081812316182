import { getComboTitle } from '@datanac/datanac-api-toolkit/dist/insurance/optimizer/optimizer'
import { useDialog } from '@datanac/datanac-mui-toolkit'
import DeleteForeverOutlined from '@mui/icons-material/DeleteForeverOutlined'
import EastOutlined from '@mui/icons-material/EastOutlined'
import { Box, Card, CardActions, CardContent, CardHeader, Chip, Divider, IconButton, Stack, Typography } from '@mui/material'
import { InsuranceIcon } from 'components/Icons/Icons'
import { useNavigate, useParams } from 'react-router-dom'
import { formatNumber, formatPracticeName, formatPrice } from 'utility/formatters'

export default function InsuranceCountyPracticeCard({
    insuranceScenario,
    budgetCountyPractice,
    onChange
}) {

    const { insurance_scenario_id } = useParams();
    const navigate = useNavigate();

    const handleCardNavigateClick = () => {
        if (insuranceScenario?.id && budgetCountyPractice.state_name) {
            navigate(`/insurance/${insuranceScenario?.id}/${budgetCountyPractice.state_name}/${budgetCountyPractice.county_name}/${budgetCountyPractice.commodity_name}/${budgetCountyPractice.type_name}/${budgetCountyPractice.practice_name}`)
        }
    }

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const handleDeleteOnClick = () => {
        confirmDialogActions.confirm({
            title: "Delete this entry?",
            prompt: "Are you sure you want to delete this entry?",
            promptDetail: null,
            callback: () => {
                const _newState = { ...budgetCountyPractice };
                _newState.is_active = false;
                onChange && onChange(_newState);
            }
        })
    }

    return (
        <>
            <Card>
                <CardHeader
                    avatar={<InsuranceIcon />}
                    title={budgetCountyPractice?.commodity_name + " - " + formatPracticeName(budgetCountyPractice?.practice_name)}
                    subheader={budgetCountyPractice?.county_name + ', ' + budgetCountyPractice?.state_name}
                    action={null}
                />

                <CardContent>
                    <Box height="100%">
                        <Stack direction="column" spacing={1} height="100%">
                            <Stack direction='row' flexWrap='wrap' spacing={1}>
                                <Box>
                                    <Typography variant='h6'>
                                        {formatPrice(budgetCountyPractice?.totals?.producer_premium_amount_per_acre, 2)}
                                    </Typography>
                                    <Typography variant='body1' sx={{ fontSize: '8pt', leading: '0px', lineHeight: '9pt', height: '100%' }}>
                                        PRODUCER<br />
                                        PREMIUM /ac
                                    </Typography>
                                </Box>

                                <Divider orientation='vertical' flexItem />

                                <Box>
                                    <Typography variant='h6'>
                                        {formatPrice(budgetCountyPractice?.totals?.liability_amount_per_acre, 0)}
                                    </Typography>
                                    <Typography variant='body1' sx={{ fontSize: '8pt', leading: '0px', lineHeight: '9pt', height: '100%' }}>
                                        COVERAGE /ac
                                    </Typography>
                                </Box>
                            </Stack>

                        </Stack>
                    </Box>
                </CardContent>

                <CardActions>
                    <Box className="flexWrapContainer">
                        {budgetCountyPractice?.insurancePlans?.map((currentInsurancePlan, index) =>
                            <Chip key={index} label={
                                getComboTitle([currentInsurancePlan])
                            } />
                        )}
                    </Box>

                    <IconButton onClick={handleCardNavigateClick}>
                        <EastOutlined sx={{ color: 'black' }} />
                    </IconButton>
                </CardActions>
            </Card>

            {confirmDialogComponent}
        </>
    )
}
