import { useDialog } from '@datanac/datanac-mui-toolkit'
import { Grid } from '@mui/material'
import { AppContext } from 'App'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { useContext, useEffect } from 'react'

export default function EmptyWorkspace() {
    const { globalState, globalDispatch, session } = useContext(AppContext);

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();

    const loadData = async () => {
        // const budgets = await selectBudgets();
        // budgetActions.replace(budgets);
    }

    useEffect(() => {
        loadData();
    }, [globalState?.producer_token]);

    return (
        <>
            <WorkspaceHeader
                title={''}
                breadCrumbs={[]}
                actions={null}
            />

            <Grid container spacing={2}>
            </Grid>

            {confirmDialogComponent}
        </>
    )
}
