import { DOCUMENT_FORM_NAME_BALANCE_SHEET, DOCUMENT_FORM_NAME_BASE_ACRES, DOCUMENT_FORM_NAME_DEBT_SCHEDULE, DOCUMENT_FORM_NAME_EQUIPMENT_INVENTORY, DOCUMENT_FORM_NAME_FARMS, DOCUMENT_FORM_NAME_INCOME_STATEMENT, DOCUMENT_FORM_NAME_SCHEDULE_F } from '@datanac/datanac-api-toolkit';
import { CONTENT_TYPE_EXCEL_XLSX, CONTENT_TYPE_PDF } from 'api-toolkit/FilesHelper';
import { MODULE_FINANCIAL } from 'components/Menu/NavigationMenu';
import { v4 } from 'uuid';
import { CONTAINER_NAME_BALANCE_SHEET_ASSETS_CURRENT, CONTAINER_NAME_BALANCE_SHEET_ASSETS_NONCURRENT, CONTAINER_NAME_BALANCE_SHEET_EQUITY, CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_CURRENT, CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_NONCURRENT, ITEM_NAME_BALANCE_SHEET_ASSETS_ACCOUNTS_RECEIVABLE_CROPS, ITEM_NAME_BALANCE_SHEET_ASSETS_INVENTORIES_CROPS, ITEM_NAME_BALANCE_SHEET_ASSETS_NONCURRENT_OTHER, ITEM_NAME_BALANCE_SHEET_ASSETS_PREPAID_EXPENSES, ITEM_NAME_BALANCE_SHEET_ASSETS_REAL_ESTATE, ITEM_NAME_BALANCE_SHEET_EQUITY_OTHER, ITEM_NAME_BALANCE_SHEET_LIABILITIES_ACCOUNTS_PAYABLE_CROPS, ITEM_NAME_BALANCE_SHEET_LIABILITIES_REAL_ESTATE_DEBT, OBJECT_NAME_BALANCE_SHEET } from './forms/BalanceSheetHelper';
import { schedule_financial_statement_accounts_liquid, schedule_financial_statement_accounts_payable, schedule_financial_statement_accounts_receivable, schedule_financial_statement_equities, schedule_financial_statement_inventories, schedule_financial_statement_notes_receivable, schedule_financial_statement_prepaids, schedule_financial_statement_realestate } from './schedule_financial_statement/useFinancialStatementSchedule';

export const DOCUMENT_FORM_NAME_LOAN_ORIGINATION_MEMO = "Loan Origination Memo";
export const OBJECT_NAME_LOAN_ORIGINATION_MEMO = "loan_origination_memo";

export const DOCUMENT_FORM_NAME_INSURANCE_SCHEDULE = "Schedule of Insurance";
export const DOCUMENT_FORM_NAME_ACTUAL_PRODUCTION_HISTORY = "Actual Production History";

export const TEMPLATE_URL_PATH = "templates";

export const DOCUMENT_CATEGORY_FARMS = "farm production";
export const DOCUMENT_CATEGORY_FINANCIAL = "financials";
export const DOCUMENT_CATEGORY_INSURANCE = "crop insurance";
export const DOCUMENT_CATEGORY_TAX = "federal taxes";

export const FINANCIAL_DOCUMENTS = [
    // Farms
    {
        document_type_name: DOCUMENT_FORM_NAME_FARMS,
        title: DOCUMENT_FORM_NAME_FARMS,
        category: DOCUMENT_CATEGORY_FARMS,
        subHeader: "FSA 578",
        templateFileName: "Farms 578 - Template.xlsx",
        content_type: CONTENT_TYPE_EXCEL_XLSX,
    },
    {
        document_type_name: DOCUMENT_FORM_NAME_BASE_ACRES,
        title: DOCUMENT_FORM_NAME_BASE_ACRES,
        category: DOCUMENT_CATEGORY_FARMS,
        subHeader: "CCC 866",
        templateFileName: "Base Acres - Template.xlsx",
        content_type: CONTENT_TYPE_EXCEL_XLSX,
    },

    // Insurance:
    {
        document_type_name: DOCUMENT_FORM_NAME_INSURANCE_SCHEDULE,
        title: DOCUMENT_FORM_NAME_INSURANCE_SCHEDULE,
        category: DOCUMENT_CATEGORY_INSURANCE,
        subHeader: null,
        templateFileName: null,
        content_type: CONTENT_TYPE_PDF,
    },
    {
        document_type_name: DOCUMENT_FORM_NAME_ACTUAL_PRODUCTION_HISTORY,
        title: DOCUMENT_FORM_NAME_ACTUAL_PRODUCTION_HISTORY,
        category: DOCUMENT_CATEGORY_INSURANCE,
        subHeader: "APH Report",
        templateFileName: null,
        content_type: CONTENT_TYPE_PDF,
    },

    // Financial:
    {
        document_type_name: DOCUMENT_FORM_NAME_BALANCE_SHEET,
        title: DOCUMENT_FORM_NAME_BALANCE_SHEET,
        category: DOCUMENT_CATEGORY_FINANCIAL,
        subHeader: null,
        templateFileName: "Balance Sheet - Template.xlsx",
        content_type: CONTENT_TYPE_EXCEL_XLSX,
    },
    {
        document_type_name: DOCUMENT_FORM_NAME_INCOME_STATEMENT,
        title: DOCUMENT_FORM_NAME_INCOME_STATEMENT,
        category: DOCUMENT_CATEGORY_FINANCIAL,
        subHeader: null,
        templateFileName: "Income Statement - Template.xlsx",
        content_type: CONTENT_TYPE_EXCEL_XLSX,
    },
    {
        document_type_name: DOCUMENT_FORM_NAME_DEBT_SCHEDULE,
        title: DOCUMENT_FORM_NAME_DEBT_SCHEDULE,
        category: DOCUMENT_CATEGORY_FINANCIAL,
        subHeader: null,
        templateFileName: "Debt Schedule - Template.xlsx",
        content_type: CONTENT_TYPE_EXCEL_XLSX,
    },
    {
        document_type_name: DOCUMENT_FORM_NAME_EQUIPMENT_INVENTORY,
        title: DOCUMENT_FORM_NAME_EQUIPMENT_INVENTORY,
        category: DOCUMENT_CATEGORY_FINANCIAL,
        subHeader: null,
        templateFileName: "Equipment Inventory - Template.xlsx",
        content_type: CONTENT_TYPE_EXCEL_XLSX,
    },

    // Tax:
    {
        document_type_name: DOCUMENT_FORM_NAME_SCHEDULE_F,
        title: DOCUMENT_FORM_NAME_SCHEDULE_F,
        category: DOCUMENT_CATEGORY_TAX,
        subHeader: "Form 1040",
        templateFileName: "Schedule F - Template.xlsx",
        content_type: CONTENT_TYPE_EXCEL_XLSX,
    },

    // {
    //     document_type_name: "Crop Inventories",
    //     title: "Crop Inventories",
    //     subHeader: "Schedule 1",
    //     templateFileName: "CropInventories-Template.xlsx"
    // },

    // {
    //     document_type_name: "Livestock Inventories",
    //     title: "Livestock Inventories",
    //     subHeader: "Schedule 2",
    //     templateFileName: "LivestockInventories-Template.xlsx"
    // },

    // {
    //     document_type_name: "Equipment Inventories",
    //     title: "Equipment Inventories",
    //     subHeader: "Schedule 3",
    //     templateFileName: "MachineEquipmentInventories-Template.xlsx"
    // },

    // {
    //     document_type_name: "Real Estate Inventories",
    //     title: "Real Estate Inventories",
    //     subHeader: "Schedule 4",
    //     templateFileName: "RealEstateInventories-Template.xlsx"
    // },

    // {
    //     document_type_name: "Deferred Taxes",
    //     title: "Deferred Taxes",
    //     subHeader: "Schedule 6",
    //     templateFileName: "DeferredTaxes-Template.xlsx"
    // },
];

export const getBalanceSheetFromFinancialStatementScheduleAccounts = (_financialStatementScheduleAccounts) => {
    if (!_financialStatementScheduleAccounts?.length) return [];

    const _documentID = v4();

    const year = _financialStatementScheduleAccounts[0]?.year;
    const _financialDocument = {
        id: _documentID,
        year: year,
        document_type_name: DOCUMENT_FORM_NAME_BALANCE_SHEET,
        is_active: true
    }

    const _documentDictionaries = [];

    _financialStatementScheduleAccounts.forEach((account) => {
        /*
            {
                "id": "42402F30-2F84-455C-B48E-29C9FB0C8B08",
                "producer_token": "ABBEF606-C2F4-47BE-8F5E-D271D45AA634",
                "document_id": "53900C8D-64CE-4478-8B61-62A2745CB368",
                "module": "financial",
                "object": "balance_sheet",
                "container": "assets_current",
                "item": "cash",
                "value": "52208",
                "CreateDate": "2024-01-10T08:42:26.037",
                "LastUpdate": "2024-01-10T08:42:26.037"
            },
        */

        let currentAccountSmociContainer = null;
        let currentAccountSmociItem = null;

        if (account?.category == schedule_financial_statement_accounts_liquid) {
            _documentDictionaries.push({
                id: v4(),
                document_id: _documentID,
                module: MODULE_FINANCIAL,
                object: OBJECT_NAME_BALANCE_SHEET,
                container: CONTAINER_NAME_BALANCE_SHEET_ASSETS_CURRENT,
                item: ITEM_NAME_BALANCE_SHEET_ASSETS_ACCOUNTS_RECEIVABLE_CROPS,
                value: account?.value
            });

        } else if (account?.category == schedule_financial_statement_accounts_receivable) {
            _documentDictionaries.push({
                id: v4(),
                document_id: _documentID,
                module: MODULE_FINANCIAL,
                object: OBJECT_NAME_BALANCE_SHEET,
                container: CONTAINER_NAME_BALANCE_SHEET_ASSETS_CURRENT,
                item: ITEM_NAME_BALANCE_SHEET_ASSETS_ACCOUNTS_RECEIVABLE_CROPS,
                value: account?.value
            });

        } else if (account?.category == schedule_financial_statement_accounts_payable) {
            _documentDictionaries.push({
                id: v4(),
                document_id: _documentID,
                module: MODULE_FINANCIAL,
                object: OBJECT_NAME_BALANCE_SHEET,
                container: CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_CURRENT,
                item: ITEM_NAME_BALANCE_SHEET_LIABILITIES_ACCOUNTS_PAYABLE_CROPS,
                value: account?.value
            });

        } else if (account?.category == schedule_financial_statement_inventories) {
            _documentDictionaries.push({
                id: v4(),
                document_id: _documentID,
                module: MODULE_FINANCIAL,
                object: OBJECT_NAME_BALANCE_SHEET,
                container: CONTAINER_NAME_BALANCE_SHEET_ASSETS_NONCURRENT,
                item: ITEM_NAME_BALANCE_SHEET_ASSETS_INVENTORIES_CROPS,
                value: account?.value
            });

        } else if (account?.category == schedule_financial_statement_prepaids) {
            _documentDictionaries.push({
                id: v4(),
                document_id: _documentID,
                module: MODULE_FINANCIAL,
                object: OBJECT_NAME_BALANCE_SHEET,
                container: CONTAINER_NAME_BALANCE_SHEET_ASSETS_CURRENT,
                item: ITEM_NAME_BALANCE_SHEET_ASSETS_PREPAID_EXPENSES,
                value: account?.value
            });

        } else if (account?.category == schedule_financial_statement_notes_receivable) {
            _documentDictionaries.push({
                id: v4(),
                document_id: _documentID,
                module: MODULE_FINANCIAL,
                object: OBJECT_NAME_BALANCE_SHEET,
                container: CONTAINER_NAME_BALANCE_SHEET_ASSETS_NONCURRENT,
                item: ITEM_NAME_BALANCE_SHEET_ASSETS_NONCURRENT_OTHER,
                value: account?.value
            });

        } else if (account?.category == schedule_financial_statement_equities) {
            _documentDictionaries.push({
                id: v4(),
                document_id: _documentID,
                module: MODULE_FINANCIAL,
                object: OBJECT_NAME_BALANCE_SHEET,
                container: CONTAINER_NAME_BALANCE_SHEET_ASSETS_NONCURRENT,
                item: ITEM_NAME_BALANCE_SHEET_ASSETS_NONCURRENT_OTHER,
                value: account?.value
            });

        } else if (account?.category == schedule_financial_statement_realestate) {
            _documentDictionaries.push({
                id: v4(),
                document_id: _documentID,
                module: MODULE_FINANCIAL,
                object: OBJECT_NAME_BALANCE_SHEET,
                container: CONTAINER_NAME_BALANCE_SHEET_ASSETS_NONCURRENT,
                item: ITEM_NAME_BALANCE_SHEET_ASSETS_REAL_ESTATE,
                value: account?.value
            });

            _documentDictionaries.push({
                id: v4(),
                document_id: _documentID,
                module: MODULE_FINANCIAL,
                object: OBJECT_NAME_BALANCE_SHEET,
                container: CONTAINER_NAME_BALANCE_SHEET_LIABILITIES_NONCURRENT,
                item: ITEM_NAME_BALANCE_SHEET_LIABILITIES_REAL_ESTATE_DEBT,
                value: account?.value * 0.50
            });
        }
    });

    // Add equity:
    CONTAINER_NAME_BALANCE_SHEET_EQUITY

    _documentDictionaries.push({
        id: v4(),
        document_id: _documentID,
        module: MODULE_FINANCIAL,
        object: OBJECT_NAME_BALANCE_SHEET,
        container: CONTAINER_NAME_BALANCE_SHEET_EQUITY,
        item: ITEM_NAME_BALANCE_SHEET_EQUITY_OTHER,
        value: 2719012
    });

    return _documentDictionaries;
}
