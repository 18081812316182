import { getCurrentReinsuranceYear } from "@datanac/datanac-api-toolkit/dist/utility/InsurancePlanHelper";

export const BUDGET_STATUS_ACTIVE = "active";
export const BUDGET_STATUS_DRAFT = "draft";

export const BUDGET_CATEGORY_DIRECT = "direct";
export const BUDGET_CATEGORY_INDIRECT = "indirect";

export const getBudgetYears = () => {
    const reinsuranceYear = getCurrentReinsuranceYear();
    return [
        reinsuranceYear - 5,
        reinsuranceYear - 4,
        reinsuranceYear - 3,
        reinsuranceYear - 2,
        reinsuranceYear - 1,
        reinsuranceYear,
        reinsuranceYear + 1,
        reinsuranceYear + 2,
    ];
}

export const getHistoryYears = () => {
    const reinsuranceYear = getCurrentReinsuranceYear();
    return [
        reinsuranceYear - 4,
        reinsuranceYear - 3,
        reinsuranceYear - 2,
        reinsuranceYear - 1,
        reinsuranceYear,
        reinsuranceYear + 1,
        reinsuranceYear + 2,
    ];
}

export const BUDGET_LINEITEM_NAME_INTEREST = "Interest";

export const BUDGET_ITEMS_DIRECT = [
    ["seed", "Seed"],
    ["irrigation", "Irrigation"],
    ["labor", "Labor"],
    ["custom_farm_work", "Custom Work"],
    ["repairs_maintenance", "Repairs"],
    ["fuel", "Fuel"],
    ["chemical_insecticide_herbicide", "Chemicals"],
    ["fertilizer", "Fertilizer"],
    ["crop_insurance", "Crop Insurance"],
];

export const BUDGET_ITEMS_INDIRECT = [
    ["cash_rent", "Cash Rent"],
    ["equipment_machinery", "Equipment"],
    ["interest_estimated", "Interest"],
    ["miscellaneous", "Misc."]
];

export const BUDGET_LINEITEMS = [
    ...BUDGET_ITEMS_DIRECT,
    ...BUDGET_ITEMS_INDIRECT,
]