import { useDialog } from '@datanac/datanac-mui-toolkit/dist/hooks'
import DeleteForeverOutlined from '@mui/icons-material/DeleteForeverOutlined'
import EastOutlined from '@mui/icons-material/EastOutlined'
import { Box, Card, CardActions, CardContent, CardHeader, Chip, Divider, IconButton, Stack, Typography } from '@mui/material'
import { FarmIcon } from 'components/Icons/Icons'
import { useNavigate, useParams } from 'react-router-dom'
import { formatNumber, formatPracticeName } from 'utility/formatters'
import { CROP_YEAR_POTENTIAL } from '../helpers'

export default function ProducerFarmCropCard({
    currentProducerFarmCrop,
    onChange
}) {
    const navigate = useNavigate();
    const {
        crop_year,
        location_state_name,
        location_county_name
    } = useParams();

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const handleDeleteOnClick = () => {
        confirmDialogActions.confirm({
            title: "Delete this entry?",
            prompt: "Are you sure you want to delete this farm?",
            promptDetail: "Warning: deleting a farm removes crops from ALL YEARS.",
            callback: () => {
                const _newState = { ...currentProducerFarmCrop };
                _newState.is_active = false;
                onChange && onChange(_newState);
            }
        })
    }

    return (
        <>
            <Card>
                <CardHeader
                    avatar={<FarmIcon />}
                    title={currentProducerFarmCrop?.farm_name}
                    subheader={"FSN " + currentProducerFarmCrop?.fsa_farm_number}
                    action={(crop_year == CROP_YEAR_POTENTIAL) &&
                        <IconButton onClick={handleDeleteOnClick}>
                            <DeleteForeverOutlined sx={{ color: 'black' }} />
                        </IconButton>}
                />
                <CardContent>
                    <Box height="100%">
                        <Stack direction="column" spacing={1} height="100%">
                            <Stack direction='row' spacing={1} alignItems='center' justifyItems='center'>
                                <Typography variant='h6'>
                                    {formatNumber(currentProducerFarmCrop?.reported_acreage, 0)}
                                </Typography>
                                <Typography variant='body1' sx={{ fontSize: '8pt', leading: '0px', lineHeight: '9pt', height: '100%' }}>
                                    ACRES
                                </Typography>

                                <Divider orientation='vertical' flexItem />

                                <Typography variant='h6'>
                                    {formatNumber(currentProducerFarmCrop?.base_acreage, 0)}
                                </Typography>
                                <Typography variant='body1' sx={{ fontSize: '8pt', leading: '0px', lineHeight: '9pt', height: '100%' }}>
                                    BASE<br />
                                    ACRES
                                </Typography>
                            </Stack>
                        </Stack>
                    </Box>
                </CardContent>

                <CardActions>
                    <Stack direction='row' spacing={0} alignItems='center' justifyItems='center'
                        sx={{ flexWrap: 'wrap', gap: 1 }}
                    >
                        {currentProducerFarmCrop?.CropProfiles?.map((currentCropProfile, index) =>
                            <Chip key={index} label={currentCropProfile?.commodity_name + ' ' + formatPracticeName(currentCropProfile?.practice_name)} />
                        )}
                    </Stack>

                    <IconButton onClick={() => { navigate(`/vault/${crop_year}/${location_state_name}/${location_county_name}/${currentProducerFarmCrop?.id}`) }}>
                        <EastOutlined />
                    </IconButton>
                </CardActions>
            </Card>

            {confirmDialogComponent}
        </>
    )
}
