import { AppContext } from 'App';
import MUIDataTable from 'mui-datatables';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDate } from 'utility/formatters';

export const MEMBER_ACTION_MANAGE_ACCOUNT = "manage_account";

export default function AgentUserGrantDataTable({
    userGrants,

    onItemAction,
    onItemSelect
}) {

    const navigate = useNavigate();
    const { globalState, globalDispatch } = useContext(AppContext);

    const COLUMNS_MEMBERS = [
        {
            name: 'producer_description',
            label: 'Name',
            flex: 1
        },
        {
            name: 'producer_email',
            label: 'Email',
            flex: 1
        },
        {
            name: 'lastupdate',
            label: 'Last Updated',
            flex: 0,
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return formatDate(value);
                },
            },
        },
    ];

    const options = {
        rowsPerPage: 10,
        rowsPerPageOptions: [5, 10, 25, 50, 100],
        selectableRows: "none",

        fixedSelectColumn: false,

        responsive: 'simple',

        expandableRows: false,

        download: false,
        filter: false,
        print: false,
        search: false,
        viewColumns: false,
    }

    return (
        <div className='MUI-Data-Table'>
            <MUIDataTable
                data={userGrants || []}
                columns={COLUMNS_MEMBERS}
                options={options}
                sx={{ width: "99%", boxSizing: "border-box" }}
                autoHeight
            />
        </div>
    );
}
