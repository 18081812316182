import SearchOutlined from '@mui/icons-material/SearchOutlined'
import { InputAdornment, List, Stack, Tooltip, useMediaQuery } from '@mui/material'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { AppContext } from 'App'
import { StatefulControlledTextField } from 'components/Core/StatefulControlledTextField'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { USER_ROLE_ADMIN, USER_ROLE_AGENT } from 'core/useSession'
import { uniqBy } from 'lodash'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AipPassApiHelper } from '../../api/ApiHelper'
import UserGrantItem from './components/UserGrantItem'

export default function UserGrantListWorkspace({
}) {
    const { globalState, globalDispatch } = useContext(AppContext);

    const navigate = useNavigate();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [searchText, setSearchText] = useState('');
    const handleSearchOnChange = (event) => {
        setSearchText(event.target.value);
    }

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    const [producers, setProducers] = useState([]);
    const loadProducerSearch = async () => {
        const __authTokens = { ...globalState };
        if (globalState.user_role == USER_ROLE_ADMIN) {
            delete __authTokens.agent_token;
        }
        delete __authTokens.producer_token;

        let _producersLastName = AipPassApiHelper.pass_selectObject("ProducerTokens", { last_name: searchText }, __authTokens);
        let _producersBusinessName = AipPassApiHelper.pass_selectObject("ProducerTokens", { business_name: searchText }, __authTokens);

        const _producers = [...(await _producersLastName), ...(await _producersBusinessName)];
        const _uniqueProducers = uniqBy(_producers, p => p.producer_token);

        setProducers(_uniqueProducers);
    };

    const loadAllProducers = async () => {
        let _producers = await AipPassApiHelper.pass_selectObject("ProducerTokens", { agent_token: globalState.agent_token }, globalState);
        _producers = uniqBy(_producers, pfc => (
            JSON.stringify({ producer_token: pfc.producer_token })
        ));

        setProducers(_producers);
    };

    const loadData = async () => {
        try {
            if (searchText) {
                await loadProducerSearch();
            } else {
                if (globalState.user_role == USER_ROLE_AGENT || globalState.producer_token) {
                    await loadAllProducers();
                }
            }
        } catch (err) {
            setProducers([]);

            handleError(err);
        }
    }

    useEffect(() => {
        loadData();
    }, [searchText, globalState.user_role, globalState.agent_token, globalState.producer_token])

    const handleGrantItemOnClick = (currentProducer) => {
        navigate(`./${currentProducer?.producer_token}`)
    }

    return (
        <>
            <WorkspaceHeader
                title='Security'
                breadCrumbs={[]}
            />

            <Stack spacing={3}>
                <div>
                    <Typography variant='h5'>
                        Search for a Producer:
                    </Typography>
                    <StatefulControlledTextField
                        onChange={handleSearchOnChange}
                        defaultValue=''
                        fullWidth={isSmall}
                        sx={{ width: '3.5in' }}
                        endAdornment={
                            <Tooltip title='Search'>
                                <InputAdornment position='end'>
                                    <IconButton edge="end" color="primary">
                                        <SearchOutlined />
                                    </IconButton>
                                </InputAdornment>
                            </Tooltip>
                        } />
                </div>
                <div>
                    <Divider />
                </div>
                <div>
                    <List dense sx={{ p: 0 }}>
                        {producers.map(p => (
                            <UserGrantItem producer={p} key={p.producer_token} onClick={handleGrantItemOnClick} />
                        ))}
                    </List>
                </div>
            </Stack>
        </>
    )
}
