import { calculate_cecl_from_stresstest } from '@datanac/datanac-api-toolkit/dist/insurance/stress-test/cecl'
import { calculateStressTest } from '@datanac/datanac-api-toolkit/dist/insurance/stress-test/crop/crop_stress_test'
import { useDialog } from '@datanac/datanac-mui-toolkit'
import { SummaryOfBusinessCauseOfLossChart } from '@datanac/datanac-visualization-toolkit'
import { DatesChart } from '@datanac/datanac-visualization-toolkit/dist/Components/rma-adm/DatesChart'
import { YieldVarianceChart } from '@datanac/datanac-visualization-toolkit/dist/Components/rma-adm/YieldVarianceChart'
import AddCircleOutline from '@mui/icons-material/AddCircleOutline'
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import CalculateOutlined from '@mui/icons-material/CalculateOutlined'
import CalendarMonthOutlined from '@mui/icons-material/CalendarMonthOutlined'
import CheckOutlined from '@mui/icons-material/CheckOutlined'
import CloseOutlined from '@mui/icons-material/CloseOutlined'
import EastOutlined from '@mui/icons-material/EastOutlined'
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Icon, IconButton, Menu, MenuItem, Skeleton, Stack, Typography, useMediaQuery } from '@mui/material'
import { AppContext } from 'App'
import { ApiHelper, RmaApiHelper, UsersApiHelper } from 'api/ApiHelper'
import { BUDGET_STATUS_ACTIVE } from 'components/Budget/helpers'
import EmptyCard from 'components/Cards/EmptyCard'
import { BudgetIcon, InsuranceIcon } from 'components/Icons/Icons'
import InsuranceOptimizeDataGrid from 'components/InsuranceOptimizeDataGrid'
import { getUniqueCounties } from 'components/Vault/Farm/helpers'
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import WorkspaceViewSelector from 'components/Workspaces/WorkspaceModeSelector'
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from 'components/Workspaces/helpers'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { SkeletonWrapper } from 'mui-toolkit/SkeletonWrapper'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { formatNumber, formatPercentage, formatPrice } from 'utility/formatters'
import { v4 as uuidv4 } from 'uuid'
import { InsuranceService } from './InsuranceService'
import { AddInsuranceDialog } from './components/AddInsuranceDialog'
import InsurancePlanCard from './components/InsurancePlanCard'
import InsuranceStressTestParametersDialog from './components/InsuranceStressTestParametersDialog'
import { OptimizeInsuranceDialog } from './components/OptimizeInsuranceDialog'
import { STRESS_TEST_FIELD_INDEMNITY_TOTAL_PER_ACRE, STRESS_TEST_FIELD_NET_INSURANCE_BENEFIT_PER_ACRE, STRESS_TEST_FIELD_NET_PROFIT_INSURED, STRESS_TEST_FIELD_NET_PROFIT_SIMPLE, calculateStressTestDisplayFieldLabel } from './helpers'
import { INSURANCE_PLAN_NAME_SCO_RP, INSURANCE_PLAN_NAME_SCO_RP_HPE, INSURANCE_PLAN_NAME_SCO_YP } from '@datanac/datanac-api-toolkit/dist/datanac-rma-api-helper'
import { ArcPlcService } from 'components/ArcPlc/ArcPlcService'
import { set } from 'lodash'


export default function InsuranceScenarioWorkspace() {

    const { globalState, globalDispatch } = useContext(AppContext);
    const {
        insurance_scenario_id,
        state_name, county_name,
        commodity_name, type_name, practice_name
    } = useParams();
    const navigate = useNavigate();

    const isXXL = useMediaQuery('(min-width:1537px)');
    const isXL = useMediaQuery((theme) => theme.breakpoints.up('xl'), { noSsr: true });
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const isXsSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'), { noSsr: true });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [workspaceMode, setWorkspaceMode] = useState(isSmall ? WORKSPACE_VIEW_CARD : WORKSPACE_VIEW_TABLE);

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();

    const [isInsuranceDatesDialogOpen, setIsInsuranceDatesDialogOpen] = useState(false);

    // --- --- ---

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    const [insurancePlanTimestamp, setInsurancePlanTimestamp] = useState(uuidv4())
    const [insurancePlans, setInsurancePlans] = useState([]);

    // --- --- ---

    const [insuranceScenario, setInsuranceScenario] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const loadInsuranceScenario = async () => {
        try {
            setIsLoading(true);

            const producerFilter = {
                producer_token: globalState.producer_token,
                id: insurance_scenario_id,
                is_active: true
            };
            const _insuranceScenario = await UsersApiHelper.users_selectObject("InsuranceScenario", producerFilter, globalState);

            if (_insuranceScenario?.length) {
                setInsuranceScenario(_insuranceScenario[0]);
            }

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setInsuranceScenario({});
            handleError(err);
        }
    }

    useEffect(() => {
        if (globalState?.producer_token) {
            loadInsuranceScenario();
        }
    }, [globalState?.producer_token]);

    // --- --- ---

    const [budgetCountyPractice, setBudgetCountyPractice] = useState(null);
    const [isLoadingBudgetCountyPractice, setIsLoadingBudgetCountyPractice] = useState(true);
    const loadBudgetCountyPractice = async () => {
        try {
            const _budgetFilter = {
                state_name, county_name,
                commodity_name, type_name, practice_name,
                crop_year: insuranceScenario?.crop_year,
                year: insuranceScenario?.crop_year,

                status: BUDGET_STATUS_ACTIVE,
                is_active: true,
            }
            const _budgets = await UsersApiHelper.selectBudgetCountyPractices(_budgetFilter, globalState);

            const _cropFilter = {
                crop_year: insuranceScenario?.crop_year,
                location_state_name: state_name, location_county_name: county_name,
                commodity_name, type_name, practice_name,
                is_active: true,
            };
            const _crops = await UsersApiHelper.selectProducerFarmFieldCrops(_cropFilter, globalState);
            const _cropsCountyPractice = getUniqueCounties(_crops);

            if (_budgets?.length) {
                const _activeBudgetCountyPractice = _budgets[0];

                _activeBudgetCountyPractice.reinsurance_year = _activeBudgetCountyPractice?.year;

                _activeBudgetCountyPractice.production_cost_per_acre = _activeBudgetCountyPractice?.reported_acreage > 0 &&
                    _activeBudgetCountyPractice?.production_cost / _activeBudgetCountyPractice?.reported_acreage;

                if (_cropsCountyPractice?.length) {
                    const _currentCropCountyPractice = _cropsCountyPractice[0];

                    _activeBudgetCountyPractice.approved_yield = _currentCropCountyPractice.approved_yield_weighted;
                    _activeBudgetCountyPractice.rate_yield = _currentCropCountyPractice.rate_yield_weighted;
                    _activeBudgetCountyPractice.adjusted_yield = _currentCropCountyPractice.adjusted_yield_weighted;
                    _activeBudgetCountyPractice.share_percent = _currentCropCountyPractice.share_percent_weighted;
                }

                setBudgetCountyPractice(_activeBudgetCountyPractice);
            }

            setIsLoadingBudgetCountyPractice(false);
        } catch (err) {
            setIsLoadingBudgetCountyPractice(false);
            setBudgetCountyPractice(null);
            handleError(err);
        }
    }

    useEffect(() => {
        if (insuranceScenario) {
            loadBudgetCountyPractice();
        }
    }, [insuranceScenario, globalState?.producer_token])

    const handleBudgetNavigateClick = () => {
        if (budgetCountyPractice) {
            navigate(`/budget/${budgetCountyPractice?.budget_id}/${budgetCountyPractice?.id}`)
        }
    }

    // --- --- ---

    const [producerFarmFieldCrops, setProducerFarmFieldCrops] = useState(null);
    const loadProducerFarmFieldCrops = async () => {
        const _cropFilter = {
            crop_year: budgetCountyPractice?.year,
            location_state_name: state_name,
            location_county_name: county_name,
            commodity_name, type_name, practice_name,
            is_active: true,
        };
        const _crops = await UsersApiHelper.selectProducerFarmFieldCrops(_cropFilter, globalState);

        if (_crops?.length) {
            setProducerFarmFieldCrops(_crops);
        }
    }

    useEffect(() => {
        if (budgetCountyPractice) {
            loadProducerFarmFieldCrops();
        }
    }, [globalState?.producer_token, budgetCountyPractice])

    // --- --- ---

    const [countyData, setCountyData] = useState(null);
    const loadCountyData = async () => {
        let _currentCounty = {
            reinsurance_year: budgetCountyPractice?.year,
            state_name,
            county_name,
            commodity_name,
            type_name,
            practice_name
        };

        const currentPrices = await RmaApiHelper.selectPrices(_currentCounty);
        const currentCountyData = await RmaApiHelper.selectCountyData(_currentCounty);
        const currentCounty = {
            ...(currentPrices && currentPrices.length && currentPrices[0]),
            ..._currentCounty,
            reinsurance_year: budgetCountyPractice?.year,
            expected_index_value: currentCountyData?.length && currentCountyData[0].expected_index_value
        }

        setCountyData(currentCounty);
    }

    useEffect(() => {
        if (budgetCountyPractice) {
            loadCountyData();
        }
    }, [globalState?.producer_token, budgetCountyPractice])

    // --- --- ---

    const [isLoadingInsurancePlans, setIsLoadingInsurancePlans] = useState(true);
    const loadInsurancePlans = async () => {
        try {
            setIsLoadingInsurancePlans(true);
            setInsurancePlanTimestamp(uuidv4());

            const _InsuranceService = new InsuranceService({
                insuranceScenarios: [insuranceScenario].filter(is => is),
                budgetCountyPractices: [budgetCountyPractice].filter(cp => cp),
                crop_year: insuranceScenario.crop_year,
                apiConfig: globalState
            });
            const _insuranceScenarioPopulated = await _InsuranceService.actions.loadInsuranceScenario();

            if (_insuranceScenarioPopulated?.length
                && _insuranceScenarioPopulated[0].budgetCountyPractices?.length
                && _insuranceScenarioPopulated[0].budgetCountyPractices[0]?.insurancePlans?.length
            ) {
                setInsurancePlans(_insuranceScenarioPopulated[0].budgetCountyPractices[0]?.insurancePlans);
                setInsurancePlanTimestamp(uuidv4());

                _InsuranceService.actions.calculatePremiums(_insuranceScenarioPopulated[0])
                    .then((calculatePremiumsResponseInsuranceScenario) => {
                        setInsurancePlans(calculatePremiumsResponseInsuranceScenario?.budgetCountyPractices[0]?.insurancePlans);
                        setInsurancePlanTimestamp(uuidv4());
                        setIsLoadingInsurancePlans(false);
                    })
            } else {
                setInsurancePlans([]);
                setInsurancePlanTimestamp(uuidv4());
                setIsLoadingInsurancePlans(false);
            }
        } catch (err) {
            setIsLoadingInsurancePlans(false);
            setInsurancePlans(null);
            setInsurancePlanTimestamp(uuidv4());
            handleError(err);
        }
    }

    useEffect(() => {
        if (globalState?.producer_token && insuranceScenario && budgetCountyPractice) {
            loadInsurancePlans();
        } else {
            setInsurancePlans([]);
            setInsurancePlanTimestamp(uuidv4());
        }
    }, [globalState?.producer_token, budgetCountyPractice, insuranceScenario]);

    // --- --- ---

    const [stressTestDisplayField, setStressTestDisplayField] = useState(STRESS_TEST_FIELD_NET_PROFIT_INSURED);
    const [isStressTestOptionsDialogOpen, setIsStressTestOptionsDialogOpen] = useState(false);

    const [stressTestParameters, setStressTestParameters] = useState(null);
    const [stressTestResults, setStressTestResults] = useState(null);
    const [isLoadingStressTestResults, setIsLoadingStressTestResults] = useState(true);
    const loadStressTestResults = async () => {
        try {
            const _stressTestParameters = stressTestParameters ? stressTestParameters
                : (await loadStressTestParameters());

            const props = {
                farmParameters: budgetCountyPractice,
                countyParameters: countyData,
                insurancePlanParameters: insurancePlans,
                testParameters: _stressTestParameters,
                apiHelper: ApiHelper
            };

            // console.log("stressTest props", props);
            const _currentStressTestResults = await calculateStressTest(props);

            if (_currentStressTestResults) {
                _currentStressTestResults.cecl = calculate_cecl_from_stresstest({
                    stressTestResults: _currentStressTestResults.results
                });

                // console.log("cecl", _currentStressTestResults?.cecl);

                setStressTestResults(_currentStressTestResults);
            }

            setIsLoadingStressTestResults(false);
        } catch (err) {
            setIsLoadingStressTestResults(false);
            setStressTestResults([]);
            handleError(err);
        }
    }

    // --- --- ---
    // ARC-CO Comparison

    const [baseAcres, setBaseAcres] = useState(null);
    const [isLoadingBaseAcres, setIsLoadingBaseAcres] = useState(true);
    const loadBaseAcres = async () => {
        try {
            setIsLoadingBaseAcres(true);

            const baseAcresFilter = {
                producer_token: globalState.producer_token,
                location_state_name: state_name,
                location_county_name: county_name,
                commodity_name,
                // type_name, practice_name,
                crop_year: insuranceScenario?.crop_year,
                is_active: true
            };
            const _baseAcres = await UsersApiHelper.users_search("ProducerFarmBaseAcres", baseAcresFilter, globalState);
            if (_baseAcres?.length) {
                setBaseAcres(_baseAcres);
            } else {
                setBaseAcres(null);
            }

            setIsLoadingBaseAcres(false);
        } catch (err) {
            setBaseAcres(null);
            setIsLoadingBaseAcres(false);
            handleError(err);
        }
    }

    useEffect(() => {
        if (globalState?.producer_token && insuranceScenario) {
            loadBaseAcres();
        }
    }, [globalState?.producer_token, insuranceScenario])


    const INSURANCE_PLAN_UUID_ARCCO = "arc-co";
    const INSURANCE_PLAN_NAME_ARCCO = "ARC County";

    const [arcCoStressTestResults, setArcCoStressTestResults] = useState(null);
    const [isLoadingArcCoStressTestResults, setIsLoadingArcCoStressTestResults] = useState(true);
    const loadARCCOStressTestResults = async () => {
        const _arccoStressTestResults = JSON.parse(JSON.stringify(stressTestResults));

        // Find SCO UUID
        const _scoInsurancePlan = _arccoStressTestResults.insurancePlans?.find(p =>
            p.insurance_plan_name == INSURANCE_PLAN_NAME_SCO_RP
            || p.insurance_plan_name == INSURANCE_PLAN_NAME_SCO_RP_HPE
            || p.insurance_plan_name == INSURANCE_PLAN_NAME_SCO_YP);

        _scoInsurancePlan.insurance_plan_name = INSURANCE_PLAN_NAME_ARCCO;

        const { actions: arcPlcActions } = new ArcPlcService({
            apiConfig: globalState,

            crop_year: insuranceScenario?.crop_year,
            location_state_name: state_name,
            location_county_name: county_name,

            baseAcres: baseAcres,
        });

        // ArcPlcService works with multiple crops:
        const _arccoYieldsAll = await arcPlcActions.getARCCOYields();
        const arccoYields = _arccoYieldsAll?.length
            ? _arccoYieldsAll?.find(y => y.commodity_name?.toLowerCase() == commodity_name.toLowerCase())
            : null;
        const _plcPaymentRatesAll = await arcPlcActions.getPLCPaymentRates();
        const plcPaymentRates = _plcPaymentRatesAll?.length
            ? _plcPaymentRatesAll?.find(p => p.commodity_name.toLowerCase() == commodity_name.toLowerCase())
            : null;

        for (var _col of _arccoStressTestResults?.results) {
            for (var _cell of _col) {
                const _indemnityResultsIndex = _cell.indemnityResults.findIndex(res =>
                    res.insurance_plan_uuid == _scoInsurancePlan.uuid
                );
                // const _currentIndemnityResults = _cell.indemnityResults[_indemnityResultsIndex];

                const stressTestPrice = _cell.price;
                const stressTestYield = _cell.yield;

                const _arcPlcResponses = await arcPlcActions.getArcPlc({
                    plcPaymentRates: plcPaymentRates,
                    arccoYields: arccoYields,
                    stressTestParameters: {
                        actual_revenue: stressTestPrice * stressTestYield,
                        effective_price: stressTestPrice,
                    }
                });
                const totalFarmPaymentArcCo = _arcPlcResponses?.arcPlcResponses?.length
                    ? _arcPlcResponses?.arcPlcResponses[0].arcPayment
                    : 0;

                // Set props individually instead
                _cell.indemnityResults[_indemnityResultsIndex].insurance_plan_name = INSURANCE_PLAN_NAME_ARCCO;
                _cell.indemnityResults[_indemnityResultsIndex].producer_premium_amount = 0;

                _cell.indemnityResults[_indemnityResultsIndex].indemnity_total = totalFarmPaymentArcCo;
                _cell.indemnityResults[_indemnityResultsIndex].indemnity_total_per_acre = _cell.reported_acreage
                    ? totalFarmPaymentArcCo / _cell.reported_acreage
                    : 0;
                _cell.indemnityResults[_indemnityResultsIndex].net_insurance_benefit = _cell.indemnityResults[_indemnityResultsIndex].indemnity_total;
                _cell.indemnityResults[_indemnityResultsIndex].net_insurance_benefit_per_acre = _cell.indemnityResults[_indemnityResultsIndex].indemnity_total_per_acre;

                _cell.indemnity_total = _cell.indemnityResults.reduce((acc, res) => acc + res.indemnity_total, 0);
                _cell.indemnity_total_per_acre = _cell.reported_acreage
                    ? _cell.indemnity_total / _cell.reported_acreage
                    : 0;

                _cell.net_insurance_benefit = _cell.indemnity_total;
                _cell.net_insurance_benefit_per_acre = _cell.reported_acreage
                    ? _cell.net_insurance_benefit / _cell.reported_acreage
                    : 0;

                // Revenue and Net Profit are reported per acre already.
                _cell.revenue_insured = _cell.revenue_simple + _cell.indemnity_total_per_acre;
                _cell.net_profit_insured = _cell.revenue_insured - _cell.production_cost_per_acre;
            };
        };

        setArcCoStressTestResults(_arccoStressTestResults);
        setIsLoadingArcCoStressTestResults(false);
    };

    useMemo(() => {
        if (baseAcres && stressTestResults && insuranceScenario) {
            // Find SCO:
            const _scoInsurancePlan = stressTestResults.insurancePlans?.find(p =>
                p.insurance_plan_name == INSURANCE_PLAN_NAME_SCO_RP
                || p.insurance_plan_name == INSURANCE_PLAN_NAME_SCO_RP_HPE
                || p.insurance_plan_name == INSURANCE_PLAN_NAME_SCO_YP);

            if (_scoInsurancePlan) {
                loadARCCOStressTestResults();
            } else {
                setArcCoStressTestResults(null);
            }
        }
    }, [stressTestResults, baseAcres, insuranceScenario]);

    // ---

    const loadStressTestParameters = async () => {
        const filter = {
            producer_token: globalState.producer_token,
            insurance_scenario_id,
            state_name,
            county_name,
            commodity_name,
            type_name,
            practice_name,
            is_active: true
        };
        const _stressTestParameters = await UsersApiHelper.users_selectObject("InsuranceStressTestParameters", filter, globalState);
        if (_stressTestParameters?.length) {
            setStressTestParameters(_stressTestParameters[0]);
            return _stressTestParameters[0];
        }
    }

    useEffect(() => {
        if (globalState?.producer_token && budgetCountyPractice && countyData && insurancePlans?.length) {
            if (!isLoadingInsurancePlans) {
                loadStressTestResults();
            } else {
                setStressTestResults([]);
            }
        }
    }, [globalState?.producer_token, budgetCountyPractice, countyData, isLoadingInsurancePlans, insurancePlanTimestamp, stressTestParameters]);

    const handleStressTestMenuClick = async (_stressTestDisplayField, popupState) => {
        setStressTestDisplayField(_stressTestDisplayField);
        popupState?.close && popupState.close();
    }

    const handleStressTestOptionsDialogOpenClick = async () => {
        setIsStressTestOptionsDialogOpen(true);
    }

    const handleStressTestParametersChange = async (_stressTestParameters) => {
        if (!_stressTestParameters?.id) {
            _stressTestParameters.insurance_scenario_id = insurance_scenario_id;
            _stressTestParameters.id = uuidv4();
            _stressTestParameters.is_active = true;
        }

        setStressTestParameters(_stressTestParameters);

        UsersApiHelper.updateInsuranceStressTestParameters(_stressTestParameters, globalState);
    }

    // --- --- ---

    const [isOptimizeDialogOpen, setIsOptimizeDialogOpen] = useState(false);

    const handleInsurancePlanCardChange = async (_insurancePlan) => {
        if (_insurancePlan) {
            _insurancePlan.insurance_scenario_id = insurance_scenario_id;
            await UsersApiHelper.updateInsurancePlans({ InsurancePlans: [_insurancePlan] }, globalState);
            loadInsurancePlans();
        }
    }

    const handleOptimizerOnChange = async (_insurancePlans) => {
        handleAddPlanSelected(_insurancePlans);
    }

    const handleAddPlanSelected = async (_newInsurancePlans) => {
        if (insurancePlans?.length) {
            insurancePlans?.forEach(p => {
                p.is_active = false;
            })
        }

        _newInsurancePlans?.forEach(p => {
            p.id = p.id || uuidv4();
            p.reinsurance_year = insuranceScenario?.crop_year;
            p.insurance_scenario_id = insurance_scenario_id;

            p.state_name = budgetCountyPractice?.state_name;
            p.county_name = budgetCountyPractice?.county_name;
            p.commodity_name = budgetCountyPractice?.commodity_name;
            p.type_name = budgetCountyPractice?.type_name;
            p.practice_name = budgetCountyPractice?.practice_name;

            p.is_active = true;
        });

        try {
            await UsersApiHelper.updateInsurancePlans({
                InsurancePlans: [
                    ...insurancePlans,
                    ..._newInsurancePlans
                ]
            }, globalState);
        } catch (err) {
            handleError(err);
        }

        loadInsurancePlans();
    }

    // --- --- ---

    const [isAddInsuranceDialogOpen, setIsAddInsuranceDialogOpen] = useState(false);

    // --- --- ---

    const calculateBreakeven = (_loanAmount) => {
        return (_loanAmount / budgetCountyPractice?.reported_acreage) / budgetCountyPractice?.yield;
    }

    return (
        <>
            <WorkspaceHeader
                title={<>
                    {commodity_name} - {type_name == "No Type Specified" ? "" : type_name + " - "} {practice_name}
                </>}
                subHeader={<>
                    {county_name + ", " + state_name}
                </>}
                breadCrumbs={[
                    {
                        title: 'Insurance',
                        path: '/insurance',
                        icon: <InsuranceIcon />
                    },
                    {
                        title: insuranceScenario?.name || "Scenario",
                        path: `/insurance/${insurance_scenario_id}`,
                        icon: null
                    },
                ]}
            />

            <Box className="flexWrapHeaderContainer">
                <Box display="block">
                    <Typography variant='h1'>
                        Crop Information
                    </Typography>
                    <Box className="flexWrapContainer">
                        <Stack direction="column">
                            <SkeletonWrapper isLoading={isLoadingBudgetCountyPractice}>
                                <Typography variant='h5'>
                                    {formatNumber(budgetCountyPractice?.approved_yield, 0)}
                                </Typography>
                                <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Approved Yield (APH)<br />Avg</Typography>
                            </SkeletonWrapper>
                        </Stack>
                        <Stack direction="column">
                            <SkeletonWrapper isLoading={isLoadingBudgetCountyPractice}>
                                <Typography variant='h5'>
                                    {formatNumber(budgetCountyPractice?.rate_yield, 0)}
                                </Typography>
                                <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Rate Yield<br />Avg</Typography>
                            </SkeletonWrapper>
                        </Stack>
                        <Stack direction="column">
                            <SkeletonWrapper isLoading={isLoadingBudgetCountyPractice}>
                                <Typography variant='h5'>
                                    {budgetCountyPractice?.adjusted_yield ? formatNumber(budgetCountyPractice?.adjusted_yield, 0) : "--"}
                                </Typography>
                                <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Adjusted Yield<br />Avg</Typography>
                            </SkeletonWrapper>
                        </Stack>
                    </Box >
                </Box>

                {isSmall &&
                    <Box display="block" sx={{ width: "100%" }}>
                        <Divider />
                    </Box>}

                <Box display="block">
                    <Stack direction="row" spacing={1} alignItems="center" className='headerWithMenuButton'>
                        <BudgetIcon />
                        <Typography variant='h1'>
                            Farm Budget
                        </Typography>

                        <IconButton onClick={handleBudgetNavigateClick}>
                            <EastOutlined />
                        </IconButton>
                        {isLoading && <CircularProgress />}
                    </Stack>
                    <Box className="flexWrapContainer">
                        <Stack direction="column">
                            <SkeletonWrapper isLoading={isLoadingBudgetCountyPractice}>
                                <Typography variant='h5'>
                                    {formatNumber(budgetCountyPractice?.reported_acreage, 0)}
                                </Typography>
                                <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Acres</Typography>
                            </SkeletonWrapper>
                        </Stack>
                        <Stack direction="column">
                            <SkeletonWrapper isLoading={isLoadingBudgetCountyPractice}>
                                <Typography variant='h5'>
                                    {formatPrice(budgetCountyPractice?.production_cost / budgetCountyPractice?.reported_acreage, 0)}
                                </Typography>
                                <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Cost /ac</Typography>
                            </SkeletonWrapper>
                        </Stack>
                        <Stack direction="column">
                            <SkeletonWrapper isLoading={isLoadingBudgetCountyPractice}>
                                <Typography variant='h5'>
                                    {formatNumber(budgetCountyPractice?.yield, 0)}
                                </Typography>
                                <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Projected Yield<br />Avg</Typography>
                            </SkeletonWrapper>
                        </Stack>
                        <Stack direction="column">
                            <SkeletonWrapper isLoading={isLoadingBudgetCountyPractice}>
                                <Typography variant='h5'>
                                    {formatPrice(budgetCountyPractice?.projected_price, 2)}
                                </Typography>
                                <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Projected<br />Price</Typography>
                            </SkeletonWrapper>
                        </Stack>
                        <Stack direction="column">
                            <SkeletonWrapper isLoading={isLoadingBudgetCountyPractice}>
                                <Typography variant='h5'>
                                    {formatPercentage(budgetCountyPractice?.share_percent, 0)}
                                </Typography>
                                <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Share %</Typography>
                            </SkeletonWrapper>
                        </Stack>
                    </Box>
                </Box>
            </Box>

            <Divider />

            <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton'>
                <Typography variant='h1'>
                    Insurance Plans
                </Typography>
                <IconButton variant="outlined" onClick={() => setIsInsuranceDatesDialogOpen(true)}>
                    <CalendarMonthOutlined />
                </IconButton>
                <WorkspaceViewSelector workspaceMode={workspaceMode} setWorkspaceMode={setWorkspaceMode} />
                {isLoadingInsurancePlans && <CircularProgress />}
            </Stack>

            <Box className='cardContainer'>
                {Boolean(insurancePlans?.length) && insurancePlans?.map((currentPlan, index) =>
                    <InsurancePlanCard key={index}
                        countyPractice={budgetCountyPractice} insurancePlan={currentPlan}
                        isLoading={isLoadingInsurancePlans}
                        onChange={handleInsurancePlanCardChange} />
                )}
            </Box>

            {
                !Boolean(insurancePlans?.length) &&
                <Box className='cardContainer'>
                    <EmptyCard
                        text="No insurance plans listed"
                        secondaryText="Get started by adding a plan"
                        isLoading={isLoadingInsurancePlans}
                    />
                </Box>
            }


            {Boolean(stressTestResults?.results?.length) && <>
                <Divider />

                <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton pageBreakBefore'>
                    <Typography variant='h1'>
                        Stress Test
                    </Typography>
                    <PopupState variant="popover" popupId="stress-test-popup-menu">
                        {(popupState) => (
                            <>
                                <Button variant="outlined" {...bindTrigger(popupState)}>
                                    {calculateStressTestDisplayFieldLabel(stressTestDisplayField)} <ArrowDropDownOutlined />
                                </Button>
                                <Menu {...bindMenu(popupState)}>
                                    <MenuItem onClick={() => handleStressTestMenuClick(STRESS_TEST_FIELD_NET_PROFIT_SIMPLE, popupState)}>
                                        {stressTestDisplayField == STRESS_TEST_FIELD_NET_PROFIT_SIMPLE ?
                                            <CheckOutlined sx={{ mr: 1 }} />
                                            : <Icon sx={{ mr: 1 }} />}
                                        Net Profit Before Insurance
                                    </MenuItem>
                                    <MenuItem onClick={() => handleStressTestMenuClick(STRESS_TEST_FIELD_NET_PROFIT_INSURED, popupState)}>
                                        {stressTestDisplayField == STRESS_TEST_FIELD_NET_PROFIT_INSURED ?
                                            <CheckOutlined sx={{ mr: 1 }} />
                                            : <Icon sx={{ mr: 1 }} />}
                                        Insured Net Profit
                                    </MenuItem>

                                    <Divider />

                                    <MenuItem onClick={() => handleStressTestMenuClick(STRESS_TEST_FIELD_INDEMNITY_TOTAL_PER_ACRE, popupState)}>
                                        {stressTestDisplayField == STRESS_TEST_FIELD_INDEMNITY_TOTAL_PER_ACRE ?
                                            <CheckOutlined sx={{ mr: 1 }} />
                                            : <Icon sx={{ mr: 1 }} />}
                                        Insurance Benefit
                                    </MenuItem>

                                    <MenuItem onClick={() => handleStressTestMenuClick(STRESS_TEST_FIELD_NET_INSURANCE_BENEFIT_PER_ACRE, popupState)}>
                                        {stressTestDisplayField == STRESS_TEST_FIELD_NET_INSURANCE_BENEFIT_PER_ACRE ?
                                            <CheckOutlined sx={{ mr: 1 }} />
                                            : <Icon sx={{ mr: 1 }} />}
                                        Net Insurance Benefit
                                    </MenuItem>
                                </Menu>
                            </>
                        )}
                    </PopupState>

                    <Button variant="outlined" onClick={handleStressTestOptionsDialogOpenClick}>
                        <CalculateOutlined sx={{ mr: 1 }} /> Options
                    </Button>
                    <Dialog open={isStressTestOptionsDialogOpen}>
                        <InsuranceStressTestParametersDialog
                            onClose={() => setIsStressTestOptionsDialogOpen(false)}
                            onChange={handleStressTestParametersChange}
                            initialStressTestParameters={stressTestParameters}
                            budgetCountyPractice={insuranceScenario?.budgetCountyPractices?.length && insuranceScenario?.budgetCountyPractices[0]}
                            countyData={insuranceScenario?.budgetCountyPractices?.length && insuranceScenario?.budgetCountyPractices[0]?.countyData}
                        />
                    </Dialog>
                </Stack>

                <Box sx={{ maxWidth: "1050px" }}>
                    <InsuranceOptimizeDataGrid
                        displayFieldName={stressTestDisplayField}
                        yAxisFieldName={"price"}
                        yAxisDisplayName={"Price"}
                        stressTestResults={stressTestResults?.results}
                        doMarkNegativeValues={true}
                        maxLossValue={-1 * budgetCountyPractice?.production_cost_per_acre}
                    />
                </Box>

                {arcCoStressTestResults?.results?.length &&
                    <>
                        <Divider />
                        <Typography variant='h1'>
                            ARC-CO Comparison
                        </Typography>
                        <Box sx={{ maxWidth: "1050px" }}>
                            <InsuranceOptimizeDataGrid
                                displayFieldName={stressTestDisplayField}
                                yAxisFieldName={"price"}
                                yAxisDisplayName={"Price"}
                                stressTestResults={arcCoStressTestResults?.results}
                                doMarkNegativeValues={true}
                                maxLossValue={-1 * budgetCountyPractice?.production_cost_per_acre}
                            />
                        </Box>
                    </>}
            </>}

            {
                Boolean(stressTestResults?.results?.length) && <>
                    <Box className="pageBreakBefore" sx={{
                        ...(isXXL && {
                            // top: 'auto', bottom: 0, pb: 2, position: 'sticky', zIndex: 1000,
                            width: "100%", backgroundColor: 'white'
                        })
                    }}>
                        <Divider />

                        <Typography variant='h1'>
                            Credit Summary
                        </Typography>

                        <Grid container justifyItems='flex-start' spacing={1}>
                            <Grid item xs={12} md={12} lg={12} xl={2} sx={{ backgroundColor: '#f5f5f5' }}>
                                <Box component={Stack} direction="column" spacing={0} sx={{ height: "100%", justifyContent: "center" }}>
                                    <Stack direction="row" sx={{ alignItems: "center" }}>
                                        <CloseOutlined fontSize="large" />
                                        <Typography variant='body1'>Uninsured</Typography>
                                    </Stack>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={2} xl={2} sx={{ backgroundColor: '#f5f5f5' }}>
                                <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                                    <Typography variant='h5'>
                                        {formatPrice(stressTestResults?.cecl?.loan_amount_simple, 0)}
                                    </Typography>
                                    <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Loan Amount</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6} lg={2} xl={2} sx={{ backgroundColor: '#f5f5f5' }}>
                                <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                                    <Typography variant='h5'>
                                        {formatPrice(stressTestResults?.cecl?.loan_amount_simple / budgetCountyPractice?.reported_acreage, 0)}
                                    </Typography>
                                    <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Cost /ac</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6} lg={2} xl={1.5} sx={{ backgroundColor: '#f5f5f5' }}>
                                <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                                    <Typography variant='h5'>
                                        {formatPrice(calculateBreakeven(stressTestResults?.cecl?.loan_amount_simple), 2)}
                                    </Typography>
                                    <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Breakeven</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6} lg={2} xl={2} sx={{ backgroundColor: '#f5f5f5' }}>
                                <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                                    <Typography variant='h5'>
                                        {formatPrice(stressTestResults?.cecl?.expected_credit_loss_simple / budgetCountyPractice?.reported_acreage, 0)}
                                    </Typography>
                                    <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Expected Loss /ac</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4} xl={1.5} sx={{ backgroundColor: '#f5f5f5' }}>
                                <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                                    <Typography variant='h5'>
                                        {formatPercentage(stressTestResults?.cecl?.cecl_simple, 2)}
                                    </Typography>
                                    <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>% ECL</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                        <br />

                        <Grid container justifyItems='flex-start' spacing={1}>
                            <Grid item xs={12} md={12} xl={2}>
                                <Box component={Stack} direction="column" spacing={0} sx={{ height: "100%", justifyContent: "center" }}>
                                    <Stack direction="row" sx={{ alignItems: "center" }}>
                                        <InsuranceIcon fontSize="large" />
                                        <Typography variant='body1'>With Insurance</Typography>
                                    </Stack>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={2} xl={2}>
                                <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                                    <Typography variant='h5'>
                                        {formatPrice(stressTestResults?.cecl?.loan_amount_insured, 0)}
                                    </Typography>
                                    <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Loan Amount</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6} lg={2} xl={2}>
                                <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                                    <Typography variant='h5'>
                                        {formatPrice(stressTestResults?.cecl?.loan_amount_insured / budgetCountyPractice?.reported_acreage, 0)}
                                    </Typography>
                                    <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Cost /ac</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6} lg={2} xl={1.5}>
                                <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                                    <Typography variant='h5'>
                                        {formatPrice(calculateBreakeven(stressTestResults?.cecl?.loan_amount_insured), 2)}
                                    </Typography>
                                    <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Breakeven</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6} lg={2} xl={2}>
                                <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                                    <Typography variant='h5'>
                                        {formatPrice(stressTestResults?.cecl?.expected_credit_loss_insured / budgetCountyPractice?.reported_acreage, 0)}
                                    </Typography>
                                    <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Expected Loss /ac</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4} xl={1.5}>
                                <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                                    <Typography variant='h5'>
                                        {formatPercentage(stressTestResults?.cecl?.cecl_insured, 2)}
                                    </Typography>
                                    <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>% ECL</Typography>
                                </Stack>
                            </Grid>
                        </Grid>

                    </Box>
                </>
            }

            <Divider />

            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    {Boolean(budgetCountyPractice && insuranceScenario?.crop_year) &&
                        <SummaryOfBusinessCauseOfLossChart
                            countyPractice={{
                                reinsurance_year: budgetCountyPractice?.reinsurance_year,
                                commodity_name: budgetCountyPractice?.commodity_name,
                                type_name: budgetCountyPractice?.type_name,
                                practice_name: budgetCountyPractice?.practice_name,
                                state_name: budgetCountyPractice?.state_name,
                                county_name: budgetCountyPractice?.county_name,
                            }}
                            reinsurance_year_minimum={2014}
                            reinsurance_year_maximum={null}
                            api={ApiHelper} size='small' />
                    }
                    {!Boolean(budgetCountyPractice && insuranceScenario?.crop_year) &&
                        <Skeleton />
                    }
                </Grid>
                <Grid item xs={12} md={6}>
                    {Boolean(budgetCountyPractice && insuranceScenario?.crop_year) &&
                        <YieldVarianceChart
                            countyPractice={{
                                reinsurance_year: budgetCountyPractice?.reinsurance_year,
                                commodity_name: budgetCountyPractice?.commodity_name,
                                type_name: budgetCountyPractice?.type_name,
                                practice_name: budgetCountyPractice?.practice_name,
                                state_name: budgetCountyPractice?.state_name,
                                county_name: budgetCountyPractice?.county_name,
                            }}
                            reinsurance_year_minimum={2014}
                            reinsurance_year_maximum={null}
                            api={ApiHelper} size='small' />
                    }
                    {!Boolean(budgetCountyPractice && insuranceScenario?.crop_year) &&
                        <Skeleton />
                    }
                </Grid>
            </Grid>

            {/* <Box sx={{
                ...(isXL && {
                    top: 'auto', bottom: 0, pb: 2, position: 'sticky',
                    width: "100%", backgroundColor: 'white', zIndex: 1000
                })
            }}>
                <Divider />
                <Grid container justifyItems='flex-start' spacing={1}>
                    <Grid item xs={12} md={6} xl={1.5}>
                        <Typography variant='h1'>
                            Totals
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} xl={1.5}>
                        <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                            <Typography variant='h5'>
                                {formatPrice(calculateProducerPremiumAmount_PerAcre(), 2)}
                            </Typography>
                            <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Producer<br />Premium /ac</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} xl={1.5} sx={{ backgroundColor: '#f5f5f5' }}>
                        <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                            <Typography variant='h5'>
                                {formatPrice(0, 2)}
                            </Typography>
                            <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Production<br />Cost /ac</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} xl={1.5} sx={{ backgroundColor: '#f5f5f5' }}>
                        <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                            <Typography variant='h5'>
                                {formatPrice(0, 2)}
                            </Typography>
                            <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Production Cost</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} xl={1.5}>
                        <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                            <Typography variant='h5'>
                                {formatPrice(0, 2)}
                            </Typography>
                            <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Coverage /ac</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} xl={1.5}>
                        <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                            <Typography variant='h5'>
                                {formatPrice(0, 2)}
                            </Typography>
                            <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Coverage</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} xl={1.5} sx={{ backgroundColor: '#f5f5f5' }}>
                        <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                            <Typography variant='h5'>
                                {formatPrice(0, 2)}
                            </Typography>
                            <Typography variant='body1' sx={{ textTransform: isSmall ? '' : 'uppercase' }}>Net Income/Acre</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6} xl={1.5} sx={{ backgroundColor: '#f5f5f5' }}>
                        <Stack direction={isSmall ? 'row-reverse' : 'column'} justifyContent='space-between'>
                            <Typography variant='h5' sx={{ fontWeight: 'bold !important' }}>
                                {formatPrice(0, 2)}
                            </Typography>
                            <Typography variant='body1' sx={{ fontWeight: 'bold !important', textTransform: isSmall ? '' : 'uppercase' }}>Net Income</Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Box> */}

            <Dialog open={isOptimizeDialogOpen} fullWidth PaperProps={{ sx: { maxHeight: "100%" } }}>
                <OptimizeInsuranceDialog
                    onClose={() => setIsOptimizeDialogOpen(false)}
                    insuranceScenario={insuranceScenario} budgetCountyPractice={budgetCountyPractice}
                    insurancePlans={insurancePlans}
                    onChange={handleOptimizerOnChange} />
            </Dialog>

            <Dialog open={isAddInsuranceDialogOpen} PaperProps={{ sx: { minHeight: "5in" } }}>
                <AddInsuranceDialog
                    farmParameters={budgetCountyPractice}
                    countyParameters={countyData}
                    onSelectPlan={handleAddPlanSelected}
                    onClose={() => setIsAddInsuranceDialogOpen(false)}
                />
            </Dialog>

            <Dialog open={isInsuranceDatesDialogOpen} PaperProps={{ sx: { width: "50%" } }}>
                <DialogTitle>Insurance Dates</DialogTitle>
                <DialogContent>
                    <DatesChart
                        title=''
                        countyPractice={budgetCountyPractice}
                        api={ApiHelper} size='small' />
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='secondary' autoFocus onClick={() => setIsInsuranceDatesDialogOpen(false)}>
                        <CloseOutlined sx={{ mr: 1 }} />
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <WorkspaceActionBar content={<>
                <Button variant="contained" onClick={() => setIsOptimizeDialogOpen(true)}>
                    <AddCircleOutline sx={{ mr: 1 }} />
                    Optimize {!isSmall && " Insurance"}
                </Button>
                <Button variant="contained" onClick={() => setIsAddInsuranceDialogOpen(true)}>
                    <AddCircleOutline sx={{ mr: 1 }} />
                    Add {!isSmall && " Plan"}
                </Button>
            </>} />

            {confirmDialogComponent}
        </>
    )
}
