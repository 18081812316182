import { useDialog } from '@datanac/datanac-mui-toolkit'
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import { Box, CircularProgress, Menu, MenuItem, Stack, Stepper, Typography, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import { AppContext } from 'App'
import { UsersApiHelper } from 'api/ApiHelper'
import { getBudgetYears } from 'components/Budget/helpers'
import { FinancialIcon } from 'components/Icons/Icons'
import { MODULE_FINANCIAL } from 'components/Menu/NavigationMenu'
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from 'components/Workspaces/helpers'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { useFormStatus } from 'mui-toolkit/useFormStatus'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { OBJECT_NAME_LOAN_ORIGINATION_MEMO } from './FinancialHelpers'
import useFinanceReport from './useFinanceReport'
import { InterviewDialog } from './InterviewDialog'

export default function InterviewPage() {
    const { globalState, globalDispatch, session } = useContext(AppContext);
    const navigate = useNavigate();
    const {
        producer_token, year
    } = useParams();

    const isPrint = useMediaQuery('print');
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true }) && !isPrint;
    const { enqueueSnackbar } = useSnackbar();
    const [workspaceMode, setWorkspaceMode] = useState(isSmall ? WORKSPACE_VIEW_CARD : WORKSPACE_VIEW_TABLE);

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();

    const [currentYear, setCurrentYear] = useState(year || globalState?.crop_year);
    useEffect(() => {
        globalDispatch({
            type: 'update',
            payload: {
                key: 'crop_year',
                value: currentYear
            },
        });
    }, [currentYear])

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || err, { preventDuplicate: true, variant: 'error' });
    }

    // ---

    const [activeProducer, setActiveProducer] = useState();
    const selectActiveProducer = async () => {
        let _producer = await UsersApiHelper.users_selectObject("Member", { producer_token: globalState.producer_token }, globalState);
        if (_producer?.length) {
            setActiveProducer(_producer[0]);
        }
    };

    useEffect(() => {
        selectActiveProducer();
    }, [globalState?.producer_token])

    // --- --- ---

    return (
        <>
            <WorkspaceHeader
                title='Financial interview'
                breadCrumbs={[
                    {
                        title: 'Financial Analysis',
                        // path: '/financial/documents',
                        icon: <FinancialIcon />
                    },
                ]}
            />

            <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton navigation'>
                <Typography variant='h1'>
                    Year
                </Typography>
                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                        <>
                            <Button variant="outlined" {...bindTrigger(popupState)}>
                                {currentYear} <ArrowDropDownOutlined />
                            </Button>
                            <Menu {...bindMenu(popupState)}>
                                {getBudgetYears().map((year, index) =>
                                    <MenuItem key={index} onClick={() => {
                                        setCurrentYear(year);
                                        popupState.close();
                                    }}>
                                        {year}
                                    </MenuItem>)}
                            </Menu>
                        </>
                    )}
                </PopupState>
                {false && <CircularProgress />}
            </Stack>

            <InterviewDialog year={currentYear} />

            <WorkspaceActionBar content={<>
            </>} />

            {confirmDialogComponent}
        </>
    )
}
