import ArrowRight from '@mui/icons-material/ArrowRight'
import MenuOutlined from '@mui/icons-material/MenuOutlined'
import { Breadcrumbs, IconButton, Link, Slide, Stack, useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import { AppContext } from 'App'
import { SkeletonWrapper } from 'mui-toolkit/SkeletonWrapper'
import React, { useContext } from 'react'
import { Link as RouterLink } from 'react-router-dom'

export default function WorkspaceHeader({
    title,
    subHeader,
    breadCrumbs,
    actions,

    isLoading
}) {
    const { globalState, globalDispatch, session } = useContext(AppContext);
    const isPrint = useMediaQuery('print');
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const isXsSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'), { noSsr: true });

    function HideOnScroll(props) {
        const { children } = props;
        const trigger = useScrollTrigger();
        return (
            <Slide appear={false} direction="down" in={isSmall ? !trigger : true}>
                {children}
            </Slide>
        );
    }

    const handleMenuButtonClick = () => {
        globalDispatch({
            type: 'update',
            payload: {
                key: 'isMenuOpen',
                value: true
            },
        });
    }

    const getBreadcrumbs = () => {
        var _breadCrumbs = [...breadCrumbs];

        if (!isPrint && isSmall && _breadCrumbs?.length > 1) {
            _breadCrumbs[0].title = "";
        }
        if (!isPrint && isXsSmall && _breadCrumbs?.length > 2) {
            _breadCrumbs = [
                _breadCrumbs[0],
                _breadCrumbs[_breadCrumbs.length - 1]
            ]
        }

        return _breadCrumbs;
    }

    const calculateDisplayTitle = () => {
        if (isLoading && title?.trim) {
            return (title.trim()?.length > 0) ? title.trim() : ""
        } else {
            return title;
        }
    }

    // ---

    return (
        <>
            <Box className='header' sx={{ height: "100px", p: 0, m: 0, mb: isXsSmall ? 0 : 3 }}>
                <Box sx={{ display: "flex", pt: (isSmall || subHeader) ? 0 : 2, alignItems: 'center' }}>
                    {!globalState?.isMenuOpen && <>
                        <IconButton className="navigation" onClick={handleMenuButtonClick}
                            sx={{ mr: (isSmall ? 0 : 2), height: "40px", width: "40px" }}
                        >
                            <MenuOutlined />
                        </IconButton>
                    </>}

                    <Stack direction={"column"} justifyContent='center' sx={{ minHeight: "60px" }}>
                        <Breadcrumbs
                            separator={<ArrowRight />}
                            sx={{ display: (isSmall && !breadCrumbs?.length) ? "none" : null }}
                            className="navigation"
                        >
                            {getBreadcrumbs()?.map((b, index) => {
                                const hasPath = Boolean(b?.path);
                                const breadcrumbContent = (
                                    <>
                                        {b?.icon && React.cloneElement(b?.icon, {
                                            sx: { mr: b?.title ? 0.5 : 0 },
                                            fontSize: "inherit"
                                        })}
                                        {b?.title && (
                                            <span display="inline-block" sx={{ textTransform: 'capitalize' }}>
                                                {b?.title}
                                            </span>
                                        )}
                                    </>
                                );

                                return hasPath ? (
                                    <Link key={index} component={RouterLink} to={b?.path}>
                                        {breadcrumbContent}
                                    </Link>
                                ) : (
                                    <Typography key={index} display="inline-block">
                                        {breadcrumbContent}
                                    </Typography>
                                );
                            })}
                        </Breadcrumbs>
                        <SkeletonWrapper isLoading={isLoading && !calculateDisplayTitle()}>
                            <Typography variant={isXsSmall ? "body1" : "h1"}
                                sx={{
                                    mt: (isSmall ? 0 : 2),
                                    mb: "0 !important",
                                    textTransform: 'capitalize',
                                }}>
                                {calculateDisplayTitle() || isLoading && "Loading..."}
                            </Typography>
                        </SkeletonWrapper>

                        {subHeader &&
                            <Typography variant={isXsSmall ? "body1" : "h1"} color="GrayText"
                                sx={{
                                    mt: (isSmall ? 0 : 2),
                                    mb: "0 !important",
                                    textTransform: 'capitalize',
                                }}>
                                {subHeader}
                            </Typography>}
                    </Stack>
                    <Box display="flex" justifyContent='flex-end'>
                        {actions}
                    </Box>
                </Box>
            </Box>

        </>
    )
}
