import { Divider, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import OnlineHelp from './OnlineHelp'

export default function BudgetOnlineHelp({
    isOpen,
    onClose,
}) {
    const VIDEOID_ONLINEHELP_BUDGET = '403530213';

    const [state, setState] = useState({
        right: false,
    });

    const [isVideoPoppedOut, setIsVideoPoppedOut] = useState(false);  // Controls whether video is popped out

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                const contentDiv = document.querySelector('.online-help-content');
                const handleScroll = () => {
                    if (contentDiv) {
                        const scrollTop = contentDiv.scrollTop;
                        const scrollHeight = contentDiv.scrollHeight;
                        const clientHeight = contentDiv.clientHeight;
                        if (scrollTop + clientHeight >= scrollHeight) {
                            onClose && onClose();
                            setIsVideoPoppedOut(true);
                        }
                    }
                };
                if (contentDiv) {
                    contentDiv.addEventListener('scroll', handleScroll);
                }
                return () => {
                    if (contentDiv) {
                        contentDiv.removeEventListener('scroll', handleScroll);
                    }
                };
            }, 1000);
        }
    }, [isOpen]);

    // ---

    return (
        <>
            <OnlineHelp title={"Help - Budgets"} header={"Building a Strong Budget"} videoId={VIDEOID_ONLINEHELP_BUDGET} isOpen={isOpen} onClose={onClose}
                content={<div>
                    <Typography paragraph>
                        Planning your farm's budget isn't just about saving and spending — it's about making smart choices for a thriving farm.
                        This lesson is the ticket to build your legacy, ensuring your farm's growth and sustainability with every budget you build —
                        so you can turn those financial forecasts into profitable futures.
                    </Typography>

                    <Typography paragraph>
                        In this course, you'll learn:
                    </Typography>

                    <Typography paragraph>
                        <li>Why budgets are important to your farm's financial health</li>
                        <li>Different types of budgets</li>
                        <li>What goes into creating an enterprise budget</li>
                        <li>How yield forecasting works and how to apply it to a budget</li>
                        <li>How Tilley can help you build and manage a budget</li>
                    </Typography>
                </div>}
            />
        </>
    )
}
