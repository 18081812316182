import EastOutlined from '@mui/icons-material/EastOutlined';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import MUIDataTable from 'mui-datatables';
import { useNavigate, useParams } from 'react-router-dom';
import { formatNumber, formatPercentage } from 'utility/formatters';

const getNameSummary = params => {
    const farmRow = params.row;

    const nameLabel = farmRow?.business_name ? farmRow.business_name : farmRow.last_name;
    return nameLabel;
}

const getStateCountySummary = params => {
    const farmRow = params.row;

    const label = `${farmRow?.location_county_name}, ${farmRow?.location_state_name}`;
    return label;
}

const getFarmRowSummary = params => {
    const farmRow = params.row;

    const nameLabel = farmRow?.business_name ? farmRow.business_name : farmRow.last_name;
    const commodityLabel = `${farmRow?.commodity_name} ${farmRow?.practice_name}`
    return String(`${nameLabel} ${farmRow.location_county_name} / ${farmRow.fsa_farm_number} T${farmRow.fsa_tract_number} (${farmRow.fsa_field_number}) / ${farmRow.basic_unit_number}-${farmRow.optional_unit_number} / ${commodityLabel}`);
}

export default function ArcPlcProducerFarmBaseAcreDataTable({
    title,
    producerFarm,
    producerFarmBaseAcres,
}) {

    const navigate = useNavigate();
    const {
        crop_year,
        location_state_name,
        location_county_name,
        fsa_farm_number,
        // fsa_tract_number,
        // fsa_field_number
    } = useParams();

    const COLUMNS_PRODUCERFARMBASEACRES = [
        // {
        //     name: 'crop_year',
        //     label: 'Year',
        //     flex: 1
        // },
        // {
        //     name: 'fsa_farm_number',
        //     label: 'FSN',
        //     flex: 1
        // },
        {
            name: 'commodity_name',
            label: 'Commodity',
            flex: 1
        },
        {
            name: 'base_acreage',
            label: 'Acres',
            flex: 1
        },
        {
            name: 'plc_yield_weighted',
            label: 'PLC Yield (avg)',
            options: {
                customBodyRender: (value, tableMeta, updateValue) =>
                    value && formatNumber(value, 0)
            },
            flex: 1
        },
        {
            name: 'share_percent_weighted',
            label: 'Share % (avg)',
            options: {
                customBodyRender: (value, tableMeta, updateValue) =>
                    value && formatPercentage(value, 0)
            },
            flex: 1,
        },
        {
            name: 'id',
            label: 'ID',
            options: {
                customHeadRender: () => "",
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const currentBaseAcres = producerFarmBaseAcres[dataIndex];
                    return <Box display="flex" justifyContent='flex-end'>
                        <IconButton onClick={() => {
                            navigate(`/arcplc/${crop_year}/${location_state_name}/${location_county_name}/${currentBaseAcres?.commodity_name}`)
                        }}>
                            <EastOutlined />
                        </IconButton>
                    </Box>;
                }
            },

            flex: 0
        },
    ];

    const COLUMNS_PRODUCERFARMBASEACRES_SMALL = [
        {
            name: "fsa_farm_number",
            label: 'Unit',
            valueGetter: getFarmRowSummary,
            flex: 1
        },
    ];

    const options = {
        rowsPerPage: 10,
        rowsPerPageOptions: [5, 10, 25, 50, 100],
        selectableRows: "none",

        downloadOptions: {
            filename: "Farms.csv"
        },
        fixedSelectColumn: false,

        responsive: 'vertical',

        expandableRows: false,
    }

    return (
        <div className='MUI-Data-Table'>
            <MUIDataTable
                title={title}
                data={producerFarmBaseAcres}
                columns={COLUMNS_PRODUCERFARMBASEACRES}
                options={options}
                sx={{ width: "99%", boxSizing: "border-box" }}
                autoHeight
            />
        </div>
    );
}
