import { useDialog } from '@datanac/datanac-mui-toolkit'
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import { Box, CircularProgress, Grid, Menu, MenuItem, Stack, Typography, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import { AppContext } from 'App'
import { getBudgetYears } from 'components/Budget/helpers'
import { FinancialIcon } from 'components/Icons/Icons'
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from 'components/Workspaces/helpers'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { ValueLabel } from 'mui-toolkit/ValueLabel'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatNumber, formatPercentage, formatPrice } from 'utility/formatters'
import useFinanceReport from './useFinanceReport'

export default function FinancialSummaryWorkspace() {
    const { globalState, globalDispatch, session } = useContext(AppContext);
    const navigate = useNavigate();

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { enqueueSnackbar } = useSnackbar();
    const [workspaceMode, setWorkspaceMode] = useState(isSmall ? WORKSPACE_VIEW_CARD : WORKSPACE_VIEW_TABLE);

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();

    const [currentYear, setCurrentYear] = useState(globalState?.crop_year);

    useEffect(() => {
        globalDispatch({
            type: 'update',
            payload: {
                key: 'crop_year',
                value: currentYear
            },
        });
    }, [currentYear])

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || err, { preventDuplicate: true, variant: 'error' });
    }

    const {
        isLoading,

        isLoadingBudget,
        budget,

        isLoadingArcPlc,
        arcPlc,

        isLoadingInsuranceScenario,
        insuranceScenario,

        isLoadingBalanceSheet,
        balanceSheet,

        isLoadingIncomeStatement,
        incomeStatement,

        isLoadingRatios,
        ratios,
    } = useFinanceReport({
        onError: handleError,
        currentYear
    })

    // --- --- ---

    return (
        <>
            <WorkspaceHeader
                title='Financial Summary'
                breadCrumbs={[
                    {
                        title: 'Financial Analysis',
                        path: '/financial/summary',
                        icon: <FinancialIcon />
                    },
                ]}
            />

            <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton'>
                <Typography variant='h1'>
                    Year
                </Typography>
                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                        <>
                            <Button variant="outlined" {...bindTrigger(popupState)}>
                                {currentYear} <ArrowDropDownOutlined />
                            </Button>
                            <Menu {...bindMenu(popupState)}>
                                {getBudgetYears().map((year, index) =>
                                    <MenuItem key={index} onClick={() => {
                                        setCurrentYear(year);
                                        popupState.close();
                                    }}>
                                        {year}
                                    </MenuItem>)}
                            </Menu>
                        </>
                    )}
                </PopupState>
                {isLoading && <CircularProgress />}
            </Stack>

            <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid item xs={12} md={6} sx={{ borderBottom: "1px solid #c0c0c0", pb: 2, mb: 2 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>Revenue</Typography>

                    <Box className="flexWrapContainer">
                        <ValueLabel label="Farm Revenue" value={formatPrice(budget?.revenue, 0)} isLoading={isLoadingBudget} />
                        <ValueLabel label="Insurance Guarantee" value={formatPrice(insuranceScenario?.totals?.liability_amount, 0)} isLoading={isLoadingInsuranceScenario} />
                        <ValueLabel label="ARC/PLC Projected" value={formatPrice(arcPlc?.totals?.programElections?.totalPayment, 0)} isLoading={isLoadingArcPlc} />
                        <ValueLabel label="Livestock Revenue" value={formatPrice(incomeStatement?.revenue_livestock?.total, 0)} isLoading={isLoadingIncomeStatement} />
                        <ValueLabel label="Other" value={formatPrice(incomeStatement?.revenue_other?.total, 0)} isLoading={isLoadingIncomeStatement} />
                    </Box>
                    <br />

                    <Box className="flexWrapContainer">
                        <ValueLabel label="Total Revenue from Operations" value={formatPrice(ratios?.revenue_operations, 0)} isLoading={isLoadingRatios} />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ borderBottom: "1px solid #c0c0c0", pb: 2, mb: 2 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>Expenses</Typography>

                    <Box className="flexWrapContainer">
                        <ValueLabel label="Production Costs" value={formatPrice(budget?.production_cost, 0)} isLoading={isLoadingBudget} />
                        <ValueLabel label="Interest Expense" value={formatPrice(budget?.interest_cost, 0)} isLoading={isLoadingBudget} />
                        <ValueLabel label="Term Debt Service" value={formatPrice(incomeStatement?.expenses_debt_service?.total, 0)} isLoading={isLoadingIncomeStatement} />
                        <ValueLabel label="Living Expenses" value={formatPrice(incomeStatement?.expenses_living_expenses?.total, 0)} isLoading={isLoadingIncomeStatement} />
                        <ValueLabel label="Taxes" value={formatPrice(incomeStatement?.expenses_taxes?.total, 0)} isLoading={isLoadingIncomeStatement} />
                    </Box>
                    <br />

                    <Box className="flexWrapContainer">
                        <ValueLabel label="Total Costs and Expenses" value={formatPrice(ratios?.expenses_operations, 0)} isLoading={isLoadingRatios} />
                    </Box>
                </Grid>


                <Grid item xs={12} md={6} sx={{ borderBottom: "1px solid #c0c0c0", pb: 2, mb: 2 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>Assets</Typography>

                    <Box className="flexWrapContainer">
                        <ValueLabel label="Total Assets" value={formatPrice(balanceSheet?.assets?.total, 0)} isLoading={isLoadingBalanceSheet} />
                        <ValueLabel label="Current Assets" value={formatPrice(balanceSheet?.assets_current?.total, 0)} isLoading={isLoadingBalanceSheet} />
                        <ValueLabel label="Long-term Assets" value={formatPrice(balanceSheet?.assets_noncurrent?.total, 0)} isLoading={isLoadingBalanceSheet} />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ borderBottom: "1px solid #c0c0c0", pb: 2, mb: 2 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>Liabilities</Typography>

                    <Box className="flexWrapContainer">
                        <ValueLabel label="Total Liabilities" value={formatPrice(balanceSheet?.liabilities?.total, 0)} sx={{ fontWeight: "bold !important" }} isLoading={isLoadingBalanceSheet} />
                        <ValueLabel label="Current Liabilities" value={formatPrice(balanceSheet?.liabilities_current?.total, 0)} isLoading={isLoadingBalanceSheet} />
                        <ValueLabel label="Long-term Liabilities" value={formatPrice(balanceSheet?.liabilities_noncurrent?.total, 0)} isLoading={isLoadingBalanceSheet} />
                    </Box>
                </Grid>


                <Grid item xs={12} sx={{ borderBottom: "1px solid #c0c0c0", pb: 2, mb: 2 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>Financial Health Snapshot</Typography>

                    <Box className="flexWrapContainer">
                        <ValueLabel label="Current Ratio" value={formatNumber(balanceSheet?.current_ratio, 2)} isLoading={isLoadingBalanceSheet} />
                        <ValueLabel label="Equity-to-Assets" value={formatPercentage(balanceSheet?.equity_to_assets_ratio, 2)} isLoading={isLoadingBalanceSheet} />
                        <ValueLabel label="Return on Assets" value={formatPercentage(ratios?.return_on_assets_ratio, 2)} isLoading={isLoadingRatios} />
                        <ValueLabel label="Return on Equity" value={formatPercentage(ratios?.return_on_equity_ratio, 2)} isLoading={isLoadingRatios} />
                    </Box>
                </Grid>
            </Grid >

            <WorkspaceActionBar content={<>
            </>} />

            {confirmDialogComponent}
        </>
    )
}
