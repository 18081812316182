import { getComboTitle } from '@datanac/datanac-api-toolkit/dist/insurance/optimizer/optimizer';
import { Alert, Button, Chip, Stack, TableBody } from '@mui/material';
import Table from '@mui/material/Table';
import TableCell from "@mui/material/TableCell";
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { getCellColor } from 'components/StressTestVisualization/getCellColor';
import _ from 'lodash';
import { formatPrice } from 'utility/formatters';

export default function InsuranceCalculatePremiumEndorsementsTableGrid({
    farmParameters,
    optimizerResults,
    onInsurancePlansSelect
}) {
    return (
        <>
            {!Boolean(optimizerResults?.length) && <>
                <Alert severity='info' sx={{ m: 3 }}>
                    Loading...
                </Alert>
            </>}

            {Boolean(optimizerResults?.length) && <>
                <Table className='reportTable' size='small' spacing={0} sx={{ width: "100%", mt: 1 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ textAlign: "left", }}>
                                <Typography>Description</Typography>
                            </TableCell>
                            <TableCell sx={{ textAlign: "center", }}>
                                <Typography>Liability</Typography>
                            </TableCell>
                            <TableCell sx={{ textAlign: "center", }}>
                                <Typography>Breakeven</Typography>
                            </TableCell>
                            <TableCell sx={{ textAlign: "center", }}>
                                <Typography>Max Loss /ac</Typography>
                            </TableCell>
                            <TableCell sx={{ textAlign: "center", }}>
                                <Typography>Producer Premium<br />(Endorsements)</Typography>
                            </TableCell>
                            <TableCell sx={{ textAlign: "center", }}>
                                <Typography>Producer Premium<br />(Total)</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Boolean(optimizerResults?.length) &&
                            optimizerResults?.map((currentOptimizerResult, rowIndex) => {
                                return (
                                    <TableRow key={rowIndex}>
                                        <TableCell sx={{ textAlign: "left" }}>
                                            <Stack flexWrap="wrap" direction="row" columnGap={1} rowGap={1}>
                                                {currentOptimizerResult?.comboStressTestResults?.insurancePlans?.map((currentInsurancePlan, index) =>
                                                    <Chip key={index} label={
                                                        getComboTitle([{
                                                            ...currentInsurancePlan,
                                                            ...currentInsurancePlan?.insurancePlanParameters
                                                        }])
                                                    } />
                                                )}
                                            </Stack>
                                        </TableCell>
                                        <TableCell sx={{ textAlign: "center" }}>
                                            <Typography>
                                                {formatPrice(
                                                    _.sumBy(currentOptimizerResult?.comboStressTestResults?.insurancePlans, p => p?.insurancePlanParameters?.liability_amount)
                                                    / farmParameters?.reported_acreage
                                                    , 0)}<br />
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ textAlign: "center" }}>
                                            <Typography>
                                                {formatPrice(currentOptimizerResult?.breakeven_projected, 2)}<br />
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{
                                            textAlign: "center",
                                            backgroundColor: currentOptimizerResult?.loss_amount_maximum_per_acre >= 0 ? ''
                                                : getCellColor(currentOptimizerResult?.loss_amount_maximum_per_acre, 0 - 1000, true)
                                        }}>
                                            <Typography>
                                                {currentOptimizerResult?.loss_amount_maximum_per_acre < 0 &&
                                                    formatPrice(currentOptimizerResult?.loss_amount_maximum_per_acre, 0)}
                                                {currentOptimizerResult?.loss_amount_maximum_per_acre >= 0 &&
                                                    "--"}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ textAlign: "center" }}>
                                            <Typography>
                                                {currentOptimizerResult?.comboStressTestResults?.insurancePlans?.length > 1 &&
                                                    formatPrice(currentOptimizerResult?.premium_cost_total_per_acre
                                                        - currentOptimizerResult?.comboStressTestResults?.insurancePlans[0]?.insurancePlanParameters?.producer_premium_amount_per_acre
                                                        , 2)}
                                                {currentOptimizerResult?.comboStressTestResults?.insurancePlans?.length == 1 &&
                                                    "--"}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ textAlign: "center", }}>
                                            <Button variant="text" onClick={() => onInsurancePlansSelect && onInsurancePlansSelect(
                                                currentOptimizerResult?.comboStressTestResults?.insurancePlans.map(p =>
                                                    ({ ...p, ...p?.insurancePlanParameters })
                                                )
                                            )}>
                                                {formatPrice(currentOptimizerResult?.premium_cost_total_per_acre, 2)}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </>}
        </>
    );
}
