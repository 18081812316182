import {
    ApiHelper as DatanacApiHelper,
    AipPassApiHelper as DatanacAipPassApiHelper,
    UsersApiHelper as DatanacUsersApiHelper,
    RmaApiHelper as DatatacRmaApiHelper,
    ModelsApiHelper as DatanacModelsApiHelper,
} from '@datanac/datanac-api-toolkit';
import { AmplifySessionManager as SessionManager } from './AmplifySessionManager';

import { InsurancePlanHelper as Datanac_InsurancePlanHelper } from '@datanac/datanac-api-toolkit';

import Axios from 'axios';
import { setupCache, buildWebStorage } from 'axios-cache-interceptor';
// import { setupCache, buildWebStorage } from 'axios-cache-interceptor/dev';

// --- --- ---

export const InsurancePlanHelper = new Datanac_InsurancePlanHelper();

export const _sessionManager = new SessionManager();

const __instance = setupCache(
    Axios.create({
        baseUrl: process.env.REACT_APP_DATANAC_AIP_PASS_API_URL_BASE
    }),
    {
        methods: ['get', 'post'],
        storage: buildWebStorage(localStorage, 'com.app.tilley-axios-cache:'),
        headerInterpreter: (headers) => {
            return 1000 * 60 * 60 * 12 + 1;
        },
        // debug: ({ id, msg, data }) => {
        //     if (msg == "Returned cached response" || msg == "Returning cached response") {
        //         // console.log("cache-interceptor", "--CACHE HIT--", id, msg, data)
        //     } else {
        //         // console.log("cache-interceptor", id, msg, data)
        //     }
        // }
    },
);

// const __instance = Axios.create({
//         baseUrl: process.env.REACT_APP_DATANAC_AIP_PASS_API_URL_BASE
//     });

export const ApiHelper = new DatanacApiHelper({
    baseUrl: process.env.REACT_APP_DATANAC_AIP_PASS_API_URL_BASE,
    config: {},
    sessionManager: _sessionManager,
    _instance: __instance
});

export const AipPassApiHelper = new DatanacAipPassApiHelper({
    baseUrl: process.env.REACT_APP_DATANAC_AIP_PASS_API_URL_BASE,
    config: {},
    sessionManager: _sessionManager,
    _instance: __instance
});

export const RmaApiHelper = new DatatacRmaApiHelper({
    baseUrl: process.env.REACT_APP_DATANAC_AIP_PASS_API_URL_BASE,
    config: {},
    sessionManager: _sessionManager,
    _instance: __instance
});

export const ModelsApiHelper = new DatanacModelsApiHelper({
    baseUrl: process.env.REACT_APP_DATANAC_AIP_PASS_API_URL_BASE,
    config: {},
    sessionManager: _sessionManager,
    _instance: __instance
});

// No caching:
export const UsersApiHelper = new DatanacUsersApiHelper({
    baseUrl: process.env.REACT_APP_DATANAC_USERS_API_URL_BASE,
    config: {},
    sessionManager: _sessionManager,
    // No caching:
    _instance: null
});