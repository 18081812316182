import React, { useMemo } from 'react';
import { Box, Skeleton } from '@mui/material';

const SkeletonTable = ({ skeletonTableRows, skeletonTableColumn }) => {

    const component = useMemo(() =>
        <Box sx={{ display: 'grid', gridTemplateColumns: `repeat(${skeletonTableColumn}, 1fr)`, gap: 1 }}>
            {[...Array(skeletonTableRows)].map((_, rowIndex) => (
                <React.Fragment key={rowIndex}>
                    {[...Array(skeletonTableColumn)].map((_, colIndex) => (
                        <Skeleton
                            key={colIndex}
                            animation="wave"
                            variant="rectangular"
                            sx={{ borderRadius: 1 }}
                        />
                    ))}
                </React.Fragment>
            ))}
        </Box>
        , [skeletonTableRows, skeletonTableColumn]);

    return component;
};

export default SkeletonTable;
