import { getCurrentReinsuranceYear } from '@datanac/datanac-api-toolkit/dist/utility/InsurancePlanHelper'
import { useDialog } from '@datanac/datanac-mui-toolkit'
import { Box, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography, useMediaQuery } from '@mui/material'
import { AppContext } from 'App'
import { getBudgetYears } from 'components/Budget/helpers'
import DataFormSelect from 'components/Core/DataFormSelect'
import { propUpdateReducer } from 'components/Core/propUpdateReducer'
import { MASK_NUMERIC, StatefulControlledTextField } from 'components/Core/StatefulControlledTextField'
import { FarmVaultIcon } from 'components/Icons/Icons'
import { useBlockerPrompt } from 'components/Menu/useBlockerPrompt'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { useFormStatus } from 'mui-toolkit/useFormStatus'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { UsersApiHelper } from '../../../api/ApiHelper'
import { CROP_YEAR_POTENTIAL } from './helpers'

export default function FarmFieldCropWorkspace() {
    const { globalState, globalDispatch, session } = useContext(AppContext);

    const {
        crop_year,
        location_state_name, location_county_name,
        producer_farm_id, producer_farm_field_id, producer_farm_field_crop_id
    } = useParams();
    const navigate = useNavigate();

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const [isLoading, setIsLoading] = useState(true);
    const { component: formStatusComponent, actions: formStatusActions, state: formStatusState } = useFormStatus();

    const [producerFarm, dispatchProducerFarm] = useReducer(propUpdateReducer, {});

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    const loadProducerFarm = async () => {
        try {
            if (location_state_name && location_county_name && producer_farm_id != 0) {
                const producerFilter = {
                    producer_token: globalState.producer_token,
                    id: producer_farm_id,
                    ...(crop_year != CROP_YEAR_POTENTIAL && { crop_year }),
                    is_active: true
                };
                const farms = await UsersApiHelper.selectProducerFarms(producerFilter, globalState);
                if (farms?.length) {
                    dispatchProducerFarm({
                        type: 'updateMany',
                        payload: farms[0]
                    });
                }
            }

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            dispatch({
                type: 'replace',
                payload: {}
            });
            handleError(err);
        }
    }

    useEffect(() => {
        if (globalState?.producer_token && producer_farm_id != 0) {
            loadProducerFarm();
        }
    }, [location_state_name, location_county_name, producer_farm_id, crop_year,
        globalState?.producer_token]);

    // --- --- ---

    const [field, setField] = useState({});
    const loadField = async () => {
        try {
            const fieldFilter = {
                producer_token: globalState.producer_token,
                id: producer_farm_field_id,
                is_active: true
            };
            const _fields = await UsersApiHelper.selectProducerFarmFields(fieldFilter, globalState);
            if (_fields?.length) {
                setField(_fields[0]);
            }
        } catch (err) {
            handleError(err);
        }
    }

    useEffect(() => {
        if (globalState?.producer_token && producer_farm_field_id != 0) {
            loadField();
        }
    }, [location_state_name, location_county_name, producer_farm_field_id,
        globalState?.producer_token]);

    // --- --- ---

    const [state, dispatch] = useReducer(propUpdateReducer, {
        is_active: true,
        crop_year: globalState?.crop_year,
    });
    const loadCrops = async () => {
        try {
            const cropsFilter = {
                producer_token: globalState.producer_token,
                id: state?.id,
                ...(crop_year != CROP_YEAR_POTENTIAL && { crop_year }),
                is_active: true
            };
            const _crops = await UsersApiHelper.selectProducerFarmFieldCrops(cropsFilter, globalState);
            if (_crops?.length) {
                dispatch({
                    type: 'updateMany',
                    payload: _crops[0]
                });
            }
        } catch (err) {
            handleError(err);
        }
    }

    useEffect(() => {
        if (state?.id && state?.id != 0 && globalState?.producer_token) {
            loadCrops();
        }
    }, [state?.id, globalState?.producer_token])

    // --- --- ---

    const handleChangeStateSelect = (event) => {
        formStatusActions.setIsFormDirty(true, false);

        dispatch({
            type: 'update',
            payload: { key: event.target.name, value: event.target.value },
        });
    }

    const handleChangeState = (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        if (state[event.target.name] != value) {
            formStatusActions.setIsFormDirty(true, false);

            dispatch({
                type: 'update',
                payload: { key: event.target.name, value: value },
            });
        }
    }

    useEffect(() => {
        dispatch({
            type: 'update',
            payload: { key: "producer_farm_field_id", value: producer_farm_field_id },
        });

        if (producer_farm_field_crop_id == 0) {
            const _producer_farm_field_crop_id = uuidv4();
            dispatch({
                type: 'update',
                payload: { key: "id", value: _producer_farm_field_crop_id },
            });
        } else {
            dispatch({
                type: 'update',
                payload: { key: "id", value: producer_farm_field_crop_id },
            });
        }
    }, [producer_farm_id, producer_farm_field_crop_id]);

    const isValid = () => {
        return (
            state?.id
            && state.id != 0
            && state?.crop_year
            && state?.commodity_name
            && state?.type_name
            && state?.practice_name
            && state?.reported_acreage
            && state?.share_percent

            && state?.approved_yield
            && state?.rate_yield
        );
    }
    useBlockerPrompt(!isValid(), "ProducerFarmFieldCrop");

    useEffect(() => {
        if (isValid() && globalState.producer_token && formStatusState?.isFormDirty) {
            formStatusActions.setIsFormDirty(true, true);

            UsersApiHelper.updateProducerFarmFieldCrop(state, globalState)
                .then(() => {
                    formStatusActions.setIsSaving(false);
                })
                .catch(err => {
                    formStatusActions.setIsFormDirty(true, false);
                    handleError(err);
                });
        }
    }, [state])

    const handleAddNewClick = () => {
        // navigate(`/`);
    }

    const formMaxWidthShort = '800px';
    const formMaxWidth = '1000px';

    const commodityDataFormSelect = useMemo(() => {
        return (<DataFormSelect
            value={state.commodity_name || ""}
            name="commodity_name"
            isRequired={false}
            onChange={handleChangeState}
            objectName="InsuranceOffer"
            objectNameLabel="Commodity"
            itemFilter={{
                reinsurance_year: String(state.crop_year),
                state_name: location_state_name,
                county_name: location_county_name,
            }}
            itemGetKey={(item) => item.commodity_name}
            itemGetLabel={(item) => item.commodity_name}
            doFetch={
                state.crop_year != null
                && location_state_name
                && location_county_name
            }
            isLoading={isLoading}
        />);
    }, [state.crop_year, location_state_name, location_county_name, state.commodity_name, isLoading]);

    const typeDataFormSelect = useMemo(() => {
        return (<DataFormSelect
            value={state.type_name || ""}
            name="type_name"
            onChange={handleChangeState}
            objectName="InsuranceOffer"
            objectNameLabel="Commodity"
            itemFilter={{
                reinsurance_year: String(state.crop_year),
                state_name: location_state_name,
                county_name: location_county_name,
                commodity_name: state.commodity_name,
            }}
            itemGetKey={(item) => item.type_name}
            itemGetLabel={(item) => item.type_name}
            doFetch={
                state.crop_year != null
                && location_state_name
                && location_county_name
                && state.commodity_name
            }
            isLoading={isLoading}
        />);
    }, [state?.crop_year, location_state_name, location_county_name, state.commodity_name, state.type_name,
        isLoading]);

    const practiceDataFormSelect = useMemo(() => {
        return (<DataFormSelect
            value={state.practice_name || ""}
            name="practice_name"
            onChange={handleChangeState}
            objectName="InsuranceOffer"
            objectNameLabel="Practice"
            itemFilter={{
                reinsurance_year: String(state.crop_year),
                state_name: location_state_name,
                county_name: location_county_name,
                commodity_name: state.commodity_name,
                type_name: state.type_name
            }}
            itemGetKey={(item) => item.practice_name}
            itemGetLabel={(item) => item.practice_name}
            doFetch={
                state.crop_year != null
                && location_state_name
                && location_county_name
                && state.commodity_name
                && state.type_name
            }
            isLoading={isLoading}
        />);
    }, [state?.crop_year, location_state_name, location_county_name, state.commodity_name, state.type_name, state.practice_name,
        isLoading]);

    return (
        <>
            <WorkspaceHeader
                title={!(state?.commodity_name && state?.crop_year) ? "Crop Profile"
                    : (state?.commodity_name || '')
                    + (" \u2013 " + state?.practice_name || '')
                    + (" \u2013 " + state?.crop_year || '')
                    // + " " + (state?.type_name && (state?.type_name != "No Type Specified" ? " \u2013 " + state?.type_name : ""))
                }
                breadCrumbs={[
                    {
                        title: (crop_year != CROP_YEAR_POTENTIAL ? 'Planted Acres' : 'Farm Potential')
                            + ((crop_year != CROP_YEAR_POTENTIAL && " - " + crop_year) || ''),
                        path: '/vault' + (crop_year == CROP_YEAR_POTENTIAL ? "/potential" : ""),
                        icon: <FarmVaultIcon fontSize='3pt' />
                    },
                    {
                        title: location_county_name + ", " + location_state_name,
                        path: `/vault/${crop_year}/${location_state_name}/${location_county_name}`,
                        icon: null
                    },
                    {
                        title: (!Boolean(producerFarm?.fsa_farm_number && producerFarm?.farm_name)) ? producerFarm?.fsa_farm_number
                            : producerFarm?.fsa_farm_number + " - " + producerFarm?.farm_name,
                        path: `/vault/${crop_year}/${location_state_name}/${location_county_name}/${producer_farm_id}`,
                        icon: null
                    },
                    {
                        title: (!Boolean(field?.fsa_tract_number && field?.fsa_field_number)) ? ""
                            : ("T" + field?.fsa_tract_number + " / " + "F" + field?.fsa_field_number) || "Field",
                        path: `/vault/${crop_year}/${location_state_name}/${location_county_name}/${producer_farm_id}/${producer_farm_field_id}`,
                        icon: null
                    },
                ]}
            />

            <Box sx={{ width: '100%', maxWidth: formMaxWidth, minHeight: '6in' }}>
                <Stack direction="row" spacing={2} className='headerWithMenuButton'>
                    <Typography variant='h1'>
                        Crop Profile
                    </Typography>
                    {formStatusComponent}
                </Stack>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={4} lg={3}>
                        <FormControl fullWidth>
                            <InputLabel id='selectYearLabel'>Crop Year</InputLabel>
                            <Select
                                labelId='selectYearLabel'
                                value={state?.crop_year || getCurrentReinsuranceYear()}
                                name={"crop_year"}
                                onChange={handleChangeStateSelect}
                                label={'Program Year'}
                            >
                                {getBudgetYears()?.map((currentItem, index) => {
                                    return <MenuItem key={index} value={currentItem}>{currentItem}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={0} md={12}>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        {commodityDataFormSelect}
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        {typeDataFormSelect}
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        {practiceDataFormSelect}
                    </Grid>

                    <Grid item xs={0} md={12}>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Acres"
                                name="reported_acreage"
                                isRequired={true}
                                is
                                defaultValue={state.reported_acreage || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                maskType={MASK_NUMERIC}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Share %"
                                name="share_percent"
                                isRequired={true}
                                is
                                defaultValue={state.share_percent || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                maskType={MASK_NUMERIC}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={0} md={12}>
                        <Divider />
                        <Typography variant='h1'>
                            Yields
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Approved Yield (APH)"
                                name="approved_yield"
                                isRequired={true}
                                is
                                defaultValue={state.approved_yield || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                maskType={MASK_NUMERIC}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Rate Yield"
                                name="rate_yield"
                                isRequired={true}
                                is
                                defaultValue={state.rate_yield || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                maskType={MASK_NUMERIC}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Adjusted Yield"
                                name="adjusted_yield"
                                isRequired={false}
                                is
                                defaultValue={state.adjusted_yield || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                maskType={MASK_NUMERIC}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} lg={3}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Average Yield"
                                name="average_yield"
                                isRequired={false}
                                is
                                defaultValue={state.average_yield || ''}
                                variant="outlined"
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                                maskType={MASK_NUMERIC}
                            />
                        </FormControl>
                    </Grid>

                </Grid>
            </Box>

            {confirmDialogComponent}
        </>
    )
}
