import useDialog from "@datanac/datanac-mui-toolkit/dist/hooks/useDialog";
import { Add, DeleteForeverOutlined } from "@mui/icons-material";
import { Box, Card, CardHeader, Dialog, IconButton } from "@mui/material";
import { FinancialIcon } from "components/Icons/Icons";
import { ButtonLargeSquare } from "mui-toolkit/ButtonLargeSquare";
import { useState } from "react";
import { v4 } from "uuid";
import { FinancialStatementSchedule_Accounts_Edit } from "./FinancialStatementSchedule_Accounts_EditDialog";
import { schedule_financial_statement_accounts_liquid } from "./useFinancialStatementSchedule";
import { useSnackbar } from "notistack";

/*

FinancialStatementSchedule_Accounts_View model
- Financial Institution name
- Account Type ["Cash", "Savings", "CD", "Money Market", "Other"]
- Value $
- Adjusted Value $
- Prior Value $

*/
export const SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_CHECKING = "Checking Account";
export const SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_RETIRMENT = "Retirement";
export const SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_CASH = "Cash";
export const SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_SAVINGS = "Savings Account";
export const SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_CD = "CD";
export const SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_MONEYMARKET = "Money Market";
export const SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_OTHER = "Other";

export const SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_SALES = "Sales";
export const SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_EXPENSE = "Expense";
export const SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_LIVESTOCK = "Livestock";
export const SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_PREPAID = "Prepaid";
export const SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_STOCKS = "Stocks";
export const SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_COMMODITIES = "Commodities";
export const SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_MORTGAGE = "Mortgage";

export const SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPES = [

    SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_CHECKING,
    SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_RETIRMENT,
    SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_CASH,
    SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_SAVINGS,
    SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_CD,
    SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_MONEYMARKET,
    SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_OTHER,

    SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_SALES,
    SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_EXPENSE,

    SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_LIVESTOCK,

    SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_PREPAID,
    SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_STOCKS,
    SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_COMMODITIES,

    SCHEDULE_FINANCIAL_STATEMENT_ACCOUNTTYPE_MORTGAGE
];

// ---

export const SYSTEM_PROMPT_ACCOUNT_PARSER_LIQUID = `
You are a customer service agent, responsible for adding customer records into "Tilley" Farm Financial Software. Please listen for details on new accounts and return the best available information in JSON format, matching the schema provided below. Only return new or affected records.

### Return values:
- Return only JSON. Do not quote the response.
- To delete a record, set 'is_active' to 'false'.  
- Set 'id' to 'null'.
- Set 'value' to '0' if not available.
- Set 'year' to the current year (2024).
- For 'financial_institution_name', expand acronyms for the professional institution name. Search online if necessary.

### Required fields:
Please provide the most likely choice for these required fields:
- year
- financial_institution_name
- account_name
- account_type
- value

**account_type** can be one of: 
- "Checking Account"
- "Savings Account"
- "CD"
- "Money Market"
- "Investment Account"
- "Retirement Account"
- "Other". 

### SAMPLE RESPONSE:
'''json
[
    {
        "id": null,
        "year": 2024,
        "financial_institution_name": "FNB",
        "account_name": "My Operating Account",
        "account_type": "Checking Account",
        "value": 100000,
        "adjusted_value": null,
        "prior_value": null,
        "term": null
    },
    {
        "id": null,
        "year": 2024,
        "financial_institution_name": "My Commty Bank",
        "account_name": "My Checking Account",
        "account_type": "Checking Account",
        "value": 1000,
        "adjusted_value": null,
        "prior_value": null,
        "term": null
    },
    {
        "id": null,
        "year": 2024,
        "financial_institution_name": "My Commty Bank",
        "account_name": "5y Deposit",
        "account_type": "CD",
        "value": 1000,
        "adjusted_value": null,
        "prior_value": null,
        "term": "5 years"
    }
]
'''
`;

export const FinancialStatementSchedule_Accounts_Liquid_View = ({
    year,
    accounts,
    onChange,
    is_lender
}) => {

    const { enqueueSnackbar } = useSnackbar();
    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();

    const CATEGORY = schedule_financial_statement_accounts_liquid;

    const [editingItem, setEditingItem] = useState(null);

    const handleChange = async (account) => {
        if (!account.id) {
            account.id = v4();
        }
        if (!account.year && year) {
            account.year = year;
        }
        if (!account.category) {
            account.category = CATEGORY;
        }

        onChange && onChange(account);
    }

    const handleDeleteOnClick = (account) => {
        confirmDialogActions.confirm({
            title: "Delete this entry?",
            prompt: "Are you sure you want to delete this entry?",
            promptDetail: null,
            callback: () => {
                const _newState = { ...account };
                _newState.is_active = false;
                enqueueSnackbar("Account deleted", { preventDuplicate: true, variant: 'info' });
                handleChange(_newState);

                return false;
            }
        })
    }

    return (<>
        <Box className='cardContainer'>
            {(accounts || [])
                ?.filter(item => item.is_active != false)
                ?.map((item, index) =>
                    <Card sx={{ height: "100%" }} key={index} onClick={() => setEditingItem(item)}>
                        <CardHeader
                            avatar={<FinancialIcon />}
                            title={item?.account_name || item?.name}
                            subheader={item?.financial_institution_name + ' (' + item?.account_type + ')'}
                            action={<>
                                <IconButton onClick={(e) => { handleDeleteOnClick(item); e.stopPropagation(); return false; }}>
                                    <DeleteForeverOutlined sx={{ color: 'black' }} />
                                </IconButton>
                            </>}
                            sx={{ mb: 2 }}
                        />
                    </Card>
                )}

            <ButtonLargeSquare
                sx={{ backgroundColor: "#efefef", justifySelf: "flex-end" }}
                icon={<Add />}
                title={"Add New"}
                subHeader={null}
                onClick={() => setEditingItem({})}
            />
        </Box>

        <Dialog open={Boolean(editingItem != null)}>
            <FinancialStatementSchedule_Accounts_Edit
                onChange={handleChange} onClose={() => setEditingItem(null)}
                account={editingItem} is_lender={is_lender} />
        </Dialog>

        {confirmDialogComponent}
    </>);
}
