import { useCardController, useDialog } from '@datanac/datanac-mui-toolkit'
import AddCircleOutline from '@mui/icons-material/AddCircleOutline'
import { Box, Button, CircularProgress, Divider, FormControl, Grid, Stack, Typography, useMediaQuery } from '@mui/material'
import { AipPassApiHelper, UsersApiHelper } from 'api/ApiHelper'
import { AppContext } from 'App'
import EmptyCard from 'components/Cards/EmptyCard'
import { propUpdateReducer } from 'components/Core/propUpdateReducer'
import { StatefulControlledTextField } from 'components/Core/StatefulControlledTextField'
import { FarmVaultIcon } from 'components/Icons/Icons'
import { useBlockerPrompt } from 'components/Menu/useBlockerPrompt'
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from 'components/Workspaces/helpers'
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import WorkspaceViewSelector from 'components/Workspaces/WorkspaceModeSelector'
import _ from 'lodash'
import { SkeletonWrapper } from 'mui-toolkit/SkeletonWrapper'
import { useFormStatus } from 'mui-toolkit/useFormStatus'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4, v4 } from 'uuid'
import CropProfileCard from './components/CropProfileCard'
import ProducerFarmCropWithHistoryDataGrid, { COLUMNS_PRODUCERFARMCROPWITHHISTORY } from './components/ProducerFarmCropWithHistoryDataGrid'
import ProducerFarmFieldCropDataGrid from './components/ProducerFarmFieldCropDataGrid'
import { CROP_YEAR_POTENTIAL } from './helpers'
import { useFarmController } from './useFarmController'
import { SYSTEM_PROMPT_FARM_EVENTS } from './VaultCurrentWorkspace'
import { ChatAssistantButton } from 'mui-toolkit/ChatAssistantButton'
import { FarmEventList } from './components/FarmEventList'
import { USER_ROLE_ADMIN } from 'core/useSession'


export default function FieldWorkspace() {

    const { globalState, globalDispatch, } = useContext(AppContext);
    const {
        crop_year,
        location_state_name,
        location_county_name,
        producer_farm_id,
        producer_farm_field_id,
    } = useParams();
    const navigate = useNavigate();

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'), { noSsr: true });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }
    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingCrops, setIsLoadingCrops] = useState(true);
    const { component: formStatusComponent, actions: formStatusActions, state: formStatusState } = useFormStatus();

    // --- --- ---

    const [farmFilter] = useState({
        crop_year
    });
    const {
        isLoadingProducerFarmFieldCrops,
        producerFarmFieldCrops,
        isLoadingProducerFarmFieldCropEvents,
        producerFarmFieldCropEvents,
        _loadProducerFarmFieldCropEvents,
    } = useFarmController({
        crop_year: crop_year,
        filter: farmFilter,
        doLoadProducerFarmFieldCropEvents: true, /*Load events*/
        onError: handleError
    });

    const [systemPromptFarmEvents, setSystemPromptFarmEvents] = useState(null);
    useEffect(() => {
        if (producerFarmFieldCrops?.length) {
            // map/filter, removing unwanted fields; only show certain fields:
            const mappedFields = [
                "id",
                "commodity_name", "type_name", "practice_name",
                "approved_yield",
            ]

            setSystemPromptFarmEvents(SYSTEM_PROMPT_FARM_EVENTS + "\n ```json" + JSON.stringify(producerFarmFieldCrops) + '\n```');
        }
    }, [producerFarmFieldCrops])

    const handleOnChangeObject_EventItem = async (_eventItem) => {
        _eventItem.id = v4();
        _eventItem.producer_token = globalState.producer_token;
        _eventItem.crop_year = crop_year;
        _eventItem.year = crop_year;
        _eventItem.is_active = true;
        if (!_eventItem.module) _eventItem.module = "Other";
        if (!_eventItem.category) _eventItem.category = "Other";

        await UsersApiHelper.updateProducerFarmFieldCropEvent(_eventItem);
        _loadProducerFarmFieldCropEvents();
    }

    // ---

    const [producerFarm, setProducerFarm] = useState({});
    const [state, dispatch] = useReducer(propUpdateReducer, {
        is_active: true
    });

    const loadData = async () => {
        try {
            const producerFarmCropUnitsFilter = {
                producer_token: globalState.producer_token,
                id: producer_farm_id,
                ...(crop_year != CROP_YEAR_POTENTIAL && { crop_year }),
                is_active: true
            };
            const farms = await UsersApiHelper.selectProducerFarms(producerFarmCropUnitsFilter, globalState);
            if (farms?.length) {
                setProducerFarm(farms[0]);
            }

            if (state?.id && state?.id != 0) {
                const fieldsFilter = {
                    producer_token: globalState.producer_token,
                    producer_farm_id: producer_farm_id,
                    id: state?.id,
                    is_active: true
                };
                const fields = (await UsersApiHelper.selectProducerFarmFields(fieldsFilter, globalState));
                if (fields?.length) {
                    dispatch({
                        type: 'updateMany',
                        payload: fields[0]
                    });
                }
            }

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);

            handleError(err);
        }
    }

    // --- --- ---

    const [cropsWorkspaceMode, setCropsWorkspaceMode] = useState(WORKSPACE_VIEW_CARD);
    const { state: crops, actions: cropActions } = useCardController([]);
    const loadProducerFarmFieldCrops = async () => {
        try {
            const cropsFilter = {
                producer_token: globalState.producer_token,
                producer_farm_field_id: state?.id,
                ...(crop_year != CROP_YEAR_POTENTIAL && { crop_year }),
                is_active: true
            };
            const crops = await UsersApiHelper.selectProducerFarmFieldCrops(cropsFilter, globalState);
            cropActions.replace(crops);

            setIsLoadingCrops(false);
        } catch (err) {
            setIsLoadingCrops(false);
            cropActions.replace([]);
            handleError(err);
        }
    }

    useEffect(() => {
        if (state?.id) {
            loadProducerFarmFieldCrops();
        }
    }, [state?.id, globalState?.producer_token])

    // ---

    const handleProducerFarmCropOnChange = (currentProducerFarmFieldCrop) => {
        if (currentProducerFarmFieldCrop?.id) {
            UsersApiHelper.updateProducerFarmFieldCrop(currentProducerFarmFieldCrop, globalState)
                .then(() => {
                    loadProducerFarmFieldCrops();
                })
                .catch(err => {
                    console.warn(err);
                    enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
                });
        }
    }

    // ---

    const { state: history, actions: historyActions } = useCardController();
    const loadProducerFarmCropWithHistory = async () => {
        try {
            const historyFilter = {
                producer_token: globalState.producer_token,

                location_state_name: producerFarm?.location_state_name,
                location_county_name: producerFarm?.location_county_name,
                fsa_farm_number: producerFarm?.fsa_farm_number,
                fsa_tract_number: state?.fsa_tract_number,
                fsa_field_number: state?.fsa_field_number,

                yield_type_code: 'A'
            };
            const history = (await AipPassApiHelper.selectProducerFarmCropWithHistory(historyFilter, globalState)).map(
                (h, index) => ({ id: index, ...h })
            );
            history.forEach((h, index) => {
                h.id = index;
                h.unique_key = JSON.stringify(
                    COLUMNS_PRODUCERFARMCROPWITHHISTORY.map(col => h[col?.field])
                );
            });
            const _historyUnique = _.uniqBy(history, "unique_key");
            historyActions.replace(_historyUnique);
        } catch (err) {
            historyActions.replace([]);
            handleError(err);
        }
    }

    useEffect(() => {
        if (globalState?.producer_token && location_state_name && location_county_name
            && producerFarm?.id && state?.id
            && producerFarm?.fsa_farm_number
            && state.fsa_tract_number && state.fsa_field_number
        ) {
            loadProducerFarmCropWithHistory();
        }
    }, [location_state_name, location_county_name, producer_farm_id,
        producerFarm, state,
        globalState?.producer_token,])

    // ---

    const handleChangeState = (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        if (state[event.target.name] != value) {
            formStatusActions.setIsFormDirty(true, false);

            dispatch({
                type: 'update',
                payload: { key: event.target.name, value: value },
            });
        }
    }

    useEffect(() => {
        dispatch({
            type: 'update',
            payload: { key: "producer_farm_id", value: producer_farm_id },
        });

        if (producer_farm_field_id == 0) {
            const _producer_farm_field_id = uuidv4();
            dispatch({
                type: 'update',
                payload: { key: "id", value: _producer_farm_field_id },
            });
        } else {
            dispatch({
                type: 'update',
                payload: { key: "id", value: producer_farm_field_id },
            });
        }
    }, [producer_farm_id, producer_farm_field_id]);

    const isValid = () => {
        return (
            state?.id
            && state.id != 0
            && state?.fsa_tract_number
            && state?.fsa_field_number
        );
    }
    useBlockerPrompt(!isValid(), "ProducerFarmField");

    useEffect(() => {
        if (isValid() && globalState.producer_token && formStatusState?.isFormDirty) {
            formStatusActions.setIsFormDirty(true, true);

            UsersApiHelper.updateProducerFarmField(state, globalState)
                .then(() => {
                    formStatusActions.setIsSaving(false);
                })
                .catch(err => {
                    formStatusActions.setIsFormDirty(true, false);
                    handleError(err);
                });
        }
    }, [state])

    // --- --- ---

    const handleAddNewClick = () => {
        if (state?.id) {
            navigate(`/vault/${crop_year}/${location_state_name}/${location_county_name}/${state?.producer_farm_id}/${state?.id}/0`);
        }
    }

    useEffect(() => {
        if (globalState?.producer_token) {
            loadData();
        }
    }, [globalState?.producer_token, state?.id]);

    return (
        <>
            <WorkspaceHeader
                title={'Field ' + ((state?.fsa_tract_number && state?.fsa_field_number) ? state?.fsa_tract_number + ' - ' + state?.fsa_field_number : '')}
                breadCrumbs={[
                    {
                        title: (crop_year != CROP_YEAR_POTENTIAL ? 'Planted Acres' : 'Farm Potential')
                            + ((crop_year != CROP_YEAR_POTENTIAL && " - " + crop_year) || ''),
                        path: '/vault' + (crop_year == CROP_YEAR_POTENTIAL ? "/potential" : ""),
                        icon: <FarmVaultIcon fontSize='3pt' />
                    },
                    {
                        title: location_county_name + ", " + location_state_name,
                        path: `/vault/${crop_year}/${location_state_name}/${location_county_name}`,
                        icon: null
                    },
                    {
                        title: (!Boolean(producerFarm?.fsa_farm_number && producerFarm?.farm_name)) ? producerFarm?.fsa_farm_number :
                            producerFarm?.fsa_farm_number + " - " + producerFarm?.farm_name,
                        path: `/vault/${crop_year}/${location_state_name}/${location_county_name}/${producerFarm?.id}`,
                        icon: null
                    },
                ]}
            />

            <Grid container spacing={2}>
                <Grid item xs={12} lg={8}>
                    <Stack direction="row" spacing={2} className='headerWithMenuButton'>
                        <Typography variant='h1'>
                            Field Details
                        </Typography>
                        {formStatusComponent}
                    </Stack>

                    <Grid container spacing={1}>
                        <Grid item xs={12} md={4} lg={3}>
                            <SkeletonWrapper isLoading={isLoading}>
                                <FormControl fullWidth={true}>
                                    <StatefulControlledTextField
                                        label="Tract #"
                                        name="fsa_tract_number"
                                        isRequired={true}
                                        defaultValue={state.fsa_tract_number || ''}
                                        variant="outlined"
                                        onChange={handleChangeState}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                            </SkeletonWrapper>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <SkeletonWrapper isLoading={isLoading}>
                                <FormControl fullWidth={true}>
                                    <StatefulControlledTextField
                                        label="Field #"
                                        name="fsa_field_number"
                                        isRequired={true}
                                        defaultValue={state.fsa_field_number || ''}
                                        variant="outlined"
                                        onChange={handleChangeState}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                            </SkeletonWrapper>
                        </Grid>
                    </Grid>

                    <Divider />

                    <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton'>
                        <Typography variant='h1'>
                            Crop Profile
                        </Typography>
                        <WorkspaceViewSelector workspaceMode={cropsWorkspaceMode} setWorkspaceMode={setCropsWorkspaceMode} />
                    </Stack>
                    {cropsWorkspaceMode == WORKSPACE_VIEW_CARD &&
                        <Box className='cardContainer'>
                            {crops.map((currentCrop, index) =>
                                <CropProfileCard producerFarm={producerFarm} cropProfile={currentCrop} onChange={handleProducerFarmCropOnChange} key={index} />
                            )}
                            {!Boolean(crops?.length) &&
                                <EmptyCard
                                    text="No crops listed"
                                    secondaryText="Get started by adding a crop profile"
                                    isLoading={isLoadingCrops}
                                />}
                        </Box>}
                    {cropsWorkspaceMode == WORKSPACE_VIEW_TABLE && <>
                        <ProducerFarmFieldCropDataGrid producerFarmFieldCrops={crops} />
                    </>}

                    {Boolean(history?.length) && <>
                        <Divider />
                        <Typography variant='h1'>
                            Field History
                        </Typography>
                        <ProducerFarmCropWithHistoryDataGrid producerFarmCropWithHistory={history} />
                    </>}
                </Grid>

                {globalState?.user_role == USER_ROLE_ADMIN &&
                    <Grid item xs={12} lg={4}>
                        {isMobile && <Divider flexItem />}
                        {Boolean(producerFarmFieldCropEvents?.length || isLoadingProducerFarmFieldCropEvents) && <>
                            <Stack direction="row" spacing={2} className='headerWithMenuButton'>
                                <Typography variant='h1'>
                                    Farm Events
                                </Typography>
                                {systemPromptFarmEvents && <ChatAssistantButton systemPrompt={systemPromptFarmEvents} onChangeObject={handleOnChangeObject_EventItem} />}
                                {isLoadingProducerFarmFieldCropEvents && <CircularProgress />}
                            </Stack>

                            <FarmEventList producerFarmFieldCropEvents={producerFarmFieldCropEvents} />
                        </>}
                    </Grid>}
            </Grid>


            <WorkspaceActionBar content={<>
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={handleAddNewClick} disabled={!isValid()}>
                        <AddCircleOutline sx={{ mr: 1 }} />
                        Add Crop
                    </Button>
                </Stack>
            </>} />

            {confirmDialogComponent}
        </>
    )
}
