import { CloseOutlined, SaveOutlined } from "@mui/icons-material";
import { Button, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Stack, useMediaQuery } from "@mui/material";
import { AppContext } from "App";
import { UsersApiHelper } from "api/ApiHelper";
import { StatefulControlledTextField } from "components/Core/StatefulControlledTextField";
import { propUpdateReducer } from "components/Core/propUpdateReducer";
import { useSnackbar } from "notistack";
import { useContext, useReducer, useState } from "react";
import { v4 as uuidv4 } from 'uuid';

export const NewMemberDialog = ({
    member,

    onClose
}) => {

    const { globalState, globalDispatch } = useContext(AppContext);

    const { enqueueSnackbar } = useSnackbar();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const [state, dispatch] = useReducer(propUpdateReducer, {
        is_active: true,
    });

    // --- --- ---

    const isValid = () => {
        return Boolean(
            user?.email
            && user?.description
        );
    }

    // --- --- ---

    const [isLoading, setIsLoading] = useState(false);

    const [user, dispatchUser] = useReducer(propUpdateReducer, member);

    const handleChangeState = (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        dispatch({
            type: 'update',
            payload: { key: event.target.name, value: value },
        });
    }

    const handleChangeStateSelect = (event) => {
        dispatch({
            type: 'update',
            payload: { key: event.target.name, value: event.target.value },
        });
    }

    // --- --- ---

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    const handleSaveClick = async () => {
        try {
            const _userGrant = {
                id: uuidv4(),
                is_master_read: true,
                is_master_write: true,
                is_master_delete: true,

                producer_token: state?.producer_token,
                user_id: member?.id,
                is_active: true
            };
            // is_active is always false for new records by default.
            await UsersApiHelper.updateUserGrant(_userGrant, {});//{}: admin function: do not pass globalState.
            // Second pass will update is_active. (only admin can update is_active)
            await UsersApiHelper.updateUserGrant(_userGrant, {});//{}: admin function: do not pass globalState.

            onClose && onClose();
        } catch (err) {
            handleError(err);
        }
    }

    return (<>
        <DialogTitle>
            Add Member
        </DialogTitle>

        <DialogContent>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth={true}>
                        <StatefulControlledTextField
                            label="DATANAC Producer Token"
                            name="producer_token"
                            isRequired={true}
                            defaultValue={member.producer_token || ''}
                            variant="outlined"
                            onChange={handleChangeState}
                            InputLabelProps={{ shrink: true }}
                            isLoading={isLoading}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </DialogContent>

        <DialogActions>
            <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap" justifyContent='flex-end'>
                <Button variant='contained' color='secondary' autoFocus onClick={onClose}>
                    <CloseOutlined sx={{ mr: 1 }} />
                    Cancel
                </Button>
                <Button variant='contained' color='primary' onClick={handleSaveClick} disabled={!isValid()}>
                    <SaveOutlined sx={{ mr: 1 }} />
                    Save
                </Button>
            </Stack>
        </DialogActions>
    </>)
}