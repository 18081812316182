import React from "react";

const VimeoPlayer = ({ videoId, width="100%", height="400px" }) => {
    const isValidId = (id) => /^[a-zA-Z0-9]{9}$/.test(id);
    if (!isValidId(videoId)) {
        return <div className="error">Invalid video ID</div>;
    }
    //const src = `https://player.vimeo.com/video/${videoId}`;
    // Budget
    const src = "https://datanac-public-files.s3.us-east-2.amazonaws.com/team-courses/RMA23CPT0013646_Tilley_Lesson2_Audio_Version_June21.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIASGVWXZQZ7TPJ67XZ%2F20241021%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20241021T221116Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=547c725dedb6c2f63a05148614bf27b194e9012132ee5bcd8ee575c989019f56";
    return (
        <iframe
            src={src}
            width={width}
            height={height}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
            title="Video Player"
            style={{
                pointerEvents: "auto",
            }}
        ></iframe>
    );
};

export default VimeoPlayer;
