import EastOutlined from '@mui/icons-material/EastOutlined';
import { Box, Chip, IconButton, Stack } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { useNavigate } from 'react-router-dom';
import { formatPracticeName } from 'utility/formatters';

const getNameSummary = params => {
    const farmRow = params.row;

    const nameLabel = farmRow?.business_name ? farmRow.business_name : farmRow.last_name;
    return nameLabel;
}

const getStateCountySummary = params => {
    const farmRow = params.row;

    const label = `${farmRow?.location_county_name}, ${farmRow?.location_state_name}`;
    return label;
}

const getFarmRowSummary = params => {
    const farmRow = params.row;

    const nameLabel = farmRow?.business_name ? farmRow.business_name : farmRow.last_name;
    const commodityLabel = `${farmRow?.commodity_name} ${farmRow?.practice_name}`
    return String(`${nameLabel} ${farmRow.location_county_name} / ${farmRow.fsa_farm_number} T${farmRow.fsa_tract_number} (${farmRow.fsa_field_number}) / ${farmRow.basic_unit_number}-${farmRow.optional_unit_number} / ${commodityLabel}`);
}

export default function ProducerFarmFieldDataTable({
    title,
    producerFarmFields,
}) {
    const navigate = useNavigate();

    const columns = [
        {
            name: 'fsa_tract_number',
            label: 'Tract',
            flex: 1
        },
        {
            name: 'fsa_field_number',
            label: 'Field',
            flex: 1
        },
        {
            name: 'reported_acreage',
            label: 'Acres',
            flex: 1
        },
        {
            name: 'CropProfiles_Sort',
            label: 'Crop Profiles',
            options: {
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const currentProducerFarmCrop = producerFarmFields[dataIndex];
                    return <Box>
                        <Stack direction="row" spacing={0} sx={{ flexWrap: "wrap", gap: 1 }}>
                            {currentProducerFarmCrop?.CropProfiles?.map((currentCropProfile, index) =>
                                <Chip key={index} label={currentCropProfile?.commodity_name + ' ' + formatPracticeName(currentCropProfile?.practice_name)} />
                            )}
                        </Stack>
                    </Box>;
                }
            },
            flex: 0
        },
        {
            name: 'id',
            label: 'ID',
            options: {
                customHeadRender: () => "",
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const currentProducerFarmField = producerFarmFields[dataIndex];
                    return <Box display="flex" justifyContent='flex-end'>
                        <IconButton onClick={() => { navigate(`./${currentProducerFarmField?.id}`) }}>
                            <EastOutlined />
                        </IconButton>
                    </Box>;
                },
            },
            flex: 0
        },
    ];

    const columns_xs = [
        {
            name: "fsa_farm_number",
            label: 'Unit',
            valueGetter: getFarmRowSummary,
            flex: 1
        },
    ];

    const options = {
        rowsPerPage: 10,
        rowsPerPageOptions: [5, 10, 25, 50, 100],
        selectableRows: "none",

        downloadOptions: {
            filename: "Fields.csv"
        },
        fixedSelectColumn: false,

        responsive: 'vertical',

        expandableRows: false,
    }

    return (
        <div className='MUI-Data-Table'>
            <MUIDataTable
                title={title}
                data={producerFarmFields}
                columns={columns}
                options={options}
                sx={{ width: "99%", boxSizing: "border-box" }}
                autoHeight
            />
        </div>
    );
}
