import { ProducerFarmHelper } from '@datanac/datanac-api-toolkit';
import { useCardController } from '@datanac/datanac-mui-toolkit';
import { ArrowDropDownOutlined } from '@mui/icons-material';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import { Box, CircularProgress, Menu, MenuItem, Stack, Typography, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import { AppContext } from 'App';
import BudgetSummaryCard from 'components/Budget/components/BudgetSummaryCard';
import { BUDGET_STATUS_ACTIVE, BUDGET_STATUS_DRAFT, getBudgetYears } from 'components/Budget/helpers';
import EmptyCard from 'components/Cards/EmptyCard';
import BudgetOnlineHelp from 'components/OnlineHelp/BudgetOnlineHelp';
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from 'components/Workspaces/helpers';
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar';
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader';
import WorkspaceViewSelector from 'components/Workspaces/WorkspaceModeSelector';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UsersApiHelper } from '../../api/ApiHelper';

export default function BudgetListWorkspace() {
    const producerFarmHelper = new ProducerFarmHelper();
    const { globalState, globalDispatch, session } = useContext(AppContext);
    const navigate = useNavigate();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const [workspaceMode, setWorkspaceMode] = useState(isSmall ? WORKSPACE_VIEW_CARD : WORKSPACE_VIEW_TABLE);
    const { state: budgetCards, actions: budgetActions } = useCardController();
    const [currentYear, setCurrentYear] = useState(globalState?.crop_year);

    useEffect(() => {
        globalDispatch({
            type: 'update',
            payload: {
                key: 'crop_year',
                value: currentYear
            },
        });
    }, [currentYear]);

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    const loadBudgets = async () => {
        try {
            const budgets = (await UsersApiHelper.selectBudgets({
                year: currentYear,
                is_active: true
            }, globalState))
                ?.sort((a, b) =>
                    a.name?.localeCompare(b.name)
                );
            budgetActions.replace(budgets);
        } catch (err) {
            budgetActions.replace([]);
            handleError(err);
        }
    };

    useEffect(() => {
        if (currentYear && globalState?.producer_token) {
            setIsLoading(true);
            loadBudgets().then(() => {
                setIsLoading(false);
            });
        }
    }, [globalState?.producer_token, currentYear]);

    const handleAddNewClick = () => {
        navigate(`/budget/0`);
    };

    const handleBudgetCardOnChange = async (_newBudget) => {
        if (_newBudget) {
            if (_newBudget.status == BUDGET_STATUS_ACTIVE) {
                var previousActive = null;
                budgetCards.forEach(b => {
                    if (b?.status == BUDGET_STATUS_ACTIVE && b?.id != _newBudget?.id) {
                        previousActive = b;
                        previousActive.status = BUDGET_STATUS_DRAFT;
                    }
                });
                if (previousActive) {
                    await UsersApiHelper.updateBudget(previousActive, globalState);
                }
            }
            await UsersApiHelper.updateBudget(_newBudget, globalState);
        }
        loadBudgets();
    };

    const [isOnlineHelpOpen, setIsOnlineHelpOpen] = useState(false);
    const toggleOnlineHelpOpen = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setIsOnlineHelpOpen(open);
    };

    const handleCloseOnlineHelp = () => {
        setIsOnlineHelpOpen(false);
    }

    // ---

    return (
        <>
            <WorkspaceHeader title='Budgets' breadCrumbs={[]} />
            <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton'>
                <Typography variant='h1'>Farm Budgets</Typography>
                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                        <>
                            <Button variant="outlined" {...bindTrigger(popupState)}>
                                {currentYear} <ArrowDropDownOutlined />
                            </Button>
                            <Menu {...bindMenu(popupState)}>
                                {getBudgetYears().map((year, index) =>
                                    <MenuItem key={index} onClick={() => {
                                        setCurrentYear(year);
                                        popupState.close();
                                    }}>
                                        {year}
                                    </MenuItem>
                                )}
                            </Menu>
                        </>
                    )}
                </PopupState>
                <WorkspaceViewSelector workspaceMode={workspaceMode} setWorkspaceMode={setWorkspaceMode} />
                {isLoading && <CircularProgress />}
            </Stack>
            <Box className='cardContainer'>
                {budgetCards.map((currentBudget, index) =>
                    <BudgetSummaryCard key={index} budget={currentBudget} onChange={handleBudgetCardOnChange} />
                )}

                {!Boolean(budgetCards?.length) &&
                    <EmptyCard
                        text="No Budgets Defined"
                        secondaryText="Get started by adding a budget"
                        isLoading={isLoading}
                    />}
            </Box>
            <WorkspaceActionBar content={<>
                <Box>
                    <Button
                        variant="contained"
                        sx={{ width: '100%' }}
                        onClick={handleAddNewClick}
                    >
                        <AddCircleOutline sx={{ mr: 1 }} />
                        New Budget
                    </Button>
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        sx={{ width: '100%' }}
                        onClick={toggleOnlineHelpOpen(true)}
                    >
                        <QuestionMarkOutlinedIcon sx={{ fontSize: 20 }} />
                    </Button>
                </Box>
            </>} />

            <BudgetOnlineHelp isOpen={isOnlineHelpOpen} onClose={handleCloseOnlineHelp} />

        </>
    );
}
