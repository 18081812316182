import { useDialog } from '@datanac/datanac-mui-toolkit'
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import { Box, CircularProgress, Grid, Menu, MenuItem, Stack, Typography, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import { AppContext } from 'App'
import { getBudgetYears } from 'components/Budget/helpers'
import { FinancialIcon } from 'components/Icons/Icons'
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from 'components/Workspaces/helpers'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { LinearIndicator } from 'mui-toolkit/LinearIndicator'
import { ValueLabel } from 'mui-toolkit/ValueLabel'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatFixed2, formatPercentage, formatPrice } from 'utility/formatters'
import useFinanceReport from './useFinanceReport'

export default function FinancialScorecardWorkspace() {
    const { globalState, globalDispatch, session } = useContext(AppContext);
    const navigate = useNavigate();

    const isXL = useMediaQuery((theme) => theme.breakpoints.up('xl'), { noSsr: true });
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { enqueueSnackbar } = useSnackbar();
    const [workspaceMode, setWorkspaceMode] = useState(isSmall ? WORKSPACE_VIEW_CARD : WORKSPACE_VIEW_TABLE);

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();

    const [currentYear, setCurrentYear] = useState(globalState?.crop_year);

    useEffect(() => {
        globalDispatch({
            type: 'update',
            payload: {
                key: 'crop_year',
                value: currentYear
            },
        });
    }, [currentYear])

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || err, { preventDuplicate: true, variant: 'error' });
    }

    const {
        isLoading,

        isLoadingBudget,
        budget,

        isLoadingArcPlc,
        arcPlc,

        isLoadingInsuranceScenario,
        insuranceScenario,

        isLoadingBalanceSheet,
        balanceSheet,

        isLoadingRatios,
        ratios,
    } = useFinanceReport({
        onError: handleError,
        currentYear
    })

    // --- --- ---

    return (
        <>
            <WorkspaceHeader
                title='Financial Score Card'
                breadCrumbs={[
                    {
                        title: 'Financial Analysis',
                        path: '/financial/summary',
                        icon: <FinancialIcon />
                    },
                ]}
            />

            <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton'>
                <Typography variant='h1'>
                    Year
                </Typography>
                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                        <>
                            <Button variant="outlined" {...bindTrigger(popupState)}>
                                {currentYear} <ArrowDropDownOutlined />
                            </Button>
                            <Menu {...bindMenu(popupState)}>
                                {getBudgetYears().map((year, index) =>
                                    <MenuItem key={index} onClick={() => {
                                        setCurrentYear(year);
                                        popupState.close();
                                    }}>
                                        {year}
                                    </MenuItem>)}
                            </Menu>
                        </>
                    )}
                </PopupState>
                {isLoading && <CircularProgress />}
            </Stack>

            <Grid container columnSpacing={10}>
                <Grid item xs={12} md={6} lg={6} sx={{ borderBottom: "1px solid #c0c0c0", pb: 2, mb: 2 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>Liquidity</Typography>

                    <Stack direction="column" spacing={5}>
                        <ValueLabel label="Working Capital"
                            value={formatPrice(balanceSheet?.working_capital)}
                            isLoading={isLoadingBalanceSheet}
                        />

                        <LinearIndicator
                            title="Current Ratio"
                            checkpoint1={1.3} checkpoint2={2.0}
                            value={balanceSheet?.current_ratio}
                            isLoading={isLoadingBalanceSheet}
                            valueFormatter={formatFixed2}
                        />

                        <LinearIndicator
                            title="Working Capital to Gross Revenue"
                            checkpoint1={0.10} checkpoint2={0.30}
                            value={ratios?.working_capital_to_gross_revenue_ratio}
                            valueFormatter={formatPercentage}
                            isLoading={isLoadingRatios}
                        />
                    </Stack>
                </Grid>

                <Grid item xs={12} md={6} lg={6} sx={{ borderBottom: "1px solid #c0c0c0", pb: 2, mb: 2 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>Solvency</Typography>

                    <Stack direction="column" spacing={5}>
                        <LinearIndicator
                            title={"Debt-to-Assets" + (!isXL ? "" : " Ratio")}
                            checkpoint1={0.60} checkpoint2={0.30}
                            value={balanceSheet?.debt_to_assets_ratio}
                            valueFormatter={formatPercentage}
                            isLoading={isLoadingBalanceSheet}
                        />

                        <LinearIndicator
                            title={"Equity-to-Assets" + (!isXL ? "" : " Ratio")}
                            checkpoint1={0.40} checkpoint2={0.70}
                            value={balanceSheet?.equity_to_assets_ratio}
                            valueFormatter={formatPercentage}
                            isLoading={isLoading}
                        />
                    </Stack>
                </Grid>

                <Grid item xs={12} md={6} sx={{ borderBottom: "1px solid #c0c0c0", pb: 2, mb: 2 }} className='pageBreakAvoid'>
                    <Typography variant="h6" sx={{ mb: 2 }}>Profitability</Typography>

                    <Stack direction="column" spacing={5}>
                        <Box className="flexWrapContainer">
                            <ValueLabel label="Net Income"
                                value={formatPrice(ratios?.net_income)}
                                isLoading={isLoadingRatios}
                            />
                            <ValueLabel label="EBIDTA"
                                value={formatPrice(ratios?.ebitda)}
                                isLoading={isLoadingRatios}
                            />
                        </Box>

                        <LinearIndicator
                            title="Return on Assets"
                            checkpoint1={0.04} checkpoint2={0.08}
                            value={ratios?.return_on_assets_ratio}
                            valueFormatter={formatPercentage}
                            isLoading={isLoadingRatios}
                        />

                        <LinearIndicator
                            title="Return on Equity"
                            checkpoint1={0.03} checkpoint2={0.10}
                            value={ratios?.return_on_equity_ratio}
                            valueFormatter={formatPercentage}
                            isLoading={isLoadingRatios}
                        />

                        <LinearIndicator
                            title="Operating Profit Margin"
                            checkpoint1={0.15} checkpoint2={0.25}
                            value={ratios?.operating_profit_margin_ratio}
                            valueFormatter={formatPercentage}
                            isLoading={isLoadingRatios}
                        />
                    </Stack>
                </Grid>

                <Grid item xs={12} md={6} sx={{ borderBottom: "1px solid #c0c0c0", pb: 2, mb: 2 }} className='pageBreakAvoid'>
                    <Typography variant="h6" sx={{ mb: 2 }}>Debt Repayment Capacity</Typography>

                    <Stack direction="column" spacing={5}>
                        <Box className="flexWrapContainer">
                            <ValueLabel label="Repayment Capacity"
                                value={formatPrice(ratios?.debt_repayment_capacity)}
                                isLoading={isLoadingRatios}
                            />
                            <ValueLabel label="Repayment Margin"
                                value={formatPrice(ratios?.repayment_margin)}
                                isLoading={isLoadingRatios}
                            />
                            <ValueLabel label="Replacement Margin"
                                value={formatPrice(ratios?.replacement_margin)}
                                isLoading={isLoadingRatios}
                            />
                        </Box>

                        <LinearIndicator
                            title={"Term-Debt Coverage" + (!isXL ? "" : " Ratio")}
                            checkpoint1={1.25} checkpoint2={1.75}
                            value={ratios?.debt_coverage_ratio}
                            valueFormatter={formatFixed2}
                            isLoading={isLoadingRatios}
                        />

                        <LinearIndicator
                            title={"Replacement Margin Coverage" + (!isXL ? "" : " Ratio")}
                            checkpoint1={1.10} checkpoint2={1.50}
                            value={ratios?.replacement_coverage_ratio}
                            valueFormatter={formatFixed2}
                            isLoading={isLoadingRatios}
                        />
                    </Stack>
                </Grid>

                <Grid item xs={12} md={6} sx={{ borderBottom: "1px solid #c0c0c0", pb: 2, mb: 2 }} className='pageBreakAvoid'>
                    <Typography variant="h6" sx={{ mb: 2 }}>Financial Efficiency</Typography>

                    <Stack direction="column" spacing={5}>
                        <LinearIndicator
                            title="Asset-Turnover Rate"
                            checkpoint1={0.30} checkpoint2={0.45}
                            value={ratios?.asset_turnover_ratio}
                            valueFormatter={formatPercentage}
                            isLoading={isLoadingRatios}
                        />

                        <LinearIndicator
                            title={"Operating/Expense" + (!isXL ? "" : " Ratio")}
                            checkpoint1={0.80} checkpoint2={0.60}
                            value={ratios?.operating_expense_ratio}
                            valueFormatter={formatPercentage}
                            isLoading={isLoadingRatios}
                        />

                        <LinearIndicator
                            title={"Depreciation/Expense" + (!isXL ? "" : " Ratio")}
                            checkpoint1={0.10} checkpoint2={0.05}
                            value={ratios?.depreciation_expense_ratio}
                            valueFormatter={formatPercentage}
                            isLoading={isLoadingRatios}
                        />

                        <LinearIndicator
                            title={"Interest/Expenses" + (!isXL ? "" : " Ratio")}
                            checkpoint1={0.10} checkpoint2={0.05}
                            value={ratios?.interest_expense_ratio}
                            valueFormatter={formatPercentage}
                            isLoading={isLoadingRatios}
                        />

                        <LinearIndicator
                            title={"Net Income" + (!isXL ? "" : " Ratio")}
                            checkpoint1={0.10} checkpoint2={0.20}
                            value={ratios?.income_ratio}
                            valueFormatter={formatPercentage}
                            isLoading={isLoadingRatios}
                        />
                    </Stack>
                </Grid>
            </Grid >

            <WorkspaceActionBar content={<>
            </>} />

            {confirmDialogComponent}
        </>
    )
}
