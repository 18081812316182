import { RmaApiHelper, UsersApiHelper } from "api/ApiHelper";
import { v4 as uuidv4 } from 'uuid';
import { BUDGET_LINEITEMS, BUDGET_LINEITEM_NAME_INTEREST, BUDGET_STATUS_ACTIVE, BUDGET_STATUS_DRAFT } from "./helpers";
import { getCurrentReinsuranceYear } from "@datanac/datanac-api-toolkit/dist/utility/InsurancePlanHelper";

export const getUnitOfMeasureAbbreviation = async (countyPractice) => {
    const insuranceOffers = await RmaApiHelper.selectObject("InsuranceOffer", {
        reinsurance_year: countyPractice.reinsurance_year || String(countyPractice.year) || getCurrentReinsuranceYear(),
        commodity_name: countyPractice.commodity_name,
        type_name: countyPractice.type_name,
        practice_name: countyPractice.practice_name,
        state_name: countyPractice.state_name,
        county_name: countyPractice.county_name
    });
    if (insuranceOffers?.length) {
        const unit_of_measure_abbreviation = insuranceOffers[0]?.unit_of_measure_abbreviation;
        return unit_of_measure_abbreviation;
    }
}

export function BudgetService({
    apiConfig,

    year
}) {

    const getBudget = async (budget_id) => {
        const _budgetQuery = {
            is_active: true
        };
        if (budget_id) {
            // Lookup by ID:
            _budgetQuery.id = budget_id;
        } else {
            // Getting current budget for this year:
            _budgetQuery.year = year;
            _budgetQuery.status = BUDGET_STATUS_ACTIVE;
        }

        const _budgets = await UsersApiHelper.selectBudgets(_budgetQuery, apiConfig);
        const _budget = _budgets?.length && _budgets[0];

        if (_budget) {
            const _budgetCountyPractices = await UsersApiHelper.selectBudgetCountyPractices({
                budget_id: _budget?.id, is_active: true,
            }, apiConfig);
            const _budgetCountyPracticeLineItems = await UsersApiHelper.selectBudgetCountyPracticeLineItems({
                budget_id: _budget?.id, is_active: true,
            }, apiConfig);

            const budget = {
                ..._budget,
                budgetCountyPractices: _budgetCountyPractices
            }

            budget.interest_cost = _.sumBy(_budgetCountyPracticeLineItems.filter(li =>
                li.name == BUDGET_LINEITEM_NAME_INTEREST
            ), li => li.value);

            for (const cp of budget?.budgetCountyPractices) {
                // budget.budgetCountyPractices.forEach(cp => {
                cp.lineItems = _budgetCountyPracticeLineItems.filter(li =>
                    li.budget_county_practice_id == cp.id
                );

                cp.unit_of_measure_abbreviation = await getUnitOfMeasureAbbreviation(cp);
            };

            const lineItems = [];
            BUDGET_LINEITEMS.forEach(lineItemDescriptor => {
                const _currentLineItemName = lineItemDescriptor[1];
                const _newTotalLineItem = {
                    name: _currentLineItemName,
                    value: 0,
                };

                budget.budgetCountyPractices.forEach(cp => {
                    cp.lineItems
                        .filter(cpli => cpli.name == _currentLineItemName)
                        .forEach(filteredli => {
                            _newTotalLineItem.value += (filteredli?.value * cp.reported_acreage) || 0
                        });

                    cp.revenue = (cp.projected_price * cp.yield * cp.reported_acreage) || 0;
                    cp.production_cost_per_acre = _.sumBy(cp.lineItems, li => li.value || 0);
                    cp.production_cost = cp.production_cost_per_acre * cp.reported_acreage;
                })

                lineItems.push(_newTotalLineItem);
            })

            budget.reported_acreage = _.sumBy(budget.budgetCountyPractices, cp => cp?.reported_acreage);
            budget.revenue = _.sumBy(budget.budgetCountyPractices, cp => cp?.revenue);
            budget.production_cost = _.sumBy(budget.budgetCountyPractices, cp => cp?.production_cost);
            budget.net_income = budget.revenue - budget.production_cost;

            budget.lineItems = lineItems;
            budget.lineItems.forEach(li => {
                budget[li.name.toLowerCase() + "_cost"] = li.value;
            });

            // Commodities:
            const _budgetCommodities = budget.budgetCountyPractices &&
                _.uniqBy(_budgetCountyPractices, cp => cp.commodity_name)
                    ?.map(cp => ({
                        commodity_name: cp.commodity_name
                    }));
            budget.budgetCommodities = _budgetCommodities;
            budget.budgetCommodities.forEach(currentCommodity => {
                const _foundCountyPractices = budget.budgetCountyPractices
                    ?.filter(cp => cp.commodity_name == currentCommodity.commodity_name);
                if (_foundCountyPractices && _foundCountyPractices?.length) {
                    currentCommodity._foundCountyPractices = _foundCountyPractices;
                    currentCommodity.revenue = _.sumBy(_foundCountyPractices, cp => cp?.revenue);
                    currentCommodity.production_cost = _.sumBy(_foundCountyPractices, cp => cp?.production_cost);
                    currentCommodity.net_income = currentCommodity.revenue - currentCommodity.production_cost;
                }
            });

            budget.expenses = {};
            budget.expenses.total = _.sum(budget.budgetCountyPractices?.map(cp => cp.production_cost));

            return budget;
        }
    }

    const cloneBudget = async ({ budget_id }) => {
        const _copyBudget = (await getBudget(budget_id)) || {};

        // Change IDs:
        const _newID = uuidv4();
        _copyBudget.id = _newID;
        _copyBudget.name = "Copy of " + _copyBudget.name;
        _copyBudget.status = BUDGET_STATUS_DRAFT;
        _copyBudget.budgetCountyPractices.forEach(cp => {
            cp.id = uuidv4();
            cp.budget_id = _newID;
            cp.lineItems.forEach(line => {
                line.id = uuidv4();
                line.budget_county_practice_id = cp.id;
                line.budget_id = _newID;
            });
        });

        await UsersApiHelper.updateBudget(_copyBudget, apiConfig);
        for (var cp of _copyBudget.budgetCountyPractices) {
            await UsersApiHelper.updateBudgetCountyPractice(cp, apiConfig);
            await UsersApiHelper.updateBudgetCountyPracticeLineItem({ BudgetCountyPracticeLineItems: cp.lineItems }, apiConfig)
        }
    }

    // --- --- ---

    const actions = {
        getBudget,
        cloneBudget
    };

    return {
        actions
    }
}