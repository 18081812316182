import useDialog from "@datanac/datanac-mui-toolkit/dist/hooks/useDialog";
import { Add, DeleteForeverOutlined } from "@mui/icons-material";
import { Box, Card, CardHeader, Dialog, IconButton } from "@mui/material";
import { FinancialIcon } from "components/Icons/Icons";
import { ButtonLargeSquare } from "mui-toolkit/ButtonLargeSquare";
import { useState } from "react";
import { FinancialStatementSchedule_Accounts_Edit } from "./FinancialStatementSchedule_Accounts_EditDialog";
import { schedule_financial_statement_inventories } from "./useFinancialStatementSchedule";
import { v4 } from "uuid";
import { useSnackbar } from "notistack";

export const SYSTEM_PROMPT_ACCOUNT_PARSER_OTHER = `
Please listen for instructions on adding new accounts. Return with the best available information, matching the schema in the document sample below. Only return new or affected records.

### Return values:
- Return only JSON. Do not quote the response.
- To delete a record, set 'is_active' to 'false'.  
- Set 'id' to 'null'.
- Set 'value' to '0' if not available.
- Set 'year' to the current year (2024).
- Set 'adjusted_value' to 'null'.
- Set 'prior_value' to 'null'.

### Required fields:
Please provide the most likely choice for these required fields:
- year
- financial_institution_name
- account_name
- account_type
- value
- term
- liquidity

**account_type** can be one of: 
- "Sales"
- "Services"
- "Leases"
- "Expense"
- "Livestock"
- "Prepaid"
- "Stocks"
- "Commodities"
- "Mortgage"

**term** is the payment terms, some examples may include:
- Net 30 days
- Net 60 days
- etc.

**liquidity** can be one of:
- "Current"
- "Intermediate"
- "Long Term"

### SAMPLE RESPONSE:
'''json
[
   {
        "id": null,
        "year": 2024,
        "financial_institution_name": "Receivables",
        "account_name": "Smith Livestock",
        "account_type": "Leases",
        "value": 100000,
        "adjusted_value": null,
        "prior_value": null,
        "term": "Net 30 days",
        "liquidity": "Current"
    },
    {
        "id": null,
        "year": 2024,
        "financial_institution_name": "Receivables",
        "account_name": "Turner Dairy",
        "account_type": "Sales",
        "value": 1000,
        "adjusted_value": null,
        "prior_value": null,
        "term": "Net 30 days",
        "liquidity": "Current"
    },
    {
        "id": null,
        "year": 2024,
        "financial_institution_name": "Receivables",
        "account_name": "Johnson Farming",
        "account_type": "Livestock",
        "value": 50000,
        "adjusted_value": null,
        "prior_value": null,
        "term": "Net 30 days",
        "liquidity": "Current"
    },
    {
        "id": null,
        "year": 2024,
        "financial_institution_name": "Payables",
        "account_name": "Greenfield Agriculture",
        "account_type": "Expense",
        "value": 2000,
        "adjusted_value": null,
        "prior_value": null,
        "term": "Net 30 days",
        "liquidity": "Current"
    },
    {
        "id": null,
        "year": 2024,
        "financial_institution_name": "Bank of Rural",
        "account_name": "Baker Rentals",
        "account_type": "Leases",
        "value": 75000,
        "adjusted_value": null,
        "prior_value": null,
        "term": "Net 60 days",
        "liquidity": "Current"
    },
    {
        "id": null,
        "year": 2024,
        "financial_institution_name": "Receivables",
        "account_name": "Hilltop Dairy",
        "account_type": "Sales",
        "value": 1200,
        "adjusted_value": null,
        "prior_value": null,
        "term": "Net 30 days",
        "liquidity": "Current"
    },
    {
        "id": null,
        "year": 2024,
        "financial_institution_name": "Farm Finance",
        "account_name": "Southern Crops",
        "account_type": "Stocks",
        "value": 15000,
        "adjusted_value": null,
        "prior_value": null,
        "term": "Net 30 days",
        "liquidity": "Current"
    },
    {
        "id": null,
        "year": 2024,
        "financial_institution_name": "Receivables",
        "account_name": "Northern Equipment",
        "account_type": "Sales",
        "value": 5000,
        "adjusted_value": null,
        "prior_value": null,
        "term": "Net 30 days",
        "liquidity": "Current"
    },
    {
        "id": null,
        "year": 2024,
        "financial_institution_name": "Farm Investments",
        "account_name": "Western Commodities",
        "account_type": "Commodities",
        "value": 20000,
        "adjusted_value": null,
        "prior_value": null,
        "term": "Net 30 days",
        "liquidity": "Current"
    },
    {
        "id": null,
        "year": 2024,
        "financial_institution_name": "Receivables",
        "account_name": "Highland Farms",
        "account_type": "Services",
        "value": 3000,
        "adjusted_value": null,
        "prior_value": null,
        "term": "Net 30 days",
        "liquidity": "Current"
    },
    {
        "id": null,
        "year": 2024,
        "financial_institution_name": "Payables",
        "account_name": "Urban Agriculture",
        "account_type": "Expense",
        "value": 10000,
        "adjusted_value": null,
        "prior_value": null,
        "term": "Net 30 days",
        "liquidity": "Current"
    },
    {
        "id": null,
        "year": 2024,
        "financial_institution_name": "Rural Bank",
        "account_name": "Field Leases",
        "account_type": "Leases",
        "value": 40000,
        "adjusted_value": null,
        "prior_value": null,
        "term": "Net 60 days",
        "liquidity": "Current"
    }
]
'''
`;

export const FinancialStatementSchedule_Inventories_View = ({
    onChange,
    year,
    accounts,
    is_lender
}) => {

    const { enqueueSnackbar } = useSnackbar();
    const CATEGORY = schedule_financial_statement_inventories;
    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();

    const handleChange = async (account) => {
        if (!account.id) {
            account.id = v4();
        }
        if (!account.year && year) {
            account.year = year;
        }
        if (!account.category) {
            account.category = CATEGORY;
        }

        onChange && onChange(account);
    }

    const handleDeleteOnClick = (account) => {
        confirmDialogActions.confirm({
            title: "Delete this entry?",
            prompt: "Are you sure you want to delete this entry?",
            promptDetail: null,
            callback: () => {
                const _newState = { ...account };
                _newState.is_active = false;
                enqueueSnackbar("Account deleted", { preventDuplicate: true, variant: 'info' });
                handleChange(_newState);

                return false;
            }
        })
    }

    // ---

    const [editingItem, setEditingItem] = useState(null);

    // ---

    return (<>
        <Box className='cardContainer'>
            {(accounts || [])
                ?.filter(item => item.is_active != false)
                ?.map((item, index) =>
                    <Card sx={{ height: "100%" }} key={index} onClick={() => setEditingItem(item)}>
                        <CardHeader
                            avatar={<FinancialIcon />}
                            title={item?.account_name || item?.name}
                            subheader={item?.financial_institution_name + ' (' + item?.account_type + ')'}
                            action={<>
                                <IconButton onClick={(e) => { handleDeleteOnClick(item); e.stopPropagation(); return false; }}>
                                    <DeleteForeverOutlined sx={{ color: 'black' }} />
                                </IconButton>
                            </>}
                            sx={{ mb: 2 }}
                        />
                    </Card>
                )}

            <ButtonLargeSquare
                sx={{ backgroundColor: "#efefef", justifySelf: "flex-end" }}
                icon={<Add />}
                title={"Add New"}
                subHeader={null}
                onClick={() => setEditingItem({})}
            />
        </Box>

        <Dialog open={Boolean(editingItem != null)}>
            <FinancialStatementSchedule_Accounts_Edit
                onChange={handleChange} onClose={() => setEditingItem(null)}
                account={editingItem} is_lender={is_lender} />
        </Dialog>

        {confirmDialogComponent}
    </>);
}
