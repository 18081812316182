import { InsurancePlanHelper as Datanac_InsurancePlanHelper } from '@datanac/datanac-api-toolkit';
import { INSURANCE_PLAN_FAMILY_CROP, INSURANCE_PLAN_NAME_APH, INSURANCE_PLAN_NAME_ECO_RP, INSURANCE_PLAN_NAME_ECO_RP_HPE, INSURANCE_PLAN_NAME_ECO_YP, INSURANCE_PLAN_NAME_RP, INSURANCE_PLAN_NAME_RP_HPE, INSURANCE_PLAN_NAME_SCO_RP, INSURANCE_PLAN_NAME_SCO_RP_HPE, INSURANCE_PLAN_NAME_SCO_YP, INSURANCE_PLAN_NAME_STAX_RP, INSURANCE_PLAN_NAME_STAX_RP_HPE, INSURANCE_PLAN_NAME_YP } from "@datanac/datanac-api-toolkit/dist/datanac-rma-api-helper";
import { optimizeInsurance } from '@datanac/datanac-api-toolkit/dist/insurance/optimizer/optimizer';
import { getInsurancePlanNameAbbreviation, getInsurancePlansByFamily } from "@datanac/datanac-api-toolkit/dist/utility/InsurancePlanHelper";
import { CloseOutlined, EastOutlined, WestOutlined } from "@mui/icons-material";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Stack, Step, StepLabel, Stepper, Typography, useMediaQuery } from "@mui/material";
import { AppContext } from "App";
import { ApiHelper, RmaApiHelper } from "api/ApiHelper";
import { InsuranceIcon } from "components/Icons/Icons";
import WorkspaceViewSelector from 'components/Workspaces/WorkspaceModeSelector';
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from 'components/Workspaces/helpers';
import { ButtonLargeSquare } from "mui-toolkit/ButtonLargeSquare";
import { useSnackbar } from 'notistack';
import { Component, useContext, useEffect, useState } from "react";
import InsuranceCalculatePremiumEndorsementsTableGrid from './InsuranceCalculatePremiumEndorsementsTableGrid';
import InsuranceCalculatePremiumTableGrid from "./InsuranceCalculatePremiumTableGrid";
import InsurancePlanCard from './InsurancePlanCard';

export const AddInsuranceDialog = ({
    farmParameters,
    countyParameters,

    onClose,
    onSelectPlan
}) => {

    const { globalState } = useContext(AppContext);

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const isLg = useMediaQuery('(min-width:1440px)');

    const _insurancePlanHelper = new Datanac_InsurancePlanHelper();

    const { enqueueSnackbar } = useSnackbar();
    const handleError = (message) => {
        enqueueSnackbar(message,
            { preventDuplicate: true, variant: 'error' });
        console.warn(message);
    }

    // --- --- ---

    const [selectedInsuranceOfferCrop, setSelectedInsuranceOfferCrop] = useState(null);

    const [selectedInsurancePlanCrop, setSelectedInsurancePlanCrop] = useState(null);
    const [selectedInsurancePlans, setSelectedInsurancePlans] = useState(null);
    const [workspaceMode, setWorkspaceMode] = useState(isSmall ? WORKSPACE_VIEW_CARD : WORKSPACE_VIEW_TABLE);

    const [insuranceOffers, setInsuranceOffers] = useState([]);
    const [insuranceOffersCropPlans, setInsuranceOffersCropPlans] = useState([]);
    const [insuranceOffersEndorsements, setInsuranceOffersEndorsements] = useState([]);

    const loadInsuranceOffers = async () => {
        const _insuranceOffers = await RmaApiHelper.selectInsuranceOffer(countyParameters, globalState);
        if (_insuranceOffers?.length) {
            setInsuranceOffers(_insuranceOffers);
        } else {
            setInsuranceOffers([]);
        }
    }

    useEffect(() => {
        if (countyParameters) {
            loadInsuranceOffers();
        }
    }, [countyParameters])

    const _cropPlanNames = [...(getInsurancePlansByFamily(INSURANCE_PLAN_FAMILY_CROP)), INSURANCE_PLAN_NAME_APH];

    useEffect(() => {
        if (insuranceOffers?.length) {
            const _insuranceOffersCropPlans = insuranceOffers.filter(io =>
                _cropPlanNames.includes(io?.insurance_plan_name)
            );
            setInsuranceOffersCropPlans(_insuranceOffersCropPlans);
        }
    }, [insuranceOffers?.length])

    useEffect(() => {
        if (insuranceOffers?.length && selectedInsuranceOfferCrop) {
            const _insuranceOffersSupplementPlans = insuranceOffers.filter(io =>
                !_cropPlanNames.includes(io?.insurance_plan_name)
                && (
                    (selectedInsuranceOfferCrop?.insurance_plan_name == INSURANCE_PLAN_NAME_RP
                        && [INSURANCE_PLAN_NAME_ECO_RP, INSURANCE_PLAN_NAME_SCO_RP, INSURANCE_PLAN_NAME_STAX_RP].includes(io.insurance_plan_name))
                    ||
                    (selectedInsuranceOfferCrop?.insurance_plan_name == INSURANCE_PLAN_NAME_RP_HPE
                        && [INSURANCE_PLAN_NAME_ECO_RP_HPE, INSURANCE_PLAN_NAME_SCO_RP_HPE, INSURANCE_PLAN_NAME_STAX_RP_HPE].includes(io.insurance_plan_name))
                    ||
                    (selectedInsuranceOfferCrop?.insurance_plan_name == INSURANCE_PLAN_NAME_YP
                        && [INSURANCE_PLAN_NAME_ECO_YP, INSURANCE_PLAN_NAME_SCO_YP].includes(io.insurance_plan_name))
                )
            );
            setInsuranceOffersEndorsements(_insuranceOffersSupplementPlans);
        }
    }, [insuranceOffers?.length, selectedInsuranceOfferCrop])

    const handleInsuranceOfferClick = (insuranceOffer) => {
        setSelectedInsuranceOfferCrop(insuranceOffer);
        handleNext();
    }

    // --- --- ---

    const [calculatePremiumsTableIsLoading, setCalculatePremiumsTableIsLoading] = useState(false);
    const [calculatePremiumsTableResult, setCalculatePremiumsTableResult] = useState([]);

    const bindCalculatePremiumsTableAsync = async () => {
        // Use ACREAGE from BUDGET
        // NOTE: the APH will be properly weighted according to Farm Vault / Fields,
        // and PREMIUMS will be properly calculated individually & weighted,
        // But we must allow the BUDGET acreage to override:
        const _reported_acreage_vault = farmParameters.producerFarmFieldCrops
            .reduce((partialSum, pfc) => partialSum + pfc.reported_acreage, 0);
        const _reported_acreage_budget = farmParameters?.reported_acreage;

        const parameters = {
            ...farmParameters,
            ...countyParameters,
            ...selectedInsuranceOfferCrop,
            reinsurance_year: countyParameters.reinsurance_year,
            insured_share_percent: farmParameters?.share_percent,

            reported_acreage: _reported_acreage_budget,
            reported_acreage_vault: _reported_acreage_vault,
            reported_acreage_budget: _reported_acreage_budget,
        };

        //console.log("InsurancePlanCard: premiums??");
        if (//parameters?.insurance_plan_name
            parameters?.reinsurance_year

            && parameters?.commodity_name
            && parameters?.type_name
            && parameters?.practice_name

            && parameters?.state_name
            && parameters?.county_name

            && parameters?.reported_acreage
            && parameters?.approved_yield

            && parameters?.insured_share_percent

            && parameters?.projected_price
            && parameters?.price_volatility_factor
        ) {
            setCalculatePremiumsTableIsLoading(true);

            const _calculatePremiumsTableResults = await RmaApiHelper.insurance_crop_CalculatePremiumsTable(parameters);
            if (_calculatePremiumsTableResults?.length) {
                setCalculatePremiumsTableResult(_calculatePremiumsTableResults);
                setCalculatePremiumsTableIsLoading(false);
            } else {
                setCalculatePremiumsTableResult([]);
                setCalculatePremiumsTableIsLoading(false);
            }
        } else {
            console.warn('bindCalculatePremiumsTableAsync / not ready', parameters, parameters?.insured_share_percent)
        }
    };

    useEffect(() => {
        if (farmParameters && countyParameters && selectedInsuranceOfferCrop) {
            bindCalculatePremiumsTableAsync()
                .catch(error => {
                    console.warn('bindCalculatePremiumsTableAsync', error);
                })
        }
    }, [farmParameters, countyParameters, selectedInsuranceOfferCrop]);

    const handleCalculatePremiumTableSelection = (_insurancePlan) => {
        setSelectedInsurancePlanCrop(_insurancePlan);
        setSelectedInsurancePlans([_insurancePlan]);
        handleNext();
    }

    // --- --- ---

    const [calculateEndorsementsPremiumsTableIsLoading, setCalculateEndorsementsPremiumsTableIsLoading] = useState(false);
    const [endorsementsInsurancePlanCombos, setEndorsementsInsurancePlanCombos] = useState([]);

    const bindCalculateEndorsementsPremiumsTableAsync = async () => {
        const parameters = {
            ...farmParameters,
            ...countyParameters,
            ...selectedInsuranceOfferCrop,
            reinsurance_year: countyParameters.reinsurance_year,
            insured_share_percent: farmParameters?.share_percent
        };

        const endorsementOptimizerConstraints = {
            reinsurance_year: countyParameters.reinsurance_year,
            unit_structure_code: selectedInsurancePlanCrop?.unit_structure_code,
            insurance_plan_filter: {
                insurance_plan_names: [selectedInsuranceOfferCrop?.insurance_plan_name,
                    INSURANCE_PLAN_NAME_ECO_RP, INSURANCE_PLAN_NAME_ECO_RP_HPE, INSURANCE_PLAN_NAME_ECO_YP,
                    INSURANCE_PLAN_NAME_SCO_RP, INSURANCE_PLAN_NAME_SCO_RP_HPE, INSURANCE_PLAN_NAME_SCO_YP,
                    INSURANCE_PLAN_NAME_STAX_RP, INSURANCE_PLAN_NAME_STAX_RP_HPE,
                ],
                coverage_level_percents: [selectedInsurancePlanCrop?.coverage_level_percent]
            },
        }

        const _filteredMyFarms = [farmParameters];
        _filteredMyFarms.forEach(f => {
            // f.reinsurance_year = budgetCountyPractice?.year;
            f.insured_share_percent = f.share_percent;
            f.location_state_name = f.state_name;
            f.location_county_name = f.county_name;
            // f.production_cost_per_acre = budgetCountyPractice?.production_cost_per_acre;
        });

        const _endorsementCombos = await optimizeInsurance({
            myFarms: _filteredMyFarms,
            constraints: endorsementOptimizerConstraints,
            apiHelper: ApiHelper,
            onError: handleError,
        });
        if (_endorsementCombos?.length) {
            setEndorsementsInsurancePlanCombos(_endorsementCombos);
        }
    };

    useEffect(() => {
        if (farmParameters && countyParameters && selectedInsuranceOfferCrop && selectedInsurancePlanCrop) {
            bindCalculateEndorsementsPremiumsTableAsync();
        }
    }, [farmParameters, countyParameters, selectedInsuranceOfferCrop, selectedInsurancePlanCrop]);

    const handleEndorsementAdd = (_insurancePlans) => {
        setSelectedInsurancePlans(_insurancePlans);
        handleNext();
    }

    // --- --- ---

    const STEP_KEY_INSURANCE_PLAN_FAMILY = "insurance_plan_family";
    const STEP_KEY_COVERAGE = "coverage";
    const STEP_KEY_ENDORSEMENTS = "endorsements";
    const STEP_KEY_REVIEW = "review";

    const [activeStep, setActiveStep] = useState(0);
    const steps = [
        {
            label: 'Insurance Plan',
            description: 'Select a base crop plan',
            key: STEP_KEY_INSURANCE_PLAN_FAMILY,
            isValid: () => { return true }
        },
        {
            label: 'Coverage',
            description: '',
            key: STEP_KEY_COVERAGE,
            isValid: () => { return true }
        },
        {
            label: 'Endorsements',
            description: '',
            key: STEP_KEY_ENDORSEMENTS,
            isValid: () => { return true }
        },
        {
            label: 'Review',
            description: '',
            key: STEP_KEY_REVIEW,
            isValid: () => { return true }
        },
    ];

    class HorizontalStepContent extends Component {
        render() {
            const thisStep = this?.props?.step;

            return (
                <>
                    {steps[activeStep]?.key == thisStep?.key &&
                        <Typography>{thisStep?.description}</Typography>}
                </>
            );
        }
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSelectPlan = () => {
        onSelectPlan && onSelectPlan(selectedInsurancePlans);
        onClose();
    }

    // --- --- ---

    return (<>
        <DialogTitle>
            Add Plan
        </DialogTitle>

        <DialogContent>
            {steps[activeStep].key == STEP_KEY_INSURANCE_PLAN_FAMILY && <>
                <Typography>
                    Select an insurance plan type:
                </Typography>
                <Stack direction={isSmall ? "column" : "row"} spacing={2}
                    alignItems="center"
                    flexWrap="wrap" justifyContent="space-around" justifyItems="stretch"
                    sx={{ mt: (isSmall ? "4px" : "50px") }}
                >
                    {insuranceOffersCropPlans && insuranceOffersCropPlans?.map((io, index) =>
                        <Box key={STEP_KEY_INSURANCE_PLAN_FAMILY + "/" + index}>
                            <ButtonLargeSquare
                                sx={{ width: "150pt" }}
                                icon={<InsuranceIcon fontSize="large" />}
                                title={getInsurancePlanNameAbbreviation(io)}
                                onClick={() => handleInsuranceOfferClick(io)}
                                subHeader={io?.insurance_plan_name}
                            />
                        </Box>
                    )}
                </Stack>
            </>}


            {steps[activeStep].key == STEP_KEY_COVERAGE && <>
                <Typography>
                    Select a coverage level and unit structure:
                </Typography>
                <InsuranceCalculatePremiumTableGrid
                    farmParameters={farmParameters}
                    insuranceOffer={selectedInsuranceOfferCrop}
                    calculatePremiumsTableResult={calculatePremiumsTableResult}
                    onPremiumPriceSelect={handleCalculatePremiumTableSelection}
                />
            </>}


            {steps[activeStep].key == STEP_KEY_ENDORSEMENTS && <>
                <Typography>
                    Review & select optional policy endorsements:
                </Typography>
                <InsuranceCalculatePremiumEndorsementsTableGrid
                    farmParameters={farmParameters}
                    optimizerResults={endorsementsInsurancePlanCombos}
                    onInsurancePlansSelect={handleEndorsementAdd}
                />
            </>}

            {steps[activeStep].key == STEP_KEY_REVIEW && <>
                <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton'>
                    <Typography variant='h1'>
                        Selected Insurance Plans
                    </Typography>
                    <WorkspaceViewSelector workspaceMode={workspaceMode} setWorkspaceMode={setWorkspaceMode} />
                </Stack>
                <Box className='cardContainer'>
                    {Boolean(selectedInsurancePlans?.length) && selectedInsurancePlans?.map((currentPlan, index) =>
                        <InsurancePlanCard key={index} countyPractice={farmParameters} insurancePlan={currentPlan} />
                    )}
                </Box>
            </>}
        </DialogContent>

        <DialogActions>
            {!isSmall &&
                <Stepper activeStep={activeStep} orientation="horizontal"
                    sx={{ flexGrow: "1", mr: (isLg ? "200px" : 0) }}
                >
                    {steps.map((step, index) => (
                        <Step key={index}>
                            <StepLabel
                                optional={null}
                            >
                                {step.label || ''}
                            </StepLabel>
                            <HorizontalStepContent step={step}>
                                <Typography>{step.description}</Typography>
                            </HorizontalStepContent>
                        </Step>))}
                </Stepper>
            }

            <Stack direction="row" spacing={1} justifyContent='flex-end'>
                <Button variant='contained' color='secondary' autoFocus onClick={onClose} sx={{ mr: { xs: 0, lg: 5 } }}>
                    <CloseOutlined sx={{ mr: 1 }} />
                    Cancel
                </Button>

                <Button
                    variant="contained" color="primary"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                >
                    <WestOutlined sx={{ mr: 1 }} />
                    Back
                </Button>

                {/* {!(steps[activeStep].key == STEP_KEY_REVIEW) &&
                    <Button
                        variant="contained" color="primary"
                        onClick={handleNext}
                        disabled={!steps[activeStep].isValid()}
                    >
                        {activeStep === steps.length - 1 ? 'Next' : 'Next'}
                        <EastOutlined sx={{ ml: 1 }} />
                    </Button>} */}

                {steps[activeStep].key == STEP_KEY_REVIEW && <>
                    <Button variant="contained"
                        onClick={handleSelectPlan}>
                        Select Plan <EastOutlined sx={{ ml: 1 }} />
                    </Button>
                </>}
            </Stack>
        </DialogActions>
    </>)
}