import { USER_ROLE_ADMIN } from '@datanac/datanac-api-toolkit/dist/api-helper';
import { Box, CircularProgress, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { UsersApiHelper } from 'api/ApiHelper';
import { AppContext } from 'App';
import EmptyCard from 'components/Cards/EmptyCard';
import { FarmVaultIcon } from 'components/Icons/Icons';
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader';
import { useContext, useEffect, useMemo, useState } from 'react';

export default function ChangeAuditReport() {

    const { globalState } = useContext(AppContext);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'), { noSsr: true });

    // ---

    const [systemChangeAudit, setSystemChangeAudit] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const loadData = async () => {
        if (globalState?.user_role == USER_ROLE_ADMIN) {
            setIsLoading(true);

            const _transactionHistoryFilter = {};
            // This is ADMIN function. Do not specify producer_token or agent_token or you will get permissions error.
            const _apiConfig = {};
            const _systemChangeAudit = await UsersApiHelper.users_search("SystemChangeAudit", _transactionHistoryFilter, _apiConfig);
            setSystemChangeAudit(_systemChangeAudit);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        loadData();
    }, [globalState?.user_role]);

    // --- --- --

    const columns = useMemo(() => [
        { field: 'user_id', headerName: 'User ID', minWidth: 200, flex: 2 },
        { field: 'producer_token', headerName: 'Producer Token', minWidth: 200, flex: 2 },
        { field: 'section', headerName: 'Section', minWidth: 100, flex: 1 },
        { field: 'module', headerName: 'Module', minWidth: 100, flex: 1 },
        { field: 'object', headerName: 'Object', minWidth: 100, flex: 1 },
        { field: 'container', headerName: 'Operation', minWidth: 100, flex: 1 },
        {
            field: 'json',
            headerName: 'Request',
            minWidth: 200,
            flex: 1,
            valueFormatter: (params) => {
                const maxLength = 30; // Character limit
                const value = params?.value;
                if (value) {
                    return value?.length > maxLength ? value.substr(0, maxLength) + '...' : value;
                } else {
                    return "";
                }
            }
        },
        {
            field: 'createdate',
            headerName: 'Timestamp',
            minWidth: 100,
            flex: 1,
            valueFormatter: (params) => {
                // If same day, return time only
                // Else return date ddd MMM DD, yy
                const date = new Date(params.value);
                const today = new Date();
                return date.toDateString() === today.toDateString() ? date.toLocaleTimeString() : date.toLocaleString();
            }
        },
    ], []);

    return (
        <>
            <WorkspaceHeader
                title={"Change Log"}
                breadCrumbs={[
                    {
                        title: 'System',
                        path: '',
                        icon: <FarmVaultIcon fontSize='3pt' />
                    },
                ]}
            />
            {isLoading && <CircularProgress />}

            {!Boolean(systemChangeAudit?.length) && <>
                <Box className='cardContainer'>
                    <EmptyCard text="No Change Log Found" secondaryText="Please check back later." isLoading={isLoading} />
                </Box>
            </>}

            {Boolean(systemChangeAudit?.length) && <>
                <DataGrid
                    rows={systemChangeAudit || []}
                    columns={columns}
                    initialState={{
                        pagination: { paginationModel: { pageSize: isMobile ? 5 : 10 } },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                    autoHeight
                />
            </>}
        </>
    )
}
