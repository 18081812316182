import { MODULE_FINANCIAL } from "components/Menu/NavigationMenu";

export const OBJECT_NAME_SCHEDULE_F = "schedule_f";

// ---

export const CONTAINER_NAME_SCHEDULE_F_HEADER = "header";

export const ITEM_NAME_SCHEDULE_F_HEADER_REPORTED_ACREAGE = "reported_acreage";
export const ITEM_NAME_SCHEDULE_F_HEADER_REPLANT_REPORTED_ACREAGE = "replant_reported_acreage";

// ---

export const CONTAINER_NAME_SCHEDULE_F_INCOME = "income";

export const ITEM_NAME_SCHEDULE_F_INCOME_LIVESTOCK_RESALE_SALES = "livestock_resale_sales";
export const ITEM_NAME_SCHEDULE_F_INCOME_LIVESTOCK_RESALE_COST = "livestock_resale_cost";
export const ITEM_NAME_SCHEDULE_F_INCOME_LIVESTOCK_SALES = "livestock_sales";
export const ITEM_NAME_SCHEDULE_F_INCOME_PATRONAGE_DIVIDENDS = "patronage_dividends";
export const ITEM_NAME_SCHEDULE_F_INCOME_PATRONAGE_DIVIDENDS_TAXABLE = "patronage_dividends_taxable";
export const ITEM_NAME_SCHEDULE_F_INCOME_PROGRAM_PAYMENTS = "program_payments";
export const ITEM_NAME_SCHEDULE_F_INCOME_PROGRAM_PAYMENTS_TAXABLE = "program_payments_taxable";
export const ITEM_NAME_SCHEDULE_F_INCOME_CCC_LOANS = "ccc_loans";
export const ITEM_NAME_SCHEDULE_F_INCOME_CCC_LOANS_FORFEITED = "ccc_loans_forfeited";
export const ITEM_NAME_SCHEDULE_F_INCOME_CCC_LOANS_FORFEITED_TAXABLE = "ccc_loans_forfeited_taxable";
export const ITEM_NAME_SCHEDULE_F_INCOME_INSURANCE_DISASTER_PROCEEDS = "insurance_disaster_proceeds";
export const ITEM_NAME_SCHEDULE_F_INCOME_INSURANCE_DISASTER_PROCEEDS_TAXABLE = "insurance_disaster_proceeds_taxable";
export const ITEM_NAME_SCHEDULE_F_INCOME_INSURANCE_DISASTER_PROCEEDS_DEFERRED = "insurance_disaster_proceeds_deferred";
export const ITEM_NAME_SCHEDULE_F_INCOME_CUSTOM_HIRE = "custom_hire";
export const ITEM_NAME_SCHEDULE_F_INCOME_OTHER = "other";

// ---

export const CONTAINER_NAME_SCHEDULE_F_EXPENSES = "expenses";
export const CONTAINER_NAME_SCHEDULE_F_EXPENSES_NAMES = "expenses_names";

export const ITEM_NAME_SCHEDULE_F_EXPENSES_VEHICLE = "vehicle";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_CHEMICALS = "chemicals";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_CONSERVATION = "conservation";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_CUSTOM_HIRE = "custom_hire";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_DEPRECIATION = "depreciation";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_EMPLOYEE_BENEFITS = "employee_benefits";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_FEED = "feed";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_FERTILIZER = "fertilizer";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_FREIGHT = "freight";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_FUEL = "fuel";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_INSURANCE = "insurance";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_INTEREST_MORTGAGE = "interest_mortgage";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_INTEREST_OTHER = "interest_other";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_LABOR = "labor";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_PENSION = "pension";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_EQUIPMENT_LEASE = "equipment_lease";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_RENT = "rent";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_REPAIRS = "repairs";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_SEEDS = "seeds";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_STORAGE = "storage";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_SUPPLIES = "supplies";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_TAXES = "taxes";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_UTILITIES = "utilities";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_VETERINARY = "veterinary";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_OTHER_1 = "other_1";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_OTHER_2 = "other_2";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_OTHER_3 = "other_3";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_OTHER_4 = "other_4";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_OTHER_5 = "other_5";
export const ITEM_NAME_SCHEDULE_F_EXPENSES_OTHER_6 = "other_6";

// --- --- ---

// export const DOCUMENT_DICTIONARY_DEFAULT_VALUES_BALANCE_SHEET = [
//     {
//         section: null,
//         module: MODULE_FINANCIAL,
//         object: OBJECT_NAME_SCHEDULE_F,
//         container: CONTAINER_NAME_SCHEDULE_F_HEADER,
//         item: ITEM_NAME_SCHEDULE_F_HEADER_REPORTED_ACREAGE,
//     },
//     {
//         section: null,
//         module: MODULE_FINANCIAL,
//         object: OBJECT_NAME_SCHEDULE_F,
//         container: CONTAINER_NAME_SCHEDULE_F_HEADER,
//         item: ITEM_NAME_SCHEDULE_F_HEADER_REPLANT_REPORTED_ACREAGE,
//     },

    
//     {
//         section: null,
//         module: MODULE_FINANCIAL,
//         object: OBJECT_NAME_SCHEDULE_F,
//         container: CONTAINER_NAME_SCHEDULE_F_INCOME,
//         item: ITEM_NAME_SCHEDULE_F_INCOME_LIVESTOCK_RESALE_SALES,
//     },
//     {
//         section: null,
//         module: MODULE_FINANCIAL,
//         object: OBJECT_NAME_SCHEDULE_F,
//         container: CONTAINER_NAME_SCHEDULE_F_INCOME,
//         item: ITEM_NAME_SCHEDULE_F_INCOME_LIVESTOCK_RESALE_COST,
//     },
//     {
//         section: null,
//         module: MODULE_FINANCIAL,
//         object: OBJECT_NAME_SCHEDULE_F,
//         container: CONTAINER_NAME_SCHEDULE_F_INCOME,
//         item: ITEM_NAME_SCHEDULE_F_INCOME_LIVESTOCK_SALES,
//     },
//     {
//         section: null,
//         module: MODULE_FINANCIAL,
//         object: OBJECT_NAME_SCHEDULE_F,
//         container: CONTAINER_NAME_SCHEDULE_F_INCOME,
//         item: ITEM_NAME_SCHEDULE_F_INCOME_PATRONAGE_DIVIDENDS,
//     },
//     {
//         section: null,
//         module: MODULE_FINANCIAL,
//         object: OBJECT_NAME_SCHEDULE_F,
//         container: CONTAINER_NAME_SCHEDULE_F_INCOME,
//         item: ITEM_NAME_SCHEDULE_F_INCOME_PATRONAGE_DIVIDENDS_TAXABLE,
//     },
//     {
//         section: null,
//         module: MODULE_FINANCIAL,
//         object: OBJECT_NAME_SCHEDULE_F,
//         container: CONTAINER_NAME_SCHEDULE_F_INCOME,
//         item: ITEM_NAME_SCHEDULE_F_INCOME_PROGRAM_PAYMENTS,
//     },
//     {
//         section: null,
//         module: MODULE_FINANCIAL,
//         object: OBJECT_NAME_SCHEDULE_F,
//         container: CONTAINER_NAME_SCHEDULE_F_INCOME,
//         item: ITEM_NAME_SCHEDULE_F_INCOME_PROGRAM_PAYMENTS_TAXABLE,
//     },
//     {
//         section: null,
//         module: MODULE_FINANCIAL,
//         object: OBJECT_NAME_SCHEDULE_F,
//         container: CONTAINER_NAME_SCHEDULE_F_INCOME,
//         item: ITEM_NAME_SCHEDULE_F_INCOME_CCC_LOANS,
//     },
//     {
//         section: null,
//         module: MODULE_FINANCIAL,
//         object: OBJECT_NAME_SCHEDULE_F,
//         container: CONTAINER_NAME_SCHEDULE_F_INCOME,
//         item: ITEM_NAME_SCHEDULE_F_INCOME_CCC_LOANS_FORFEITED,
//     },
//     {
//         section: null,
//         module: MODULE_FINANCIAL,
//         object: OBJECT_NAME_SCHEDULE_F,
//         container: CONTAINER_NAME_SCHEDULE_F_INCOME,
//         item: ITEM_NAME_SCHEDULE_F_INCOME_CCC_LOANS_FORFEITED_TAXABLE,
//     },
//     {
//         section: null,
//         module: MODULE_FINANCIAL,
//         object: OBJECT_NAME_SCHEDULE_F,
//         container: CONTAINER_NAME_SCHEDULE_F_INCOME,
//         item: ITEM_NAME_SCHEDULE_F_INCOME_LIVESTOCK_RESALE_xxxxxxxxx,
//     },
//     {
//         section: null,
//         module: MODULE_FINANCIAL,
//         object: OBJECT_NAME_SCHEDULE_F,
//         container: CONTAINER_NAME_SCHEDULE_F_INCOME,
//         item: ITEM_NAME_SCHEDULE_F_INCOME_LIVESTOCK_RESALE_xxxxxxxxx,
//     },
//     {
//         section: null,
//         module: MODULE_FINANCIAL,
//         object: OBJECT_NAME_SCHEDULE_F,
//         container: CONTAINER_NAME_SCHEDULE_F_INCOME,
//         item: ITEM_NAME_SCHEDULE_F_INCOME_LIVESTOCK_RESALE_xxxxxxxxx,
//     },
//     {
//         section: null,
//         module: MODULE_FINANCIAL,
//         object: OBJECT_NAME_SCHEDULE_F,
//         container: CONTAINER_NAME_SCHEDULE_F_INCOME,
//         item: ITEM_NAME_SCHEDULE_F_INCOME_LIVESTOCK_RESALE_xxxxxxxxx,
//     },
//     {
//         section: null,
//         module: MODULE_FINANCIAL,
//         object: OBJECT_NAME_SCHEDULE_F,
//         container: CONTAINER_NAME_SCHEDULE_F_INCOME,
//         item: ITEM_NAME_SCHEDULE_F_INCOME_LIVESTOCK_RESALE_xxxxxxxxx,
//     },
// ]
