import { COMMODITY_NAME_CORN } from '@datanac/datanac-api-toolkit/dist/datanac-rma-api-helper'
import { useDialog } from '@datanac/datanac-mui-toolkit'
import EastOutlined from '@mui/icons-material/EastOutlined'
import { Box, Divider, Grid, IconButton, Stack, Typography, useMediaQuery } from '@mui/material'
import { AppContext } from 'App'
import EmptyCard from 'components/Cards/EmptyCard'
import DataFormSelect from 'components/Core/DataFormSelect'
import { propUpdateReducer } from 'components/Core/propUpdateReducer'
import { FarmVaultIcon } from 'components/Icons/Icons'
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from 'components/Workspaces/helpers'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { useSnackbar } from 'notistack'
import { Fragment, useContext, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { formatPracticeName } from 'utility/formatters'
import { useFarmController } from './useFarmController'

export default function VaultPrintWorkspace() {
    const { globalState } = useContext(AppContext);
    const {
        crop_year,
    } = useParams();
    const navigate = useNavigate();

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || err, { preventDuplicate: true, variant: 'error' });
    }

    const [workspaceMode, setWorkspaceMode] = useState(isSmall ? WORKSPACE_VIEW_CARD : WORKSPACE_VIEW_TABLE);

    const [currentYear, setCurrentYear] = useState(crop_year);
    const [filter, dispatchFilter] = useReducer(propUpdateReducer, {
        commodity_name: [],
        practice_name: [],
    });

    const {
        counties,
        countyPractices,
        producerFarms,
        producerFarmFieldCrops,
        producerFarm_union_Crops,
        isLoading
    } = useFarmController({
        crop_year: currentYear,
        filter: filter,
        onError: handleError
    });

    // ---


    const filterHandleChangeState = (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        dispatchFilter({
            type: 'update',
            payload: { key: event.target.name, value: value },
        });
    }

    // ---

    const handleFarmNavigateClick = (_currentProducerFarm) => {
        navigate(`/vault/${crop_year}/${_currentProducerFarm?.location_state_name}/${_currentProducerFarm?.location_county_name}/${_currentProducerFarm?.id}`)
    }

    // ---

    return (
        <>
            <WorkspaceHeader
                title={"Planted Acres"}
                breadCrumbs={[
                    {
                        title: 'Farm Vault ' + crop_year,
                        path: '/vault',
                        icon: <FarmVaultIcon fontSize='3pt' />
                    },
                ]}
            />

            <Grid container className='navigation' spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} md={6} lg={4}>
                    <DataFormSelect
                        value={filter.commodity_name || ""}
                        name="commodity_name"
                        isRequired={false}
                        onChange={filterHandleChangeState}
                        objectName="Commodity"
                        itemFilter={{ reinsurance_year: String(crop_year) }}
                        itemGetKey={(item) => item.commodity_name}
                        itemGetLabel={(item) => item.commodity_name}
                        doFetch={crop_year != null}
                        isLoading={isLoading}
                        multiple={true}
                        limitTags={isSmall ? 2 : 3}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <DataFormSelect
                        value={filter.practice_name || ""}
                        name="practice_name"
                        isRequired={false}
                        onChange={filterHandleChangeState}
                        objectName="Practice"
                        itemFilter={{
                            reinsurance_year: String(crop_year),
                            commodity_name: (filter.commodity_name[0] || COMMODITY_NAME_CORN)
                        }}
                        itemGetKey={(item) => item.practice_name}
                        itemGetLabel={(item) => item.practice_name}
                        doFetch={crop_year != null}
                        isLoading={isLoading}
                        multiple={true}
                        limitTags={isSmall ? 2 : 3}
                    />
                </Grid>
            </Grid>

            {!Boolean(counties?.length) && <>
                <EmptyCard text="No search results" secondaryText="Remove some filters or add a new farm in the Farm Vault" />
            </>}

            {counties?.map((currentCounty, countyPracticeIndex) => (
                <Fragment key={countyPracticeIndex}>
                    <Typography variant="h1">
                        {currentCounty?.location_county_name}, {currentCounty?.location_state_name}
                    </Typography>
                    <Box sx={{ ml: (isSmall ? 0 : 5), mb: 2 }}>
                        {currentCounty?.ProducerFarms?.map((currentProducerFarm, producerFarmIndex) =>
                            <Box key={producerFarmIndex} sx={{ pageBreakInside: "avoid" }}>
                                <Stack direction="row" spacing={1} alignItems="center" className='headerWithMenuButton'>
                                    <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
                                        {Boolean(currentProducerFarm?.farm_name) ?
                                            (currentProducerFarm?.farm_name?.toLowerCase() + " - #")
                                            : "Farm "}
                                        {currentProducerFarm?.fsa_farm_number}
                                    </Typography>
                                    <IconButton className="navigation" onClick={() => handleFarmNavigateClick(currentProducerFarm)}>
                                        <EastOutlined />
                                    </IconButton>
                                </Stack>

                                <Box sx={{ ml: (isSmall ? 0 : 5), mb: 2 }}>
                                    <Grid container spacing={2} className="reportTable">
                                        <Grid item xs={2} className="headerCell">
                                            <Typography>
                                                Tract
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} className="headerCell">
                                            <Typography>
                                                Field
                                            </Typography>
                                        </Grid>

                                        {!isSmall && <>
                                            <Grid item xs={3} className="headerCell">
                                                <Typography>
                                                    Crop / Type
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3} className="headerCell">
                                                <Typography>
                                                    Practice
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} className="headerCell">
                                                <Typography>
                                                    Acres
                                                </Typography>
                                            </Grid>
                                        </>}
                                        {isSmall && <>
                                            <Grid item xs={4} className="headerCell">
                                                <Typography>
                                                    Crop
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} className="headerCell">
                                                <Typography>
                                                    Acres
                                                </Typography>
                                            </Grid>
                                        </>}

                                        {currentProducerFarm.ProducerFarmFieldCrops?.map((currentCrop, cropIndex) =>
                                            <Fragment key={cropIndex}>
                                                <Grid item xs={2} className={(cropIndex % 2) ? null : "stripeCell"}>
                                                    {currentCrop?.fsa_tract_number}
                                                </Grid>
                                                <Grid item xs={2} className={(cropIndex % 2) ? null : "stripeCell"}>
                                                    {currentCrop?.fsa_field_number}
                                                </Grid>

                                                {!isSmall && <>
                                                    <Grid item xs={3} className={(cropIndex % 2) ? null : "stripeCell"}>
                                                        {currentCrop?.commodity_name}
                                                        {currentCrop.type_name == "No Type Specified" ? "" : " / " + currentCrop.type_name}
                                                    </Grid>
                                                    <Grid item xs={3} className={(cropIndex % 2) ? null : "stripeCell"}>
                                                        {formatPracticeName(currentCrop?.practice_name)}
                                                    </Grid>
                                                    <Grid item xs={2} className={(cropIndex % 2) ? null : "stripeCell"}>
                                                        {currentCrop?.reported_acreage}
                                                    </Grid>
                                                </>}
                                                {isSmall && <>
                                                    <Grid item xs={4} className={(cropIndex % 2) ? null : "stripeCell"}>
                                                        {currentCrop?.commodity_name} - {currentCrop?.type_name == "No Type Specified" ? "" : currentCrop?.type_name + " - "}
                                                        {formatPracticeName(currentCrop?.practice_name)}
                                                    </Grid>
                                                    <Grid item xs={4} className={(cropIndex % 2) ? null : "stripeCell"}>
                                                        {currentCrop?.reported_acreage}
                                                    </Grid>
                                                </>}
                                            </Fragment>
                                        )}
                                    </Grid>
                                </Box>
                            </Box>
                        )}

                        {/* 
                        {countyPractices
                            ?.filter(cp => (
                                cp.location_state_name == currentCounty?.location_state_name
                                && cp.location_county_name == currentCounty?.location_county_name
                            ))?.map((currentCountyPractice, countyPracticeIndex) => (
                                <Fragment key={countyPracticeIndex}>
                                    <CountyDataView countyPractice={{
                                        ...currentCountyPractice,
                                        state_name: currentCountyPractice?.location_state_name,
                                        county_name: currentCountyPractice?.location_county_name,
                                        reinsurance_year: currentYear,
                                    }} />
                                </Fragment>
                            ))} 
                            */}

                        <Divider />
                    </Box>
                </Fragment >
            ))}

            {confirmDialogComponent}
        </>
    )
}
