import EastOutlined from '@mui/icons-material/EastOutlined'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import WarningOutlined from '@mui/icons-material/WarningOutlined'
import { Box, Card, CardActions, CardContent, CardHeader, Chip, Divider, IconButton, Skeleton, Stack, Typography } from '@mui/material'
import { useArcPlc } from 'api-toolkit/useArcPlc'
import { AppContext } from 'App'
import { FarmCountyIcon } from 'components/Icons/Icons'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatNumber, formatPrice } from 'utility/formatters'


export default function ArcPlcFarmCountyCard({
    currentCounty,
    crop_year,

    isNavigation,
    icon
}) {
    const { globalState, globalDispatch, session } = useContext(AppContext);
    const navigate = useNavigate();

    const arcPlc = useArcPlc({ baseAcres: currentCounty?.BaseAcres });

    return (
        <>
            <Card>
                <CardHeader
                    avatar={icon || <FarmCountyIcon fontSize='large' />}
                    title={<>
                        {currentCounty?.location_county_name}, {currentCounty?.location_state_name}
                    </>}
                    subheader={""}
                />

                <CardContent>
                    <Stack direction="column" spacing={2}>
                        <Stack direction='row' spacing={1} alignItems='center' justifyItems='center'>
                            <Typography variant='h6'>
                                {formatNumber(currentCounty?.base_acreage, 0)}
                            </Typography>
                            <Typography variant='caption' color="secondary">
                                BASE<br />
                                ACRES
                            </Typography>
                        </Stack>

                        {!arcPlc?.isError && (arcPlc?.totals?.arcPayment != 0 || arcPlc?.totals?.plcPayment != 0) && Boolean(currentCounty?.BaseAcres?.length) &&
                            <Stack direction='row' spacing={1}>
                                <Box component={Stack} direction="column" spacing={0}>
                                    <Typography variant='caption' color="secondary">
                                        ARC-CO PAYMENT
                                    </Typography>
                                    <Typography variant='h6'>
                                        {!arcPlc?.isLoading && <>
                                            {formatPrice(arcPlc?.totals?.arcPayment, 0)}
                                        </>}
                                        {arcPlc?.isLoading && <>
                                            <Skeleton />
                                        </>}
                                    </Typography>
                                </Box>

                                <Divider orientation="vertical" flexItem />

                                <Box component={Stack} direction="column" spacing={0}>
                                    <Typography variant='caption' color="secondary">
                                        PLC PAYMENT
                                    </Typography>
                                    <Typography variant='h6'>
                                        {!arcPlc?.isLoading && <>
                                            {formatPrice(arcPlc?.totals?.plcPayment, 0)}
                                        </>}
                                        {arcPlc?.isLoading && <>
                                            <Skeleton />
                                        </>}
                                    </Typography>
                                </Box>
                            </Stack>}

                        {!Boolean(currentCounty?.BaseAcres?.length) &&
                            <Chip icon={<InfoOutlined />} label={"No base acres"} variant="outlined" />
                        }

                        {!arcPlc?.isError && arcPlc?.totals?.arcPayment == 0 && arcPlc?.totals?.plcPayment == 0 &&
                            <Chip icon={<InfoOutlined />} label={"No payments projected"} variant="outlined" />
                        }

                        {arcPlc?.isError &&
                            <Chip icon={<WarningOutlined />} label={"Unable to calculate ARC /PLC projections"} variant="filled" color="error" />
                        }
                    </Stack>
                </CardContent>

                <CardActions>
                    <Stack direction='row' spacing={0} alignItems='center' justifyItems='center'
                        sx={{ flexWrap: 'wrap', gap: 1 }}
                    >
                        {currentCounty?.CropProfiles?.map((currentCropProfile, index) =>
                            <Chip key={index} label={currentCropProfile?.commodity_name} />
                        )}
                    </Stack>

                    {isNavigation != false &&
                        <IconButton onClick={() => { navigate(`/arcplc/${crop_year}/${currentCounty.location_state_name}/${currentCounty.location_county_name}`) }}>
                            <EastOutlined />
                        </IconButton>}
                </CardActions>
            </Card>
        </>
    )
}
