import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Collapse from '@mui/material/Collapse'
import Paper from '@mui/material/Paper'
import Portal from '@mui/material/Portal'
import Popper from '@mui/material/Popper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { Fragment, useEffect, useReducer, useState } from 'react'
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { debounce } from 'lodash'
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

import { InsurancePlanHelper } from '@datanac/datanac-api-toolkit'

import { CircularProgress, Dialog, IconButton, Popover, Slider } from '@mui/material';
import ExpandMoreOutlined from '@mui/icons-material/ExpandMoreOutlined'
import DataFormSelect from './Core/DataFormSelect'
import { StatefulControlledTextField } from './Core/StatefulControlledTextField'
import { SkeletonWrapper } from 'mui-toolkit/SkeletonWrapper'

const reducer = (state, action) => {
    switch (action.type) {
        case 'update':
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            }
        default:
            throw new Error(`Unknown action type: ${action.type}`)
    }
}

export default function InputTextPair({
    label,

    name,
    value,

    inputProps,
    disabled,
    isLoading,

    maskType,

    onChange,
}) {

    const handleChange = (event) => {
        onChange && onChange(event);
    }

    return (
        <Box className='mui-InputPair mui-InputTextPair'>
            <Box
                sx={{
                    p: 0, m: 0,
                    display: "flex",
                    //border: "1px solid #bebebe", borderRadius: "5px",
                    alignItems: "center", justifyContent: "center",
                    // '&:focus-within': { boxShadow: "0 0 0 3px #8CC63F" }
                }}>
                <Box sx={{
                    display: "flex",
                    m: 0, height: "100%",
                    boxSizing: "border-box",
                    // justifyContent: "center",
                    width: "50%",
                    //p: "9pt", 
                    //borderRight: "1px solid #bebebe",
                    //borderRadius: 0, 
                }}
                >
                    <Typography sx={{ m: 0, p: 0 }}>
                        {label}
                    </Typography>
                </Box>
                <Box sx={{ width: "50%" }}>
                    {(!disabled || isLoading) &&
                        <StatefulControlledTextField
                            fullWidth={true}
                            name={name}
                            className="noMargin"
                            defaultValue={value || ""}
                            onChange={handleChange}
                            inputProps={inputProps}
                            disabled={disabled}
                            maskType={maskType}
                            isLoading={isLoading}
                        />}
                    {disabled && !isLoading &&
                        <Typography variant="body1" sx={{ borderBottom: "1px solid #efefef", p: 0.50, ...inputProps?.sx }}>
                            {value || ""}
                        </Typography>}
                </Box>
            </Box>
        </Box>
    );
}
