import CloseOutlined from '@mui/icons-material/CloseOutlined'
import { Box, Card, CardContent, IconButton, Typography } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { AppContext } from 'App'
import DraggableContainer from 'components/shared/Draggable/DraggableContainer'
import VimeoPlayer from 'components/shared/VimeoPlayer/VimeoPlayer'
import { useContext, useEffect, useState } from 'react'

export default function OnlineHelp({
    title,
    header,
    content,
    videoId,

    isOpen,
    onClose,
}) {
    const [state, setState] = useState({
        right: false,
    });

    const [isVideoPoppedOut, setIsVideoPoppedOut] = useState(false);  // Controls whether video is popped out

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                const contentDiv = document.querySelector('.online-help-content');
                const handleScroll = () => {
                    if (contentDiv) {
                        const scrollTop = contentDiv.scrollTop;
                        const scrollHeight = contentDiv.scrollHeight;
                        const clientHeight = contentDiv.clientHeight;
                        if (scrollTop + clientHeight >= scrollHeight) {
                            onClose && onClose();
                            setIsVideoPoppedOut(true);
                        }
                    }
                };
                if (contentDiv) {
                    contentDiv.addEventListener('scroll', handleScroll);
                }
                return () => {
                    if (contentDiv) {
                        contentDiv.removeEventListener('scroll', handleScroll);
                    }
                };
            }, 1000);
        }
    }, [isOpen]);

    // ---

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open });
    };

    return (
        <>

            <Drawer
                anchor="right"
                open={isOpen}
                onClose={onClose}
                onOpen={isOpen}
                className='online-help-drawer'
            >
                <Card sx={{ maxWidth: 600, margin: 'auto', boxShadow: 3 }}>
                    <Box
                        className='online-help-drawer-header'
                        sx={{
                            background: 'linear-gradient(90deg, #8FB954 0%, #D2D550 100%)',
                            color: 'white',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            p: 2,
                        }}
                    >
                        <Typography variant="subtitle1">
                            {title}
                        </Typography>
                        <IconButton sx={{ color: 'white' }} aria-label="close" onClick={onClose}>
                            <CloseOutlined className='drawer-close-btn' sx={{ mr: 1 }} />
                        </IconButton>
                    </Box>
                    <CardContent className='online-help-content'>
                        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', mt: 1, fontSize: '14pt' }}>
                            {header}
                        </Typography>

                        {content}

                        {Boolean(videoId) &&
                            <div style={{ transition: 'all 0.5s ease-in-out' }}>
                                <VimeoPlayer videoId={videoId} />
                            </div>
                        }
                    </CardContent>
                </Card>
            </Drawer>

            {!isOpen && isVideoPoppedOut && Boolean(videoId) && (
                <div className={`video-container ${isVideoPoppedOut ? 'fixed-video show' : 'slide-right'}`}>
                    <DraggableContainer>
                        <div className="drag-icon">
                            <OpenWithIcon className='video-icons drag-icon' sx={{ mr: 1 }} />
                        </div>
                        <VimeoPlayer videoId={videoId} />
                        <HighlightOffOutlinedIcon className='video-icons close-icon' sx={{ mr: 1 }} onClick={onClose} />
                    </DraggableContainer>
                </div>
            )}
        </>
    )
}
