import { Agriculture, AttachMoney, CalendarMonth, Grass, LocalShipping, MoneyOff, Note, Water } from "@mui/icons-material";
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Avatar, Box, Chip, Divider, Stack } from "@mui/material";
import { blue, deepOrange, green, grey, lightGreen, orange } from "@mui/material/colors";
import Typography from '@mui/material/Typography';
import { InsuranceIcon } from "components/Icons/Icons";
import { useNavigate } from "react-router-dom";
import { formatDate, formatPracticeName } from "utility/formatters";


export const FarmEventList = ({
    producerFarmFieldCropEvents
}) => {
    const navigate = useNavigate();

    const handleFarmClick = (producerFarmCrop) => {
        http://localhost:3000/vault/2023/Alabama/Madison/dfe88ca2-5e40-4af3-a7a4-4c9ceff8f4f8
        navigate(`/vault/${producerFarmCrop?.crop_year}/${producerFarmCrop?.location_state_name}/${producerFarmCrop?.location_county_name}/${producerFarmCrop?.producer_farm_id}`)
    }

    // ---

    const getTimelineItem = (producerFarmFieldCropEvent, key) => {
        // Switch case based on producerFarmFieldCropEvent.category
        switch (producerFarmFieldCropEvent?.category?.toLowerCase()) {
            case "expense":
                return <DefaultTimelineItem producerFarmFieldCropEvent={producerFarmFieldCropEvent} key={key} />;
            case "revenue":
                return <DefaultTimelineItem producerFarmFieldCropEvent={producerFarmFieldCropEvent} key={key} />;


            case "planting":
                return <DefaultTimelineItem producerFarmFieldCropEvent={producerFarmFieldCropEvent} key={key} />;
            case "harvest":
                return <DefaultTimelineItem producerFarmFieldCropEvent={producerFarmFieldCropEvent} key={key} />;
            case "yield":
                return <DefaultTimelineItem producerFarmFieldCropEvent={producerFarmFieldCropEvent} key={key} />;
            case "irrigation":
                return <DefaultTimelineItem producerFarmFieldCropEvent={producerFarmFieldCropEvent} key={key} />;

            case "insurance":
                return <DefaultTimelineItem producerFarmFieldCropEvent={producerFarmFieldCropEvent} key={key} />;

            case "other":
                return <DefaultTimelineItem producerFarmFieldCropEvent={producerFarmFieldCropEvent} key={key} />;
            default:
                return <DefaultTimelineItem producerFarmFieldCropEvent={producerFarmFieldCropEvent} key={key} />;
        }
    }

    const getIcon = (producerFarmFieldCropEvent) => {
        // Switch case based on producerFarmFieldCropEvent.category
        switch (producerFarmFieldCropEvent?.category?.toLowerCase()) {
            // Vault
            case "production":
                return <TimelineDot sx={{ bgcolor: green[500] }}><Grass /></TimelineDot>
            case "yields":
                return <TimelineDot sx={{ bgcolor: green[500] }}><Grass /></TimelineDot>
            case "planting":
                return <TimelineDot sx={{ bgcolor: green[500] }}><Grass /></TimelineDot>
            case "harvest":
                return <TimelineDot sx={{ bgcolor: green[500] }}><Grass /></TimelineDot>
            case "irrigation":
                return <TimelineDot sx={{ bgcolor: green[500] }}><Water /></TimelineDot>
            case "fertilizer":
                return <TimelineDot sx={{ bgcolor: green[500] }}><Grass /></TimelineDot>
            case "seed":
                return <TimelineDot sx={{ bgcolor: green[500] }}><Grass /></TimelineDot>

            // Budgets:
            case "expense":
                return <TimelineDot sx={{ bgcolor: lightGreen[500] }}><MoneyOff /></TimelineDot>
            case "revenue":
                return <TimelineDot sx={{ bgcolor: lightGreen[500] }}><AttachMoney /></TimelineDot>

            // Insurance
            case "insurance":
                return <TimelineDot sx={{ bgcolor: deepOrange[500] }}><InsuranceIcon /></TimelineDot>
            case "claim":
                return <TimelineDot sx={{ bgcolor: deepOrange[500] }}><AttachMoney /></TimelineDot>
            case "payment":
                return <TimelineDot sx={{ bgcolor: deepOrange[500] }}><InsuranceIcon /></TimelineDot>

            // Financials:
            case "equipment":
                return <TimelineDot sx={{ bgcolor: blue[500] }}><Agriculture /></TimelineDot>
            case "vehicles":
                return <TimelineDot sx={{ bgcolor: blue[500] }}><LocalShipping /></TimelineDot>
            case "note":
                return <TimelineDot sx={{ bgcolor: blue[500] }}><Note /></TimelineDot>

            case "other":
                return <TimelineDot sx={{ bgcolor: grey[500] }}><CalendarMonth /></TimelineDot>
            default:
                return <TimelineDot sx={{ bgcolor: grey[500] }}><CalendarMonth /></TimelineDot>
        }
    }

    const DefaultTimelineItem = ({ producerFarmFieldCropEvent }) => {
        return <TimelineItem>
            <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
            >
                <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                        {producerFarmFieldCropEvent?.ProducerFarmFieldCrop?.farm_name &&
                            <Chip variant="outlined" label={producerFarmFieldCropEvent?.ProducerFarmFieldCrop?.farm_name} onClick={() => handleFarmClick(producerFarmFieldCropEvent?.ProducerFarmFieldCrop)} />}
                        {!producerFarmFieldCropEvent?.ProducerFarmFieldCrop?.farm_name && <>
                            <Typography variant="caption" color="secondary">
                                (No farm associated)
                            </Typography>
                        </>}
                    </Stack>
                    <Stack direction="row" spacing={1}>
                        {producerFarmFieldCropEvent?.ProducerFarmFieldCrop?.location_county_name &&
                            <Chip size="small" label={producerFarmFieldCropEvent?.ProducerFarmFieldCrop?.location_county_name} />}
                        {producerFarmFieldCropEvent?.ProducerFarmFieldCrop?.commodity_name &&
                            <Chip size="small" label={producerFarmFieldCropEvent?.ProducerFarmFieldCrop?.commodity_name} />}
                    </Stack>
                </Stack>
                <Divider />
            </TimelineOppositeContent>

            <TimelineSeparator>
                <TimelineConnector />
                {getIcon(producerFarmFieldCropEvent)}
                <TimelineConnector />
            </TimelineSeparator>

            <TimelineContent sx={{ width: "50%" }}>
                <Typography display="inline">
                    {producerFarmFieldCropEvent?.category}
                </Typography>
                <Typography display="inline" variant="body2">
                    {" – " + formatDate(producerFarmFieldCropEvent?.createdate)}
                </Typography>
                <Typography variant="caption" color="secondary" display="block">
                    {producerFarmFieldCropEvent?.notes}
                </Typography>
            </TimelineContent>
        </TimelineItem>
    }

    return (
        <Box className='cardContainer'>
            <Timeline position="left">
                {producerFarmFieldCropEvents.map((producerFarmFieldCropEvent, index) =>
                    getTimelineItem(producerFarmFieldCropEvent, index)
                )}
            </Timeline>
        </Box>
    );
}