export const STRESS_TEST_FIELD_NET_PROFIT_SIMPLE = "net_profit_simple";
export const STRESS_TEST_FIELD_NET_PROFIT_INSURED = "net_profit_insured";
export const STRESS_TEST_FIELD_INDEMNITY_TOTAL_PER_ACRE = "indemnity_total_per_acre";
export const STRESS_TEST_FIELD_NET_INSURANCE_BENEFIT_PER_ACRE = "net_insurance_benefit_per_acre";

export const calculateStressTestDisplayFieldLabel = (stressTestDisplayField) => {
    switch (stressTestDisplayField) {
        case STRESS_TEST_FIELD_NET_PROFIT_SIMPLE:
            return "Net Profit Before Insurance";
        case STRESS_TEST_FIELD_NET_PROFIT_INSURED:
            return "Insured Net Profit";
        case STRESS_TEST_FIELD_INDEMNITY_TOTAL_PER_ACRE:
            return "Insurance Benefit";
        case STRESS_TEST_FIELD_NET_INSURANCE_BENEFIT_PER_ACRE:
            return "Net Insurance Benefit";
    }
}