import { InsurancePlanStateHelper } from "@datanac/datanac-api-toolkit/dist/insurance/optimizer/InsurancePlanStateHelper";
import { getCurrentReinsuranceYear } from "@datanac/datanac-api-toolkit/dist/utility/InsurancePlanHelper";
import { ProducerFarmHelper } from "@datanac/datanac-api-toolkit/dist/utility/ProducerFarmHelper";
import { round_ex } from "@datanac/datanac-visualization-toolkit/dist/utility/Math";
import { ApiHelper, RmaApiHelper, UsersApiHelper } from "api/ApiHelper";
import { BUDGET_STATUS_ACTIVE } from "components/Budget/helpers";
import _ from "lodash";
import { optimizeInsurance } from "@datanac/datanac-api-toolkit/dist/insurance/optimizer/optimizer";

export const ERROR_NO_INSURANCE_SCENARIO_SPECIFIED = "No insurance scenario is specified. Please start over and try again.";
export const ERROR_NO_BUDGET_SPECIFIED = "No active budget is available.  Please create an active budget and try again.";
export const ERROR_NO_CROPS_SPECIFIED = "No crops are available.  Record your planting intentions in the Farm Vault and try again.";
// export const ERROR_NO_INSURANCE_PLANS_SPECIFIED = "No active insurance plans are available.  Choose your insurance elections and try again.";

export function InsuranceService({
    apiConfig,

    crop_year,
    status,

    insuranceScenarios,
    budgetCountyPractices,
    insurancePlans,

    producerFarmFieldCrops,

    onError
}) {

    const loadInsuranceScenario = async () => {
        const _current_crop_year = crop_year;

        if (!insuranceScenarios?.length) {
            const _insuranceScenarioFilter = {
                crop_year: _current_crop_year,
                status: status,
                is_active: true
            };
            insuranceScenarios = await UsersApiHelper.users_selectObject("InsuranceScenario", _insuranceScenarioFilter, apiConfig);
        }
        if (!insuranceScenarios?.length) {
            onError && onError(ERROR_NO_INSURANCE_SCENARIO_SPECIFIED);
            if (!onError) throw ERROR_NO_INSURANCE_SCENARIO_SPECIFIED;
        }

        if (!budgetCountyPractices?.length) {
            const _budgetFilter = {
                year: _current_crop_year,
                status: BUDGET_STATUS_ACTIVE,
                is_active: true,
            }
            budgetCountyPractices = await UsersApiHelper.selectBudgetCountyPractices(_budgetFilter, apiConfig);
        }
        if (!budgetCountyPractices?.length) {
            onError && onError(ERROR_NO_BUDGET_SPECIFIED);
            if (!onError) throw ERROR_NO_BUDGET_SPECIFIED;
        }

        if (!producerFarmFieldCrops?.length) {
            const _cropFilter = {
                crop_year: _current_crop_year,
                is_active: true,
            };
            producerFarmFieldCrops = await UsersApiHelper.selectProducerFarmFieldCrops(_cropFilter, apiConfig);
        }
        if (!producerFarmFieldCrops?.length) {
            onError && onError(ERROR_NO_CROPS_SPECIFIED);
            if (!onError) throw ERROR_NO_CROPS_SPECIFIED;
        }

        if (!insurancePlans?.length) {
            const _insurancePlanFilter = {
                crop_year: _current_crop_year,
                is_active: true
            };
            const _insurancePlans = await UsersApiHelper.selectInsurancePlan(_insurancePlanFilter, apiConfig);
            if (_insurancePlans?.length) {
                insurancePlans = _insurancePlans.sort((a, b) =>
                    (a?.line_number || 0) - (b?.line_number || 0)
                );
            }
        }
        // if (!insurancePlans?.length) {
        //     throw ERROR_NO_INSURANCE_PLANS_SPECIFIED;
        // }

        if (insuranceScenarios?.length) {
            for (const currentScenario of insuranceScenarios) {
                currentScenario.budgetCountyPractices = await _loadBudgetCountyPractices_intern(currentScenario, false);
                currentScenario.totals = _calculateTotals(currentScenario);
            }
        }

        return insuranceScenarios;
    }

    const calculatePremiums = async (__currentInsuranceScenario) => {
        if (__currentInsuranceScenario) {
            __currentInsuranceScenario.budgetCountyPractices = await _loadBudgetCountyPractices_intern(__currentInsuranceScenario, true);
            __currentInsuranceScenario.totals = _calculateTotals(__currentInsuranceScenario);
        }

        return __currentInsuranceScenario;
    }

    const _loadBudgetCountyPractices_intern = async (currentScenario, doCalculatePremiums) => {
        const _insurancePlans = insurancePlans?.filter(p =>
            p.insurance_scenario_id == currentScenario.id
        );

        const producerFarmHelper = new ProducerFarmHelper();
        const _insuranceCountyPractices = _insurancePlans?.length ?
            producerFarmHelper.getUniqueCountyPractices(_insurancePlans)
            : [];

        if (budgetCountyPractices?.length) {
            for (const currentBudgetCountyPractice of budgetCountyPractices) {
                currentBudgetCountyPractice.producerFarmFieldCrops = _filterProducerFarmFieldCrops(currentBudgetCountyPractice);

                // console.log(currentScenario, currentBudgetCountyPractice, currentBudgetCountyPractice.producerFarmFieldCrops);

                // Use ACREAGE from BUDGET
                // NOTE: the APH will be properly weighted according to Farm Vault / Fields,
                // and PREMIUMS will be properly calculated individually & weighted,
                // But we must allow the BUDGET acreage to override:
                const _reported_acreage_vault = currentBudgetCountyPractice.producerFarmFieldCrops
                    .reduce((partialSum, pfc) => partialSum + pfc.reported_acreage, 0);
                const _reported_acreage_budget = currentBudgetCountyPractice.reported_acreage;

                // Insurance:
                const _foundInsuranceCountyPractice = _insuranceCountyPractices.find(icp =>
                    icp.state_name?.toLowerCase() == currentBudgetCountyPractice.state_name?.toLowerCase()
                    && icp.county_name?.toLowerCase() == currentBudgetCountyPractice.county_name?.toLowerCase()

                    && icp.commodity_name?.toLowerCase() == currentBudgetCountyPractice.commodity_name?.toLowerCase()
                    && icp.type_name?.toLowerCase() == currentBudgetCountyPractice.type_name?.toLowerCase()
                    && icp.practice_name?.toLowerCase() == currentBudgetCountyPractice.practice_name?.toLowerCase()
                );
                if (_foundInsuranceCountyPractice) {
                    currentBudgetCountyPractice.countyData = await _loadCountyData_intern(currentBudgetCountyPractice);

                    currentBudgetCountyPractice.insurancePlans = await _loadInsurancePlans_intern(currentScenario, currentBudgetCountyPractice, doCalculatePremiums);

                    const _producer_premium_amount = currentBudgetCountyPractice.insurancePlans
                        .reduce((partialSum, ip) => partialSum + ip.producer_premium_amount, 0);
                    const _liability_amount = currentBudgetCountyPractice.insurancePlans
                        .reduce((partialSum, ip) => partialSum + ip.liability_amount, 0);

                    currentBudgetCountyPractice.totals = {
                        reported_acreage: _reported_acreage_budget,

                        producer_premium_amount: _producer_premium_amount,// * (_reported_acreage_budget / _reported_acreage_vault),
                        producer_premium_amount_per_acre: _producer_premium_amount / _reported_acreage_budget,

                        liability_amount: _liability_amount,// * (_reported_acreage_budget / _reported_acreage_vault),
                        liability_amount_per_acre: _liability_amount / _reported_acreage_budget,
                    }
                } else {
                    currentBudgetCountyPractice.insurancePlans = [];
                    currentBudgetCountyPractice.totals = {
                        reported_acreage: _reported_acreage_budget
                    };
                }
            }
        }

        return budgetCountyPractices;
    }

    const _filterProducerFarmFieldCrops = (currentBudgetCountyPractice) => {
        if (currentBudgetCountyPractice) {
            const _producerFarmFieldCrops = producerFarmFieldCrops.filter(pfc =>
                pfc.location_state_name?.toLowerCase() == currentBudgetCountyPractice.state_name?.toLowerCase()
                && pfc.location_county_name?.toLowerCase() == currentBudgetCountyPractice.county_name?.toLowerCase()

                && pfc.commodity_name?.toLowerCase() == currentBudgetCountyPractice.commodity_name?.toLowerCase()
                && pfc.type_name?.toLowerCase() == currentBudgetCountyPractice.type_name?.toLowerCase()
                && pfc.practice_name?.toLowerCase() == currentBudgetCountyPractice.practice_name?.toLowerCase()
            );

            return _producerFarmFieldCrops;
        } else {
            return null;
        }
    }

    const _loadInsurancePlans_intern = async (currentScenario, currentBudgetCountyPractice, doCalculatePremiums) => {
        const _insurancePlans = insurancePlans?.filter(p =>
            p.insurance_scenario_id == currentScenario.id
            && p.state_name?.toLowerCase() == currentBudgetCountyPractice?.state_name?.toLowerCase()
            && p.county_name?.toLowerCase() == currentBudgetCountyPractice?.county_name?.toLowerCase()

            && p.commodity_name?.toLowerCase() == currentBudgetCountyPractice?.commodity_name?.toLowerCase()
            && p.type_name?.toLowerCase() == currentBudgetCountyPractice?.type_name?.toLowerCase()
            && p.practice_name?.toLowerCase() == currentBudgetCountyPractice?.practice_name?.toLowerCase()
        );

        const _countyData = await _loadCountyData_intern(currentBudgetCountyPractice);



        // Scale each PFC FARM's acreage WRT proportion of "missing" acres
        // between BUDGET and FARM VAULT data:
        // This is required for supplements & underlying liability amount:
        // We must ensure the InsurancePlan reported_acreage matches the budget's reported_acrage:
        const _reported_acreage_budget = currentBudgetCountyPractice?.reported_acreage;
        const _reported_acreage_vault = _.sum(currentBudgetCountyPractice?.producerFarmFieldCrops?.map(pfc => pfc.reported_acreage));
        // if (currentBudgetCountyPractice?.producerFarmFieldCrops?.length > 0) {
        //     const _reportedAcreageScaleFactor = _budgetReportedAcreage / _farmVaultReportedAcrage / currentBudgetCountyPractice?.producerFarmFieldCrops?.length;
        //     currentBudgetCountyPractice?.producerFarmFieldCrops?.forEach(pfc => {
        //         pfc.reported_acreage *= _reportedAcreageScaleFactor;
        //     })
        // }

        let _insurancePlansWithPremium = [];
        if (doCalculatePremiums) {
            if (currentBudgetCountyPractice?.producerFarmFieldCrops?.length) {
                for (const currentProducerFarmFieldCrop of currentBudgetCountyPractice?.producerFarmFieldCrops) {
                    const {
                        actions: insurancePlanActions
                    } = new InsurancePlanStateHelper({ reinsurance_year: crop_year, onError: onError, apiHelper: ApiHelper });

                    currentProducerFarmFieldCrop.area_quantity = currentBudgetCountyPractice?.reported_acreage;
                    insurancePlanActions.setFarmParameters(currentProducerFarmFieldCrop);
                    insurancePlanActions.setCountyParameters(_countyData);
                    _insurancePlans?.forEach(p => {
                        insurancePlanActions.addInsurancePlan(p);
                    })
                    try {
                        const _farmInsurancePlansWithPremium = await insurancePlanActions.calculatePremiums();
                        if (_farmInsurancePlansWithPremium?.length) {
                            _farmInsurancePlansWithPremium.forEach(p => {
                                _insurancePlansWithPremium.push(p);
                            });
                            currentProducerFarmFieldCrop.insurancePlans = _farmInsurancePlansWithPremium;
                        }
                    } catch (err) {
                        // Error on calculatePremium
                        console.warn("Error on calculatePremiums()", err.detail || err);

                        _insurancePlans.forEach(p => {
                            _insurancePlansWithPremium.push(p);
                        });
                    }
                }
            }
        } else {
            _insurancePlansWithPremium = _insurancePlans;
        }
        const _insurancePlansGrouped = _(_insurancePlansWithPremium)
            .groupBy("id")
            .map((currentGroup, id) => {
                return {
                    ...currentGroup[0],

                    // Deserialize GROUP_KEY id object props:
                    id: id,
                    insurance_plan_id: id,

                    reported_acreage:
                        round_ex((
                            _.sumBy(currentGroup, item => Number(item.reported_acreage) || 0)
                        ), 2),

                    producer_premium_amount:
                        round_ex((
                            _.sumBy(currentGroup, item => Number(item.producer_premium_amount) || 0)
                        ), 2),
                    // Weighted:
                    producer_premium_amount_per_acre:
                        round_ex((
                            _.sumBy(currentGroup, item => Number(item.producer_premium_amount_per_acre * item.reported_acreage) || 0)
                            / _.sumBy(currentGroup, item => Number(item?.reported_acreage) || 0)
                        ), 2),


                    liability_amount:
                        round_ex((
                            _.sumBy(currentGroup, item => Number(item.liability_amount) || 0)
                        ), 2),
                    liability_amount_per_acre:
                        round_ex((
                            _.sumBy(currentGroup, item => Number(item.liability_amount) || 0)
                            / _.sumBy(currentGroup, item => Number(item.reported_acreage) || 0)
                        ), 2),

                    // Weighted:
                    approved_yield:
                        round_ex((
                            _.sumBy(currentGroup, item => Number(item.approved_yield * item.reported_acreage) || 0)
                            / _.sumBy(currentGroup, item => Number(item.reported_acreage) || 0)
                        ), 2),
                    rate_yield:
                        round_ex((
                            _.sumBy(currentGroup, item => Number(item.rate_yield * item.reported_acreage) || 0)
                            / _.sumBy(currentGroup, item => Number(item.reported_acreage) || 0)
                        ), 2),
                    adjusted_yield:
                        round_ex((
                            _.sumBy(currentGroup, item => Number(item.adjusted_yield * item.reported_acreage) || 0)
                            / _.sumBy(currentGroup, item => Number(item.reported_acreage) || 0)
                        ), 2),
                    average_yield:
                        round_ex((
                            _.sumBy(currentGroup, item => Number(item.average_yield * item.reported_acreage) || 0)
                            / _.sumBy(currentGroup, item => Number(item.reported_acreage) || 0)
                        ), 2),
                }
            }).value();

        if (doCalculatePremiums) {
            _insurancePlansGrouped.forEach(currentGroup => {
                // After calculating premium, ADJUST to budget acreage (see above.)
                currentGroup.reported_acreage = _reported_acreage_budget;
                currentGroup.producer_premium_amount = currentGroup.producer_premium_amount * (_reported_acreage_budget / _reported_acreage_vault);
                currentGroup.producer_premium_amount_per_acre = currentGroup.producer_premium_amount / _reported_acreage_budget;
                currentGroup.liability_amount = currentGroup.liability_amount * (_reported_acreage_budget / _reported_acreage_vault);
                currentGroup.liability_amount_per_acre = currentGroup.liability_amount / _reported_acreage_budget;
                currentGroup.underlying_liability_amount = currentGroup.underlying_liability_amount * (_reported_acreage_budget / _reported_acreage_vault);
            });
        }

        return _insurancePlansGrouped;
    }

    const _loadCountyData_intern = async (currentBudgetCountyPractice) => {
        let _currentCounty = {
            reinsurance_year: currentBudgetCountyPractice?.year,
            state_name: currentBudgetCountyPractice?.state_name,
            county_name: currentBudgetCountyPractice?.county_name,
            commodity_name: currentBudgetCountyPractice?.commodity_name,
            type_name: currentBudgetCountyPractice?.type_name,
            practice_name: currentBudgetCountyPractice?.practice_name
        };

        const currentPrices = await RmaApiHelper.selectPrices(_currentCounty);
        const currentCountyData = await RmaApiHelper.selectCountyData(_currentCounty);


        const historicalYieldTrend = await RmaApiHelper.selectHistoricalYieldTrend({
            ..._currentCounty,
            state_name: _currentCounty.state_name,
            county_name: _currentCounty.county_name
        });
        let __countyYieldLowAmount = 0;
        let __countyHighYieldAmount = 0;
        if (historicalYieldTrend && historicalYieldTrend.length) {
            __countyYieldLowAmount = _.min(historicalYieldTrend.map(t => t.yield_amount));
        } else {
            const transitionalYields = await RmaApiHelper.selectYieldAndTyield({
                ..._currentCounty,
                state_name: _currentCounty.state_name,
                county_name: _currentCounty.county_name
            });
            if (transitionalYields && transitionalYields.length) {
                __countyYieldLowAmount = transitionalYields[0].transitional_amount;
                __countyHighYieldAmount = __countyYieldLowAmount * 1.2;
            }
        }
        const countyYieldLowAmount = __countyYieldLowAmount;

        const { projected_price, price_volatility_factor } = (await currentPrices)[0] || { projected_price: 0, price_volatility_factor: 0 };

        const _currentCountyDataResolved = await currentCountyData;
        const expected_index_value = _currentCountyDataResolved && _currentCountyDataResolved[0]?.expected_index_value;

        const _price_low = round_ex((projected_price - 1 * (price_volatility_factor * projected_price)), 2);
        const _price_high = round_ex((projected_price + 0.05 * (price_volatility_factor * projected_price)), 2);

        const _yield_low = 0;
        const _yield_high = Math.round(currentBudgetCountyPractice?.approved_yield);

        const currentCounty = {
            ...(currentPrices && currentPrices.length && currentPrices[0]),
            expected_index_value: expected_index_value,

            price_low: _price_low,
            price_high: _price_high,

            yield_low: _yield_low,
            yield_high: _yield_high,

            county_yield_low: Math.round(countyYieldLowAmount),
            county_yield_high: Math.round(expected_index_value || __countyHighYieldAmount),

            ..._currentCounty,
        }

        return currentCounty
    }

    const _calculateTotals = (currentInsuranceScenario) => {
        const _reported_acreage = _.sumBy(currentInsuranceScenario.budgetCountyPractices, cp => cp.totals?.reported_acreage);

        const _producer_premium_amount = _.sumBy(currentInsuranceScenario.budgetCountyPractices, cp => cp.totals?.producer_premium_amount);

        const _producer_premium_amount_per_acre_weight = _.sumBy(currentInsuranceScenario.budgetCountyPractices, cp =>
            cp.totals?.producer_premium_amount_per_acre * cp.totals?.reported_acreage);
        const _producer_premium_amount_per_acre = _producer_premium_amount_per_acre_weight / _reported_acreage;

        const _liability_amount = _.sumBy(currentInsuranceScenario.budgetCountyPractices, cp => cp.totals?.liability_amount);

        const _liability_amount_per_acre_weight = _.sumBy(currentInsuranceScenario.budgetCountyPractices, cp =>
            cp.totals?.liability_amount_per_acre * cp.totals?.reported_acreage);
        const _liability_amount_per_acre = _liability_amount_per_acre_weight / _reported_acreage;

        const _uniqueCounties = _calculateUniqueCounties(currentInsuranceScenario.budgetCountyPractices);

        const _insurancePlans = currentInsuranceScenario.budgetCountyPractices?.reduce((accumulator, currentValue) => {
            return [...accumulator, ...currentValue?.insurancePlans]
        }, []);

        const _totals = {
            insurancePlans: _insurancePlans,

            reported_acreage: _reported_acreage,

            counties: _uniqueCounties,

            producer_premium_amount: _producer_premium_amount,
            producer_premium_amount_per_acre: _producer_premium_amount_per_acre,

            liability_amount: _liability_amount,
            liability_amount_per_acre: _liability_amount_per_acre,
        }

        return _totals;
    }

    const _calculateUniqueCounties = (budgetCountyPractices) => {
        const _counties = _.uniqBy(budgetCountyPractices, cp => JSON.stringify({
            state_name: cp.state_name,
            county_name: cp.county_name
        }))
            ?.map(cp => ({
                state_name: cp.state_name,
                county_name: cp.county_name
            }))
            ?.sort((a, b) => {
                return (
                    a?.state_name.localeCompare(b?.state_name)
                    || a?.county_name.localeCompare(b?.county_name)
                );
            });

        return _counties;
    }

    // --- --- ---

    const _optimizeInsurance = async ({
        constraints,

        onError,
        onStatus,
        onProgress
    }) => {
        for (const currentInsuranceScenario of insuranceScenarios) {
            for (const currentBudgetCountyPractice of currentInsuranceScenario?.budgetCountyPractices) {
                currentBudgetCountyPractice?.producerFarmFieldCrops?.forEach(f => {
                    f.insured_share_percent = f.share_percent;
                    f.production_cost_per_acre = currentBudgetCountyPractice.production_cost_per_acre;
                });

                const _optimizerResults = await optimizeInsurance({
                    myFarms: currentBudgetCountyPractice?.producerFarmFieldCrops,
                    countyData: await _loadCountyData_intern(currentBudgetCountyPractice),
                    constraints,

                    apiHelper: ApiHelper,

                    onError,
                    onStatus,
                    onProgress,
                });

                currentBudgetCountyPractice.optimizerResults = _optimizerResults;
            }

        }

        return insuranceScenarios;
    }

    // --- --- ---

    const actions = {
        loadInsuranceScenario,
        calculatePremiums,
        optimizeInsurance: _optimizeInsurance
    };

    return {
        actions
    }
}
