import { useDialog } from '@datanac/datanac-mui-toolkit'
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import { Alert, CircularProgress, Divider, Menu, MenuItem, Stack, Table, TableBody, TableCell, TableHead, TableRow, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import { AppContext } from 'App'
import { UsersApiHelper } from 'api/ApiHelper'
import { getBudgetYears } from 'components/Budget/helpers'
import { FinancialIcon } from 'components/Icons/Icons'
import { SECTION_FINANCIAL_SCHEDULE_F } from 'components/Menu/NavigationMenu'
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from 'components/Workspaces/helpers'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { useFormStatus } from 'mui-toolkit/useFormStatus'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatNumber, formatPrice } from 'utility/formatters'
import { v4 as uuidv4 } from 'uuid'
import { FinancialService } from '../FinancialService'
import useFinanceReport from '../useFinanceReport'
import { useReportByYear } from './useReportByYear'

export default function ScheduleFReport() {
    const { globalState, globalDispatch, session } = useContext(AppContext);
    const navigate = useNavigate();

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { enqueueSnackbar } = useSnackbar();
    const [workspaceMode, setWorkspaceMode] = useState(isSmall ? WORKSPACE_VIEW_CARD : WORKSPACE_VIEW_TABLE);

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const { component: formStatusComponent, actions: formStatusActions } = useFormStatus();

    const [currentYear, setCurrentYear] = useState(globalState.crop_year);

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    const loadReport = async (_currentYear) => {
        const { actions: financialActions } = new FinancialService({
            apiConfig: globalState,
            year: _currentYear
        });
        return await financialActions.getScheduleF();
    }

    const { state: reportState } = useReportByYear({ currentYear, onError: handleError, loadReportCallback: loadReport });

    const {
        isLoading,

        isLoadingScheduleF,
        scheduleF,
        loadScheduleF,
    } = useFinanceReport({
        onError: handleError,
        currentYear: currentYear,

        loadModules: [SECTION_FINANCIAL_SCHEDULE_F]
    })

    useEffect(() => {
        globalDispatch({
            type: 'update',
            payload: {
                key: 'crop_year',
                value: currentYear
            },
        });
    }, [currentYear])

    const calculateContainersIncome = () => {
        return _.uniq(scheduleF?.income?.values?.map(v => v.container));
    }

    const calculateContainersExpenses = () => {
        return _.uniq(scheduleF?.expenses?.values?.map(v => v.container));
    }

    // --- --- ---

    const [isPerAcre, setIsPerAcre] = useState(0);
    const handleChangeState_isPerAcre = (event, value) => {
        setIsPerAcre(value || 0);
    }

    const handleChangeState_LineItem = async (event, _currentDocumentDictionary) => {
        if (event?.target?.value != scheduleF?.values.find(v => v.id == _currentDocumentDictionary?.id)?.value) {
            formStatusActions.setIsFormDirty(true, true);

            const _newValue = {
                ..._currentDocumentDictionary,
                id: _currentDocumentDictionary?.id || uuidv4(),
                value: event?.target?.value || 0
            }

            const _uploadFormData = {
                producer_token: globalState.producer_token,
                agent_token: globalState.agent_token,
                Document: {
                    id: _newValue?.document_id || uuidv4(),
                    values: [_newValue]
                }
            };

            await UsersApiHelper.updateFinancialDocument(scheduleF, globalState);

            UsersApiHelper.updateDocumentDictionary(_uploadFormData, globalState)
                .then(() => {
                    formStatusActions.setIsSaving(false);
                    loadScheduleF();
                })
                .catch(err => {
                    formStatusActions.setIsFormDirty(true, false);
                    handleError(err);
                });
        }
    }

    const formatName = (value) => {
        if (value) {
            return value.replaceAll("_", " ")
        }
    }

    // --- --- ---

    return (
        <>
            <WorkspaceHeader
                title={'Schedule F' + (isPerAcre ? " per Acre" : "")}
                subHeader='Form 1040'
                breadCrumbs={[
                    {
                        title: 'Financial Analysis',
                        // path: '/financial/documents',
                        icon: <FinancialIcon />
                    },
                ]}
            />

            <Stack direction="row" spacing={2} alignItems='center' className='headerWithMenuButton'>
                <Typography variant='h1'>
                    Year
                </Typography>
                <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                        <>
                            <Button variant="outlined" {...bindTrigger(popupState)}>
                                {currentYear} <ArrowDropDownOutlined />
                            </Button>
                            <Menu {...bindMenu(popupState)}>
                                {getBudgetYears().map((year, index) =>
                                    <MenuItem key={index} onClick={() => {
                                        setCurrentYear(year);
                                        popupState.close();
                                    }}>
                                        {year}
                                    </MenuItem>)}
                            </Menu>
                        </>
                    )}
                </PopupState>

                <ToggleButtonGroup value={isPerAcre} onChange={handleChangeState_isPerAcre} exclusive className='navigation'>
                    <ToggleButton value={0}>Totals</ToggleButton>
                    <ToggleButton value={1}>Per Acre</ToggleButton>
                </ToggleButtonGroup>

                {isLoading && <CircularProgress />}
                {formStatusComponent}
            </Stack>

            {scheduleF?.values?.length == 0 && <>
                <Alert severity="warning">
                    No Schedule F form is found.
                    <Divider />
                    Please upload or enter the document and try again.
                </Alert>
                <br/>
            </>}

            <Table className='reportTable'>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            By Tax Year:
                        </TableCell>
                        <TableCell align='right'>
                            <Button variant='text' onClick={() => navigate(`/financial/schedule_f?year=${currentYear}`)}>
                                {currentYear}
                            </Button>
                        </TableCell>
                        <TableCell align='right'>
                            <Button variant='text' onClick={() => navigate(`/financial/schedule_f?year=${currentYear - 1}`)}>
                                {currentYear - 1}
                            </Button>
                        </TableCell>
                        <TableCell align='right'>
                            <Button variant='text' onClick={() => navigate(`/financial/schedule_f?year=${currentYear - 2}`)}>
                                {currentYear - 2}
                            </Button>
                        </TableCell>
                        <TableCell align='right'>
                            <Button variant='text' onClick={() => navigate(`/financial/schedule_f?year=${currentYear - 3}`)}>
                                {currentYear - 3}
                            </Button>
                        </TableCell>
                        <TableCell align='right'>
                            <Button variant='text' onClick={() => navigate(`/financial/schedule_f?year=${currentYear - 4}`)}>
                                {currentYear - 4}
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>

                    <TableRow>
                        <TableCell>
                            <Typography sx={{ textTransform: "capitalize" }}>
                                # Acres Planted:
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            {reportState[String(currentYear)]?.data?.header?.reported_acreage &&
                                formatNumber(reportState[String(currentYear)]?.data?.header?.reported_acreage)}
                        </TableCell>
                        <TableCell align='right'>
                            {reportState[String(currentYear - 1)]?.data?.header?.reported_acreage &&
                                formatNumber(reportState[String(currentYear - 1)]?.data?.header?.reported_acreage)}
                        </TableCell>
                        <TableCell align='right'>
                            {reportState[String(currentYear - 2)]?.data?.header?.reported_acreage &&
                                formatNumber(reportState[String(currentYear - 2)]?.data?.header?.reported_acreage)}
                        </TableCell>
                        <TableCell align='right'>
                            {reportState[String(currentYear - 3)]?.data?.header?.reported_acreage &&
                                formatNumber(reportState[String(currentYear - 3)]?.data?.header?.reported_acreage)}
                        </TableCell>
                        <TableCell align='right'>
                            {reportState[String(currentYear - 4)]?.data?.header?.reported_acreage &&
                                formatNumber(reportState[String(currentYear - 4)]?.data?.header?.reported_acreage)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography sx={{ textTransform: "capitalize" }}>
                                # Replant Acres:
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            {reportState[String(currentYear)]?.data?.header?.replant_reported_acreage &&
                                formatNumber(reportState[String(currentYear)]?.data?.header?.replant_reported_acreage)}
                        </TableCell>
                        <TableCell align='right'>
                            {reportState[String(currentYear - 1)]?.data?.header?.replant_reported_acreage &&
                                formatNumber(reportState[String(currentYear - 1)]?.data?.header?.replant_reported_acreage)}
                        </TableCell>
                        <TableCell align='right'>
                            {reportState[String(currentYear - 2)]?.data?.header?.replant_reported_acreage &&
                                formatNumber(reportState[String(currentYear - 2)]?.data?.header?.replant_reported_acreage)}
                        </TableCell>
                        <TableCell align='right'>
                            {reportState[String(currentYear - 3)]?.data?.header?.replant_reported_acreage &&
                                formatNumber(reportState[String(currentYear - 3)]?.data?.header?.replant_reported_acreage)}
                        </TableCell>
                        <TableCell align='right'>
                            {reportState[String(currentYear - 4)]?.data?.header?.replant_reported_acreage &&
                                formatNumber(reportState[String(currentYear - 4)]?.data?.header?.replant_reported_acreage)}
                        </TableCell>
                    </TableRow>

                    <TableRow className='separator'>
                        <TableCell colSpan={6}>
                            Part I - Farm Income
                        </TableCell>
                    </TableRow>

                    {scheduleF?.income?.values?.map((dictionaryValue, index) => {

                        const perAcreMultiplier = (isPerAcre && reportState[String(currentYear)]?.data?.header?.reported_acreage != 0)
                            ? 1 / reportState[String(currentYear)]?.data?.header?.reported_acreage
                            : 1;

                        return <TableRow key={index}>
                            <TableCell>
                                <Typography sx={{ textTransform: "capitalize" }}>
                                    {formatName(dictionaryValue.item)}
                                </Typography>
                            </TableCell>
                            <TableCell align='right'>
                                {Boolean(reportState[String(currentYear)]?.data?.income?.[dictionaryValue.item]) &&
                                    formatPrice(reportState[String(currentYear)]?.data?.income?.[dictionaryValue.item] * perAcreMultiplier)}
                            </TableCell>
                            <TableCell align='right'>
                                {Boolean(reportState[String(currentYear - 1)]?.data?.income?.[dictionaryValue.item]) &&
                                    formatPrice(reportState[String(currentYear - 1)]?.data?.income?.[dictionaryValue.item] * perAcreMultiplier)}
                            </TableCell>
                            <TableCell align='right'>
                                {Boolean(reportState[String(currentYear - 2)]?.data?.income?.[dictionaryValue.item]) &&
                                    formatPrice(reportState[String(currentYear - 2)]?.data?.income?.[dictionaryValue.item] * perAcreMultiplier)}
                            </TableCell>
                            <TableCell align='right'>
                                {Boolean(reportState[String(currentYear - 3)]?.data?.income?.[dictionaryValue.item]) &&
                                    formatPrice(reportState[String(currentYear - 3)]?.data?.income?.[dictionaryValue.item] * perAcreMultiplier)}
                            </TableCell>
                            <TableCell align='right'>
                                {Boolean(reportState[String(currentYear - 4)]?.data?.income?.[dictionaryValue.item]) &&
                                    formatPrice(reportState[String(currentYear - 4)]?.data?.income?.[dictionaryValue.item] * perAcreMultiplier)}
                            </TableCell>
                        </TableRow>
                    })}
                    <TableRow className='separator'>
                        <TableCell>
                            <Typography sx={{ textTransform: "capitalize" }}>
                                Gross Income:
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            {formatPrice(
                                reportState[String(currentYear)]?.data?.income.total
                                * ((isPerAcre && reportState[String(currentYear)]?.data?.header?.reported_acreage != 0)
                                    ? 1 / reportState[String(currentYear)]?.data?.header?.reported_acreage
                                    : 1)
                            )}
                        </TableCell>
                        <TableCell align='right'>
                            {formatPrice(
                                reportState[String(currentYear - 1)]?.data?.income.total
                                * ((isPerAcre && reportState[String(currentYear - 1)]?.data?.header?.reported_acreage != 0)
                                    ? 1 / reportState[String(currentYear - 1)]?.data?.header?.reported_acreage
                                    : 1)
                            )}
                        </TableCell>
                        <TableCell align='right'>
                            {formatPrice(
                                reportState[String(currentYear - 2)]?.data?.income.total
                                * ((isPerAcre && reportState[String(currentYear - 2)]?.data?.header?.reported_acreage != 0)
                                    ? 1 / reportState[String(currentYear - 2)]?.data?.header?.reported_acreage
                                    : 1)
                            )}
                        </TableCell>
                        <TableCell align='right'>
                            {formatPrice(
                                reportState[String(currentYear - 3)]?.data?.income.total
                                * ((isPerAcre && reportState[String(currentYear - 3)]?.data?.header?.reported_acreage != 0)
                                    ? 1 / reportState[String(currentYear - 3)]?.data?.header?.reported_acreage
                                    : 1)
                            )}
                        </TableCell>
                        <TableCell align='right'>
                            {formatPrice(
                                reportState[String(currentYear - 4)]?.data?.income.total
                                * ((isPerAcre && reportState[String(currentYear - 4)]?.data?.header?.reported_acreage != 0)
                                    ? 1 / reportState[String(currentYear - 4)]?.data?.header?.reported_acreage
                                    : 1)
                            )}
                        </TableCell>
                    </TableRow>

                    <TableRow className='separator pageBreakBefore'>
                        <TableCell colSpan={6}>
                            Part II - Farm Expenses
                        </TableCell>
                    </TableRow>

                    {scheduleF?.expenses?.values?.map((dictionaryValue, index) => {
                        const perAcreMultiplier = (isPerAcre && reportState[String(currentYear)]?.data?.header?.reported_acreage != 0)
                            ? 1 / reportState[String(currentYear)]?.data?.header?.reported_acreage
                            : 1;

                        return <TableRow key={index}>
                            <TableCell>
                                <Typography sx={{ textTransform: "capitalize" }}>
                                    {formatName(dictionaryValue.item)}

                                    {
                                        (dictionaryValue.item?.startsWith("other") ?
                                            " (" + reportState[String(currentYear)]?.data?.expenses_names[dictionaryValue.item]?.toLowerCase() + ")"
                                            : ""
                                        )
                                    }
                                </Typography>
                            </TableCell>
                            <TableCell align='right'>
                                {Boolean(reportState[String(currentYear)]?.data?.expenses?.[dictionaryValue.item]) &&
                                    formatPrice(reportState[String(currentYear)]?.data?.expenses?.[dictionaryValue.item] * perAcreMultiplier)}
                            </TableCell>
                            <TableCell align='right'>
                                {Boolean(reportState[String(currentYear - 1)]?.data?.expenses?.[dictionaryValue.item]) &&
                                    formatPrice(reportState[String(currentYear - 1)]?.data?.expenses?.[dictionaryValue.item] * perAcreMultiplier)}
                            </TableCell>
                            <TableCell align='right'>
                                {Boolean(reportState[String(currentYear - 2)]?.data?.expenses?.[dictionaryValue.item]) &&
                                    formatPrice(reportState[String(currentYear - 2)]?.data?.expenses?.[dictionaryValue.item] * perAcreMultiplier)}
                            </TableCell>
                            <TableCell align='right'>
                                {Boolean(reportState[String(currentYear - 3)]?.data?.expenses?.[dictionaryValue.item]) &&
                                    formatPrice(reportState[String(currentYear - 3)]?.data?.expenses?.[dictionaryValue.item] * perAcreMultiplier)}
                            </TableCell>
                            <TableCell align='right'>
                                {Boolean(reportState[String(currentYear - 4)]?.data?.expenses?.[dictionaryValue.item]) &&
                                    formatPrice(reportState[String(currentYear - 4)]?.data?.expenses?.[dictionaryValue.item] * perAcreMultiplier)}
                            </TableCell>
                        </TableRow>
                    })}
                    <TableRow className='separator'>
                        <TableCell>
                            <Typography sx={{ textTransform: "capitalize" }}>
                                Total Expenses:
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            {formatPrice(
                                reportState[String(currentYear)]?.data?.expenses.total
                                * ((isPerAcre && reportState[String(currentYear)]?.data?.header?.reported_acreage != 0)
                                    ? 1 / reportState[String(currentYear)]?.data?.header?.reported_acreage
                                    : 1)
                            )}
                        </TableCell>
                        <TableCell align='right'>
                            {formatPrice(
                                reportState[String(currentYear - 1)]?.data?.expenses.total
                                * ((isPerAcre && reportState[String(currentYear - 1)]?.data?.header?.reported_acreage != 0)
                                    ? 1 / reportState[String(currentYear - 1)]?.data?.header?.reported_acreage
                                    : 1)
                            )}
                        </TableCell>
                        <TableCell align='right'>
                            {formatPrice(
                                reportState[String(currentYear - 2)]?.data?.expenses.total
                                * ((isPerAcre && reportState[String(currentYear - 2)]?.data?.header?.reported_acreage != 0)
                                    ? 1 / reportState[String(currentYear - 2)]?.data?.header?.reported_acreage
                                    : 1)
                            )}
                        </TableCell>
                        <TableCell align='right'>
                            {formatPrice(
                                reportState[String(currentYear - 3)]?.data?.expenses.total
                                * ((isPerAcre && reportState[String(currentYear - 3)]?.data?.header?.reported_acreage != 0)
                                    ? 1 / reportState[String(currentYear - 3)]?.data?.header?.reported_acreage
                                    : 1)
                            )}
                        </TableCell>
                        <TableCell align='right'>
                            {formatPrice(
                                reportState[String(currentYear - 4)]?.data?.expenses.total
                                * ((isPerAcre && reportState[String(currentYear - 4)]?.data?.header?.reported_acreage != 0)
                                    ? 1 / reportState[String(currentYear - 4)]?.data?.header?.reported_acreage
                                    : 1)
                            )}
                        </TableCell>
                    </TableRow>


                    <TableRow className='separator'>
                        <TableCell>
                            <Typography sx={{ textTransform: "capitalize" }}>
                                Net Farm Profit (or Loss):
                            </Typography>
                        </TableCell>
                        <TableCell align='right'>
                            {formatPrice(
                                (reportState[String(currentYear - 0)]?.data?.income.total - reportState[String(currentYear - 0)]?.data?.expenses.total)
                                * ((isPerAcre && reportState[String(currentYear - 0)]?.data?.header?.reported_acreage != 0)
                                    ? 1 / reportState[String(currentYear - 0)]?.data?.header?.reported_acreage
                                    : 1)
                            )}
                        </TableCell>
                        <TableCell align='right'>
                            {formatPrice(
                                (reportState[String(currentYear - 1)]?.data?.income.total - reportState[String(currentYear - 1)]?.data?.expenses.total)
                                * ((isPerAcre && reportState[String(currentYear - 1)]?.data?.header?.reported_acreage != 0)
                                    ? 1 / reportState[String(currentYear - 1)]?.data?.header?.reported_acreage
                                    : 1)
                            )}
                        </TableCell>
                        <TableCell align='right'>
                            {formatPrice(
                                (reportState[String(currentYear - 2)]?.data?.income.total - reportState[String(currentYear - 2)]?.data?.expenses.total)
                                * ((isPerAcre && reportState[String(currentYear - 2)]?.data?.header?.reported_acreage != 0)
                                    ? 1 / reportState[String(currentYear - 2)]?.data?.header?.reported_acreage
                                    : 1)
                            )}
                        </TableCell>
                        <TableCell align='right'>
                            {formatPrice(
                                (reportState[String(currentYear - 3)]?.data?.income.total - reportState[String(currentYear - 3)]?.data?.expenses.total)
                                * ((isPerAcre && reportState[String(currentYear - 3)]?.data?.header?.reported_acreage != 0)
                                    ? 1 / reportState[String(currentYear - 3)]?.data?.header?.reported_acreage
                                    : 1)
                            )}
                        </TableCell>
                        <TableCell align='right'>
                            {formatPrice(
                                (reportState[String(currentYear - 4)]?.data?.income.total - reportState[String(currentYear - 4)]?.data?.expenses.total)
                                * ((isPerAcre && reportState[String(currentYear - 4)]?.data?.header?.reported_acreage != 0)
                                    ? 1 / reportState[String(currentYear - 4)]?.data?.header?.reported_acreage
                                    : 1)
                            )}
                        </TableCell>
                    </TableRow>

                </TableBody>
            </Table>


            <WorkspaceActionBar content={<>
            </>} />

            {confirmDialogComponent}
        </>
    )
}
