import { useDialog } from '@datanac/datanac-mui-toolkit'
import { Box, Button, CircularProgress, Link, useMediaQuery } from '@mui/material'
import { AppContext } from 'App'
import EmptyCard from 'components/Cards/EmptyCard'
import { propUpdateReducer } from 'components/Core/propUpdateReducer'
import { FarmVaultIcon } from 'components/Icons/Icons'
import { useFarmController } from 'components/Vault/Farm/useFarmController'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { WORKSPACE_VIEW_CARD, WORKSPACE_VIEW_TABLE } from 'components/Workspaces/helpers'
import { useSnackbar } from 'notistack'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { formatNumber } from 'utility/formatters';
import { ReportViewer } from './ReportViewer';

export default function FarmTractFieldReport() {
    const {
        crop_year,
    } = useParams();
    
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || err, { preventDuplicate: true, variant: 'error' });
    }

    const [currentYear, setCurrentYear] = useState(crop_year);
    const [filter, dispatchFilter] = useReducer(propUpdateReducer, {
        commodity_name: [],
        practice_name: [],
    });

    const [isDetailViewOn, setIsDetailViewOn] = useState(false);

    const setIsDetailViewOnFunc = (val) => {
        setIsDetailViewOn(val);
    }

    const {
        counties,
        // countyPractices,
        // producerFarms,
        // producerFarmFieldCrops,
        // producerFarm_union_Crops,
        // isLoading,
        isLoadingCounties,
    } = useFarmController({
        crop_year: currentYear,
        filter: filter,
        onError: handleError
    });

    const columnsDirective = [ 
        { field: 'location_county_name', headerText: 'County', width: 100, textAlign: 'Left', visible: true },
        { field: 'location_state_name', headerText: 'State', width: 100, textAlign: 'Left', visible: true },
        { field: 'fsa_farm_number', headerText: 'Farm Number', width: 100, textAlign: 'Right', visible: true },
        { field: 'fsa_tract_number', headerText: 'Track', width: 100, textAlign: 'Right', visible: true },
        { field: 'fsa_field_number', headerText: 'Field', width: 100, textAlign: 'Right', visible: true },
        { field: 'crop_year', headerText: 'Year', width: 150, textAlign: 'Right', visible: isDetailViewOn},
        { field: 'reported_acreage', headerText: 'Reported Acreage', width: 150, textAlign: 'Right', visible: true },
        { field: 'share_percent', headerText: 'Share Percent', width: 150, textAlign: 'Right', format: 'P0', visible: isDetailViewOn},
        { field: 'commodity_name', headerText: 'Commodity', width: 150, visible: true },
        { field: 'type_name', headerText: 'Type', width: 150, visible: isDetailViewOn },
        { field: 'practice_name', headerText: 'Practice', width: 150, visible: true },
        { field: 'approved_yield', headerText: 'Approved Yield', width: 150, textAlign: 'Right', visible: true },
        { field: 'rate_yield', headerText: 'Rate Yield', width: 150, textAlign: 'Right', visible: isDetailViewOn },
        { field: 'lastupdate', headerText: 'Last Update', width: 150,  textAlign: 'Right', type: 'date', format: 'yMd', visible: isDetailViewOn },
    ];

    return (
        <>
            <WorkspaceHeader
                title={"Planted Acres by Field"}
                breadCrumbs={[
                    {
                        title: 'Farm Vault ' + crop_year,
                        path: '/vault',
                        icon: <FarmVaultIcon fontSize='3pt' />
                    },
                ]}
            />

            {!isLoadingCounties && !Boolean(counties?.length) && <>
                <EmptyCard text="No search results" secondaryText="Remove some filters or add a new farm in the Farm Vault" />
            </>}

            <ReportViewer 
                columnsDirective={columnsDirective} 
                counties={counties}
                producerFarmFieldCrops={counties}
                isLoadingCounties={isLoadingCounties}
                reportType="field"
                setIsDetailViewOnFunc={setIsDetailViewOnFunc}
            />
        </>
    )
}
