import EastOutlined from '@mui/icons-material/EastOutlined';
import { Box, Chip, IconButton, Stack } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { useNavigate } from 'react-router-dom';
import { formatNumber, formatPracticeName } from 'utility/formatters';

const getNameSummary = params => {
    const farmRow = params.row;

    const nameLabel = farmRow?.business_name ? farmRow.business_name : farmRow.last_name;
    return nameLabel;
}

const getStateCountySummary = params => {
    const farmRow = params.row;

    const label = `${farmRow?.location_county_name}, ${farmRow?.location_state_name}`;
    return label;
}

const getFarmRowSummary = params => {
    const farmRow = params.row;

    const nameLabel = farmRow?.business_name ? farmRow.business_name : farmRow.last_name;
    const commodityLabel = `${farmRow?.commodity_name} ${farmRow?.practice_name}`
    return String(`${nameLabel} ${farmRow.location_county_name} / ${farmRow.fsa_farm_number} T${farmRow.fsa_tract_number} (${farmRow.fsa_field_number}) / ${farmRow.basic_unit_number}-${farmRow.optional_unit_number} / ${commodityLabel}`);
}

export default function ProducerFarmCropDataTable({
    title,
    producerFarmCrops,
}) {
    const navigate = useNavigate();

    const columns = [
        {
            name: 'farm_name',
            label: 'Name',
            flex: 2
        },
        {
            name: 'fsa_farm_number',
            label: 'FSN',
            flex: 1
        },
        {
            name: 'reported_acreage',
            label: 'Acres',
            options: {
                customBodyRender: (value, tableMeta, updateValue) =>
                    value && formatNumber(value, 2),
            },
            flex: 1
        },
        {
            name: 'base_acreage',
            label: 'Base Acres',
            options: {
                customBodyRender: (value, tableMeta, updateValue) =>
                    value && formatNumber(value, 2),
            },
            flex: 1
        },
        {
            name: 'CropProfiles_Sort',
            label: 'Crop Profiles',
            options: {
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const currentProducerFarmCrop = producerFarmCrops[dataIndex];
                    return <Box key={rowIndex}>
                        <Stack direction="row" spacing={0} sx={{ flexWrap: "wrap", gap: 1 }}>
                            {currentProducerFarmCrop?.CropProfiles?.map((currentCropProfile, index) =>
                                <Chip key={index} label={currentCropProfile?.commodity_name + ' ' + formatPracticeName(currentCropProfile?.practice_name)} />
                            )}
                        </Stack>
                    </Box>;
                }
            },
            flex: 0
        },
        {
            name: 'last_name',
            label: 'Producer',
            valueGetter: getNameSummary,
            flex: 1
        },
        {
            name: 'id',
            label: 'ID',
            options: {
                customHeadRender: () => "",
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <Box display="flex" justifyContent='flex-end'>
                        <IconButton onClick={() => { navigate(`./${value}`) }}>
                            <EastOutlined />
                        </IconButton>
                    </Box>;
                },
            },
            flex: 0
        },
    ];

    const options = {
        rowsPerPage: 10,
        rowsPerPageOptions: [5, 10, 25, 50, 100],
        selectableRows: "none",

        downloadOptions: {
            filename: "Farms.csv"
        },
        fixedSelectColumn: false,

        responsive: 'vertical',

        expandableRows: false,
    }

    return (
        <div className='MUI-Data-Table'>
            <MUIDataTable
                title={title}
                data={producerFarmCrops}
                columns={columns}
                options={options}
                sx={{ width: "99%", boxSizing: "border-box" }}
                autoHeight
            />
        </div>
    );
}
