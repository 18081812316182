import EastOutlined from '@mui/icons-material/EastOutlined'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import WarningOutlined from '@mui/icons-material/WarningOutlined'
import { Box, Card, CardActions, CardContent, CardHeader, Chip, Divider, IconButton, Skeleton, Stack, Typography } from '@mui/material'
import { AppContext } from 'App'
import { FarmIcon } from 'components/Icons/Icons'
import { useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { formatNumber, formatPrice } from 'utility/formatters'

export default function ArcPlcCountyCropCard({
    producerFarm,
    baseAcres,
    arcPlc,
    isLoadingArcPlc,
    isErrorArcPlc,
    onChange
}) {
    const { globalState, globalDispatch, session } = useContext(AppContext);

    const navigate = useNavigate();
    const {
        crop_year,
        location_state_name,
        location_county_name,
        fsa_farm_number,
        // fsa_tract_number,
        // fsa_field_number
    } = useParams();

    return (
        <>
            <Card>
                <CardHeader
                    avatar={<FarmIcon />}
                    title={baseAcres?.commodity_name}
                />
                <CardContent>
                    <Stack direction="column" spacing={1} sx={{ height: "100%" }}>
                        <Stack direction='row' spacing={1} alignItems='center' justifyItems='center'>
                            <Typography variant='h6'>
                                {formatNumber(baseAcres?.base_acreage, 0)}
                            </Typography>
                            <Typography variant='body1' sx={{ fontSize: '8pt', leading: '0px', lineHeight: '9pt', height: '100%' }}>
                                BASE<br />
                                ACRES
                            </Typography>

                            <Divider orientation='vertical' flexItem />

                            <Typography variant='h6'>
                                {formatNumber(baseAcres?.plc_yield_weighted, 0)}
                            </Typography>
                            <Typography variant='body1' sx={{ fontSize: '8pt', leading: '0px', lineHeight: '9pt', height: '100%' }}>
                                PLC<br />
                                YIELD
                            </Typography>
                        </Stack>

                        {!isErrorArcPlc && (arcPlc?.arcPayment != 0 || arcPlc?.plcPayment != 0) &&
                            <Stack direction='row' spacing={1}>
                                <Box component={Stack} direction="column" spacing={0}>
                                    <Typography variant='caption' color="secondary">
                                        ARC-CO PAYMENT
                                    </Typography>
                                    <Typography variant='h6'>
                                        {!isLoadingArcPlc && <>
                                            {formatPrice(arcPlc?.arcPayment, 0)}
                                        </>}
                                        {isLoadingArcPlc && <>
                                            <Skeleton />
                                        </>}
                                    </Typography>
                                </Box>

                                <Divider orientation="vertical" flexItem />

                                <Box component={Stack} direction="column" spacing={0}>
                                    <Typography variant='caption' color="secondary">
                                        PLC PAYMENT
                                    </Typography>
                                    <Typography variant='h6'>
                                        {!isLoadingArcPlc && <>
                                            {formatPrice(arcPlc?.plcPayment, 0)}
                                        </>}
                                        {isLoadingArcPlc && <>
                                            <Skeleton />
                                        </>}
                                    </Typography>
                                </Box>
                            </Stack>}
                    </Stack>
                </CardContent>

                <CardActions>
                    {!isErrorArcPlc && arcPlc?.arcPayment == 0 && arcPlc?.plcPayment == 0 &&
                        <Chip icon={<InfoOutlined />} label={"No payments projected"} variant="outlined" />
                    }

                    {isErrorArcPlc &&
                        <Chip icon={<WarningOutlined />} label={"Unable to calculate ARC /PLC projections"} variant="filled" color="error" />
                    }

                    <IconButton onClick={() => {
                        navigate(`/arcplc/${crop_year}/${location_state_name}/${location_county_name}/${baseAcres?.commodity_name}`)
                    }}>
                        <EastOutlined />
                    </IconButton>
                </CardActions>
            </Card>
        </>
    )
}
