export const OBJECT_NAME_DEBT_SCHEDULE = "debt_schedule";

// ---

export const CONTAINER_NAME_DEBT_SCHEDULE_HEADER = "header";

export const CONTAINER_NAME_DEBT_SCHEDULE_CROPS = "crops";
export const CONTAINER_NAME_DEBT_SCHEDULE_EQUIPMENT = "equipment";
export const CONTAINER_NAME_DEBT_SCHEDULE_LIVESTOCK = "livestock";
export const CONTAINER_NAME_DEBT_SCHEDULE_REAL_ESTATE = "real_estate";

// ---

export const ITEM_NAME_DEBT_SCHEDULE_AMOUNT = "amount";
export const ITEM_NAME_DEBT_SCHEDULE_INTEREST_PAID = "interest_paid";
export const ITEM_NAME_DEBT_SCHEDULE_PRINCIPAL_PAID = "principal_paid";
export const ITEM_NAME_DEBT_SCHEDULE_LIABILITY_CURRENT = "liability_current";
export const ITEM_NAME_DEBT_SCHEDULE_LIABILITY_NONCURRENT = "liability_noncurrent";
export const ITEM_NAME_DEBT_SCHEDULE_PRINCIPAL = "principal";
export const ITEM_NAME_DEBT_SCHEDULE_INTEREST_ACCRUED = "interest_accrued";

// ---

export const COLLATERAL_MARGIN_RATE_EQUIPMENT = 0.65;
export const COLLATERAL_MARGIN_RATE_REAL_ESTATE = 0.80;
export const COLLATERAL_MARGIN_RATE_LIVESTOCK = 0.65;
export const COLLATERAL_MARGIN_RATE_CROPS = 0.75;
export const COLLATERAL_MARGIN_RATE_ARC_PLC = 0.90;

// --- --- ---

// export const DOCUMENT_DICTIONARY_DEFAULT_VALUES_DEBT_SCHEDULE = [
// ]
