import EastOutlined from '@mui/icons-material/EastOutlined';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import { AppContext } from 'App';
import { MemberManagementIcon } from 'components/Icons/Icons';
import { USER_ROLE_ADMIN } from 'core/useSession';
import MUIDataTable from 'mui-datatables';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export const MEMBER_ACTION_MANAGE_ACCOUNT = "manage_account";

export default function MemberDataTable({
    title,
    members,

    onItemAction,

    itemSelectLabel,
    onItemSelect
}) {

    const navigate = useNavigate();
    const { globalState, globalDispatch } = useContext(AppContext);

    const COLUMNS_MEMBERS = [
        {
            name: 'last_name',
            label: 'Last',
            flex: 1
        },
        {
            name: 'first_name',
            label: 'First',
            flex: 1
        },
        {
            name: 'business_name',
            label: 'Business',
            flex: 1
        },
        {
            name: 'city',
            label: 'City',
            flex: 1
        },
        {
            name: 'state_abbreviation',
            label: 'State',
            flex: 1
        },
        {
            name: 'phone',
            label: 'Phone #',
            flex: 1
        },
        ...((onItemAction && globalState?.user_role == USER_ROLE_ADMIN &&
            [{
                name: 'id',
                label: 'Manage',
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const producer = members?.find(m => m.id == value);
                        return <Box display="flex" justifyContent='flex-start'>
                            <IconButton
                                disabled={!producer}
                                onClick={() => {
                                    if (producer) {
                                        onItemAction && onItemAction(producer, MEMBER_ACTION_MANAGE_ACCOUNT);
                                    }
                                }}>
                                <MemberManagementIcon />
                            </IconButton>
                        </Box>;
                    },
                },
                flex: 0
            }]
        ) || []),
        {
            name: 'producer_token',
            label: itemSelectLabel || 'View Account',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    const producer = members?.find(m => m.producer_token == value);

                    return <Box display="flex" justifyContent='flex-start'>
                        <IconButton
                            disabled={!producer?.producer_token}
                            onClick={() => {
                                if (producer?.producer_token) {
                                    onItemSelect && onItemSelect(producer);
                                }
                            }}>
                            <EastOutlined />
                        </IconButton>
                    </Box>;
                },
            },
            flex: 0
        },
    ];

    const options = {
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 25, 50, 100],
        selectableRows: "none",

        downloadOptions: {
            filename: "Members.csv"
        },
        fixedSelectColumn: false,

        responsive: 'vertical',

        expandableRows: false,
    }

    return (
        <div className='MUI-Data-Table'>
            <MUIDataTable
                title={title}
                data={members}
                columns={COLUMNS_MEMBERS}
                options={options}
                sx={{ width: "99%", boxSizing: "border-box" }}
                autoHeight
            />
        </div>
    );
}
