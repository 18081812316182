import { DOCUMENT_FORM_NAME_BALANCE_SHEET, DOCUMENT_FORM_NAME_BASE_ACRES, DOCUMENT_FORM_NAME_DEBT_SCHEDULE, DOCUMENT_FORM_NAME_EQUIPMENT_INVENTORY, DOCUMENT_FORM_NAME_FARMS, DOCUMENT_FORM_NAME_INCOME_STATEMENT, DOCUMENT_FORM_NAME_SCHEDULE_F, handleProcessExcelForm, parseExcelForm } from '@datanac/datanac-api-toolkit'
import { useDialog } from '@datanac/datanac-mui-toolkit'
import CloseOutlined from '@mui/icons-material/CloseOutlined'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import SaveOutlined from '@mui/icons-material/SaveOutlined'
import { Box, Button, DialogActions, DialogContent, DialogTitle, InputLabel, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Select, Stack, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { CONTENT_TYPE_EXCEL_XLS, CONTENT_TYPE_EXCEL_XLSX, CONTENT_TYPE_PDF, getFileIcon, getFileTypeName, uploadDocument } from 'api-toolkit/FilesHelper'
import { ApiHelper, UsersApiHelper } from 'api/ApiHelper'
import { AppContext } from 'App'
import { propUpdateReducer } from 'components/Core/propUpdateReducer'
import { FINANCIAL_DOCUMENTS } from 'components/Financial/FinancialHelpers'
import { FileUploadPanel } from 'mui-toolkit/upload/FileUploadPanel'
import { useSnackbar } from 'notistack'
import { useContext, useMemo, useReducer, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

export default function ImportUploadFileDialog({
    year,
    onClose
}) {
    const { globalState, globalDispatch } = useContext(AppContext);

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    
    // ---

    const [fileContents, setFileContents] = useState(null);
    const [file, setFile] = useState(null);
    const handleFileUploadOnChange = async (_currentFile) => {
        if (_currentFile?.type == CONTENT_TYPE_PDF) {
            setFile(_currentFile);
        } else if (
            _currentFile?.type == CONTENT_TYPE_EXCEL_XLS
            || _currentFile?.type == CONTENT_TYPE_EXCEL_XLSX
        ) {
            try {
                const fileBuffer = await _currentFile.arrayBuffer();
                const _parsedExcelFormData = await parseExcelForm(fileBuffer);
                const processedFormData = await handleProcessExcelForm(_parsedExcelFormData, ApiHelper);

                if (processedFormData?.dataItems?.length) {
                    setFileContents(processedFormData);
                    setFile(_currentFile);
                } else {
                    setFile(_currentFile);
                }

                if (processedFormData?.formName) {
                    dispatch({
                        type: 'update',
                        payload: { key: "document_type_name", value: processedFormData?.formName },
                    });
                }
            } catch (err) {
                console.warn(err);
                handleFileUploadOnError(err);
            }
        }

        setEventErrors([]);
    }

    const [eventErrors, setEventErrors] = useState([]);
    const handleFileUploadOnError = (err) => {
        console.warn(err);
        setEventErrors([
            "Error opening file. Please select a valid file and try again."
        ]);
    }

    // ---

    const [state, dispatch] = useReducer(propUpdateReducer);
    const handleChangeState = (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        dispatch({
            type: 'update',
            payload: { key: event.target.name, value: value },
        });
    }

    const handleChangeStateSelect = (event) => {
        dispatch({
            type: 'update',
            payload: { key: event.target.name, value: event.target.value },
        });
    }

    const FORM_NAMES_SUPPORTED = [
        DOCUMENT_FORM_NAME_FARMS,
        DOCUMENT_FORM_NAME_BASE_ACRES,

        DOCUMENT_FORM_NAME_BALANCE_SHEET,
        DOCUMENT_FORM_NAME_INCOME_STATEMENT,

        DOCUMENT_FORM_NAME_SCHEDULE_F,
        DOCUMENT_FORM_NAME_EQUIPMENT_INVENTORY,

        DOCUMENT_FORM_NAME_DEBT_SCHEDULE
    ];

    const isValid = useMemo(() => {
        let isValid = false;

        if (fileContents?.dataItems?.length) {
            return FORM_NAMES_SUPPORTED.includes(fileContents?.formName);
        } else if (file) {
            return state?.document_type_name;
        }

        return isValid;
    }, [state, fileContents, file]);

    const validationErrors = useMemo(() => {
        const _validationErrors = [];
        if (!isValid) {
            if (fileContents && !FORM_NAMES_SUPPORTED.includes(fileContents?.formName)) {
                _validationErrors.push(`Unsupported file type (${fileContents?.formName}). You can add this file to your Farm Vault, but the data cannot be automatically imported.`);
            }
        }

        return _validationErrors || [];
    }, [isValid]);

    // ---

    const handleSaveClick = async () => {
        const _document_id = uuidv4();
        const _document = {
            id: _document_id,
            year: year,
            document_type_name: state?.document_type_name,
            content_type: file?.type,
            is_active: true,
        }

        if (fileContents?.formName == DOCUMENT_FORM_NAME_BASE_ACRES) {
            const _uploadFormData = {
                producer_token: globalState.producer_token,
                agent_token: globalState.agent_token,
                ProducerFarmBaseAcres: fileContents?.dataItems.map(row => ({
                    id: uuidv4(),
                    ...row,
                    producer_token: globalState.producer_token,
                    agent_token: globalState.agent_token
                }))
            };
            UsersApiHelper.updateProducerFarmBaseAcres(_uploadFormData, globalState)
                .then(() => {
                    onClose && onClose()
                })
                .catch(err => {
                    console.warn(err);
                    enqueueSnackbar("Error uploading file. Please select another file or try again." + (err?.detail || ''), { preventDuplicate: true, variant: 'error' });
                });
        } else if (fileContents?.formName == DOCUMENT_FORM_NAME_FARMS) {
            const _uploadFormData = {
                producer_token: globalState.producer_token,
                agent_token: globalState.agent_token,
                ProducerFarmFieldCrops: fileContents?.dataItems
                    ?.filter(row =>
                        row.fsa_farm_number
                        // Filter for valid/supported datanac commodities:
                        && row.commodity_name
                    )
                    ?.map(row => ({
                        id: uuidv4(),
                        ...row,
                        producer_token: globalState.producer_token,
                        agent_token: globalState.agent_token
                    }))
            };
            UsersApiHelper.updateProducerFarmFieldCrop(_uploadFormData, globalState)
                .then(() => {
                    onClose && onClose()
                })
                .catch(err => {
                    console.warn(err);
                    enqueueSnackbar("Error uploading file. Please select another file or try again." + (err?.detail || ''), { preventDuplicate: true, variant: 'error' });
                });
        } else if (FINANCIAL_DOCUMENTS.find(doc => doc.document_type_name == fileContents?.formName)) {
            const _uploadFormData = {
                producer_token: globalState.producer_token,
                agent_token: globalState.agent_token,
                Document: {
                    id: _document_id,
                    values: fileContents?.dataItems.map(row => ({
                        id: uuidv4(),
                        ...row,
                        producer_token: globalState.producer_token,
                        agent_token: globalState.agent_token
                    }))
                }
            };

            UsersApiHelper.updateDocumentDictionary(_uploadFormData, globalState);
        }

        if (file) {
            await uploadDocument(_document, file, globalState);
            onClose && onClose();
        }
    }

    const columns = useMemo(() => fileContents?.dataItems[0]
        ? Object.keys(fileContents?.dataItems[0]).map(key => ({
            field: key,
            headerName: key,
            flex: 1,
            minWidth: 150,
        }))
        : [], [fileContents]);

    // ---

    return (
        <>
            <DialogTitle>
                Upload Files
            </DialogTitle>
            <DialogContent>
                {((!Boolean(fileContents?.dataItems?.length) || !isValid) && !file) &&
                    <FileUploadPanel onChange={handleFileUploadOnChange}
                        onError={handleFileUploadOnError} />
                }

                {(Boolean(file)) &&
                    <Box>
                        <Stack direction="column" spacing={2} alignItems="center">
                            {getFileIcon(file)}
                            <Typography>
                                {getFileTypeName(file)}
                            </Typography>
                            <Typography>
                                {file?.name}
                            </Typography>
                        </Stack>
                        <br />

                        <InputLabel id="document_type_name_label">Select Document Type:</InputLabel>
                        <Select
                            fullWidth
                            labelId='document_type_name_label'
                            value={state?.document_type_name || ''}
                            name={"document_type_name"}
                            onChange={handleChangeStateSelect}
                            required={true}
                            label={"Document Type"}
                        >
                            {FINANCIAL_DOCUMENTS?.map((currentItem, index) => {
                                return <MenuItem key={index} value={currentItem?.document_type_name}>{currentItem?.document_type_name}</MenuItem>
                            })}
                        </Select>
                    </Box>}


                <List>
                    {[...validationErrors, ...eventErrors].map((err, index) =>
                        <ListItem key={index}>
                            <ListItemAvatar>
                                <ErrorOutline color='error' />
                            </ListItemAvatar>
                            <ListItemText>
                                <Typography color="error">
                                    {err}
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    )}
                </List>

                {Boolean(fileContents?.dataItems?.length) && <>
                    <Typography variant='h1'>
                        {fileContents?.formName}
                    </Typography>
                    <DataGrid
                        rows={fileContents?.dataItems.map((row, index) => ({
                            id: index,
                            ...row,
                        }))}
                        columns={columns}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 5 } },
                        }}
                        pageSizeOptions={[5, 10, 25]}
                        autoHeight
                    />
                </>}
            </DialogContent>

            <DialogActions>
                <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap" justifyContent='flex-end'>
                    <Button variant='contained' color='secondary' autoFocus onClick={onClose}>
                        <CloseOutlined sx={{ mr: 1 }} />
                        Cancel
                    </Button>
                    <Button variant='contained' color='primary' onClick={handleSaveClick} disabled={!isValid}>
                        <SaveOutlined sx={{ mr: 1 }} />
                        Save
                    </Button>
                </Stack>
            </DialogActions>

            {confirmDialogComponent}
        </>
    )
}
