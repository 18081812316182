import { CloseOutlined, SaveOutlined } from "@mui/icons-material";
import { Button, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, Stack, Typography } from "@mui/material";
import { MASK_NUMERIC, StatefulControlledTextField } from "components/Core/StatefulControlledTextField";
import { propUpdateReducer } from "components/Core/propUpdateReducer";
import { useReducer } from "react";
import { formatNumber, formatPercentage, formatPrice } from "utility/formatters";

export default function InsuranceStressTestParametersDialog({
    initialStressTestParameters,
    budgetCountyPractice,
    countyData,

    onChange,
    onClose
}) {
    const [stressTestParameters, dispatchStressTestParameters] = useReducer(propUpdateReducer, initialStressTestParameters || countyData);
    const handleChangeStressTestParameters = (event) => {
        dispatchStressTestParameters({
            type: 'update',
            payload: { key: event?.target?.name, value: event?.target?.value },
        });
    }

    const isValid = () => {
        return false;
    }

    const handleSaveClick = () => {
        onChange && onChange(stressTestParameters);
        onClose();
    }

    return (<>
        <DialogTitle>
            Stress Test Options
        </DialogTitle>

        <DialogContent>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={6}>
                    <fieldset>
                        <legend>Farm Yields:</legend>
                        <Grid container direction="column" spacing={2}>
                            <Grid item xs={6}>
                                <FormControl>
                                    <StatefulControlledTextField
                                        label="Low Yield"
                                        defaultValue={stressTestParameters?.yield_low || 0}
                                        onChange={handleChangeStressTestParameters}
                                        name="yield_low"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        maskType={MASK_NUMERIC}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl>
                                    <StatefulControlledTextField
                                        label="High Yield"
                                        defaultValue={stressTestParameters?.yield_high || 100}
                                        onChange={handleChangeStressTestParameters}
                                        name="yield_high"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        maskType={MASK_NUMERIC}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </fieldset>
                </Grid>
                <Grid item xs={12} md={6}>
                    <fieldset>
                        <legend>Price:</legend>
                        <Grid container direction="column" spacing={2}>
                            <Grid item xs={6}>
                                <FormControl>
                                    <StatefulControlledTextField
                                        label="Low Price"
                                        defaultValue={stressTestParameters?.price_low || 0.00}
                                        onChange={handleChangeStressTestParameters}
                                        name="price_low"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        maskType={MASK_NUMERIC}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl>
                                    <StatefulControlledTextField
                                        label="High Price"
                                        defaultValue={stressTestParameters?.price_high || 10.00}
                                        onChange={handleChangeStressTestParameters}
                                        name="price_high"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        maskType={MASK_NUMERIC}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </fieldset>
                </Grid>

                <Grid item xs={12} md={6}>
                    <fieldset>
                        <legend>County Yields:</legend>
                        <Grid container direction="column" spacing={2}>
                            <Grid item xs={6}>
                                <FormControl>
                                    <StatefulControlledTextField
                                        label="Low Yield"
                                        defaultValue={stressTestParameters?.county_yield_low || 0}
                                        onChange={handleChangeStressTestParameters}
                                        name="county_yield_low"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        maskType={MASK_NUMERIC}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl>
                                    <StatefulControlledTextField
                                        label="High Yield"
                                        defaultValue={stressTestParameters?.county_yield_high || 100}
                                        onChange={handleChangeStressTestParameters}
                                        name="county_yield_high"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        maskType={MASK_NUMERIC}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </fieldset>
                </Grid>
                {budgetCountyPractice?.commodity_name == "Cotton"
                    &&
                    <Grid item xs={12} md={6}>
                        <fieldset>
                            <legend>Cottonseed SE:</legend>
                            <Grid container direction="column" spacing={2}>
                                <Grid item xs={6}>
                                    Price: {formatPrice(stressTestParameters?.projected_price_cottonseed, 4)}<br />
                                </Grid>
                            </Grid>
                        </fieldset>
                    </Grid>
                }
            </Grid>
        </DialogContent>

        <DialogActions>
            <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap" justifyContent='flex-end'>
                <Button variant='contained' color='secondary' autoFocus onClick={onClose}>
                    <CloseOutlined sx={{ mr: 1 }} />
                    Cancel
                </Button>
                <Button variant='contained' color='primary' onClick={handleSaveClick} disabled={!isValid}>
                    <SaveOutlined sx={{ mr: 1 }} />
                    Save
                </Button>
            </Stack>
        </DialogActions>

    </>)
}