import { useDialog } from '@datanac/datanac-mui-toolkit'
import EastOutlined from '@mui/icons-material/EastOutlined'
import DeleteForeverOutlined from '@mui/icons-material/DeleteForeverOutlined'
import { Box, Card, CardActions, CardContent, CardHeader, Chip, Divider, IconButton, Stack, Typography } from '@mui/material'
import { AppContext } from 'App'
import { FarmIcon } from 'components/Icons/Icons'
import { useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { formatNumber, formatPracticeName } from 'utility/formatters'
import { CROP_YEAR_POTENTIAL } from '../helpers'


export default function FieldCard({
    producerFarm,
    producerFarmField,

    onChange
}) {
    const { globalState, globalDispatch, session } = useContext(AppContext);
    const navigate = useNavigate();
    const {
        crop_year,
        location_state_name,
        location_county_name,
        fsa_farm_number,
        // fsa_tract_number,
        // fsa_field_number
    } = useParams();

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const handleDeleteOnClick = () => {
        confirmDialogActions.confirm({
            title: "Delete this entry?",
            prompt: "Are you sure you want to delete this entry?",
            promptDetail: null,
            callback: () => {
                const _newState = { ...producerFarmField };
                _newState.is_active = false;
                onChange && onChange(_newState);
            }
        })
    }

    return (
        <>
            <Card>
                <CardHeader
                    avatar={<FarmIcon />}
                    title={<Stack direction='row' spacing={1} alignItems='center' justifyItems='center'>
                        <Typography variant='body1'>
                            TRACT
                        </Typography>
                        <Typography variant='h6'>
                            {producerFarmField?.fsa_tract_number}
                        </Typography>

                        <Divider orientation='vertical' flexItem />

                        <Typography variant='body1'>
                            FIELD
                        </Typography>
                        <Typography variant='h6'>
                            {producerFarmField?.fsa_field_number}
                        </Typography>
                    </Stack>}
                    action={<IconButton onClick={handleDeleteOnClick}>
                        <DeleteForeverOutlined sx={{ color: 'black' }} />
                    </IconButton>
                    }
                />
                <CardContent>
                    <Box height="100%">
                        <Stack direction="column" spacing={1} height="100%">
                            <Stack direction='row' spacing={1} alignItems='center' justifyItems='center'>
                                <Typography variant='h6'>
                                    {formatNumber(producerFarmField?.reported_acreage, 0)}
                                </Typography>
                                <Typography variant='body1' sx={{ fontSize: '8pt', leading: '0px', lineHeight: '9pt', height: '100%' }}>
                                    ACRES
                                </Typography>
                            </Stack>
                        </Stack>
                    </Box>
                </CardContent>

                <CardActions>
                    <Stack direction='row' spacing={0} alignItems='center' justifyItems='center'
                        sx={{ flexWrap: 'wrap', gap: 1 }}
                    >
                        {producerFarmField?.CropProfiles?.map((currentCropProfile, index) =>
                            <Chip key={index} label={currentCropProfile?.commodity_name + ' ' + formatPracticeName(currentCropProfile?.practice_name)} />
                        )}
                    </Stack>

                    {crop_year != CROP_YEAR_POTENTIAL &&
                        <IconButton onClick={() => {
                            navigate(`/vault/${crop_year}/${location_state_name}/${location_county_name}/${producerFarm?.id}/${producerFarmField?.id}`)
                        }}>
                            <EastOutlined />
                        </IconButton>}
                </CardActions>
            </Card>

            {confirmDialogComponent}
        </>
    )
}
