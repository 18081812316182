import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Stack, Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import { AppContext } from 'App'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'


export default function UserGrantItemCard({
    userGrant,
    userGrantItem,
    onChange
}) {

    const { globalState, globalDispatch } = useContext(AppContext);

    const navigate = useNavigate();

    const isValid = () => {
        return (
            userGrant?.id
            && userGrantItem?.id
        );
    }
    // useBlockerPrompt(!isValid(), "UserGrantItemCard");

    const handleOnChange = (e) => {
        const _state = { ...userGrantItem };
        _state[e.target.name] = e.target.checked;
        onChange && onChange(_state);
    }

    return (
        <>
            <Paper sx={{ p: 1, pl: 2.5, pr: 2.5, backgroundColor: 'white', width: "100%" }}>
                <Grid container spacing={1} alignItems='center'>
                    <Grid item xs={11}>
                        <Stack direction='row' alignItems='center' spacing={1}>
                            {userGrantItem?.icon}
                            <Typography variant='h5' sx={{ m: 0, p: 0, mb: '0px !important' }}>
                                {userGrantItem?.title}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={1} justifyContent={'flex-end'}>
                        <Box display="flex" justifyContent="flex-end">
                            <Typography variant='body1' sx={{ display: 'inline' }}>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={10}>
                        <Stack direction='row' spacing={1} alignItems='center' justifyItems='center'>
                            <FormGroup>
                                <FormControlLabel disabled={userGrant?.is_master_read} name="is_read" control={
                                    <Checkbox checked={userGrantItem?.is_read || userGrant?.is_master_read || false} onChange={handleOnChange} />} label="View" />
                                <FormControlLabel disabled={userGrant?.is_master_write} name="is_write" control={
                                    <Checkbox checked={userGrantItem?.is_write || userGrant?.is_master_write || false} />} onChange={handleOnChange} label="Edit" />
                                {/* <FormControlLabel disabled={userGrant?.is_master_delete} name="is_delete" control={
                                    <Checkbox checked={userGrantItem?.is_delete || userGrant?.is_master_delete || false} onChange={handleOnChange} />} label="Delete" /> */}
                            </FormGroup>
                        </Stack>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}
