import { useDialog } from '@datanac/datanac-mui-toolkit'
import DeleteForeverOutlined from '@mui/icons-material/DeleteForeverOutlined'
import EastOutlined from '@mui/icons-material/EastOutlined'
import { Card, CardActions, CardContent, CardHeader, Divider, IconButton, Stack, Typography } from '@mui/material'
import { AppContext } from 'App'
import { FarmIcon } from 'components/Icons/Icons'
import { useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { formatNumber } from 'utility/formatters'

export default function CropProfileCard({
    producerFarm,
    cropProfile,
    onChange
}) {
    const { globalState, globalDispatch, session } = useContext(AppContext);
    const navigate = useNavigate();
    const {
        crop_year,
        location_state_name,
        location_county_name,
        fsa_farm_number,
        // fsa_tract_number,
        // fsa_field_number
    } = useParams();

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const handleDeleteOnClick = () => {
        confirmDialogActions.confirm({
            title: "Delete this entry?",
            prompt: "Are you sure you want to delete this entry?",
            promptDetail: null,
            callback: () => {
                const _newState = { ...cropProfile };
                _newState.is_active = false;
                onChange && onChange(_newState);
            }
        })
    }

    return (
        <>
            <Card>
                <CardHeader
                    title={cropProfile?.commodity_name
                        + " " + (cropProfile?.type_name && (cropProfile?.type_name != "No Type Specified" ? " \u2013 " + cropProfile?.type_name : ""))}
                    subheader={cropProfile?.practice_name}
                    avatar={<FarmIcon />}
                    action={
                        <IconButton onClick={handleDeleteOnClick}>
                            <DeleteForeverOutlined sx={{ color: 'black' }} />
                        </IconButton>
                    }
                />
                <CardContent>
                    <Stack direction='row' spacing={1} alignItems='center' justifyItems='center'>
                        <Typography variant='h6'>
                            {formatNumber(cropProfile?.reported_acreage, 0)}
                        </Typography>
                        <Typography variant='body1' sx={{ fontSize: '8pt', leading: '0px', lineHeight: '9pt', height: '100%' }}>
                            ACRES
                        </Typography>

                        <Divider orientation='vertical' flexItem />

                        <Typography variant='h6'>
                            {formatNumber(cropProfile?.approved_yield, 0)}
                        </Typography>
                        <Typography variant='body1' sx={{ fontSize: '8pt', leading: '0px', lineHeight: '9pt', height: '100%' }}>
                            APH<br />
                            YIELD
                        </Typography>
                    </Stack>

                </CardContent>

                <CardActions>
                    <IconButton onClick={() => {
                        navigate(`./${cropProfile?.id}`)
                    }}>
                        <EastOutlined />
                    </IconButton>
                </CardActions>
            </Card>
            {confirmDialogComponent}
        </>
    )
}
