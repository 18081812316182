import FolderCopy from '@mui/icons-material/FolderCopy';
import FolderOutlined from '@mui/icons-material/FolderOutlined';
import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { AppContext } from 'App';
import { useContext, useState } from 'react';

export default function PortfolioItem({
    producer,
    userGrant,
    onClick
}) {
    const { globalState, globalDispatch } = useContext(AppContext);

    const [drafts, setDrafts] = useState([]);

    const [expanded, setExpanded] = useState((globalState.producer_token == (producer?.producer_token || userGrant?.producer_token)));

    const handleItemOnClick = async () => {
        onClick && onClick({
            producer_token: producer?.producer_token || userGrant?.producer_token,
            agent_token: globalState?.agent_token
        });
    }

    return (<>
        <ListItem>
            <ListItemButton onClick={handleItemOnClick}
                sx={{
                    p: 2,
                    ...{ backgroundImage: (globalState.producer_token == (producer?.producer_token || userGrant?.producer_token)) && 'linear-gradient(90deg, #8FB954 0%, #D2D550 100%)' }
                }}
            >
                <ListItemIcon>
                    <FolderCopy />
                </ListItemIcon>
                <ListItemText
                    primary={(producer?.first_name && (producer?.first_name + ' ' + producer?.last_name))
                        || producer?.business_name
                        || userGrant?.producer_description}
                />
                {/* {expanded ? <ExpandLessOutlined /> : <ExpandMoreOutlined />} */}
            </ListItemButton>
        </ListItem>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {drafts.map((currentDraft, index) => (
                    <ListItemButton onClick={handleItemOnClick} key={index}
                        sx={{
                            p: 2,
                            ml: 4,
                            ...{ backgroundImage: (globalState.producer_token == (producer?.producer_token || userGrant?.producer_token)) && 'linear-gradient(90deg, #8FB954 0%, #D2D550 100%)' }
                        }}>
                        <ListItemIcon>
                            <FolderOutlined />
                        </ListItemIcon>
                        <ListItemText primary={currentDraft.name} />
                    </ListItemButton>
                ))}

                {/* <ListItemButton sx={{ ml: 4, p: 2 }} onClick={addNewLine}>
                    <ListItemIcon>
                        <CreateNewFolderOutlined />
                    </ListItemIcon>
                    <ListItemText primary='(New)' />
                </ListItemButton> */}
            </List>
        </Collapse>
    </>)
}
