import { blue, pink, grey } from '@mui/material/colors'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { datanacTheme } from 'App'

export default function ThemeProvider({ children }) {
  return (
    <MuiThemeProvider theme={datanacTheme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  )
}
